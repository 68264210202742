import React, { useState } from "react";

import { FaTimes } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

import { useGenerateContentContext } from "../../../context/generateContent";

const GenerateImage = ({
  setShowImageModal,
  setImage,
  setThumbnail,
  setThumbnailId,
}) => {
  const [prompt, setPrompt] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");

  const [allGeneratedImages, setAllGeneratedImages] = useState([]);

  const { generateImage } = useGenerateContentContext();

  const sendPrompt = async () => {
    if (!prompt || prompt === "") setIsError(true);

    setLoading(true);
    setIsError(false);

    const response = await generateImage({ data: { message: prompt } });

    setLoading(false);

    if (response) {
      setThumbnail(response?.image_kit_url);
      setThumbnailId(response?.image_kit_file_id);
      setSelected(response?.image_kit_file_id);

      const images =
        allGeneratedImages.length > 0
          ? allGeneratedImages?.map((img) => img)
          : [];

      images.push({
        image_url: response?.image_kit_url,
        image_id: response?.image_kit_file_id,
      });

      setAllGeneratedImages(images);
    }
  };

  return (
    <div className="bg-black/60 w-full h-full absolute z-[999999] overflow-y-auto top-0 left-0 flex justify-center items-start p-4">
      <div className="bg-white p-5 rounded-md w-full md:w-80 lg:w-[500px] space-y-4 overflow-y-auto">
        <div className="flex items-center justify-between border-b pb-4">
          <span className="text-base font-bold">Generate Image</span>
          <button
            onClick={() => setShowImageModal(false)}
            className="bg-black text-white p-2 rounded-md cursor-pointer text-base"
          >
            <FaTimes />
          </button>
        </div>
        <div className="text-xs py-2 space-y-2">
          <p className="text-xs font-bold">Enter Your Prompt Here:</p>
          <textarea
            rows="10"
            value={prompt}
            onChange={(e) => {
              setIsError(false);
              setPrompt(e.target.value);
            }}
            className={`border w-full text-xs rounded-md outline-none p-4 ${
              isError ? "border border-red-700" : ""
            }`}
          />
        </div>
        <p className="text-xs font-bold">Generated Result:</p>
        <div className="border text-xs p-2 rounded-md space-y-4">
          {allGeneratedImages?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {allGeneratedImages.map((image, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      setSelected(image.image_id);
                      setThumbnail(image.image_url);
                      setThumbnailId(image.image_id);
                    }}
                    className={`border-4 p-1 ${
                      selected === image.image_id
                        ? "border-primary rounded-md"
                        : ""
                    }`}
                  >
                    <img
                      key={key}
                      src={image?.image_url}
                      alt={image.image_id}
                      className="w-full h-full file:w-auto font-bold text-gray-800 cursor-pointer text-xs mx-auto file:h-10 underline file:bg-gray-800 file:border-none file:mb-4 file:mt-2 file:py-2 file:px-4 file:hover:bg-gray-600 file:text-white file:font-bold file:cursor-pointer file:text-xs file:capitalize"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="py-5 text-center text-[10px] font-semibold uppercase">
              Your generated images will appear here
            </div>
          )}
        </div>
        <button
          disabled={loading}
          onClick={sendPrompt}
          className="w-auto bg-black flex space-x-2 items-center text-xs font-bold text-white rounded-md py-2 px-4 disabled:cursor-not-allowed disabled:bg-black/60"
        >
          {loading ? <AiOutlineLoading className="animate-spin" /> : null}
          <span>Generate Image</span>
        </button>
      </div>
    </div>
  );
};

export default GenerateImage;
