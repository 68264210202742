import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  Button,
  TextArea,
  MyMessage,
  PhoneInput,
  InputField,
  DashboardIntro,
  MyActionButton,
  MyButton,
} from "../../home/component/Component";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { getOneRestaurant } from "../../../middlewares/sysNavigation/SysNavigation";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import ModalDetails from "../utils/hotelRestauDetails/ModalDetails";
import ExteriorImages from "./exteriorImages";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import Pricing from "../../home/component/Pricing";
import GenerateDescription from "../utils/GenerateDescription";

const Edit = () => {
  const { updateOneContent } = useDoSeeDataContext();
  const restaurant = getOneRestaurant();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const restaurant_id = restaurant?.restaurant_id;
  const [title, setTitle] = useState(restaurant?.name);
  const [thumbnail, setThumbnail] = useState(restaurant?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(restaurant?.image_cdn_id);
  const [openDays, setOpenDays] = useState(restaurant?.opened_days);
  const [openedAt, setOpenedAt] = useState(restaurant?.opened_at);
  const [closedAt, setClosedAt] = useState(restaurant?.closed_at);
  const [rating, setRating] = useState(restaurant?.rating);
  const [email, setEmail] = useState(restaurant?.email_address);
  const [phoneNumber, setPhoneNumber] = useState(restaurant?.phone_number);
  const [whatsapp, setWhatsapp] = useState(restaurant?.whatsapp);
  const [facebook, setFacebook] = useState(restaurant?.facebook);
  const [twitter, setTwitter] = useState(restaurant?.twitter);
  const [instagram, setInstagram] = useState(restaurant?.instagram);
  const [youtube, setYoutube] = useState(restaurant?.youtube);
  const [hashtags, setHashtags] = useState(restaurant?.hashtag);
  const [googleMapCode, setGoogleMapCode] = useState(
    restaurant?.google_map_code
  );
  const [latitude, setLatitude] = useState(restaurant?.latitude);
  const [longitude, setLongitude] = useState(restaurant?.longitude);
  const [formattedAddress, setFormattedAddress] = useState(
    restaurant?.formatted_address
  );
  const [promoted, setPromoted] = useState(
    restaurant?.promoted ? restaurant?.promoted : "no"
  );
  const [restaurantStatus, setRestaurantStatus] = useState(restaurant?.status);
  const [description, setDescription] = useState(restaurant?.description);
  const [collection, setCollection] = useState(
    restaurant?.HotelRestauDescriptions
      ? restaurant?.HotelRestauDescriptions
      : []
  );
  const [expo, setExpo] = useState(restaurant?.expo);
  const [exteriorImages, setExteriorImages] = useState(
    restaurant?.exterior_images
  );
  const [showExtImages, setShowExtImages] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(restaurant?.type);
  const [pricing, setPricing] = useState(restaurant?.Pricings);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  // Desctructuring the menu item
  // const [menu, setMenu] = useState(
  //   restaurant?.RestaurantMenus?.length > 0
  //     ? restaurant?.RestaurantMenus?.map((v) => {
  //         return {
  //           name: v?.name,
  //           description: v?.description,
  //           category: v?.category,
  //           thumbnail: v?.thumbnail,
  //           image_cdn_id: v?.image_cdn_id,
  //         };
  //       })
  //     : []
  // );

  const [menu, setMenu] = useState(
    restaurant?.RestaurantMenus ? restaurant?.RestaurantMenus?.thumbnail : null
  );
  const [menu_id, setMenuId] = useState(
    restaurant?.RestaurantMenus
      ? restaurant?.RestaurantMenus?.image_cdn_id
      : null
  );

  // const [showMenu, setShowMenu] = useState(false);

  const restaurantRoutes = "/data/restaurant";

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id ? country_id : restaurant?.country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      name: title,
      slug: title?.toLowerCase().replace(/\s/g, "_"),
      type: type ? type : "african",
      description: description,
      city_level: "level",
      opened_days: openDays,
      opened_at: openedAt,
      closed_at: closedAt,
      rating: rating,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      phone_number: phoneNumber,
      email_address: email,
      whatsapp: whatsapp,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
      hashtag: hashtags,
      audio_ad: "none",
      google_map_code: googleMapCode,
      latitude: latitude,
      longitude: longitude,
      formatted_address: formattedAddress,
      country_short: "none",
      status: restaurantStatus,
      promoted: promoted,
      subdata: collection?.length > 0 ? collection : [],
      menu: menu
        ? {
            name: "...",
            category: "...",
            description: "...",
            thumbnail: menu,
            image_cdn_id: menu_id,
          }
        : null,
      expo: expo,
      exterior_images: exteriorImages?.filter((x) => x?.thumbnail !== null),
      pricing: pricing,
    };

    const { message, status } = await updateOneContent({
      data_id: restaurant_id,
      data: data,
      content: _TYPES_.RESTAURANT,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });

    if (status === 200) responses(false, message);
    else responses(true, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: restaurantRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-3">
        <DashboardIntro
          props={{ intro: `Edit Restaurant`, name: entry_label }}
        />
      </div>

      <ModalDetails
        onClick={() => setShowModal(false)}
        showModal={showModal}
        collection={collection}
        setCollection={setCollection}
      />

      <ExteriorImages
        exteriorImages={exteriorImages}
        setExteriorImages={setExteriorImages}
        showModal={showExtImages}
        onClick={() => setShowExtImages(false)}
      />

      <form onSubmit={updateOneEventHandler}>
        <div className="mt-3 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <div className="flex space-x-5 w-full justify-around items-center">
            <div className="">
              <p className="text-xs font-bold">Profile Image</p>
              <ImageKitUpload
                thumbnail={thumbnail}
                thumbnailId={thumbnailId}
                setThumbnail={setThumbnail}
                setThumbnailId={setThumbnailId}
                folder={image_kit_folder(country_name, FOLDERS.RESTAURANTS)}
              />
            </div>
            <div>
              <p className="text-xs font-bold">Menu PDF</p>
              <ImageKitUpload
                thumbnail={menu}
                thumbnailId={menu_id}
                setThumbnail={setMenu}
                setThumbnailId={setMenuId}
                folder={image_kit_folder(country_name, FOLDERS.RESTAURANTS)}
              />
            </div>
          </div>

          {/* <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.RESTAURANTS)}
          /> */}

          <Title props={{ title: "Event Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "type",
                title: "Type",
                type: "text",
                preselect: type !== null ? [{ name: type }] : null,
                option: [
                  "african",
                  "american",
                  "south american",
                  "european",
                  "asian",
                  "oceanian",
                  "intercontinental",
                ],
                onChange: (e) => {
                  setType(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "formatted_address",
                title: "Formatted Address",
                type: "address",
                value: formattedAddress ? formattedAddress : "",
                onChange: (e) => {
                  setFormattedAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Date & Time" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "opened_days",
                title: "Open Days",
                type: "text",
                value: openDays ? openDays : "",
                onChange: (e) => {
                  setOpenDays(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "opened_at",
                title: "Opens At",
                type: "time",
                value: openedAt ? openedAt : "",
                onChange: (e) => {
                  setOpenedAt(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "closed_at",
                title: "closes At",
                type: "time",
                value: closedAt ? closedAt : "",
                onChange: (e) => {
                  setClosedAt(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "rating",
                title: "Rating",
                type: "text",
                value: rating ? rating : "",
                onChange: (e) => {
                  setRating(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Pricing" }} />

          <Pricing pricing={pricing} setPricing={setPricing} />

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phoneNumber}
                required={false}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "whatsapp",
                title: "Whatsapp",
                type: "text",
                value: whatsapp ? whatsapp : "",
                onChange: (e) => {
                  setWhatsapp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                value: facebook ? facebook : "",
                onChange: (e) => {
                  setFacebook(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                value: twitter ? twitter : "",
                onChange: (e) => {
                  setTwitter(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                value: instagram ? instagram : "",
                onChange: (e) => {
                  setInstagram(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                value: youtube ? youtube : "",
                onChange: (e) => {
                  setYoutube(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Geo Position" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "google_map_code",
                title: "Google Map Code",
                type: "text",
                value: googleMapCode ? googleMapCode : "",
                onChange: (e) => {
                  setGoogleMapCode(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "latitude",
                title: "Latitude",
                type: "text",
                value: latitude ? latitude : "",
                onChange: (e) => {
                  setLatitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "longitude",
                title: "Longitude",
                type: "text",
                value: longitude ? longitude : "",
                onChange: (e) => {
                  setLongitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "restaurant_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  restaurantStatus !== null
                    ? [
                        {
                          name:
                            restaurantStatus === 1 ? "visible" : "invisible",
                        },
                      ]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setRestaurantStatus(1);
                  else setRestaurantStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Select
              props={{
                id: "promoted",
                title: "Promoted",
                option: ["no", "yes"],
                preselect: promoted !== null ? [{ name: promoted }] : null,
                onChange: (e) => {
                  setPromoted(e.target.value);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "gallery",
                title: "Show in Gallery",
                option: ["no", "yes"],
                preselect:
                  expo !== null ? [{ name: expo === 0 ? "no" : "yes" }] : null,
                onChange: (e) => {
                  const choice = e.target.value;
                  setExpo(choice === "no" ? 0 : 1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="w-full space-x-4 flex justify-start items-center py-8">
            <Button
              props={{
                type: "button",
                title: "Change Inter. / Exter. Images",
                category: "default",
                action: "edit",
                onClick: () => setShowExtImages(true),
              }}
            />
            {/* <Button
              props={{
                type: "button",
                title: "Modify Restaurant Menu",
                category: "orange",
                action: "",
                onClick: () => setShowMenu(true),
              }}
            /> */}
            <Button
              props={{
                type: "button",
                title: "Change More Details",
                category: "default",
                action: "edit",
                onClick: () => setShowModal(true),
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
