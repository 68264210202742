import React from "react";

import { Link } from "react-router-dom";

import { FaCamera, FaMusic, FaPen, FaArrowRight } from "react-icons/fa";

import {
  Select,
  InputField,
  TextArea,
  GoBack,
  Title,
} from "../../home/component/Component";

const newsRoutes = "/data/news";
const newsEditRoute = "/data/news/edit";

const Details = () => {
  const date = new Date();
  date.getDate();
  const defaultDate = date;

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: newsRoutes }} />
      <form action="">
        <div className="mt-8 flex flex-col items-center  font-medium text-sm md:text-base">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full my-3">
            <div className="flex flex-col my-5 items-center w-full px-2 text-gray-500">
              <FaCamera className="w-[80px] h-[80px]" />
              <p className="text-xs font-bold">image </p>
            </div>
            <div className="flex flex-col my-5 items-center w-full px-2 text-gray-500">
              <FaMusic className="w-[80px] h-[80px]" />
              <p className="text-xs font-bold">Audio </p>
            </div>
          </div>

          <Title props={{ title: "Administrative Location" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full my-3">
            <Select
              props={{
                id: "country",
                title: "Country",
                option: ["Country One", "Country Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "FirstLevel",
                title: "First Level",
                option: ["First Level One", "First Level Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "SecondLevel",
                title: "Second Level",
                option: ["Second Level One", "Second Level Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "ThirdLevel",
                title: "Third Level",
                option: ["Third Level One", "Third Level Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "FourthLevel",
                title: "Fourth Level",
                option: ["Fourth Level One", "Fourth Level Two"],
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "News Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "slug",
                title: "Slug",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "Date & Time" }} />

          <div className="grid grid-cols-1 gap-4 w-full">
            <InputField
              props={{
                id: "published_on",
                title: "Published On",
                type: "datetime",
                default: defaultDate,
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <div>
              <label htmlFor="phone">Phone Number</label>
              <br />
              <div className="flex flex-row">
                <select
                  name="country_code"
                  id="country_code"
                  disabled={true}
                  className="w-[110px] mr-2 text-xs font-bold border-2 bg-white focus:border-gray-300 rounded-lg focus:outline-none h-12 px-2"
                >
                  <option value="ug">UG (+256)</option>
                  <option value="cd">CD (+243)</option>
                  <option value="rw">RW (+250)</option>
                </select>
                <input
                  id="phone"
                  type="phone"
                  className="w-full border-2 bg-white focus:border-gray-300 rounded-lg text-sm md:text-base focus:outline-none h-12 px-4"
                  defaultValue={"777777777"}
                  disabled={true}
                />
              </div>
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                default: "email@gmail.com",
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "whatsapp",
                title: "Whatsapp",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <Select
              props={{
                id: "featured",
                title: "Featured",
                option: ["Yes", "No"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "status",
                title: "Status",
                option: ["approved", "revoked", "pending"],
                disabled: true,
              }}
            />
          </div>

          <TextArea
            props={{
              id: "description",
              title: "Description",
              type: "text",
              default:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet molestias blanditiis quam quos assumenda quia, temporibus est? Dolores fugiat eaque consequuntur maxime nesciunt, beatae, consequatur tempora iste laborum harum id?",
              disabled: true,
            }}
          />

          <Link to={newsEditRoute}>
            <button
              type="button"
              className="flex flex-row items-center justify-between px-6 py-4 my-10 w-full bg-blue-500 text-white font-bold leading-tight uppercase rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out text-center"
            >
              <div className="flex flex-row items-center justify-start">
                <FaPen className="mx-3" />
                Edit News
              </div>
              <FaArrowRight className="mx-2" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Details;
