/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { VscFileSubmodule } from "react-icons/vsc";
import { BsBook, BsPeople } from "react-icons/bs";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import {
  MdMusicNote,
  MdOutlineMuseum,
  MdOutlineFoodBank,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { SiHotelsdotcom } from "react-icons/si";
import { AiOutlineShopping } from "react-icons/ai";
import { BiCameraMovie } from "react-icons/bi";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { Card, GoBack, DashboardIntro } from "../../component/Component";
import { useStaticContext } from "../../../../context/static/StaticContext";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { getOneCompany } from "../../../../middlewares/sysNavigation/SysNavigation";
import { _TYPES_ } from "../../../doseeData/utils/types";

const Icon = [
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
];

const companiesRoute = "/company";

const CompanyDash = () => {
  const { setSingleAdmin } = useAdminContext();
  const { stats, getCompanyStats } = useStaticContext();
  const company = getOneCompany();
  const company_name = company?.name;
  const company_owner = company?.owner_id;

  useEffect(() => {
    if (!stats) {
      getCompanyStats({ data: { ids: [company_owner] } });
    }
  }, [stats]);

  const List = [
    {
      name: "events",
      quantity: stats ? stats?.event : "-",
      route: "/data/event",
      type: _TYPES_.EVENT,
    },
    {
      name: "attractions",
      quantity: stats ? stats?.attraction : "-",
      route: "/data/attraction",
      type: _TYPES_.ATTRACTION,
    },
    {
      name: "restaurants",
      quantity: stats ? stats?.restaurant : "-",
      route: "/data/restaurant",
      type: _TYPES_.RESTAURANT,
    },
    {
      name: "best of africa",
      quantity: stats ? stats?.best_of_africa : "-",
      route: "/data/bestofafrica",
      type: _TYPES_.BESTOFAFRICA,
    },
    {
      name: "music",
      quantity: stats ? stats?.music : "-",
      route: "/data/music",
      type: _TYPES_.MUSIC,
    },
    {
      name: "news",
      quantity: stats ? stats?.news : "-",
      route: "/data/news",
      type: _TYPES_.NEWS,
    },
    {
      name: "safaris",
      quantity: stats ? stats?.safari : "-",
      route: "/data/safari",
      type: _TYPES_.SAFARI,
    },
    {
      name: "tour packages",
      quantity: stats ? stats?.tour_package : "-",
      route: "/data/tour/package",
      type: _TYPES_.TOURPACKAGE,
    },
    {
      name: "cinema",
      quantity: stats ? stats?.cinema : "-",
      route: "/data/cinema",
      type: _TYPES_.CINEMA,
    },
    {
      name: "museums",
      quantity: stats ? stats?.museum : "-",
      route: "/data/museum",
      type: _TYPES_.MUSEUM,
    },
    {
      name: "night life",
      quantity: stats ? stats?.nightlife : "-",
      route: "/data/nightlife",
      type: _TYPES_.NIGHTLIFE,
    },
    {
      name: "hotels",
      quantity: stats ? stats?.hotel : "-",
      route: "/data/hotel",
      type: _TYPES_.HOTEL,
    },
    {
      name: "classes",
      quantity: stats ? stats?.class : "-",
      route: "/data/class",
      type: _TYPES_.CLASSES,
    },
    {
      name: "shopping",
      quantity: stats ? stats?.shopping : "-",
      route: "/data/shopping",
      type: _TYPES_.SHOPPING,
    },

    {
      name: "food",
      quantity: stats ? stats?.food : "-",
      route: "/data/food",
      type: _TYPES_.FOOD,
    },

    {
      name: "books",
      quantity: stats ? stats?.book : "-",
      route: "/data/book",
      type: _TYPES_.BOOK,
    },
    {
      name: "neighborhoods",
      quantity: stats ? stats?.neighborhood : "-",
      route: "/data/neighborhood",
      type: _TYPES_.NEIGHBORHOOD,
    },
    {
      name: "arts",
      quantity: stats ? stats?.art : "-",
      route: "/data/art",
      type: _TYPES_.ART,
    },
    {
      name: "dance",
      quantity: stats ? stats?.dance : "-",
      route: "/data/dance",
      type: _TYPES_.DANCE,
    },
    {
      name: "theaters",
      quantity: stats ? stats?.theater : "-",
      route: "/data/theater",
      type: _TYPES_.THEATER,
    },
    {
      name: "City Guides",
      quantity: stats ? stats?.city_guide : "-",
      route: "/data/city/guide",
      type: _TYPES_.CITYGUIDE,
    },
    {
      name: "Parties",
      quantity: stats ? stats?.party : "-",
      route: "/data/party",
      type: _TYPES_.PARTY,
    },
    {
      name: "Things To Do",
      quantity: stats ? stats?.things_to_do : "-",
      route: "/data/things/todo",
      type: _TYPES_.THINGSTODO,
    },
    {
      name: "Tips And Advice",
      quantity: stats ? stats?.tips_and_advice : "-",
      route: "/data/tips/advice",
      type: _TYPES_.TIPSANDADVICE,
    },

    {
      name: "Ministries",
      quantity: stats ? stats?.ministry : "-",
      route: "/data/ministry",
      type: _TYPES_.MINISTRY,
    },
    {
      name: "Movies",
      quantity: stats ? stats?.movie : "-",
      route: "/data/movie",
      type: _TYPES_.MOVIE,
    },
    {
      name: "Getting Around",
      quantity: stats ? stats?.getting_around : "-",
      route: "/data/get/around",
      type: _TYPES_.GETTINGAROUND,
    },
  ];

  return (
    <div className="overflow-y-auto p-3 lg:p-4 h-full rounded-b-xl bg-gray-50 pb-5">
      <GoBack props={{ route: companiesRoute }} />
      <div className="flex flex-row justify-center items-center my-4">
        <DashboardIntro props={{ intro: `Company`, name: company_name }} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900 mb-5 mt-4">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                setSingleAdmin({
                  ids: [company_owner],
                  type: card?.type,
                  route: "/company/dashboard",
                });
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CompanyDash;
