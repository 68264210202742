import React from "react";
import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { PageProvider } from "../../../../context/page/PageContext";

export const Page = () => {
  return (
    <PageProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Page"
          />
        }
      />
    </PageProvider>
  );
};

export const AddPage = () => {
  return (
    <PageProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Page"
          />
        }
      />
    </PageProvider>
  );
};

export const EditPage = () => {
  return (
    <PageProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Page"
          />
        }
      />
    </PageProvider>
  );
};
