import React, { useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { codeOk, errorMessage } from "../../../../middlewares/index";
import { useUserContext } from "../../../../context/user/UserContext";
import { AiOutlineLoading, AiOutlineCloseCircle } from "react-icons/ai";
import {
  Title,
  GoBack,
  InputField,
  PhoneInput,
} from "../../component/Component";

const Add = () => {
  const { createOneUser } = useUserContext();

  const [error, setError] = useState(false);
  const [status, setStatus] = useState(0);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(0);
  const [phone, setPhone] = useState(0);
  const [password, setPassword] = useState(0);

  const userRoutes = "/user";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);

    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPhone(null);
    setPassword(null);
  };

  const responses = (isError, status, message) => {
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  const addOneTourPackageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!firstName || firstName === "") {
      responses(true, 400, "First Name is required");
    } else if (!lastName || lastName === "") {
      responses(true, 400, "Last Name is required");
    } else if (!email || email === "") {
      responses(true, 400, "Email is required");
    } else if (!phone || phone === "") {
      responses(true, 400, "Phone is required");
    } else if (!password || password === "") {
      responses(true, 400, "Password is required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} {}
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      password: password,
    };

    const response = await createOneUser(data);
    // console.log(response);
    if (response) {
      // console.log('success uploading');
      if (response.data.status === codeOk) {
        // Clean all filds that were holding data
        cleanFields();
        return responses(false, response.data.status, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.status, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: userRoutes }} />
      <form onSubmit={addOneTourPackageHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Title props={{ title: "User Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "First Name",
                type: "text",
                value: firstName ? firstName : "",
                onChange: (e) => {
                  setFirstName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "title",
                title: "Last Name",
                type: "text",
                value: lastName ? lastName : "",
                onChange: (e) => {
                  setLastName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <PhoneInput
              setPhone={setPhone}
              phone={phone}
              required={true}
            />

            <InputField
              props={{
                id: "password",
                title: "Password",
                type: "password",
                value: password ? password : "",
                onChange: (e) => {
                  setPhone(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          {error || success ? (
            <div
              className={
                "flex flex-row items-center justify-between w-full bg-white font-bold rounded-md shadow-md h-16 md:h-[80px] cursor-pointer mt-3 " +
                +(status === codeOk
                  ? " bg-green-400 text-green-900"
                  : " bg-red-400 text-red-900")
              }
              onClick={() => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              }}
            >
              <div className="flex flex-row items-center justify-start space-x-3 w-full h-full">
                <div
                  className={
                    "rounded-l-md w-2  h-full " +
                    (status === codeOk ? "bg-green-400" : "bg-red-400")
                  }
                ></div>

                {status === codeOk ? (
                  <BsCheckCircle className="w-9 h-9 text-green-400" />
                ) : (
                  <AiOutlineCloseCircle className="w-9 h-9 text-red-400" />
                )}

                <div className="pr-3">
                  <p className="text-gray-700 text-base font-bold">
                    {status === codeOk ? "Success" : "Error"}
                  </p>
                  <p className="text-xs text-gray-400 font-medium text-justify">
                    {message}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-center items-center border-l-[1px] border-gray-300 w-[80px] h-full cursor-pointer text-gray-300 hover:text-gray-500">
                <p className="text-sm uppercase">Close</p>
              </div>
            </div>
          ) : (
            <span className="hidden"></span>
          )}
          {loading ? (
            <button
              type="button"
              className="flex items-center justify-center px-6 py-4 my-10 w-full md:w-3/12 bg-blue-400 text-white font-bold leading-tight uppercase rounded-lg shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-400 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition email-150 ease-in-out text-center"
              // onClick={() => {
              //   setLoading(false);
              // }}
            >
              <AiOutlineLoading className="w-7 h-7 animate-spin" />
            </button>
          ) : (
            <button
              type="submit"
              className="px-6 py-4 my-10 w-full md:w-3/12 bg-blue-500 text-white font-bold leading-tight uppercase rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition email-150 ease-in-out text-center"
            >
              Add User
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Add;
