import React, { createContext, useContext } from "react";
import Service from "../../api/service";

const GenerateContentContext = createContext();

export const useGenerateContentContext = () =>
  useContext(GenerateContentContext);

export const GenerateContentProvider = (props) => {
  /**
   * Generate AI Content
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const generateContent = async ({ data }) => {
    const response = await Service.post(`/api/v1/content-generator`, data);
    if (response) return response?.data?.data;
    return null;
  };

  /**
   * Generate AI Image
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const generateImage = async ({ data }) => {
    const response = await Service.post(`/api/v1/image-generator`, data);
    if (response) return response?.data?.data;
    return null;
  };

  /**
   * Generate full content
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const generateFullContentData = async ({ data }) => {
    const response = await Service.post(`/api/v1/ai-data-generator`, data);
    if (response) return response?.data?.data;
    return null;
  };

  return (
    <GenerateContentContext.Provider
      value={{ generateContent, generateImage, generateFullContentData }}
    >
      {props.children}
    </GenerateContentContext.Provider>
  );
};
