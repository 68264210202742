/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { _PAGE_SIZE_ } from "../../../middlewares/other/tools";
import { MySearch } from "../../home/component/searchUtils";
import { MyButton } from "../../home/component/ButtonUtils";
import { Pagination } from "../../home/component/Pagination";
import { Modal } from "../../home/component/ModalUtils";
import { pushOneProduct } from "../../../middlewares/sysNavigation/SysNavigation";
import { GoBack } from "../../home/component/GoBackUtils";
import { _TYPES_ } from "../utils/types";
import { useGeoPositionContext } from "../utils/geoPosition";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { DashboardIntro } from "../../home/component/DashboardIntroUtils";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";

const addRoute = "/data/product/add";
const editRoute = "/data/product/edit";
const doseeDataRoutes = "/dosee/data";

const Overview = () => {
  const { products, productsCount, fetchDoSeeData } = useDoSeeDataContext();
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  const [localSearch, setLocalSearch] = useState([]);

  const {
    country_id,
    entry_label,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const data_request_body = {
    country_id,
    first_level_id,
    second_level_id,
    third_level_id,
    fourth_level_id,
    content: _TYPES_.PRODUCT,
  };

  useEffect(() => {
    if (!products) fetchDoSeeData(data_request_body, page, pageSize);
    else setProductList(products);
  }, [products]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (products) {
      products.map((prod) => {
        if (prod?.name.toString().toLowerCase().includes(key))
          return searchArray.push(prod);
        return true;
      });
    }

    setProductList(searchArray);
    if (key === "") setProductList(products);
  };

  const LocalData = localSearch?.length > 0 ? localSearch : productList;

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center space-y-4">
        <BreadCrumbComponent />
        <GoBack props={{ route: doseeDataRoutes }} />
        <div className="my-3">
          <DashboardIntro props={{ intro: `Products`, name: entry_label }} />
        </div>
        <MySearch
          type={_TYPES_.PRODUCT}
          value={localSearch}
          showGlobalSearch={true}
          setValue={setLocalSearch}
          onChange={(e) => SearchList(e)}
        />

        <div className="w-full flex justify-end my-5">
          {country_id ? (
            <Link to={addRoute}>
              <MyButton
                title="Add Product"
                className="py-3 px-8 bg-blue-900 hover:bg-blue-600"
                onClick={() => {}}
              />
            </Link>
          ) : null}
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/4">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Category</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Price</th>
                <th className="border text-center w-1/4">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {products || LocalData ? (
                LocalData?.map((prod, key) => (
                  <tr key={key}>
                    <td className="border px-2 lg:px-4 w-1/4">{prod?.name}</td>
                    <td className="border px-2 lg:px-4 w-1/4">
                      {prod?.category}
                    </td>
                    <td className="border px-2 lg:px-4 w-1/4">{prod?.price}</td>
                    <td className="border w-1/4">
                      <ActionButton props={prod} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border w-1/4">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={productsCount}
          callbackFunction={(p, size) => {
            fetchDoSeeData(data_request_body, p, size);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { products, setDataState, deleteOneContent } = useDoSeeDataContext();

  const [showModal, setShowModal] = useState(false);

  const product_id = props?.product_id;
  const product_name = props?.name;
  const prod = props;

  const deleteOneProductById = async (product_id) => {
    const { status } = await deleteOneContent({
      data_id: product_id,
      content: _TYPES_.PRODUCT,
    });
    if (status === 200) {
      setDataState({
        content: _TYPES_.PRODUCT,
        data: products?.filter((prod) => prod?.product_id !== product_id),
      });
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        props={{
          name: product_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneProductById(product_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-3 px-8 bg-blue-800 hover:bg-blue-600`}
            onClick={() => pushOneProduct(prod)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-3 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
