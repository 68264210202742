/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../../../../context/dashboard/context/DashboardContext";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";

const TableStats = () => {
  const { sysAdmin } = useAdminContext();
  const { table, getTableData } = useDashboardContext();
  const [localTable, setLocalTable] = useState(null);

  useEffect(() => {
    if (!table) getTableData();
    else setLocalTable(table);
  }, [table]);

  const role = sysAdmin?.role;
  const isMainRole = () => ["super_admin", "admin"].includes(role);

  return (
    <div className="w-full min-w-fit">
      <div className="overflow-hidden overflow-x-auto overflow-y-auto h-[200px] mt-10 mb-2 selection:text-white selection:bg-green-700">
        <div className="relative">
          <table className="absolute table-auto border-collapse border border-gray-300 text-base text-left">
            <thead className="font-bold">
              <tr className="space-x-2 text-xs">
                <th className="border p-2 lg:px-4 min-w-[160px]">Regions</th>
                {isMainRole() ? (
                  <>
                    <th className="border p-2 lg:px-4">Countries</th>
                    <th className="border p-2 lg:px-4">First/Levels</th>
                    <th className="border p-2 lg:px-4">Second/Levels</th>
                    <th className="border p-2 lg:px-4">Third/Levels</th>
                    <th className="border p-2 lg:px-4">Fourth/Levels</th>
                  </>
                ) : null}
                <th className="border p-2 lg:px-4">Events</th>
                <th className="border p-2 lg:px-4">Attractions</th>
                <th className="border p-2 lg:px-4">News</th>
                <th className="border p-2 lg:px-4">Museums</th>
                <th className="border p-2 lg:px-4">Restaurants</th>
                <th className="border p-2 lg:px-4">Night/Life</th>
                <th className="border p-2 lg:px-4">Hotels</th>
                <th className="border p-2 lg:px-4">Classes</th>
                <th className="border p-2 lg:px-4">Shopping/Centers</th>
                <th className="border p-2 lg:px-4">Foods</th>
                <th className="border p-2 lg:px-4">Books</th>
                <th className="border p-2 lg:px-4">Neighborhoods</th>
                <th className="border p-2 lg:px-4">Arts</th>
                <th className="border p-2 lg:px-4">Movies</th>
                <th className="border p-2 lg:px-4">Dances</th>
                <th className="border p-2 lg:px-4">Theaters</th>
                <th className="border p-2 lg:px-4">Best/Of/Africa</th>
                <th className="border p-2 lg:px-4">Cinema</th>
                <th className="border p-2 lg:px-4">City/Guide</th>
                <th className="border p-2 lg:px-4">Getting/Around</th>
                <th className="border p-2 lg:px-4">Ministries</th>
                <th className="border p-2 lg:px-4">Musics</th>
                <th className="border p-2 lg:px-4">Parties</th>
                <th className="border p-2 lg:px-4">Safaris</th>
                <th className="border p-2 lg:px-4">Things/ToDo</th>
                <th className="border p-2 lg:px-4">Tips/And/Advices</th>
                <th className="border p-2 lg:px-4">Tour/Packages</th>
                <th className="border p-2 lg:px-4">Products</th>
              </tr>
            </thead>
            <tbody className="space-x-1 text-sm text-center border-gray-300">
              <Content
                title={"East Africa"}
                data={localTable ? localTable.EastAfrica : null}
              />
              <Content
                title={"Central Africa"}
                data={localTable ? localTable.CentralAfrica : null}
              />
              <Content
                title={"North Africa"}
                data={localTable ? localTable.NorthAfrica : null}
              />
              <Content
                title={"West Africa"}
                data={localTable ? localTable.WestAfrica : null}
              />
              <Content
                title={"Southern Africa"}
                data={localTable ? localTable.SouthernAfrica : null}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Content = ({ title, data }) => {
  const { sysAdmin } = useAdminContext();
  const isMainRole = () => {
    return sysAdmin?.role === "super_admin" ? true : false;
  };
  return (
    <tr>
      <td className="border px-4 text-left">{title}</td>
      {isMainRole() ? (
        <>
          <td className="border px-4">{data ? data?.country : 0}</td>
          <td className="border px-4">{data ? data?.first_level : 0}</td>
          <td className="border px-4">{data ? data?.second_level : 0}</td>
          <td className="border px-4">{data ? data?.third_level : 0}</td>
          <td className="border px-4">{data ? data?.fourth_level : 0}</td>
        </>
      ) : null}
      <td className="border px-4">{data ? data?.event : 0}</td>
      <td className="border px-4">{data ? data?.attraction : 0}</td>
      <td className="border px-4">{data ? data?.news : 0}</td>
      <td className="border px-4">{data ? data?.museum : 0}</td>
      <td className="border px-4">{data ? data?.restaurant : 0}</td>
      <td className="border px-4">{data ? data?.nightlife : 0}</td>
      <td className="border px-4">{data ? data?.hotel : 0}</td>
      <td className="border px-4">{data ? data?.class : 0}</td>
      <td className="border px-4">{data ? data?.shopping : 0}</td>
      <td className="border px-4">{data ? data?.food : 0}</td>
      <td className="border px-4">{data ? data?.book : 0}</td>
      <td className="border px-4">{data ? data?.neighborhood : 0}</td>
      <td className="border px-4">{data ? data?.art : 0}</td>
      <td className="border px-4">{data ? data?.movie : 0}</td>
      <td className="border px-4">{data ? data?.dance : 0}</td>
      <td className="border px-4">{data ? data?.theater : 0}</td>
      <td className="border px-4">{data ? data?.best_of_africa : 0}</td>
      <td className="border px-4">{data ? data?.cinema : 0}</td>
      <td className="border px-4">{data ? data?.city_guide : 0}</td>
      <td className="border px-4">{data ? data?.getting_around : 0}</td>
      <td className="border px-4">{data ? data?.ministry : 0}</td>
      <td className="border px-4">{data ? data?.music : 0}</td>
      <td className="border px-4">{data ? data?.party : 0}</td>
      <td className="border px-4">{data ? data?.safari : 0}</td>
      <td className="border px-4">{data ? data?.things_to_do : 0}</td>
      <td className="border px-4">{data ? data?.tips_and_advice : 0}</td>
      <td className="border px-4">{data ? data?.tour_package : 0}</td>
      <td className="border px-4">{data ? data?.product : 0}</td>
    </tr>
  );
};

export default TableStats;
