import React from "react";

import { Auth } from "../../../../context/admin/Authentication";
import Edit from "./Edit";

import Screen from "../Screen";
import Overview from "./Overview";
import { InfoProvider } from "../../../../context/info/InfoContext";

export const About = () => {
  return (
    <InfoProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="About"
          />
        }
      />
    </InfoProvider>
  );
};

export const EditAbout = () => {
  return (
    <InfoProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit About"
          />
        }
      />
    </InfoProvider>
  );
};
