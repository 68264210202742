/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SearchList } from "../../component/Functions";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { useCompanyContext } from "../../../../context/company/companyContext";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { pushOneCompany } from "../../../../middlewares/sysNavigation/SysNavigation";
import {
  Modal,
  MyButton,
  MySearch,
  Pagination,
} from "../../component/Component";

const Overview = () => {
  const [page, setPage] = useState(1);
  const {
    sysAdmin: { role },
  } = useAdminContext();
  const [companiesList, setCompanies] = useState([]);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);
  const { companies, getAll, count } = useCompanyContext();

  useEffect(() => {
    if (!companies) getAll(page, pageSize);
    else setCompanies(companies);
  }, [companies]);

  return (
    <div className="flex flex-col p-3 space-y-2 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <MySearch
          onChange={(e) =>
            setCompanies(SearchList(e?.target?.value, "name", companies))
          }
        />

        <div className="w-full flex justify-end my-5">
          {["admin", "super_admin"].includes(role) ? (
            <Link to={"/company/add"}>
              <MyButton
                title="Add New Company"
                className="bg-gray-800 hover:bg-gray-600"
                onClick={() => {}}
              />
            </Link>
          ) : null}
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 font-bold w-1/3">Name</th>
                <th className="border p-2 font-bold w-1/3">Website</th>
                <th className="border px-2 w-1/3 text-center">Operations</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {companiesList && companiesList?.length > 0 ? (
                companiesList?.map((cmp) => {
                  return (
                    <tr key={cmp?.company_id}>
                      <td className="border px-2 w-1/3">{cmp?.name}</td>
                      <td className="border px-2 w-1/3">{cmp?.website}</td>
                      <td className="border px-2 w-1/3">
                        <CallToAction
                          company={cmp}
                          name={cmp?.name}
                          company_id={cmp?.company_id}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 w-1/3">-</td>
                  <td className="border px-2 w-1/3">-</td>
                  <td className="border px-2 w-1/3">-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          total_items={count}
          setPageSize={setPageSize}
          callbackFunction={(p, size) => getAll(p, size)}
        />
      </div>
    </div>
  );
};

/**
 * @author Ntavigwa Bashombe
 * @param { company_id, name, company }
 * @returns
 */
const CallToAction = ({ company_id, name, company }) => {
  const [showModal, setShowModal] = useState(false);
  const { companies, setCompany, deleteCompany, setCompanies } =
    useCompanyContext();

  // Delete a company by company ID
  const deleteOneCompanyById = async (company_id) => {
    const { status } = await deleteCompany(company_id, company?.owner_id);
    if (status === 200) {
      setShowModal(false);
      setCompanies(
        companies
          ? companies?.filter((cmp) => cmp?.company_id !== company_id)
          : null
      );
    }
  };

  return (
    <>
      <Modal
        props={{
          name: name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneCompanyById(company_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={"/company/view"}>
          <MyButton
            className="bg-green-800 hover:bg-green-600 w-full relative"
            onClick={() => pushOneCompany(company)}
          >
            View
          </MyButton>
        </Link>
        <Link to={"/company/dashboard"}>
          <MyButton
            className="bg-blue-800 hover:bg-blue-600 w-full relative"
            onClick={() => pushOneCompany(company)}
          >
            Dashboard
          </MyButton>
        </Link>
        <Link to={"/company/edit"}>
          <MyButton
            title="Edit"
            className="bg-gray-800 hover:bg-gray-600"
            onClick={() => {
              pushOneCompany(company);
              setCompany(null);
            }}
          />
        </Link>
        <MyButton
          title="Delete"
          className="bg-red-800 hover:bg-red-600 min-w-fit"
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
