export const roles = (role) => {
  if (["super_admin", "admin"].includes(role)) {
    return [
      "admin",
      "content admin",
      "content creator",
      "company owner",
      "system admin",
    ];
  } else if (["company_owner"].includes(role)) {
    return [
      "company_owner",
      "system admin",
      "content admin",
      "content creator",
    ];
  } else if (["system_admin"].includes(role)) {
    return ["system admin", "content admin", "content creator"];
  } else {
    return ["content creator"];
  }
};
