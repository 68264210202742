/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useCountryContext } from "../../../../../context/country/countryContext/CountryContext";
import {
  Title,
  GoBack,
  Select,
  TextArea,
  MyMessage,
  InputField,
  SelectInput,
  MyActionButton,
  MyButton,
} from "../../../component/Component";
import { getAddLevel } from "../../../../../middlewares/sysNavigation/SysNavigation";
import { validateFields } from "../../../../../controllers/levels/levelsControllers";
import { defaultImage } from "../../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS, image_kit_folder } from "../../../../doseeData/utils/Utils";
import { useBaseLevelContext } from "../../../../../context/levels/BaseLevelContext";
import GenerateDescription from "../../../../doseeData/utils/GenerateDescription";

const levelsRoute = "/levels";

const Add = () => {
  const { getLevelsByCountryId, create } = useBaseLevelContext();

  const { countries, fetchCountries } = useCountryContext();

  const [firstLevels, setFirstLevels] = useState(null);
  const [secondLevels, setSecondLevels] = useState(null);
  const [thirdLevels, setThirdLevels] = useState(null);

  const [isCity, setIsCity] = useState(0);
  const [countryId, setCountryId] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [thirdLevelId, setThirdLevelId] = useState(null);
  const [firstLevelId, setFirstLevelId] = useState(null);
  const [secondLevelId, setSecondLevelId] = useState(null);

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");

  const [gdp, set_gdp] = useState(null);
  const [levelName, set_levelName] = useState(null);
  const [population, set_population] = useState(null);
  const [description, set_description] = useState(null);

  const [tourism, set_tourism] = useState("safe");
  const [safety_indicator, set_safety_indicator] = useState("safe");

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const label = getAddLevel() ? getAddLevel() : "First";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setThumbnail(null);
    setThumbnailId(null);
    set_gdp(null);
    set_levelName(null);
    set_population(null);
    set_description(null);
  };

  useEffect(() => {
    if (!countries) fetchCountries();
  }, [countries]);

  const responses = (isError, message) => {
    setError(isError);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const sendAddCountryRequest = async (e) => {
    e.preventDefault();

    setLoading(true);

    const validation = validateFields(
      gdp,
      levelName,
      population,
      tourism,
      safety_indicator,
      description
    );

    if (validation.isError) {
      setLoading(false);
      return responses(validation.isError, validation.message);
    } else {
      if (!thumbnail || !thumbnailId) {
        setLoading(false);
        return responses(true, "Image Required");
      } else {
        await sendDBRequest();
      }
    }
  };

  const fetchAllLevelsByCountryId = async (countryId) => {
    const first = await getLevelsByCountryId({
      level: "first",
      country_id: countryId,
      page: 1,
      pageSize: 250,
    });
    const second = await getLevelsByCountryId({
      level: "second",
      country_id: countryId,
      page: 1,
      pageSize: 250,
    });
    const third = await getLevelsByCountryId({
      level: "third",
      country_id: countryId,
      page: 1,
      pageSize: 250,
    });

    if (first?.data) setFirstLevels(first?.data);
    if (second?.data) setSecondLevels(second?.data);
    if (third?.data) setThirdLevels(third?.data);
  };

  /**
   * Sending DB request to DO SEE AFRICA Database
   * @returns
   */
  const sendDBRequest = async () => {
    const dataObject = {
      country_id: countryId,
      name: levelName,
      slug: levelName.toLowerCase().replace(/\s/g, "_"),
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "x",
      description: description,
      population: population,
      is_city: isCity,
      main_city: levelName,
      gdp: gdp,
      tourism: tourism,
      safety_indicator: safety_indicator,
      status: 1,
    };

    if (label === "First") {
      dataObject.first_level_label = levelName;
    } else if (label === "Second") {
      dataObject.first_level_id = firstLevelId;
      dataObject.second_level_label = levelName;
    } else if (label === "Third") {
      dataObject.first_level_id = firstLevelId;
      dataObject.second_level_id = secondLevelId;
      dataObject.third_level_label = levelName;
    } else if (label === "Fourth") {
      dataObject.first_level_id = firstLevelId;
      dataObject.second_level_id = secondLevelId;
      dataObject.third_level_id = thirdLevelId;
      dataObject.fourth_level_label = levelName;
    }

    setLoading(true);
    const { message, status } = await create({
      data: dataObject,
      level: String(label).toLowerCase(),
    });

    setLoading(false);

    if (status === 200 || status === 201) {
      cleanFields();
      return responses(false, message);
    } else {
      return responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack
        props={{
          route: `${levelsRoute}/${label.toString().toLowerCase()}`,
        }}
      />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={set_description}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <form onSubmit={sendAddCountryRequest}>
        <div className="mt-8 flex flex-col items-center  font-medium text-sm md:text-base">
          <Title props={{ title: `Add ${label} Level.` }} />
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />
          {countries && countryId ? (
            <ImageKitUpload
              thumbnail={thumbnail}
              thumbnailId={thumbnailId}
              setThumbnail={setThumbnail}
              setThumbnailId={setThumbnailId}
              folder={image_kit_folder(countryName, FOLDERS.LEVELS)}
            />
          ) : (
            <div className="text-sm text-blue-500 font-bold border border-gray-200 p-4 rounded-xl">
              Please, <br /> Select a country first, <br /> then, you can add an
              image
            </div>
          )}

          <Title props={{ title: "Level Details" }} />
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 py-4 w-full">
            <SelectInput
              options={
                countries
                  ? [
                      "Select a Country",
                      ...countries?.map((country) => country?.name),
                    ]
                  : []
              }
              title={"Countries"}
              onChange={(e) => {
                const country = countries?.find(
                  (country) => country?.name === e.target.value
                );
                setCountryId(country?.country_id);
                setCountryName(country?.name);
                setFirstLevels(null);
                setSecondLevels(null);
                setThirdLevels(null);
                fetchAllLevelsByCountryId(country?.country_id);
              }}
            />
            {["Second", "Third", "Fourth"].includes(label) &&
            countryId &&
            firstLevels ? (
              <SelectInput
                options={
                  firstLevels
                    ? [
                        "Select a first level",
                        ...firstLevels?.map((first) => first?.name),
                      ]
                    : []
                }
                title={"First Levels"}
                onChange={(e) => {
                  const id = firstLevels?.find(
                    (first) => first?.name === e.target.value
                  )?.first_level_id;
                  setFirstLevelId(id);
                }}
              />
            ) : null}

            {["Third", "Fourth"].includes(label) &&
            countryId &&
            secondLevels ? (
              <SelectInput
                options={
                  secondLevels
                    ? [
                        "Select a second level",
                        ...secondLevels?.map((second) => second?.name),
                      ]
                    : []
                }
                title={"Second Levels"}
                onChange={(e) => {
                  const id = secondLevels?.find(
                    (second) => second?.name === e.target.value
                  )?.second_level_id;
                  setSecondLevelId(id);
                }}
              />
            ) : null}

            {["Fourth"].includes(label) && countryId && thirdLevels ? (
              <SelectInput
                options={
                  thirdLevels
                    ? [
                        "Select a third level",
                        ...thirdLevels?.map((third) => third?.name),
                      ]
                    : []
                }
                title={"Third Levels"}
                onChange={(e) => {
                  const id = thirdLevels?.find(
                    (third) => third?.name === e.target.value
                  )?.third_level_id;
                  setThirdLevelId(id);
                }}
              />
            ) : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 w-full mt-3">
            <InputField
              props={{
                id: "levelName",
                title: `${label} Level Name`,
                type: "text",
                required: true,
                value: levelName ? levelName : "",
                onChange: (e) => {
                  set_levelName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "isCity",
                title: "Is It a City?",
                required: true,
                option: ["no", "yes"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) {
                    setIsCity(0);
                  } else {
                    setIsCity(1);
                  }
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "population",
                title: "Population",
                type: "number",
                required: true,
                value: population ? population : "",
                onChange: (e) => {
                  set_population(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "gdp",
                title: "GDP",
                type: "number",
                required: true,
                value: gdp ? gdp : "",
                onChange: (e) => {
                  set_gdp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  set_description(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Safety and Security" }} />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "tourism",
                title: "Tourism",
                required: true,
                option: ["safe", "intermediate", "danger"],
                onChange: (e) => {
                  set_tourism(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "safety_indicator",
                title: "Safety Indicator",
                required: true,
                option: ["safe", "intermediate", "danger"],
                onChange: (e) => {
                  set_safety_indicator(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title={`Add ${label} Level`}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
