import React from "react";

const MonthPicker = ({
  setTotal,
  currentYear,
  currentMonth,
  loadStatistics,
  setCurrentYear,
  setCurrentMonth,
  setLocalSetStats,
}) => {
  const YEARS = Array.from({ length: 4 }).map(
    (x, i) => new Date().getFullYear() - 3 + i
  );
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="text-sm text-black uppercase flex flex-col justify-center space-y-5">
      <div className="flex flex-col space-y-2">
        <p className="font-bold text-base">Select A Year</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {YEARS.map((year, key) => {
            return (
              <div
                key={key}
                onClick={() => {
                  setLocalSetStats(null);
                  setTotal(0);
                  setCurrentYear(year);
                  loadStatistics({
                    year,
                    month: currentMonth,
                  });
                }}
                className={`text-sm text-center font-bold py-2 px-4 cursor-pointer ${
                  currentYear === year
                    ? "bg-gray-800 text-white"
                    : "border-2 border-gray-800 text-black hover:bg-gray-800 hover:text-white"
                } `}
              >
                {year}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <p className="font-bold text-base">Select A Month</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4">
          {MONTHS.map((month, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setLocalSetStats(null);
                  setTotal(0);
                  setCurrentMonth(index + 1);
                  loadStatistics({
                    month: index + 1,
                    year: currentYear,
                  });
                }}
                className={`text-sm text-center font-bold py-2 px-4 cursor-pointer ${
                  currentMonth - 1 === index
                    ? "bg-gray-800 text-white"
                    : "border-2 border-gray-800 text-black hover:bg-gray-800 hover:text-white"
                } `}
              >
                {month}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MonthPicker;
