import React from "react";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../context/admin/context/AdminContext";
import {
  getOneLevel,
  getOneRegion,
  cleanOneLevel,
  getOneCountry,
  cleanOneRegion,
  cleanOneCountry,
} from "./BreadCrumb";

const dosee_home_route = "/";
const region_overview = "/region/manage";
const country_overview = "/countries";
const levels_overview = "/levels/first";

export const BreadCrumbComponent = (props) => {
  // We get our data destructured
  const { sysAdmin, singleAdmin } = useAdminContext();
  let our_region = getOneRegion();
  let our_country = getOneCountry();
  let our_level = getOneLevel();

  const isMainAdmin = sysAdmin?.role === "super_admin" ? true : false;

  // Get if the default value will be display or not
  const default_value = props.default_value ? props.default_value : false;
  // This will show only default values on certain calls
  if (default_value === true) {
    our_region = [];
    our_country = [];
    our_level = [];
  }

  // CONDITIONS
  const isRegion = our_region.length > 0 ? true : false;
  const isCountry = our_country.length > 0 ? true : false;
  const isLevel = our_level.length > 0 ? true : false;

  // NAMES
  const region_name = isRegion ? our_region[0].name : "Our Regions";
  const country_name = isCountry ? our_country[0].name : "Our Countries";
  const level_name = isLevel ? our_level[0].name : "Our Levels";

  // ROUTES
  const region_route = isRegion ? our_region[0].route : region_overview;
  const country_route = isCountry ? our_country[0].route : country_overview;
  const level_route = isLevel ? our_level[0].route : levels_overview;

  // Get data inside our List
  const Data = [
    {
      route: dosee_home_route,
      title: "Home",
      onClick: () => null,
    },
    {
      route: region_route,
      title: region_name,
      onClick: () => {
        cleanOneRegion();
        cleanOneCountry();
        cleanOneLevel();
      },
    },
    {
      route: country_route,
      title: country_name,
      onClick: () => {
        cleanOneCountry();
        cleanOneLevel();
      },
    },
    {
      route: level_route,
      title: level_name,
      onClick: () => cleanOneLevel(),
    },
  ];

  if (singleAdmin) return null;
  return isMainAdmin ? (
    <div className="overflow-x-auto overflow-y-hidden">
      <div className="flex flex-row text-xs font-bold text-gray-600 hover:text-gray-500 hover:cursor-pointer my-2">
        {Data.map((_breadcrumb_, index) => (
          <BreadCrumb
            key={index}
            props={{
              route: _breadcrumb_.route,
              title: _breadcrumb_.title,
              onClick: _breadcrumb_.onClick,
            }}
          />
        ))}
      </div>
    </div>
  ) : null;
};

const BreadCrumb = ({ props }) => {
  try {
    // values from props
    const link_route = props.route;
    const title = props.title;
    const onClick = props.onClick;

    return (
      <Link to={link_route}>
        <div
          className="flex justify-start items-center"
          onClick={() => onClick()}
        >
          <p className="underline decoration-brand">{title}</p>
          <span className="mx-2 font-bold text-xl text-black">{" > "}</span>
        </div>
      </Link>
    );
  } catch (error) {}
};
