/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { _PAGE_SIZE_ } from "../../../middlewares/other/tools";
import { MySearch } from "../../home/component/searchUtils";
import { MyButton } from "../../home/component/ButtonUtils";
import { Pagination } from "../../home/component/Pagination";
import { Modal } from "../../home/component/ModalUtils";
import { pushOneTourPackage } from "../../../middlewares/sysNavigation/SysNavigation";
import { GoBack } from "../../home/component/GoBackUtils";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { DashboardIntro } from "../../home/component/DashboardIntroUtils";

const addRoute = "/data/tour/package/add";
const editRoute = "/data/tour/package/edit";
const doseeDataRoutes = "/dosee/data";

const Overview = () => {
  const [tourPackagesList, setTourPackageList] = useState([]);
  const { tourPackages, tourPackagesCount, fetchDoSeeData } =
    useDoSeeDataContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  const [localSearch, setLocalSearch] = useState([]);

  const {
    country_id,
    entry_label,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const data_request_body = {
    country_id: country_id,
    first_level_id: first_level_id,
    second_level_id: second_level_id,
    third_level_id: third_level_id,
    fourth_level_id: fourth_level_id,
    content: _TYPES_.TOURPACKAGE,
  };

  useEffect(() => {
    if (!tourPackages) fetchDoSeeData(data_request_body, page, pageSize);
    else setTourPackageList(tourPackages);
  }, [tourPackages]);

  const SearchList = (e) => {
    const key = String(e.target.value).toLowerCase();
    let searchArray = [];
    if (tourPackages) {
      tourPackages.map((_tourpackage_) => {
        if (_tourpackage_.title.toString().toLowerCase().includes(key))
          return searchArray.push(_tourpackage_);
        return true;
      });
    }

    setTourPackageList(searchArray);
    if (key === "") setTourPackageList(tourPackages);
  };

  const LocalData = localSearch?.length > 0 ? localSearch : tourPackagesList;

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center space-y-4">
        <BreadCrumbComponent />
        <GoBack props={{ route: doseeDataRoutes }} />
        <div className="my-3">
          <DashboardIntro
            props={{ intro: `Tour Packages`, name: entry_label }}
          />
        </div>
        <MySearch
          type={_TYPES_.TOURPACKAGE}
          value={localSearch}
          showGlobalSearch={true}
          setValue={setLocalSearch}
          onChange={(e) => SearchList(e)}
        />

        <div className="w-full flex justify-end my-5">
          {country_id ? (
            <Link to={addRoute}>
              <MyButton
                title="Add Tour Package"
                className="py-3 px-8 bg-blue-900 hover:bg-blue-600"
                onClick={() => {}}
              />
            </Link>
          ) : null}
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">
                  Duration (Days)
                </th>
                <th className="border text-center w-1/3">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {tourPackages || LocalData ? (
                LocalData?.map((tour) => (
                  <tr key={tour?.tour_package_id}>
                    <td className="border px-2 lg:px-4 w-1/3">{tour?.title}</td>
                    <td className="border px-2 lg:px-4 w-1/3">
                      {tour?.duration}
                    </td>
                    <td className="border w-1/3">
                      <ActionButton props={tour} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={tourPackagesCount}
          callbackFunction={(p, size) => {
            fetchDoSeeData(data_request_body, p, size);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { tourPackages, setDataState, deleteOneContent } =
    useDoSeeDataContext();

  const [showModal, setShowModal] = useState(false);

  const tour_package_id = props?.tour_package_id;
  const tourpackage_name = props?.title;
  const _tourpackage_ = props;

  const deleteOneTourPackageById = async (tour_package_id) => {
    const { status } = await deleteOneContent({
      data_id: tour_package_id,
      content: _TYPES_.TOURPACKAGE,
    });
    if (status === 200) {
      setDataState({
        content: _TYPES_.TOURPACKAGE,
        data: tourPackages?.filter(
          (tour) => tour?.tour_package_id !== tour_package_id
        ),
      });
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        props={{
          name: tourpackage_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneTourPackageById(tour_package_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-3 px-8 bg-blue-800 hover:bg-blue-600`}
            onClick={() => pushOneTourPackage(_tourpackage_)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-3 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
