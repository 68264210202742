import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { TradeOrganizationProvider } from "../../../../context/tradeOrganization/TradeOrganizationContext";

export const TradeOrganization = () => {
  return (
    <TradeOrganizationProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="TradeOrganization"
          />
        }
      />
    </TradeOrganizationProvider>
  );
};

export const AddTradeOrganization = () => {
  return (
    <TradeOrganizationProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Trade Organization"
          />
        }
      />
    </TradeOrganizationProvider>
  );
};

export const EditTradeOrganization = () => {
  return (
    <TradeOrganizationProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Trade Organization"
          />
        }
      />
    </TradeOrganizationProvider>
  );
};
