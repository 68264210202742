import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = (props) => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <NavigationContext.Provider value={{ showSideBar, setShowSideBar }}>
      {props.children}
    </NavigationContext.Provider>
  );
};
