import React from "react";

import { Auth } from "../../../context/admin/Authentication";

import Screen from "../../home/content/Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { DoSeeDataProvider } from "../../../context/doSeeData/DoSeeDataContext";
import { CountryProvider } from "../../../context/country/countryContext/CountryContext";
import { PositionProvider } from "../../../context/position/PositionContext";

export const TipsAndAdvice = () => {
  return (
    <DoSeeDataProvider>
      <PositionProvider>
        <Auth
          children={
            <Screen
              component={<Overview />}
              title="Tips And Advice"
            />
          }
        />
      </PositionProvider>
    </DoSeeDataProvider>
  );
};

export const AddTipsAndAdvice = () => {
  return (
    <CountryProvider>
      <DoSeeDataProvider>
        <Auth
          children={
            <Screen
              component={<Add />}
              title="Add Tips And Advice"
            />
          }
        />
      </DoSeeDataProvider>
    </CountryProvider>
  );
};

export const EditTipsAndAdvice = () => {
  return (
    <CountryProvider>
      <DoSeeDataProvider>
        <Auth
          children={
            <Screen
              component={<Edit />}
              title="Edit Tips And Advice"
            />
          }
        />
      </DoSeeDataProvider>
    </CountryProvider>
  );
};
