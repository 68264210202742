import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const TradeOrganizationContext = createContext();

export const useTradeOrganizationContext = () =>
  useContext(TradeOrganizationContext);

export const TradeOrganizationProvider = (props) => {
  const [tradeOrganization, setTradeOrganization] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateTradeOrganizationState = async (data) => {
    return setTradeOrganization(data);
  };

  /**
   * Fetch all tradeOrganization
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchTradeOrganizations = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/trade/organization/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setTradeOrganization(data);
      setCount(count);
    });
  };

  /**
   * Add a new tradeOrganization
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneTradeOrganization = async (data) => {
    const url = "/api/v1/admin/trade/organization/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a tradeOrganization
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateTradeOrganization = async (tradeOrganization_id, data) => {
    const url = `/api/v1/admin/trade/organization/update/${tradeOrganization_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a tradeOrganization
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteTradeOrganization = async (tradeOrganization_id) => {
    const url = `/api/v1/admin/trade/organization/delete/${tradeOrganization_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <TradeOrganizationContext.Provider
      value={{
        tradeOrganization,
        createOneTradeOrganization,
        fetchTradeOrganizations,
        updateTradeOrganization,
        deleteTradeOrganization,
        updateTradeOrganizationState,
        count,
      }}
    >
      {props.children}
    </TradeOrganizationContext.Provider>
  );
};
