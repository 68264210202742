import { _TYPES_ } from "../../../doseeData/utils/types";
import {
  pushOneArt,
  pushOneAttraction,
  pushOneBestOfAfrica,
  pushOneBook,
  pushOneCinema,
  pushOneCityGuide,
  pushOneClasses,
  pushOneDance,
  pushOneEvent,
  pushOneFood,
  pushOneGettingAround,
  pushOneHotel,
  pushOneMinistry,
  pushOneMovie,
  pushOneMuseum,
  pushOneMusic,
  pushOneNeighborhood,
  pushOneNews,
  pushOneNightlife,
  pushOneParty,
  pushOneProduct,
  pushOneRestaurant,
  pushOneSafari,
  pushOneShopping,
  pushOneTheater,
  pushOneThingsToDo,
  pushOneTipsAndAdvice,
  pushOneTourPackage,
} from "../../../../middlewares/sysNavigation/SysNavigation";
import { ContentTypes } from "../../../../middlewares/other/tools";

export const getContentMasterSearchParams = (localSearch) => {
  return [
    // {
    //   title: "Countries",
    //   data: localSearch?.Country,
    //   titleLabel: "name",
    //   route: "/data/country/edit",
    //   idLabel: "country_id",
    // },
    // {
    //   title: "Cities & Regions",
    //   data: localSearch?.FirstLevel,
    //   titleLabel: "name",
    //   route: "/data/city/edit/first/level",
    //   idLabel: "first_level_id",
    // },
    // {
    //   title: "Cities & Regions",
    //   data: localSearch?.SecondLevel,
    //   titleLabel: "name",
    //   route: "/data/city/edit/second/level",
    //   idLabel: "second_level_id",
    // },
    // {
    //   title: "Cities & Regions",
    //   data: localSearch?.ThirdLevel,
    //   titleLabel: "name",
    //   route: "/data/city/edit/third/level",
    //   idLabel: "third_level_id",
    // },
    // {
    //   title: "Cities & Regions",
    //   data: localSearch?.FourthLevel,
    //   titleLabel: "name",
    //   route: "/data/city/edit/fourth/level",
    //   idLabel: "fourth_level_id",
    // },
    {
      title: "Events",
      data: localSearch?.Event,
      titleLabel: "title",
      route: "/data/event/edit",
      idLabel: "event_id",
      collection: _TYPES_.EVENT,
      type: ContentTypes.Event,
      pushMethod: pushOneEvent,
    },
    {
      title: "Parties",
      data: localSearch?.Party,
      titleLabel: "title",
      route: "/data/party/edit",
      idLabel: "party_id",
      type: ContentTypes.Party,
      collection: _TYPES_.PARTY,
      pushMethod: pushOneParty,
    },
    {
      title: "Attractions",
      data: localSearch?.Attraction,
      titleLabel: "title",
      route: "/data/attraction/edit",
      idLabel: "attraction_id",
      type: ContentTypes.Attraction,
      collection: _TYPES_.ATTRACTION,
      pushMethod: pushOneAttraction,
    },
    {
      title: "Arts",
      data: localSearch?.Art,
      titleLabel: "title",
      route: "/data/art/edit",
      idLabel: "art_id",
      type: ContentTypes.Art,
      collection: _TYPES_.ART,
      pushMethod: pushOneArt,
    },
    {
      title: "Books",
      data: localSearch?.Book,
      titleLabel: "title",
      route: "/data/book/edit",
      idLabel: "book_id",
      type: ContentTypes.Book,
      collection: _TYPES_.BOOK,
      pushMethod: pushOneBook,
    },
    {
      title: "Cinemas",
      data: localSearch?.Cinema,
      titleLabel: "title",
      route: "/data/cinema/edit",
      idLabel: "cinema_id",
      type: ContentTypes.Cinema,
      collection: _TYPES_.CINEMA,
      pushMethod: pushOneCinema,
    },
    {
      title: "CityGuides",
      data: localSearch?.CityGuide,
      titleLabel: "title",
      route: "/data/city/guide/edit",
      idLabel: "city_guide_id",
      type: ContentTypes["City Guide"],
      collection: _TYPES_.CITYGUIDE,
      pushMethod: pushOneCityGuide,
    },
    {
      title: "Classes",
      data: localSearch?.Class,
      titleLabel: "title",
      route: "/data/class/edit",
      idLabel: "class_id",
      type: ContentTypes.Class,
      collection: _TYPES_.CLASSES,
      pushMethod: pushOneClasses,
    },
    {
      title: "Dances",
      data: localSearch?.Dance,
      titleLabel: "title",
      route: "/data/dance/edit",
      idLabel: "dance_id",
      type: ContentTypes.Dance,
      collection: _TYPES_.DANCE,
      pushMethod: pushOneDance,
    },
    {
      title: "Hotels",
      data: localSearch?.Hotel,
      titleLabel: "title",
      route: "/data/hotel/edit",
      idLabel: "hotel_id",
      type: ContentTypes.Hotels,
      collection: _TYPES_.HOTEL,
      pushMethod: pushOneHotel,
    },
    {
      title: "Best Of Africa",
      data: localSearch?.BestOfAfrica,
      titleLabel: "title",
      route: "/data/bestofafrica/edit",
      idLabel: "best_of_africa_id",
      type: ContentTypes["Best of Africa"],
      collection: _TYPES_.BESTOFAFRICA,
      pushMethod: pushOneBestOfAfrica,
    },
    // {
    //   title: "Company",
    //   data: localSearch?.Company,
    //   titleLabel: "name",
    //   route: "/data/company/edit",
    //   idLabel: "company_id",
    //  type: ContentTypes.Company,
    // collection: _TYPES_.COMPANY,
    //   pushMethod: pushOneCompany,
    // },
    {
      title: "Ministries",
      data: localSearch?.Ministry,
      titleLabel: "title",
      route: "/data/ministry/edit",
      idLabel: "ministry_id",
      type: ContentTypes.Ministry,
      collection: _TYPES_.MINISTRY,
      pushMethod: pushOneMinistry,
    },
    {
      title: "Movies",
      data: localSearch?.Movie,
      titleLabel: "title",
      route: "/data/movie/edit",
      idLabel: "movie_id",
      type: ContentTypes.Movie,
      collection: _TYPES_.MOVIE,
      pushMethod: pushOneMovie,
    },
    {
      title: "Museums",
      data: localSearch?.Museum,
      titleLabel: "title",
      route: "/data/museum/edit",
      idLabel: "museum_id",
      type: ContentTypes.Museum,
      collection: _TYPES_.MUSEUM,
      pushMethod: pushOneMuseum,
    },
    {
      title: "Concerts",
      data: localSearch?.Music,
      titleLabel: "category",
      route: "/data/music/edit",
      idLabel: "music_id",
      type: ContentTypes.Music,
      collection: _TYPES_.MUSIC,
      pushMethod: pushOneMusic,
    },
    {
      title: "News",
      data: localSearch?.News,
      titleLabel: "title",
      route: "/data/news/edit",
      idLabel: "news_id",
      type: ContentTypes.News,
      collection: _TYPES_.NEWS,
      pushMethod: pushOneNews,
    },
    {
      title: "Night Life",
      data: localSearch?.Nightlife,
      titleLabel: "title",
      route: "/data/nightlife/edit",
      idLabel: "nightlife_id",
      type: ContentTypes.Nightlife,
      collection: _TYPES_.NIGHTLIFE,
      pushMethod: pushOneNightlife,
    },
    {
      title: "Products",
      data: localSearch?.Product,
      titleLabel: "name",
      route: "/data/product/edit",
      idLabel: "product_id",
      type: ContentTypes.Product,
      collection: _TYPES_.PRODUCT,
      pushMethod: pushOneProduct,
    },
    {
      title: "Restaurants",
      data: localSearch?.Restaurant,
      titleLabel: "name",
      route: "/data/restaurant/edit",
      idLabel: "restaurant_id",
      type: ContentTypes.Restaurant,
      collection: _TYPES_.RESTAURANT,
      pushMethod: pushOneRestaurant,
    },
    {
      title: "Foods",
      data: localSearch?.Food,
      titleLabel: "title",
      route: "/data/food/edit",
      idLabel: "food_id",
      type: ContentTypes.Food,
      collection: _TYPES_.FOOD,
      pushMethod: pushOneFood,
    },
    {
      title: "Getting Around",
      data: localSearch?.GettingAround,
      titleLabel: "title",
      route: "/data/get/around/edit",
      idLabel: "getting_around_id",
      type: ContentTypes["Getting around"],
      collection: _TYPES_.GETTINGAROUND,
      pushMethod: pushOneGettingAround,
    },
    {
      title: "Neighborhoods",
      data: localSearch?.Neighborhood,
      titleLabel: "name",
      route: "/data/neighborhood/edit",
      idLabel: "neighborhood_id",
      type: ContentTypes.Neighborhood,
      collection: _TYPES_.NEIGHBORHOOD,
      pushMethod: pushOneNeighborhood,
    },
    {
      title: "Safaris",
      data: localSearch?.Safari,
      titleLabel: "title",
      route: "/data/safari/edit",
      idLabel: "safari_id",
      type: ContentTypes.Safari,
      collection: _TYPES_.SAFARI,
      pushMethod: pushOneSafari,
    },
    {
      title: "Shoppings",
      data: localSearch?.Shopping,
      titleLabel: "title",
      route: "/data/shopping/edit",
      idLabel: "shopping_id",
      type: ContentTypes.Shopping,
      collection: _TYPES_.SHOPPING,
      pushMethod: pushOneShopping,
    },
    {
      title: "Theaters",
      data: localSearch?.Theater,
      titleLabel: "title",
      route: "/data/theater/edit",
      idLabel: "theater_id",
      type: ContentTypes.Theater,
      collection: _TYPES_.THEATER,
      pushMethod: pushOneTheater,
    },
    {
      title: "Tour Packages",
      titleLabel: "title",
      route: "/data/tour/package/edit",
      idLabel: "tour_package_id",
      type: ContentTypes["Tour package"],
      collection: _TYPES_.TOURPACKAGE,
      pushMethod: pushOneTourPackage,
      data: localSearch?.TourPackage,
    },
    {
      title: "Things To Do",
      data: localSearch?.ThingsToDo,
      titleLabel: "title",
      route: "/data/things/todo/edit",
      idLabel: "things_to_do_id",
      type: ContentTypes["Things to do"],
      collection: _TYPES_.THINGSTODO,
      pushMethod: pushOneThingsToDo,
    },
    {
      title: "Tips And Advice",
      data: localSearch?.TipsAndAdvice,
      titleLabel: "title",
      route: "/data/tips/advice/edit",
      idLabel: "tips_and_advice_id",
      type: ContentTypes["Tips and Advice"],
      collection: _TYPES_.TIPSANDADVICE,
      pushMethod: pushOneTipsAndAdvice,
    },
  ];
};
