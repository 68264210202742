import React, { useState } from "react";
import {
  Title,
  GoBack,
  TextArea,
  MyMessage,
  MyActionButton,
} from "../../component/Component";
import { useStaticContext } from "../../../../context/static/StaticContext";
import { getOneTerms } from "../../../../middlewares/sysNavigation/SysNavigation";

const Edit = () => {
  const { updateTerms } = useStaticContext();
  const terms = getOneTerms();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const terms_id = terms?.terms_id;
  const [description, setDescription] = useState(terms?.description);

  const termsRoutes = "/terms";

  const responses = (error, message) => {
    setError(error);
    setSuccess(!error);
    setLoading(false);
    setErrorMessage(message);
  };

  const updateOneTermsHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const { status, message } = await updateTerms({
      id: terms_id,
      data: { description },
    });
    if (status === 200) responses(false, message);
    else responses(true, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: termsRoutes }} />
      <form onSubmit={updateOneTermsHandler}>
        <div className="mt-4 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Terms & Conditions Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            title="Save Changes"
            loading={loading}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
