import React from "react";

import { Link } from "react-router-dom";

const homeRoute = "/";

const PageNotFound = () => {
  return (
    <div className='w-screen h-screen'>
      <div className='flex flex-col items-center justify-center h-full'>
        <p className='text-4xl font-bold'>Page Not Found</p>
        <Link to={homeRoute}>
          <p className='text-base text-gray-500 hover:underline hover:cursor-pointer mt-2 hover:text-blue-700'>
            Go Back to Home Page
          </p>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
