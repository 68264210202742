import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { API_URL } from "../../config";

import { useAdminContext } from "../../admin/context/AdminContext";

const FirstLevelContext = createContext();

export const useFirstLevelContext = () => useContext(FirstLevelContext);

export const FirstLevelProvider = (props) => {
  const [firstLevels, setFirstLevels] = useState(null);
  const [firstLevel, setFirstLevel] = useState(null);
  const [countFirst, setCountFirst] = useState(0);

  const { sysAdmin } = useAdminContext();

  // Update the state
  const updateFirstLevelState = async (data) => {
    return setFirstLevels(data);
  };

  /**
   * Fetch all first levels
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchFirstLevels = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/first/level/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });
      if (response) {
        setFirstLevels(response.data.data.data);
        setCountFirst(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch by country id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchFirstByCountryId = async ({
    country_id,
    page = 1,
    pageSize = 20,
  }) => {
    try {
      const fetch_url =
        API_URL +
        `/api/v1/first/level/location/?page=${page}&pageSize=${pageSize}&country_id=${country_id}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setFirstLevels(response.data.data.data);
        setCountFirst(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Get a single first_level with metadata
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */

  const fetchFirstLevel = async (first_level_id) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/first/level/metadata/${first_level_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });
      if (response) {
        setFirstLevel(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new first level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addFirstLevel = async (data) => {
    try {
      const token = sysAdmin.token;
      const fetch_url = API_URL + `/api/v1/admin/first/level/create`;
      let errorResponse = null;

      const response = await axios
        .post(fetch_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update First level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateFirstLevel = async (first_level_id, data) => {
    try {
      const token = sysAdmin.token;
      const update_url =
        API_URL + `/api/v1/admin/first/level/update/${first_level_id}`;
      let errorResponse = null;

      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a single first level by id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteFirstLevel = async (first_level_id) => {
    try {
      const token = sysAdmin.token;
      const fetch_url =
        API_URL + `/api/v1/admin/first/level/delete/${first_level_id}`;
      let errorResponse = null;

      const response = await axios
        .delete(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <FirstLevelContext.Provider
      value={{
        firstLevels,
        fetchFirstLevels,
        deleteFirstLevel,
        updateFirstLevelState,
        addFirstLevel,
        firstLevel,
        fetchFirstLevel,
        updateFirstLevel,
        countFirst,
        fetchFirstByCountryId,
      }}
    >
      {props.children}
    </FirstLevelContext.Provider>
  );
};
