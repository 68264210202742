import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";

import { InfoProvider } from "../../../../context/info/InfoContext";

export const Contact = () => {
  return (
    <InfoProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Contact"
          />
        }
      />
    </InfoProvider>
  );
};
