/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  PhoneInput,
  SelectInput,
  MyActionButton,
} from "../../component/Component";
import {
  codeOk,
  errorMessage,
  pushCountry,
} from "../../../../middlewares/index";
import { useCountryContext } from "../../../../context/country/countryContext/CountryContext";
import { useTourPackageContext } from "../../../../context/tourPackage/TourPackageContext";
import { useAgentContext } from "../../../../context/agent/AgentContext";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS, image_kit_folder } from "../../../doseeData/utils/Utils";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { countriesList } from "../../component/data";

const Add = () => {
  const { createOneAgent } = useAgentContext();
  const { countries, fetchCountries } = useCountryContext();
  const { tourPackages, fetchTourPackages } = useTourPackageContext();
  const [fetchedTour, setFetchedTour] = useState(null);
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [country, setCountry] = useState("Uganda");
  const [agent_package_id, setAgentPackageId] = useState(0);
  const [agent_payment_id, setAgentPaymentId] = useState(0);
  const [agent_email, setAgentEmail] = useState(null);
  const [agent_phone, setAgentPhone] = useState(null);
  const [agent_contact, setAgentContact] = useState(null);
  const [agent_transactions, setAgentTransactions] = useState(0);
  const [agent_rating, setAgentRating] = useState(0.0);
  const [description, setDescription] = useState(null);

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [thumbnailLogo, setThumbnailLogo] = useState(null);
  const [thumbnailIdLogo, setThumbnailIdLogo] = useState(null);

  const agentRoutes = "/agent";

  useEffect(() => {
    if (!countries) fetchCountries();

    if (!tourPackages) {
      fetchTourPackages();
    } else {
      const this_tour = [];
      tourPackages.map((tour) =>
        this_tour.push({
          name: tour.title,
          id: tour.tour_package_id,
        })
      );
      setFetchedTour(this_tour);
    }
  }, [countries, tourPackages]);

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setAgentEmail(null);
    setAgentPhone(null);
    setAgentContact(null);
    setThumbnail(null);
    setThumbnailId(null);
    setThumbnailLogo(null);
    setThumbnailIdLogo(null);
    setAgentTransactions(0);
    setAgentRating(0.0);
    setTitle(null);
    setDescription(null);
  };

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setErrorMessage(message);
  };

  const addOneAgentHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId || !thumbnailLogo || !thumbnailIdLogo) {
      setLoading(false);
      return responses(true, "Image or Logo Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      agent_package_id: agent_package_id,
      agent_payment_id: agent_payment_id,
      agent_biz_reg_no: title?.replace(/ /g, "-") || "reg-no-agent",
      logo: thumbnailLogo ? thumbnailLogo : defaultImage,
      logo_ref_id: thumbnailIdLogo,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      email_address: agent_email,
      phonenumber: agent_phone,
      contact_person_name: agent_contact,
      country: country ? country : "...",
      number_of_transaction: agent_transactions,
      rating: agent_rating,
      name: title,
      description: description,
    };

    const response = await createOneAgent(data);
    if (response) {
      if (response.data.status === codeOk) {
        cleanFields();
        return responses(true, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: agentRoutes }} />
      <form onSubmit={addOneAgentHandler}>
        <div className="mt-3 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Basic Info" }} />

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-5">
            <div className="flex flex-col space-y-2">
              <p className="text-sm font-bold">Agent Profile</p>
              <ImageKitUpload
                thumbnail={thumbnail}
                thumbnailId={thumbnailId}
                setThumbnail={setThumbnail}
                setThumbnailId={setThumbnailId}
                folder={image_kit_folder("dosee", FOLDERS.AGENTS)}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <p className="text-sm font-bold">Agent Logo</p>
              <ImageKitUpload
                thumbnail={thumbnailLogo}
                thumbnailId={thumbnailIdLogo}
                setThumbnail={setThumbnailLogo}
                setThumbnailId={setThumbnailIdLogo}
                folder={image_kit_folder("dosee", FOLDERS.AGENTS)}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <SelectInput
              title="Country"
              defaultValue={country ? country : null}
              required={true}
              value={country ? country : null}
              options={countries ? countries?.map((x) => x?.name) : []}
              onChange={(e) => {
                setCountry(e.target.value);

                const pickedCountry = countriesList?.find(
                  (_c) =>
                    String(_c.name).toLowerCase() ===
                    String(e.target.value).toLowerCase()
                );

                pushCountry({
                  country: {
                    id: pickedCountry?.id,
                    name: pickedCountry?.name,
                    slug: pickedCountry?.name,
                    route: "/admin",
                  },
                });
              }}
            />
            <PhoneInput
              phone={agent_phone}
              required={true}
              setPhone={setAgentPhone}
              country={country ? country : ""}
            />

            <Select
              props={{
                id: "tour_package_id",
                title: "Tour Package",
                option:
                  fetchedTour !== null && tourPackages !== null
                    ? [...fetchedTour.map((_tour) => _tour.name)]
                    : [],
                onChange: (e) => {
                  const index = e.target.selectedIndex;
                  setAgentPackageId(fetchedTour[index].id);
                  // setIsThirdLevelSelected(false);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Select
              props={{
                id: "agent_payment_id",
                title: "Payment Method",
                option: ["Visa", "Cash", "E-Wallet", "Mobile Money"],
                onChange: (e) => {
                  const index = e.target.selectedIndex;
                  setAgentPaymentId(index);
                  // setIsThirdLevelSelected(false);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Agent Details" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "agent_email",
                title: "Agent Email",
                type: "text",
                value: agent_email ? agent_email : "",
                onChange: (e) => {
                  setAgentEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "agent_contact",
                title: "Agent Contact Person",
                type: "text",
                value: agent_contact ? agent_contact : "",
                onChange: (e) => {
                  setAgentContact(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "agent_transaction",
                title: "Agent Transactions",
                type: "number",
                value: agent_transactions ? agent_transactions : "",
                onChange: (e) => {
                  setAgentTransactions(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "agent_rating",
                title: "Agent Rating",
                type: "number",
                value: agent_rating ? agent_rating : "",
                onChange: (e) => {
                  setAgentRating(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <TextArea
            props={{
              id: "description",
              title: "Description",
              type: "text",
              value: description ? description : "",
              onChange: (e) => {
                setDescription(e.target.value);
                setError(null);
                setSuccess(null);
              },
            }}
          />

          <MyActionButton
            loading={loading}
            title="Add Agent"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
