import React, { createContext, useContext, useState } from "react";
import { useAdminContext } from "../admin/context/AdminContext";
import Service from "../../api/service";

const PlugImageContext = createContext();

export const usePlugImageContext = () => useContext(PlugImageContext);

export const PlugImageProvider = (props) => {
  const [plugImage, setPlugImage] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updatePlugImageState = async (data) => {
    return setPlugImage(data);
  };

  /**
   * Fetch all plugImage
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchPlugImages = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/plug/image/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setPlugImage(data);
      setCount(count);
    });
  };

  /**
   * Add a new plugImage
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOnePlugImage = async (data) => {
    const url = "/api/v1/admin/plug/image/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a plugImage
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updatePlugImage = async (plugImage_id, data) => {
    const url = `/api/v1/admin/plug/image/update/${plugImage_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a plugImage
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deletePlugImage = async (plugImage_id) => {
    const url = `/api/v1/admin/plug/image/delete/${plugImage_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <PlugImageContext.Provider
      value={{
        plugImage,
        createOnePlugImage,
        fetchPlugImages,
        updatePlugImage,
        deletePlugImage,
        updatePlugImageState,
        count,
      }}
    >
      {props.children}
    </PlugImageContext.Provider>
  );
};
