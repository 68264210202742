export const Search = ({ props }) => {
  return (
    <div className="bg-gray-800 p-2">
      <label htmlFor="search" className="text-xs px-2 text-white">
        Search Key
      </label>
      <input
        value={props.value}
        placeholder={`Search here...`}
        onChange={props.onChange}
        className="border-none outline-none bg-white focus:border-none w-auto text-xs focus:outline-none px-2 py-3 placeholder:text-xs"
      />
    </div>
  );
};

export const MySearch = ({ type, value, setValue, onChange }) => {
  return (
    <div className="bg-gray-800 w-full space-x-2 grid grid-cols-1 lg:grid-cols-2 gap-4 py-2 rounded-md">
      <div className="p-2 w-full space-x-2 md:space-x-4 flex items-center">
        <label htmlFor="search" className="text-xs text-white">
          Search
        </label>
        <div className="w-full">
          <input
            id="search"
            onChange={onChange}
            placeholder="Context Search here..."
            className="rounded-md border-none outline-none bg-white focus:border-none w-full lg:w-80 text-xs focus:outline-none px-2 py-3 lg:py-4 placeholder:text-xs"
          />
        </div>
      </div>
    </div>
  );
};
