import React from "react";
import { getOneCompany } from "../../../../middlewares/sysNavigation/SysNavigation";

const View = () => {
  const company = getOneCompany();

  return (
    <div className="w-full overflow-y-auto bg-gray-50">
      <div className="mx-auto w-full px-5 lg:w-1/2 py-10">
        <div className="flex w-full flex-col items-start justify-center space-y-2 lg:col-span-2 xl:col-span-3">
          <div className="py-2 text-xl font-semibold uppercase text-black lg:text-xl xl:pt-0">
            {company ? company?.name : "-"}
          </div>

          <div className="flex w-full items-center">
            <div className="w-full">
              {company && company?.thumbnail ? (
                <img
                  className="h-40 w-full rounded-md object-cover lg:h-80"
                  src={company ? company?.thumbnail : null}
                  alt=""
                />
              ) : (
                <div className="h-40 w-full bg-gray-200"></div>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-2 pt-5">
            <p className="text-base">Code:</p>
            {company && company?.code ? (
              <p className="text-base font-bold">{company?.code}</p>
            ) : (
              "-"
            )}
          </div>
          <div className="flex items-center space-x-2">
            <p className="text-base">Website:</p>
            {company && company?.website ? (
              <a
                href={company?.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-xs font-bold italic underline hover:text-primary hover:decoration-primary"
              >
                Click here
              </a>
            ) : (
              "-"
            )}
          </div>
          <div className="flex w-full items-center justify-between space-x-2">
            <div className="flex items-center space-x-2">
              <p className="text-sm">Address:</p>
              {company ? (
                <p className="text-sm font-bold">{`${company?.country} ${
                  company?.city ? ` - ${company?.city}` : ""
                }`}</p>
              ) : (
                "-"
              )}
            </div>
          </div>

          <div className="pt-5 w-full">
            <div className="w-full rounded-md border border-gray-200 p-5 text-sm">
              {company && company?.description ? (
                <div
                  dangerouslySetInnerHTML={{ __html: company?.description }}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
