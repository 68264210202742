/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { Modal, Search, MyButton, Pagination } from "../../component/Component";
import { useCurrencyContext } from "../../../../context/currency/CurrencyContext";
import { pushOneCurrency } from "../../../../middlewares/sysNavigation/SysNavigation";

const addRoute = "/currency/add";
const editRoute = "/currency/edit";

const Overview = () => {
  const [currencyList, setCurrencyList] = useState([]);
  const { currency, fetchCurrencies, count } = useCurrencyContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!currency) fetchCurrencies();
    else setCurrencyList(currency);
  }, [currency]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (currency !== null && currency !== undefined)
      currency.map((value) => {
        if (value?.name.toString().toLowerCase().includes(key))
          return searchArray.push(value);
        return true;
      });

    setCurrencyList(searchArray);
    if (key === "") setCurrencyList(currency);
  };

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Currency",
            onChange: (e) => SearchList(e),
          }}
        />

        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <MyButton
              title="Add Currency"
              onClick={() => {}}
              className="bg-gray-800 hover:bg-gray-600"
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/4">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Code</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  Currency Rate
                </th>
                <th className="border text-center w-1/4">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 border-gray-300">
              {currency ? (
                currencyList.map((value) => {
                  return (
                    <tr key={value?.currency_id}>
                      <td className="border px-2 lg:px-4 w-1/4">
                        {value?.name}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/4">
                        {value?.code}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/4">
                        {value?.exchange_rate_to_usd}
                      </td>
                      <td className="border w-1/4">
                        <ActionButton props={value} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border w-1/4">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchCurrencies(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { currency, updateCurrencyState, deleteCurrency } =
    useCurrencyContext();

  const [showModal, setShowModal] = useState(false);

  const currency_id = props.currency_id;
  const currency_name = props.name;
  const value = props;

  const deleteOneCurrencyById = async (currency_id) => {
    const { status } = await deleteCurrency(currency_id);
    if (status === 200) {
      if (currency) {
        // Update the currency data
        const update = currency.filter(
          (value) => value?.currency_id !== currency_id
        );
        // Update the Global State of Regions
        updateCurrencyState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: currency_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneCurrencyById(currency_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600"
            onClick={() => pushOneCurrency(value)}
          />
        </Link>
        <MyButton
          title="Delete"
          className="bg-red-800 hover:bg-red-600"
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
