import React from "react";
import Add from "./Add";
import Edit from "./Edit";
import Overview from "./Overview";
import Screen from "../../home/content/Screen";
import { Auth } from "../../../context/admin/Authentication";

export const Product = () => {
  return (
    <Auth
      children={
        <Screen
          component={<Overview />}
          title="Product"
        />
      }
    />
  );
};

export const AddProduct = () => {
  return (
    <Auth
      children={
        <Screen
          component={<Add />}
          title="Add Product"
        />
      }
    />
  );
};

export const EditProduct = () => {
  return (
    <Auth
      children={
        <Screen
          component={<Edit />}
          title="Edit Product"
        />
      }
    />
  );
};
