import Service from "../../../api/service";
import React, { createContext, useContext, useState } from "react";
import { useAdminContext } from "../../admin/context/AdminContext";

const CityContext = createContext();
export const useCityContext = () => useContext(CityContext);

export const CityProvider = (props) => {
  const { sysAdmin } = useAdminContext();
  const [count, setCount] = useState(null);
  const [cities, setCities] = useState(null);

  const token = sysAdmin?.token;

  /**
   * Fetch all cities
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchCities = async (page, pageSize) => {
    const url = `/api/v1/admin/city/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data }) => {
      // sort cities by priority
      setCities(
        data?.data
          ?.sort((a, b) => {
            if (a?.priority > b?.priority) return -1;
            if (a?.priority < b?.priority) return 1;
            return true;
          })
          ?.map((x) => x)
      );
      setCount(data?.count);
    });
  };

  /**
   * Add a new priority
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addPriority = async ({ key, priority, id }) => {
    const url = `/api/v1/city/priority/set/?key=${key}&priority=${priority}&content_id=${id}`;
    const { message, status } = await Service.post(url, {}, token);
    return { message, status };
  };

  return (
    <CityContext.Provider
      value={{
        count,
        cities,
        setCities,
        fetchCities,
        addPriority,
      }}
    >
      {props.children}
    </CityContext.Provider>
  );
};
