import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { pushOneReligion } from "../../../../middlewares/sysNavigation/SysNavigation";

import { useReligionContext } from "../../../../context/religion/ReligionContext";

import { Modal, Button, Search, Pagination } from "../../component/Component";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";

const addRoute = "/religion/add";
const editRoute = "/religion/edit";

const Overview = () => {
  let [religionList, setReligionList] = useState([]);
  const { religion, fetchReligions, count } = useReligionContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!religion) fetchReligions(page, pageSize);
    setReligionList(religion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [religion]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (religion !== null && religion !== undefined)
      religion.map((_religion_) => {
        if (_religion_.name.toString().toLowerCase().includes(key))
          return searchArray.push(_religion_);
        return true;
      });

    setReligionList(searchArray);
    if (key === "") setReligionList(religion);
  };

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Religion",
            onChange: (e) => SearchList(e),
          }}
        />

        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <Button
              props={{
                type: "button",
                title: "Add Religion",
                category: "default",
                action: "add",
                onClick: null,
              }}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-base text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-full">Name</th>
                <th className="border text-center">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {religion ? (
                (() => {
                  if (!religionList) religionList = [...religion];
                  return religionList.map((_religion_) => {
                    return (
                      <tr key={_religion_.religion_id}>
                        <td className="border px-2 lg:px-4 w-full">
                          {_religion_.name}
                        </td>
                        <td className="border">
                          <ActionButton props={_religion_} />
                        </td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-full">Loading...</td>
                  <td className="border">Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchReligions(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { religion, updateReligionState, deleteReligion } =
    useReligionContext();

  const [showModal, setShowModal] = useState(false);

  const religion_id = props.religion_id;
  const religion_name = props.name;
  const _religion_ = props;

  const deleteOneReligionById = async (religion_id) => {
    const response = await deleteReligion(religion_id);
    if (response.data.status === 200) {
      if (religion) {
        // Update the religion data
        const update = religion.filter(
          (_religion_) => _religion_.religion_id !== religion_id
        );
        // Update the Global State of Regions
        updateReligionState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: religion_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneReligionById(religion_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <Button
            props={{
              type: "button",
              title: "Edit",
              category: "blue",
              action: "edit",
              onClick: () => {
                pushOneReligion(_religion_);
              },
            }}
          />
        </Link>
        <Button
          props={{
            type: "button",
            title: "Delete",
            category: "red",
            action: "delete",
            onClick: () => {
              setShowModal(true);
            },
          }}
        />
      </div>
    </>
  );
};

export default Overview;
