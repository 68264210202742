import React from "react";

export const Card = ({ card }) => {
  return (
    <div className="w-auto h-auto p-2 rounded bg-white hover:shadow-md border border-gray-200 hover:border-none">
      <div className="flex flex-col space-y-2 items-center text-black text-xl">
        {card.icon}
        <p className="text-gray-800 text-xs font-bold uppercase">{card.name}</p>
        <hr className="text-black w-full my-2 border-[1px] rounded-2xl" />
        <p className="text-gray-800 text-sm font-bold">{card.quantity}</p>
      </div>
    </div>
  );
};
