/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { getCustomCity, getLevelIds, searchCity } from "./helper";
import { useCityContext } from "../../../../../context/levels/levelsContext";
import { MyButton, MySearch, Pagination } from "../../../component/Component";
import { addOneLevel } from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../../context/admin/context/AdminContext";
import {
  pushLevel,
  pushEditLevel,
  pushCityDashboard,
} from "../../../../../middlewares/sysNavigation/SysNavigation";

const editLevelRoute = "/level/edit";
const addContentRoute = "/dosee/data";
const dashboardLevelRoute = "/city/dashboard";

const Overview = () => {
  const [localCities, setLocalCities] = useState([]);
  const { cities, fetchCities, count } = useCityContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(150);

  useEffect(() => {
    if (!cities) fetchCities(page, pageSize);
  }, [cities]);

  if (cities && cities?.length > 0 && localCities?.length === 0) {
    const priorities = cities
      ?.filter((city) => city?.priority === 1)
      .sort((a, b) => {
        if (a?.name > b?.name) return 1;
        if (a?.name < b?.name) return -1;
        return true;
      });

    const otherCities = cities
      ?.filter((city) => city?.priority === 0)
      .sort((a, b) => {
        if (a?.name > b?.name) return 1;
        if (a?.name < b?.name) return -1;
        return true;
      });

    setLocalCities([...priorities, ...otherCities]);
  }

  return (
    <div className="overflow-y-auto p-3 h-full rounded-b-xl bg-gray-50 w-full">
      <div className="flex flex-col w-full">
        <div className="p-2 h-full rounded-b-xl bg-gray-50">
          <div className="flex flex-col space-y-5 justify-center">
            <MySearch
              onChange={(e) => setLocalCities(searchCity({ key: e, cities }))}
            />
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize * 4} // 4 levels
              total_items={count}
              setPageSize={setPageSize}
              callbackFunction={(p, size) => fetchCities(p, pageSize)}
            />

            <div className="my-5 overflow-y-auto w-full py-2">
              <table className="table-auto w-full border-collapse border border-gray-300 text-sm font-medium text-left">
                <thead>
                  <tr className="space-x-2">
                    <th className="border p-2 lg:px-4 w-1/2">Name</th>
                    <th className="border text-center w-1/2">Action</th>
                  </tr>
                </thead>
                <tbody className="text-left border-gray-300 text-xs">
                  {localCities && localCities?.length > 0 ? (
                    localCities?.map((city, key) => {
                      return (
                        <tr key={key}>
                          <td className="border px-4 w-1/2">{city?.name}</td>
                          <td className="border px-4 w-1/2">
                            <CallToAction city={city} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="border px-4 w-1/2">-</td>
                      <td className="border px-4 w-1/2">-</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize * 4} // 4 levels
              total_items={count}
              setPageSize={setPageSize}
              callbackFunction={(p, size) => fetchCities(p, pageSize)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CallToAction = ({ city }) => {
  const { sysAdmin } = useAdminContext();
  const { name, level, priority } = city;
  const { addPriority, cities, setCities } = useCityContext();

  const [loading, setLoading] = useState(false);
  const {
    level_id,
    first_level_id,
    third_level_id,
    fourth_level_id,
    second_level_id,
  } = getLevelIds({ city });
  const customCity = getCustomCity({
    city,
    first_level_id,
    third_level_id,
    second_level_id,
    fourth_level_id,
  });

  const addToTopView = async () => {
    let key = "first_level_id";
    if (String(level) === "First") key = "first_level_id";
    else if (String(level) === "Second") key = "second_level_id";
    else if (String(level) === "Third") key = "third_level_id";
    else if (String(level) === "Fourth") key = "fourth_level_id";

    setLoading(true);
    const rule = priority === 0 ? 1 : 0;
    const { status } = await addPriority({
      key,
      id: level_id,
      priority: rule,
    });

    if (status === 200) {
      for (let j = 0; j < cities?.length; j++) {
        if (cities[j][key] === level_id) {
          cities[j].priority = rule;
          setCities(
            cities
              ?.sort((a, b) => {
                if (a?.priority > b?.priority) return -1;
                if (a?.priority < b?.priority) return 1;
                return true;
              })
              ?.map((x) => x)
          );
          break;
        }
      }
    }
    setLoading(false);
  };

  const isMainRole = () => {
    return sysAdmin?.role === "super_admin" ? true : false;
  };

  return (
    <div className="flex flex-row justify-center px-2 py-3 space-x-4">
      {isMainRole() ? (
        <MyButton
          onClick={addToTopView}
          className={`${
            priority === 0
              ? "bg-orange-800 hover:bg-orange-600"
              : "bg-purple-800 hover:bg-purple-600"
          }`}
        >
          {loading ? (
            <p className="px-[28px]">
              <AiOutlineLoading className="animate-spin text-white" />
            </p>
          ) : priority !== 0 ? (
            `ON TOP`
          ) : (
            "PUSH ON TOP"
          )}
        </MyButton>
      ) : null}

      {isMainRole() && (
        <Link to={editLevelRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600"
            onClick={() => {
              pushEditLevel({
                data: city,
                level: city?.level,
                country_id: city?.country_id,
              });
            }}
          />
        </Link>
      )}

      <Link to={dashboardLevelRoute}>
        <MyButton
          title="Dashboard"
          className="bg-gray-800 hover:bg-gray-600"
          onClick={() => {
            pushCityDashboard([level, customCity]);
          }}
        />
      </Link>
      <Link to={addContentRoute}>
        <MyButton
          title="Content"
          className="bg-green-800 hover:bg-green-600"
          onClick={() => {
            // This will add a level into the array of levels that can be accessed later
            // in the breadcrumb menu
            addOneLevel({
              id: level_id,
              name: name,
              route: "/levels/" + level.toString().toLowerCase(),
              level: level,
              data: { data: customCity },
            });

            pushLevel({
              level: {
                id: level_id,
                name: name,
                level: level,
                data: customCity,
                route: "/levels/" + level.toString().toLowerCase(),
              },
            });
          }}
        />
      </Link>
    </div>
  );
};

export default Overview;
