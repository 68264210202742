/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAdminContext } from "./context/AdminContext";

export const Auth = ({ children }) => {
  const localUser = localStorage.getItem("auth");
  const { sysAdmin, setSysAdmin } = useAdminContext();

  useEffect(() => {
    if (!sysAdmin && localUser !== "undefined") {
      setSysAdmin(JSON.parse(localUser));
    }
  }, [sysAdmin]);

  if (sysAdmin) return <>{children}</>;
  return (
    <Navigate
      replace
      to="/login"
    />
  );
};
