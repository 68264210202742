/**
 * @author Ntavigwa Bashombe
 * @param {*} isError
 * @param {*} status
 * @param {*} message
 * @returns
 */
export const Responses = (isError, status, message) => {
  return {
    isError,
    status,
    message,
  };
};
