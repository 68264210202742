import React from "react";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { DoSeeDataProvider } from "../../../../context/doSeeData/DoSeeDataContext";

export const Search = () => {
  return (
    <Auth
      children={
        <Screen
          component={
            <DoSeeDataProvider>
              <Overview />
            </DoSeeDataProvider>
          }
          title="Global Search"
        />
      }
    />
  );
};
