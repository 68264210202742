import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
import {
  urlEndpoint,
  authEndpoint,
  pubKeyImageKit,
} from "../../../middlewares/libraries/imageKit/ImageKit";
import GenerateImage from "../../../pages/doseeData/utils/GenerateImage";
import { MyButton } from "../../../pages/home/component/ButtonUtils";

export const ImageKitUpload = ({
  folder,
  thumbnail,
  onSuccess,
  thumbnailId,
  setThumbnail,
  setThumbnailId,
  isRestaurantModal = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(null);

  return (
    <>
      {showImageModal ? (
        <GenerateImage
          setThumbnail={setThumbnail}
          setThumbnailId={setThumbnailId}
          setShowImageModal={setShowImageModal}
        />
      ) : null}

      <IKContext
        urlEndpoint={urlEndpoint}
        publicKey={pubKeyImageKit}
        authenticationEndpoint={authEndpoint}
      >
        <IKImage
          src={thumbnail}
          width="200"
          className="rounded-md mx-auto w-auto h-44"
        />

        <AiOutlineLoading
          className={
            `h-6 text-gray-800 w-24 my-2 mx-auto animate-spin ` +
            (loading ? ` block` : `hidden`)
          }
        />

        <IKUpload
          fileName="image-upload.png"
          folder={folder}
          onError={(e) => setLoading(false)}
          onChange={(e) => setLoading(true)}
          onClick={() => {
            setLoading(false);
            setThumbnail(null);
            setThumbnailId(null);
          }}
          onSuccess={(e) => {
            setLoading(false);
            setThumbnail(e.url);
            setThumbnailId(e.fileId);
            if (isRestaurantModal) onSuccess(e.url, e.fileId);
          }}
          className={
            `w-48 file:w-auto font-bold text-gray-800 cursor-pointer text-xs mx-auto file:h-10 underline file:bg-gray-800 file:border-none file:mb-4 file:mt-2 file:py-2 file:px-4 file:hover:bg-gray-600 file:text-white file:font-bold file:cursor-pointer file:text-xs file:capitalize ` +
            (loading ? ` hidden` : `block`)
          }
        />
      </IKContext>

      <div className="w- h-auto">
        <MyButton
          onClick={() => setShowImageModal(true)}
          className="bg-blue-700 py-3"
        >
          Generate Image
        </MyButton>
      </div>
    </>
  );
};
