import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";

import { isPositionSelected } from "./positionHelper";
import { usePositionContext } from "../../../context/position/PositionContext";

export const ModalPosition = ({
  id,
  name,
  type,
  showPosition,
  setShowPosition,
}) => {
  const { position, createPosition, fetchPosition } = usePositionContext();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [localPosition, setLocalPosition] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  useEffect(() => {
    if (!position) fetchPosition();
    else setLocalPosition(position);
    // eslint-disable-next-line
  }, [position]);

  const save = async () => {
    setLoading(true);
    const { response, error } = await createPosition({
      id: id,
      position: selectedPosition,
      type: type,
    });

    if (response) {
      await fetchPosition();
      setSuccess(true);
      setError(false);
      setSelectedPosition(null);
    }
    if (error) {
      setError(true);
      setSuccess(false);
    }

    setLoading(false);
  };

  return (
    <div className={showPosition ? "block" : "hidden"}>
      <div className="justify-center w-fit mx-5 md:mx-0 md:w-full h-full items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none">
        <div className="relative w-auto mx-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="relative rounded-lg shadow p-4">
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={() => setShowPosition(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setShowPosition(false)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <p className="text-base capitalize py-4 text-center font-bold underline">
                Promoting Everywhere {type}s are listed
              </p>
              <p className="text-center pb-4">
                <b>Notice:</b> Wherever {type}s are listed, <br /> This is the
                position this {type} will occupy.
              </p>
              <div className="p-6 pt-0 text-left">
                <h3 className="mb-1 text-sm font-medium text-gray-900 capitalize">
                  {type}:{" "}
                  <span className="text-brand font-medium text-sm leading-loose line-clamp-1">
                    {name}
                  </span>
                  <br />
                </h3>
                <div className="mb-5 space-y-2 text-sm">
                  <p className="flex justify-start items-center space-x-1">
                    <span className="w-4 h-4 rounded-full bg-green-600/70"></span>
                    <span>This {type}'s position </span>
                  </p>
                  <p className="flex justify-start items-center space-x-1">
                    <span className="w-4 h-4 rounded-full bg-orange-500"></span>
                    <span>Remove at current Position </span>
                  </p>
                  <p className="flex justify-start items-center space-x-1">
                    <span className="w-4 h-4 rounded-full bg-red-600/70"></span>
                    <span>Position Taken</span>
                  </p>
                  <p className="flex justify-start items-center space-x-1">
                    <span className="w-4 h-4 rounded-full bg-gray-600/70"></span>
                    <span>Position Empty</span>
                  </p>
                  <p className="flex justify-start items-center space-x-1">
                    <span className="w-4 h-4 bg-blue-600/80 font-bold rounded-lg"></span>
                    <span>Selected Position</span>
                  </p>
                  <p className="text-xs text-gray-800">
                    <br />
                    Notice: <br /> 1. Placing an item on a taken place, <br />{" "}
                    It will override that previous item at that position. <br />{" "}
                    <br />
                    2. Selecting the current item position, <br /> will remove
                    that item at that position leaving that position empty
                  </p>
                </div>
                <div className="grid grid-cols-4 gap-4 mt-2">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((val, index) => {
                    return (
                      <Button
                        val={val}
                        key={index}
                        status={
                          localPosition
                            ? isPositionSelected(localPosition, val, type, id)
                            : null
                        }
                        selectedPosition={selectedPosition}
                        setSelectedPosition={setSelectedPosition}
                      />
                    );
                  })}
                </div>
                <div className="mt-5 w-full flex flex-col justify-center">
                  <span
                    className={
                      `my-2 text-xs font-bold ` +
                      (error || success
                        ? error
                          ? "text-red-600/80"
                          : "text-green-600/80"
                        : "hidden")
                    }
                  >
                    {error ? " An Error Occurred!" : "Success"}
                  </span>
                  {selectedPosition ? (
                    <button
                      type="button"
                      className={`text-white w-full font-mono text-sm flex items-center justify-center py-2 bg-black hover:bg-black/70`}
                      onClick={() => (loading ? null : save())}
                    >
                      {loading ? (
                        <AiOutlineLoading className="w-5 h-5 animate-spin" />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`text-white w-full font-mono text-sm flex items-center justify-center py-2 bg-black/70 cursor-not-allowed`}
                    >
                      No Position Selected
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed w-full h-full inset-0 z-[100] bg-black"></div>
    </div>
  );
};

const Button = ({ val, status, setSelectedPosition, selectedPosition }) => {
  return (
    <button
      type="button"
      className={
        `text-white font-mono text-sm flex items-center justify-center px-5 py-2 ` +
        (status === true && selectedPosition === val
          ? "bg-orange-500  hover:bg-orange-600 font-bold"
          : selectedPosition === val
          ? "bg-blue-600/80 hover:bg-blue-600/90 font-bold"
          : status === true
          ? "bg-green-600/70 hover:bg-green-600/80"
          : status === false
          ? "bg-red-800/80 hover:bg-red-800/90"
          : "bg-gray-600/70 hover:bg-gray-700/80")
      }
      onClick={() => setSelectedPosition(selectedPosition === val ? 0 : val)}
    >
      {val}
    </button>
  );
};
