import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  Message,
  TextArea,
  InputField,
  ActionButton,
} from "../../component/Component";
import { FOLDERS } from "../../../doseeData/utils/Utils";
import { codeOk, errorMessage } from "../../../../middlewares/index";
import { useBannerContext } from "../../../../context/banner/BannerContext";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";

const Add = () => {
  const { createOneBanner } = useBannerContext();
  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(0);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [type, setType] = useState("home");
  const [bannerStatus, setBannerStatus] = useState(1);
  const [description, setDescription] = useState(null);
  const bannerRoutes = "/banner";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setThumbnail(null);
    setThumbnailId(null);
    setTitle(null);
    setDescription(null);
  };

  const responses = (isError, status, message) => {
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  const addOneBannerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      return responses(true, 400, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      title: title,
      description: description,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      status: bannerStatus,
      type: type,
    };

    const response = await createOneBanner(data);
    if (response) {
      if (response.data.status === codeOk) {
        cleanFields();
        return responses(true, response.data.status, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.status, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: bannerRoutes }} />
      <form onSubmit={addOneBannerHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Message
            props={{
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.BANNER}
          />

          <Title props={{ title: "Banner Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Select
              props={{
                id: "type",
                title: "Page",
                option: [
                  "home",
                  "about",
                  "tour",
                  "market",
                  "contact",
                  "attraction",
                  "best",
                  "cities",
                  "countries",
                  "events",
                  "hotels",
                  "parties",
                  "safaris",
                  "timeframe events",
                ],
                onChange: (e) => {
                  setType(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Select
              props={{
                id: "banner_status",
                title: "Status",
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setBannerStatus(1);
                  else setBannerStatus(0);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <ActionButton
            props={{
              title: "Add Banner",
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
