/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  AiFillStar,
  AiOutlineCaretDown,
  AiOutlineFileImage,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import { default as CustomInput } from "react-phone-input-2";
import { MdOutlineCheckCircle, MdOutlineClose } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { countriesList, map } from "./data";
import { getCountry } from "../../../middlewares/sysNavigation/SysNavigation";
import { getOneLevel } from "../../../middlewares/breadCrumb/BreadCrumb";
import JoditEditor from "jodit-react";

export const Codes = [
  { country_short: "", country_code: "Select", value: "" },
  { country_short: "cd", country_code: "CD +243", value: "243" },
  { country_short: "ug", country_code: "UG +256", value: "256" },
];

export const InputField = ({ props }) => {
  return (
    <div className="space-y-2">
      <label
        htmlFor={props.id}
        className="flex items-center text-xs font-medium capitalize"
      >
        {props.title}
        {props.required === true ? (
          <AiFillStar className="w-3 h-3 text-red-700/70 mx-1 p-0" />
        ) : (
          <span className="hidden"></span>
        )}
      </label>
      <input
        id={props.id}
        type={props.type}
        className="w-full border-2 bg-white focus:border-gray-200 rounded text-sm focus:outline-none py-2 px-4 placeholder:text-xs placeholder:capitalize"
        defaultValue={props.default}
        disabled={props.disabled}
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.title}
      />
    </div>
  );
};

export const PasswordField = ({
  value,
  required,
  disabled,
  onChange,
  isConfirmPassword,
}) => {
  const [show, setShow] = useState(false);
  const label = !isConfirmPassword ? "Password" : "Confirm Password";
  return (
    <div className="relative">
      <label
        htmlFor={label}
        className="flex items-center space-x-1 text-xs font-medium"
      >
        <span>{label}</span>
        {required ? <AiFillStar className="text-sm text-red-700/70" /> : null}
      </label>
      <input
        id={label}
        disabled={disabled}
        onChange={onChange}
        placeholder={label}
        value={value ? value : ""}
        type={show ? "text" : "password"}
        className="w-full border-2 bg-white focus:border-gray-200 rounded text-sm focus:outline-none py-2 px-4 placeholder:text-xs"
      />
      <div
        onClick={() => setShow(!show)}
        className="absolute top-7 right-3 cursor-pointer hover:text-gray-600"
      >
        {show ? <FaEyeSlash /> : <FaEye />}
      </div>
    </div>
  );
};

export const Select = ({ props }) => {
  return (
    <div className="flex flex-col space-y-2">
      <label
        htmlFor={props?.id}
        className="flex items-center text-xs font-medium capitalize"
      >
        {props?.title}
        {props?.required ? (
          <AiFillStar className="w-3 h-3 text-red-700/70 mx-1 p-0" />
        ) : null}
      </label>
      <select
        name={props?.id}
        id={props?.id}
        disabled={props?.disabled}
        onClick={props?.onClick}
        onChange={props?.onChange}
        defaultValue={props?.preselect ? String(props?.preselect[0]?.name) : ""}
        className="w-full border-[1px] bg-white focus:border-gray-300 rounded text-xs py-[10px] focus:outline-none px-4 cursor-pointer"
      >
        {props?.option?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export const SelectInput = ({
  title,
  value,
  options,
  onClick,
  required,
  disabled,
  onChange,
  defaultValue,
  className,
}) => {
  return (
    <div className="flex flex-col space-y-1 capitalize text-xs font-medium">
      <label htmlFor={title} className="flex items-center">
        <span>{title}</span>
        {required ? <AiFillStar className="text-sm text-red-700/70" /> : null}
      </label>
      <select
        id={title}
        name={title}
        onClick={onClick}
        value={value}
        disabled={disabled}
        onChange={onChange}
        required={required}
        defaultValue={defaultValue}
        className={`w-full border-[1px] bg-white focus:border-gray-300 rounded text-xs py-2 focus:outline-none px-4 cursor-pointer ${className}`}
      >
        {options?.map((op, key) => (
          <option key={key} value={String(op)}>
            {op}
          </option>
        ))}
      </select>
    </div>
  );
};

export const SearchSelectInput = ({
  title,
  value,
  options,
  onClick,
  required,
  disabled,
  onChange,
}) => {
  const inputRef = useRef();
  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    const handleDropDown = () => {
      if (document.activeElement !== inputRef.current && dropDown) {
        setDropDown(false);
      }
    };
    document.addEventListener("click", handleDropDown);
    return () => document.removeEventListener("click", handleDropDown);
  }, [dropDown, value, document.activeElement, inputRef, options]);

  const optionsFilter = () => {
    if (!value || String(value) === "") return options;
    else {
      return options?.filter((x) =>
        String(x).toLowerCase().includes(String(value).toLowerCase())
      );
    }
  };

  return (
    <div className="flex flex-col relative capitalize z-[999] text-xs font-medium text-black">
      <div
        onClick={() => setDropDown(!dropDown)}
        className="flex flex-col relative w-full space-y-2"
      >
        <label htmlFor={title} className="flex items-center">
          <span>{title}</span>
          {required ? <AiFillStar className="text-sm text-red-700/70" /> : null}
        </label>
        <input
          id={title}
          type="text"
          ref={inputRef}
          onClick={onClick}
          disabled={disabled}
          aria-autocomplete="none"
          autoComplete="none"
          value={value ? value : ""}
          placeholder={`Search or Select ${title}`}
          className="py-[10px] rounded-md px-4 z-[999] focus:outline-none text-xs border-2 border-gray-200 placeholder:text-xs cursor-pointer"
          onChange={(e) => {
            setDropDown(true);
            onChange(e.target.value);
          }}
        />
        <AiOutlineCaretDown className="text-gray-800 absolute z-[999] right-2 top-7 cursor-pointer hover:text-gray-600 text-base" />
      </div>

      {dropDown ? (
        <div className="w-full bg-white text-gray-800 max-h-96 h-auto z-[999] absolute top-[55px] shadow-xl cursor-pointer overflow-y-auto border border-gray-600">
          {optionsFilter()?.map((op, key) => (
            <p
              key={key}
              onClick={() => {
                inputRef?.current?.focus();
                onChange(op);
                setDropDown(false);
              }}
              className={`hover:bg-gray-800 hover:text-white py-1 px-4 ${
                value === op
                  ? "bg-gray-800 text-white"
                  : "bg-white text-gray-600"
              }`}
            >
              {op}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const UploadButton = ({ props }) => {
  return (
    <div className="flex flex-col w-full items-center py-5 px-2 md:px-6 rounded shadow text-white bg-brand/10 opacity-90">
      <p className="font-bold text-base my-1 uppercase text-black">
        Upload {props.datatype}
      </p>

      <div className="flex flex-col justify-center items-center bg-gray-50 mt-5 w-full px-2 py-4 rounded shadow">
        {(() => {
          if (props.isLoaded && props.id !== "audio") {
            return (
              <img
                src={props.file}
                alt="select thumbnail"
                className="w-fit max-h-[150px] rounded"
              />
            );
          } else if (props.isLoaded && props.id === "audio") {
            return (
              <div className="flex flex-row justify-center items-center h-[150px] max-h-[150px]">
                <MdOutlineCheckCircle className="w-16 h-16 text-brand animate-pulse" />
              </div>
            );
          }
        })()}

        {props.isLoaded === false && (
          <AiOutlineCloudUpload className="w-16 h-16 text-gray-600 my-3" />
        )}

        <div
          className={
            "flex flex-col items-center justify-center " +
            (props.isLoaded === true && "hidden")
          }
        >
          <p className="flex justify-center items-center text-sm text-gray-600 font-bold">
            Browse to choose a file
            {props.required === true ? (
              <AiFillStar className="w-4 h-4 text-red-700/70 mx-1" />
            ) : (
              <span className="hidden"></span>
            )}
          </p>
          <div className="px-0">
            <input
              id={props.id}
              name={props.id}
              type="file"
              onChange={props.onChange}
              value={props.value}
              accept={!props.format ? ".jpg, .png, .jepg" : ".mp3"}
              className="w-full file:w-full file:h-12 file:bg-brand/70 file:border-none file:shadow-md file:rounded file:my-4 file:py-2 file:px-4 file:hover:bg-brand/80 file:text-white file:font-bold file:cursor-pointer file:text-sm file:uppercase"
            />
          </div>
        </div>
      </div>
      {props.isLoaded === true && (
        <div className="w-full mt-4">
          <p className="text-xs font-bold text-gray-600 my-2">Done!</p>
          <div className="bg-white flex w-full justify-between items-center px-2 py-4 rounded-md shadow">
            <div className="flex flex-row justify-center items-center">
              <AiOutlineFileImage className="w-8 h-8 text-gray-500" />
              <div className="mx-2">
                <p className="text-xs text-gray-700">{props.fileName}</p>
                <p className="text-xs text-gray-500">{props.size} Kb</p>
              </div>
            </div>
            <MdOutlineClose
              className="w-8 h-8 text-gray-900 hover:text-red-700/70 cursor-pointer"
              onClick={props.closeAction}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const PhoneInput = ({
  country,
  setPhone,
  phone = "",
  required = false,
}) => {
  const inputStyle = {
    width: "100%",
    height: "38px",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderColor: "rgb(229,231,235)",
  };

  const buttonStyle = {
    height: "38px",
    borderColor: "rgb(229,231,235)",
    backgroundColor: "rgb(243,244,246)",
  };

  let defaultCountry = undefined;

  if (getCountry()) {
    defaultCountry = countriesList?.find(
      (x) =>
        String(x.name).toLowerCase() ===
        String(getCountry()?.country?.name).toLowerCase()
    )?.code;
  } else if (getOneLevel().length > 0) {
    defaultCountry = countriesList?.find(
      (x) => x.id === getOneLevel()[0]?.data?.data?.country_id
    )?.code;
  } else {
    defaultCountry = countriesList?.find(
      (x) => x.name === (country || "Uganda")
    )?.code;
  }

  return (
    <div className="w-full space-y-2">
      <label className="flex items-center text-xs space-x-1 font-medium capitalize">
        <span>Phone Number</span>
        {required ? <AiFillStar className="text-sm text-red-700/70" /> : null}
      </label>
      <div className="flex flex-row">
        <CustomInput
          autoFormat={true}
          enableSearch={true}
          preferredCountries={["ug", "cd", "ke"]}
          onlyCountries={[
            "cd",
            "bu",
            "ke",
            "ug",
            "tz",
            "dz",
            "ao",
            "bj",
            "bw",
            "bf",
            "cm",
            "cv",
            "cf",
            "td",
            "km",
            "cg",
            "ci",
            "dj",
            "eg",
            "gq",
            "er",
            "sz",
            "et",
            "ga",
            "gm",
            "gh",
            "gn",
            "gw",
            "ls",
            "lr",
            "ly",
            "mg",
            "mw",
            "ml",
            "mr",
            "mu",
            "ma",
            "mz",
            "na",
            "ne",
            "ng",
            "rw",
            "st",
            "sn",
            "sc",
            "sl",
            "so",
            "za",
            "ss",
            "sd",
            "tg",
            "tn",
            "eh",
            "zm",
            "zw",
          ]}
          inputStyle={inputStyle}
          buttonStyle={buttonStyle}
          enableLongNumbers={false}
          value={phone ? phone : ""}
          onChange={(phone) => setPhone(phone)}
          country={defaultCountry ? defaultCountry : map?.Uganda?.code}
        />
      </div>
    </div>
  );
};

export const TextArea = ({ props }) => {
  const editor = useRef(null);

  return (
    <div className="w-full h-full relative space-y-2 col-span-1 xl:col-span-2 pt-4">
      <label
        htmlFor={props?.id}
        className="flex items-center text-xs font-medium capitalize"
      >
        {props?.title}
        {props?.required === true ? (
          <AiFillStar className="w-3 h-3 text-red-700/70 mx-1 p-0" />
        ) : null}
      </label>
      <JoditEditor
        ref={editor}
        className="text-sm"
        value={props?.value}
        onChange={(newContent) => {
          const e = {
            target: {
              value: newContent,
            },
          };
          return props?.onChange(e);
        }}
      />
    </div>
  );
};
