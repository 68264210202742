const api_version = "/api/v1";

const ENDPOINTS = Object.freeze({
  LOGIN_URL: `${api_version}/auth/admin/login`,
  SIGNUP_URL: `${api_version}/company/user/signup`,
  UPDATE_BY_SUPER_ADMIN: `${api_version}/company/update/byadmin`,
  UPDATE_PASSWORD: `${api_version}/auth/admin/update/password`,
  EVENTS: `${api_version}/event`,
});

export default ENDPOINTS;
