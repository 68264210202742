export const getPageRouteFromKey = ({ key }) => {
  switch (key) {
    case "event":
      return "/data/event";
    case "attraction":
      return "/data/attraction";
    case "best_of_africa":
      return "/data/bestofafrica";
    case "cinema":
      return "/data/cinema";
    case "music":
      return "/data/music";
    case "news":
      return "/data/news";
    case "restaurant":
      return "/data/restaurant";
    case "safari":
      return "/data/safari";
    case "city_guide":
      return "/data/city/guide";
    case "class":
      return "/data/class";
    case "food":
      return "/data/food";
    case "hotel":
      return "/data/hotel";
    case "museum":
      return "/data/museum";
    case "nightlife":
      return "/data/nightlife";
    case "party":
      return "/data/party";
    case "shopping":
      return "/data/shopping";
    case "things_to_do":
      return "/data/things/todo";
    case "tips_and_advice":
      return "/data/tips/advice";
    case "art":
      return "/data/art";
    case "book":
      return "/data/book";
    case "dance":
      return "/data/dance";
    case "ministry":
      return "/data/ministry";
    case "movie":
      return "/data/movie";
    case "theater":
      return "/data/theater";
    case "neighborhood":
      return "/data/neighborhood";
    case "product":
      return "/data/product";
    case "tour_package":
      return "/data/tour/package";
    case "getting_around":
      return "/data/get/around";
    default:
      return "/data/event";
  }
};
