/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { VscFileSubmodule } from "react-icons/vsc";
import { BsBook, BsPeople } from "react-icons/bs";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import {
  MdOutlineMuseum,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFoodBank,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
  MdMusicNote,
} from "react-icons/md";
import { SiHotelsdotcom } from "react-icons/si";
import { AiOutlineShopping } from "react-icons/ai";
import { BiCameraMovie } from "react-icons/bi";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { Card } from "../../component/Component";
import { useDashboardContext } from "../../../../context/dashboard/context/DashboardContext";
import { TableStatistics } from "../dashboard/DashBoard";
import { pushLevel, setAdminOption } from "../../../../middlewares";
import { addOneLevel } from "../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { Link } from "react-router-dom";

const Icon = [
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
];

const Content = () => {
  const { sysAdmin } = useAdminContext();
  const { stats, fetchData } = useDashboardContext();

  useEffect(() => {
    if (!stats) fetchData();
  }, [stats]);

  const List = [
    {
      name: "events",
      quantity: stats ? stats.event : "-",
      route: "/data/event",
    },
    {
      name: "attractions",
      quantity: stats ? stats.attraction : "-",
      route: "/data/attraction",
    },
    {
      name: "restaurants",
      quantity: stats ? stats.restaurant : "-",
      route: "/data/restaurant",
    },
    {
      name: "best of africa",
      quantity: stats ? stats.best_of_africa : "-",
      route: "/data/bestofafrica",
    },
    {
      name: "music",
      quantity: stats ? stats.music : "-",
      route: "/data/music",
    },
    {
      name: "news",
      quantity: stats ? stats.news : "-",
      route: "/data/news",
    },
    {
      name: "safaris",
      quantity: stats ? stats.safari : "-",
      route: "/data/safari",
    },
    {
      name: "tour packages",
      quantity: stats ? stats.tour_package : "-",
      route: "/data/tour/package",
    },
    {
      name: "cinema",
      quantity: stats ? stats.cinema : "-",
      route: "/data/cinema",
    },
    {
      name: "museums",
      quantity: stats ? stats.museum : "-",
      route: "/data/museum",
    },
    {
      name: "night life",
      quantity: stats ? stats.nightlife : "-",
      route: "/data/nightlife",
    },
    {
      name: "hotels",
      quantity: stats ? stats.hotel : "-",
      route: "/data/hotel",
    },
    {
      name: "classes",
      quantity: stats ? stats.class : "-",
      route: "/data/class",
    },
    {
      name: "shopping",
      quantity: stats ? stats.shopping : "-",
      route: "/data/shopping",
    },

    {
      name: "food",
      quantity: stats ? stats.food : "-",
      route: "/data/food",
    },

    {
      name: "books",
      quantity: stats ? stats.book : "-",
      route: "/data/book",
    },
    {
      name: "neighborhoods",
      quantity: stats ? stats.neighborhood : "-",
      route: "/data/neighborhood",
    },
    {
      name: "arts",
      quantity: stats ? stats.art : "-",
      route: "/data/art",
    },
    {
      name: "dance",
      quantity: stats ? stats.dance : "-",
      route: "/data/dance",
    },
    {
      name: "theaters",
      quantity: stats ? stats.theater : "-",
      route: "/data/theater",
    },
    {
      name: "City Guides",
      quantity: stats ? stats.city_guide : "-",
      route: "/data/city/guide",
    },
    {
      name: "Parties",
      quantity: stats ? stats.party : "-",
      route: "/data/party",
    },
    {
      name: "Things To Do",
      quantity: stats ? stats.things_to_do : "-",
      route: "/data/things/todo",
    },
    {
      name: "Tips And Advice",
      quantity: stats ? stats.tips_and_advice : "-",
      route: "/data/tips/advice",
    },

    {
      name: "Ministries",
      quantity: stats ? stats.ministry : "-",
      route: "/data/ministry",
    },
    {
      name: "Movies",
      quantity: stats ? stats.movie : "-",
      route: "/data/movie",
    },
    {
      name: "Getting Around",
      quantity: stats ? stats.getting_around : "-",
      route: "/data/get/around",
    },
  ];

  return (
    <div className="overflow-y-auto p-4 h-full rounded-b-xl bg-gray-50">
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                // We are excluding the first index since they are not pointing to
                if (
                  ![
                    "regions",
                    "countries",
                    "First Level",
                    "Second Level",
                    "Third Level",
                    "Fourth Level",
                    "tour package",
                  ].includes(card.name)
                ) {
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: `${sysAdmin?.first_name} ${sysAdmin?.last_name}`,
                    route: "/levels/first",
                    level: "",
                    country_id: 0,
                    data: {
                      data: {
                        name: "",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                  setAdminOption(sysAdmin?.admin_id);
                } else {
                  setAdminOption(undefined);
                }
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>

      <TableStatistics />
    </div>
  );
};

export default Content;
