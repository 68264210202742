/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { pushOnePlugImage } from "../../../../middlewares/sysNavigation/SysNavigation";
import { usePlugImageContext } from "../../../../context/plugImage/PlugImageContext";
import { Modal, Search, Pagination, MyButton } from "../../component/Component";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";

const addRoute = "/plug/image/add";
const editRoute = "/plug/image/edit";

const Overview = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);
  const [plugImageList, setPlugImageList] = useState([]);
  const { plugImage, fetchPlugImages, count } = usePlugImageContext();

  useEffect(() => {
    if (!plugImage) fetchPlugImages(page, pageSize);
    else setPlugImageList(plugImage);
  }, [plugImage]);

  const SearchList = (e) => {
    const key = String(e.target.value).toLowerCase();
    let searchArray = [];
    if (plugImage) {
      plugImage?.map((pImage) => {
        if (String(pImage?.type).toLowerCase().includes(key))
          return searchArray.push(pImage);
        return true;
      });
    }

    setPlugImageList(searchArray);
    if (key === "") setPlugImageList(plugImage);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Plug Image",
            onChange: (e) => SearchList(e),
          }}
        />
        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <MyButton
              title="Add Plug Image"
              className="bg-gray-800 hover:bg-gray-600 min-w-fit"
              onClick={() => {}}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/2">Type</th>
                <th className="border text-center w-1/2">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {plugImage ? (
                plugImageList?.map((pImage, key) => {
                  return (
                    <tr key={key}>
                      <td className="border px-2 lg:px-4 w-1/2">
                        {pImage?.type}
                      </td>
                      <td className="border w-1/2">
                        <ActionButton props={pImage} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/2">...</td>
                  <td className="border w-1/2">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchPlugImages(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { plugImage, updatePlugImageState, deletePlugImage } =
    usePlugImageContext();

  const [showModal, setShowModal] = useState(false);

  const plug_image_id = props.plug_image_id;
  const plugImage_name = props.type;
  const pImage = props;

  const deleteOnePlugImageById = async (plug_image_id) => {
    const { status } = await deletePlugImage(plug_image_id);
    if (status === 200) {
      if (plugImage) {
        const update = plugImage?.filter(
          (pImage) => pImage?.plug_image_id !== plug_image_id
        );
        updatePlugImageState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: plugImage_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOnePlugImageById(plug_image_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-3 px-8 bg-blue-800 hover:bg-blue-600`}
            onClick={() => pushOnePlugImage(pImage)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-3 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
