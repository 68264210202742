import React, { useState } from "react";

import { FaTimes } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

import { useGenerateContentContext } from "../../../context/generateContent";

const GenerateFullContentData = ({
  type,
  structure,
  onGenerated,
  setShowModal,
}) => {
  const [prompt, setPrompt] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { generateFullContentData } = useGenerateContentContext();

  const sendPrompt = async () => {
    if (!prompt || prompt === "") setIsError(true);

    setLoading(true);
    setIsError(false);

    const response = await generateFullContentData({
      data: {
        message: `${prompt}. The time format is "HH:mm", "HH:mm:ss" or "HH:mm:ss.SSS" where HH is 00-23, mm is 00-59, ss is 00-59, and SSS is 000-999. ALWAYS HAVE A DEFAULT VALUE FOR ALL THE FIELDS. IF YOU HAVE NO INFO FOR A FIELD, USE "none"`,
        numberOfContents: 1,
        contentType: type,
        structure,
      },
    });

    setLoading(false);

    if (response && response?.length > 0) {
      onGenerated(response[0]);
      setShowModal(false);
    }
  };

  return (
    <div className="bg-black/60 w-full h-full absolute z-[999999] overflow-y-auto top-0 left-0 flex justify-center items-start p-4">
      <div className="bg-white p-5 rounded-md w-full md:w-[450px] lg:w-[500px] space-y-4 overflow-y-auto">
        <div className="flex items-center justify-between border-b pb-4">
          <span className="text-base font-bold capitalize">
            Generate {type}
          </span>
          <button
            onClick={() => setShowModal(false)}
            className="bg-black text-white p-2 rounded-md cursor-pointer text-base"
          >
            <FaTimes />
          </button>
        </div>
        <div className="text-xs py-2 space-y-2">
          <p className="text-xs font-bold">Enter Your Prompt Here:</p>
          <textarea
            rows="3"
            value={prompt}
            onChange={(e) => {
              setIsError(false);
              setPrompt(e.target.value);
            }}
            className={`border w-full text-xs rounded-md outline-none p-4 ${
              isError ? "border border-red-700" : ""
            }`}
          />
        </div>
        {/* <p className="text-xs font-bold">Generated Result:</p>
        <div className="border text-xs p-2 rounded-md space-y-4">
          <div className="py-5 text-center text-[10px] font-semibold uppercase">
            Your generated {type} will appear here
          </div>
        </div> */}
        <button
          disabled={loading}
          onClick={sendPrompt}
          className="w-auto bg-black flex space-x-2 items-center text-xs font-bold text-white rounded-md py-3 px-5 disabled:cursor-not-allowed disabled:bg-black/60"
        >
          {loading ? <AiOutlineLoading className="animate-spin" /> : null}
          <span className="capitalize">Generate {type}</span>
        </button>
      </div>
    </div>
  );
};

export default GenerateFullContentData;
