/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  PhoneInput,
  InputField,
  DashboardIntro,
  MyActionButton,
  MyButton,
} from "../../home/component/Component";
import { codeOk } from "../../../middlewares/index";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { getOneDance } from "../../../middlewares/sysNavigation/SysNavigation";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import Pricing from "../../home/component/Pricing";
import GenerateDescription from "../utils/GenerateDescription";

const Edit = () => {
  const { updateOneContent } = useDoSeeDataContext();
  const dance = getOneDance();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const dance_id = dance?.dance_id;
  const [title, setTitle] = useState(dance?.title);
  const [thumbnail, setThumbnail] = useState(dance?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(dance?.image_cdn_id);
  const [venue, setVenue] = useState(dance?.venue);
  const [venueCapacity, setVenueCapacity] = useState(dance?.venue_capacity);
  const [startDate, setStartDate] = useState(dance?.start_date);
  const [endDate, setEndDate] = useState(dance?.end_date);
  const [startTime, setStartTime] = useState(dance?.start_time);
  const [endTime, setEndTime] = useState(dance?.end_time);
  const [email, setEmail] = useState(dance?.email_address);
  const [phoneNumber, setPhoneNumber] = useState(dance?.phone_number);
  const [whatsapp, setWhatsapp] = useState(dance?.whatsapp);
  const [facebook, setFacebook] = useState(dance?.facebook);
  const [twitter, setTwitter] = useState(dance?.twitter);
  const [instagram, setInstagram] = useState(dance?.instagram);
  const [youtube, setYoutube] = useState(dance?.youtube);
  const [hashtags, setHashtags] = useState(dance?.hashtag);
  const [googleMapCode, setGoogleMapCode] = useState(dance?.google_map_code);
  const [latitude, setLatitude] = useState(dance?.latitude);
  const [longitude, setLongitude] = useState(dance?.longitude);
  const [formattedAddress, setFormattedAddress] = useState(
    dance?.formatted_address
  );
  const [featured, setFeatured] = useState(dance?.featured);
  const [promoted, setPromoted] = useState(
    dance?.promoted ? dance?.promoted : "no"
  );
  const [danceStatus, setDanceStatus] = useState(dance?.status);
  const [description, setDescription] = useState(dance?.description);
  const [expo, setExpo] = useState(dance?.expo);
  const [pricing, setPricing] = useState(dance?.Pricings);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const danceRoutes = "/data/dance";

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneDanceHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id ? country_id : dance?.country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      title: title,
      slug: title ? title.toLowerCase().replace(/\s/g, "_") : null,
      description: description,
      venue: venue,
      venue_capacity: venueCapacity,
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      phone_number: phoneNumber,
      email_address: email,
      whatsapp: whatsapp,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
      hashtag: hashtags,
      audio_adv: "none",
      google_map_code: googleMapCode,
      latitude: latitude,
      longitude: longitude,
      formatted_address: formattedAddress,
      country_short: "none",
      status: danceStatus,
      featured: featured,
      promoted: promoted,
      expo: expo,
      pricing: pricing,
    };

    const { message, status } = await updateOneContent({
      data_id: dance_id,
      data: data,
      content: _TYPES_.DANCE,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });

    if (status === codeOk) responses(false, message);
    else responses(true, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: danceRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-5">
        <DashboardIntro props={{ intro: `Edit Dance`, name: entry_label }} />
      </div>

      <form onSubmit={updateOneDanceHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.DANCES)}
          />

          <Title props={{ title: "Dance Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                required: true,
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "venue",
                title: "Venue",
                type: "text",
                value: venue ? venue : "",
                onChange: (e) => {
                  setVenue(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "venue_capacity",
                title: "Venue Capacity",
                type: "number",
                value: venueCapacity ? venueCapacity : "",
                onChange: (e) => {
                  setVenueCapacity(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "formatted_address",
                title: "Formatted Address",
                type: "address",
                value: formattedAddress ? formattedAddress : "",
                onChange: (e) => {
                  setFormattedAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Date & Time" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "start_date",
                title: "Start Date",
                type: "date",
                value: startDate ? startDate : "",
                onChange: (e) => {
                  setStartDate(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "end_date",
                title: "End Date",
                type: "date",
                value: endDate ? endDate : "",
                onChange: (e) => {
                  setEndDate(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "start_time",
                title: "Start Time",
                type: "time",
                value: startTime ? startTime : "",
                onChange: (e) => {
                  setStartTime(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "end_time",
                title: "End Time",
                type: "time",
                value: endTime ? endTime : "",
                onChange: (e) => {
                  setEndTime(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Pricing" }} />

          <Pricing pricing={pricing} setPricing={setPricing} />

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phoneNumber}
                required={false}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "whatsapp",
                title: "Whatsapp",
                type: "text",
                value: whatsapp ? whatsapp : "",
                onChange: (e) => {
                  setWhatsapp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                value: facebook ? facebook : "",
                onChange: (e) => {
                  setFacebook(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                value: twitter ? twitter : "",
                onChange: (e) => {
                  setTwitter(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                value: instagram ? instagram : "",
                onChange: (e) => {
                  setInstagram(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                value: youtube ? youtube : "",
                onChange: (e) => {
                  setYoutube(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Geo Position" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "google_map_code",
                title: "Google Map Code",
                type: "text",
                value: googleMapCode ? googleMapCode : "",
                onChange: (e) => {
                  setGoogleMapCode(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "latitude",
                title: "Latitude",
                type: "text",
                value: latitude ? latitude : "",
                onChange: (e) => {
                  setLatitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "longitude",
                title: "Longitude",
                type: "text",
                value: longitude ? longitude : "",
                onChange: (e) => {
                  setLongitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "dance_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  danceStatus !== null
                    ? [{ name: danceStatus === 1 ? "visible" : "invisible" }]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setDanceStatus(1);
                  else setDanceStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "featured",
                title: "Featured",
                option: ["no", "yes"],
                preselect: featured !== null ? [{ name: featured }] : null,
                onChange: (e) => {
                  setFeatured(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "promoted",
                title: "Promoted",
                option: ["no", "yes"],
                preselect: promoted !== null ? [{ name: promoted }] : null,
                onChange: (e) => {
                  setPromoted(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "gallery",
                title: "Show in Gallery",
                option: ["no", "yes"],
                preselect:
                  expo !== null ? [{ name: expo === 0 ? "no" : "yes" }] : null,
                onChange: (e) => {
                  const choice = e.target.value;
                  setExpo(choice === "no" ? 0 : 1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
