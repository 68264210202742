import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../../config';

import { useAdminContext } from '../../admin/context/AdminContext';

const SecondLevelContext = createContext();

export const useSecondLevelContext = () => useContext(SecondLevelContext);

export const SecondLevelProvider = (props) => {
  const [secondLevels, setSecondLevels] = useState(null);
  const [secondLevel, setSecondLevel] = useState(null);
  const [countSecond, setCountSecond] = useState(0);

  const { sysAdmin } = useAdminContext();

  // Update the state
  const updateSecondLevelState = async (data) => {
    return setSecondLevels(data);
  };

  /**
   * Fetch all second levels
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchSecondLevels = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/second/level/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });
      if (response) {
        setSecondLevels(response.data.data.data);
        setCountSecond(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch by country id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchSecondByCountryId = async ({
    country_id,
    page = 1,
    pageSize = 20,
  }) => {
    try {
      const fetch_url =
        API_URL +
        `/api/v1/second/level/location/?page=${page}&pageSize=${pageSize}&country_id=${country_id}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setSecondLevels(response.data.data.data);
        setCountSecond(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Get a single second_level with metadata
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */

  const fetchSecondLevel = async (second_level_id) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/second/level/metadata/${second_level_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });
      if (response) {
        setSecondLevel(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new second level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addSecondLevel = async (data) => {
    try {
      const token = sysAdmin.token;
      const fetch_url = API_URL + `/api/v1/admin/second/level/create`;
      let errorResponse = null;

      const response = await axios
        .post(fetch_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update Second level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateSecondLevel = async (second_level_id, data) => {
    try {
      const token = sysAdmin.token;
      const update_url =
        API_URL + `/api/v1/admin/second/level/update/${second_level_id}`;
      let errorResponse = null;

      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a single second level by id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteSecondLevel = async (second_level_id) => {
    try {
      const token = sysAdmin.token;
      const fetch_url =
        API_URL + `/api/v1/admin/second/level/delete/${second_level_id}`;
      let errorResponse = null;

      const response = await axios
        .delete(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <SecondLevelContext.Provider
      value={{
        secondLevels,
        fetchSecondLevels,
        deleteSecondLevel,
        updateSecondLevelState,
        addSecondLevel,
        secondLevel,
        fetchSecondLevel,
        updateSecondLevel,
        countSecond,
        fetchSecondByCountryId,
      }}
    >
      {props.children}
    </SecondLevelContext.Provider>
  );
};
