/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { FaTimes } from "react-icons/fa";

import { MyButton } from "./ButtonUtils";
import { useCurrencyContext } from "../../../context/currency/CurrencyContext";
import { getCountry } from "../../../middlewares/sysNavigation/SysNavigation";
import { getOneLevel } from "../../../middlewares/breadCrumb/BreadCrumb";
import { useGeoPositionContext } from "../../doseeData/utils/geoPosition";

const Pricing = ({ pricing, setPricing }) => {
  const { currency } = useGeoPositionContext();

  return (
    <div className="pb-10 py-5 space-y-8 w-full">
      <div className="grid grid-cols-1 gap-5 w-full">
        {pricing?.map((price, key) => (
          <PricingComponent
            key={key}
            index={key}
            title={`Pricing Details - ${key + 1}`}
            name={price.name}
            pricing={pricing}
            amount={price.amount}
            setPricing={setPricing}
            currency={price.currency}
          />
        ))}
      </div>
      <div className="border-t border-gray-200 py-8">
        <MyButton
          type="button"
          onClick={() => {
            setPricing([
              ...pricing,
              {
                name: "",
                currency: currency,
                amount: 0,
                quantity: 1,
                status: 1,
              },
            ]);
          }}
          className="bg-black text-white font-bold text-sm rounded-xl p-5"
        >
          Add More Prices / Fees
        </MyButton>
      </div>
    </div>
  );
};

export default Pricing;

const PricingComponent = ({
  name,
  title,
  index,
  amount,
  pricing,
  currency,
  setPricing,
}) => {
  const removePrice = () => {
    // const arr = pricing?.filter(
    //   (p) => p?.name !== name && p?.currency === currency
    // );
    const arr = pricing.filter(
      (item) => !(item?.name === name && item?.currency === currency)
    );
    setPricing(arr);
  };

  const setPriceName = (name) => {
    const data = pricing.map((x) => x);
    data[index] = { ...data[index], name };
    setPricing(data);
  };

  const setCurrencyCode = (currency) => {
    const data = pricing.map((x) => x);
    data[index] = { ...data[index], currency };
    setPricing(data);
  };

  const setAmount = (amount) => {
    const data = pricing.map((x) => x);
    data[index] = { ...data[index], amount };
    setPricing(data);
  };

  return (
    <div className="space-y-2 w-full">
      <div className="flex items-center space-x-2">
        <div
          onClick={removePrice}
          className="bg-red-300 hover:bg-red-600 p-2 rounded-xl text-white text-base cursor-pointer"
        >
          <FaTimes />
        </div>
        <p className="font-bold">{title}</p>
      </div>
      <div className="flex space-x-2 items-center">
        <InputField
          title={"Price Name"}
          type={"text"}
          value={name}
          onChange={(e) => setPriceName(e.target.value)}
        />
        <InputField
          title={"Amount"}
          type={"number"}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <Select
          code={currency}
          onChange={(e) => setCurrencyCode(e.target.value)}
        />
      </div>
    </div>
  );
};

const InputField = ({ type, value, onChange, title }) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={title}
      className="w-full border-2 bg-white focus:border-gray-200 rounded text-sm focus:outline-none py-3 px-4 placeholder:text-xs placeholder:capitalize"
    />
  );
};

const Select = ({ onChange, code }) => {
  const { currency, fetchCurrencies } = useCurrencyContext();

  useEffect(() => {
    if (!currency) fetchCurrencies();
  }, [currency]);

  let defaultCurrency = undefined;

  if (getCountry()) {
    defaultCurrency = currency?.find(
      (x) =>
        String(x.name).toLowerCase() ===
        String(getCountry()?.country?.name).toLowerCase()
    )?.currency_symbol;
  } else if (getOneLevel().length > 0) {
    defaultCurrency = currency?.find(
      (x) => x?.country_id === getOneLevel()[0]?.data?.data?.country_id
    )?.currency_symbol;
  } else {
    defaultCurrency = currency[0]?.currency_symbol;
  }

  return currency ? (
    <select
      name={"price"}
      value={code ? code : defaultCurrency}
      onChange={onChange}
      defaultValue={defaultCurrency || "Select Currency"}
      className="w-full border-[1px] bg-white focus:border-gray-300 rounded text-xs py-3 focus:outline-none px-4 cursor-pointer"
    >
      {currency
        ? currency?.map((curr, key) => (
            <option key={key} value={curr?.currency_symbol}>
              {curr?.currency_symbol} - {curr?.currency_name}
            </option>
          ))
        : null}
    </select>
  ) : null;
};
