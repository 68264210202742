export const map = {
  "Congo, Democratic Republic of the ": {
    code: "cd",
  },
  Burundi: {
    code: "bu",
  },
  Kenya: {
    code: "ke",
  },
  Uganda: {
    code: "ug",
  },
  Tanzania: {
    code: "tz",
  },
  Algeria: {
    code: "dz",
  },
  Angola: {
    code: "ao",
  },
  Benin: {
    code: "bj",
  },
  Botswana: {
    code: "bw",
  },
  "Burkina Faso": {
    code: "bf",
  },
  Cameroon: {
    code: "cm",
  },
  "Cape Verde": {
    code: "cv",
  },
  "Central African Republic": {
    code: "cf",
  },
  Chad: {
    code: "td",
  },
  "Comoros, Union of the ": {
    code: "km",
  },
  "Congo, Republic of the": {
    code: "cg",
  },
  "Cote D'Ivoire": {
    code: "ci",
  },
  Djibouti: {
    code: "dj",
  },
  Egypt: {
    code: "eg",
  },
  "Equatorial Guinea": {
    code: "gq",
  },
  Eritrea: {
    code: "er",
  },
  Eswatini: {
    code: "sz",
  },
  Ethiopia: {
    code: "et",
  },
  Gabon: {
    code: "ga",
  },
  "Gambia, The ": {
    code: "gm",
  },
  Ghana: {
    code: "gh",
  },
  Guinea: {
    code: "gn",
  },
  "Guinea-Bissau": {
    code: "gw",
  },
  Lesotho: {
    code: "ls",
  },
  Liberia: {
    code: "lr",
  },
  "Libya (Libyan Arab Jamahiriya)": {
    code: "ly",
  },
  Madagascar: {
    code: "mg",
  },
  Malawi: {
    code: "mw",
  },
  Mali: {
    code: "ml",
  },
  Mauritania: {
    code: "mr",
  },
  Mauritius: {
    code: "mu",
  },
  Morocco: {
    code: "ma",
  },
  Mozambique: {
    code: "mz",
  },
  Namibia: {
    code: "na",
  },
  Niger: {
    code: "ne",
  },
  Nigeria: {
    code: "ng",
  },
  Rwanda: {
    code: "rw",
  },
  "Sao Tome and Principe": {
    code: "st",
  },
  Senegal: {
    code: "sn",
  },
  Seychelles: {
    code: "sc",
  },
  "Sierra Leone": {
    code: "sl",
  },
  Somalia: {
    code: "so",
  },
  "South Africa": {
    code: "za",
  },
  "South Sudan": {
    code: "ss",
  },
  Sudan: {
    code: "sd",
  },
  Togo: {
    code: "tg",
  },
  Tunisia: {
    code: "tn",
  },
  "Western Sahara": {
    code: "eh",
  },
  "Zambia, Republic of": {
    code: "zm",
  },
  "Zimbabwe, Republic of ": {
    code: "zw",
  },
};

export const countriesList = [
  { name: "Uganda", id: 52, code: "ug", slug: "uganda" },
  {
    name: "Congo, Democratic Republic of the ",
    id: 12,
    code: "cd",
    slug: "congo_drc",
  },
  { name: "Kenya", id: 26, code: "ke", slug: "kenya" },
  { name: "Tanzania", id: 49, code: "tz", slug: "tanzania" },
  { name: "Algeria", id: 1, code: "dz", slug: "algeria" },
  { name: "Angola", id: 2, code: "ao", slug: "angola" },
  { name: "Benin", id: 3, code: "bj", slug: "benin" },
  { name: "Burundi", id: 6, code: "bi", slug: "burundi" },
  { name: "Botswana", id: 4, code: "bw", slug: "botswana" },
  { name: "Burkina Faso", id: 5, code: "bf", slug: "burkina_faso" },
  { name: "Cameroon", id: 7, code: "cm", slug: "cameroon" },
  { name: "Cape Verde", id: 8, code: "cv", slug: "cape_verde" },
  {
    name: "Central African Republic",
    id: 9,
    code: "cf",
    slug: "central_african_republic",
  },
  { name: "Chad", id: 10, code: "td", slug: "chad" },
  { name: "Comoros, Union of the ", id: 11, code: "km", slug: "comoros" },
  { name: "Congo, Republic of the", id: 13, code: "cg", slug: "congo_bra" },
  { name: "Cote D'Ivoire", id: 14, code: "ci", slug: "cote_d_ivoire " },
  { name: "Djibouti", id: 15, code: "dj", slug: "djibouti" },
  { name: "Egypt", id: 16, code: "eg", slug: "egypt" },
  { name: "Equatorial Guinea", id: 17, code: "gq", slug: "equatorial_guinea" },
  { name: "Eritrea", id: 18, code: "er", slug: "eritrea" },
  { name: "Eswatini", id: 19, code: "sz", slug: "eswatini" },
  { name: "Ethiopia", id: 20, code: "et", slug: "ethiopia" },
  { name: "Gabon", id: 21, code: "ga", slug: "gabon" },
  { name: "Gambia, The ", id: 22, code: "gm", slug: "gambia" },
  { name: "Ghana", id: 23, code: "gh", slug: "ghana" },
  { name: "Guinea", id: 24, code: "gn", slug: "guinea" },
  { name: "Guinea-Bissau", id: 25, code: "gw", slug: "guinea_bissau" },
  { name: "Lesotho", id: 27, code: "ls", slug: "lesotho" },
  { name: "Liberia", id: 28, code: "lr", slug: "liberia" },
  {
    name: "Libya (Libyan Arab Jamahiriya)",
    id: 29,
    code: "ly",
    slug: "libya",
  },
  { name: "Madagascar", id: 30, code: "mg", slug: "madagascar" },
  { name: "Malawi", id: 31, code: "mw", slug: "malawi" },
  { name: "Mali", id: 32, code: "ml", slug: "mali" },
  { name: "Mauritania", id: 33, code: "mr", slug: "mauritania" },
  { name: "Mauritius", id: 34, code: "mu", slug: "mauritius" },
  { name: "Morocco", id: 35, code: "ma", slug: "morocco" },
  { name: "Mozambique", id: 36, code: "mz", slug: "mozambique" },
  { name: "Namibia", id: 37, code: "na", slug: "namibia" },
  { name: "Niger", id: 38, code: "ne", slug: "niger" },
  { name: "Nigeria", id: 39, code: "ng", slug: "nigeria" },
  { name: "Rwanda", id: 40, code: "rw", slug: "rwanda" },
  { name: "Sao Tome and Principe", id: 41, code: "st", slug: "sao_tome" },
  { name: "Senegal", id: 42, code: "sn", slug: "senegal" },
  { name: "Seychelles", id: 43, code: "sc", slug: "seychelles" },
  { name: "Sierra Leone", id: 44, code: "sl", slug: "sierra_leone" },
  { name: "Somalia", id: 45, code: "so", slug: "somalia" },
  { name: "South Africa", id: 46, code: "za", slug: "south_africa" },
  { name: "South Sudan", id: 47, code: "ss", slug: "south_sudan" },
  { name: "Sudan", id: 48, code: "sd", slug: "sudan" },
  { name: "Togo", id: 50, code: "tg", slug: "togo" },
  { name: "Tunisia", id: 51, code: "tn", slug: "tunisia" },
  { name: "Western Sahara", id: 53, code: "eh", slug: "western_sahara" },
  { name: "Zambia, Republic of", id: 54, code: "zm", slug: "zambia" },
  { name: "Zimbabwe, Republic of ", id: 55, code: "zw", slug: "zimbabwe" },
];

export const allowedCountries = [
  "cd",
  "bu",
  "ke",
  "ug",
  "tz",
  "dz",
  "ao",
  "bj",
  "bw",
  "bf",
  "cm",
  "cv",
  "cf",
  "td",
  "km",
  "cg",
  "ci",
  "dj",
  "eg",
  "gq",
  "er",
  "sz",
  "et",
  "ga",
  "gm",
  "gh",
  "gn",
  "gw",
  "ls",
  "lr",
  "ly",
  "mg",
  "mw",
  "ml",
  "mr",
  "mu",
  "ma",
  "mz",
  "na",
  "ne",
  "ng",
  "rw",
  "st",
  "sn",
  "sc",
  "sl",
  "so",
  "za",
  "ss",
  "sd",
  "tg",
  "tn",
  "eh",
  "zm",
  "zw",
];
