import React from "react";

import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";

export const Password = () => {
  return (
    <Auth children={<Screen component={<Overview />} title="Password" />} />
  );
};
