import React from "react";

import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";

import { RegionalBlocksProvider } from "../../../../context/regionalBlocks/RegionalBlocksContext";

export const RegionalBlocks = () => {
  return (
    <RegionalBlocksProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Regional Blocks"
          />
        }
      />
    </RegionalBlocksProvider>
  );
};

export const AddRegionalBlocks = () => {
  return (
    <RegionalBlocksProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Regional Block"
          />
        }
      />
    </RegionalBlocksProvider>
  );
};

export const EditRegionalBlocks = () => {
  return (
    <RegionalBlocksProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Regional Block"
          />
        }
      />
    </RegionalBlocksProvider>
  );
};
