/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { MyButton } from "../../component/Component";
import { useInfoContext } from "../../../../context/info/InfoContext";
import { pushOneAbout } from "../../../../middlewares/sysNavigation/SysNavigation";

const editRoute = "/about/edit";

const Overview = () => {
  const [localAbout, setLocalAbout] = useState(null);
  const { about, fetchAbout } = useInfoContext();

  useEffect(() => {
    if (!about) fetchAbout();
    else setLocalAbout(about);
  }, [about]);

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/5">Mission</th>
                <th className="border p-2 lg:px-4 font-bold w-1/5">Vision</th>
                <th className="border p-2 lg:px-4 font-bold w-1/5">Goals</th>
                <th className="border p-2 lg:px-4 font-bold w-1/5">
                  Objectives
                </th>
                <th className="border text-center w-1/5">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {localAbout ? (
                <tr key={localAbout.about_us_id}>
                  <td className="border px-2 lg:px-4 w-1/5">Our Mission</td>
                  <td className="border px-2 lg:px-4 w-1/5">Our Vision</td>
                  <td className="border px-2 lg:px-4 w-1/5">Our Goal</td>
                  <td className="border px-2 lg:px-4 w-1/5">Our Objectives</td>
                  <td className="border w-1/5">
                    <ActionButton props={localAbout} />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/5">...</td>
                  <td className="border px-2 lg:px-4 w-1/5">...</td>
                  <td className="border px-2 lg:px-4 w-1/5">...</td>
                  <td className="border px-2 lg:px-4 w-1/5">...</td>
                  <td className="border w-1/5">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  return (
    <>
      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600"
            onClick={() => pushOneAbout(props)}
          />
        </Link>
      </div>
    </>
  );
};

export default Overview;
