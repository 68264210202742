/* eslint-disable array-callback-return */
const tL = (val, k) => String(val).toLowerCase().includes(k);
export const SearchMatchingAdmins = (e, admins) => {
  const search = [];
  const k = String(e?.target?.value).toLowerCase();
  admins?.map((X) => {
    const { email: e, phonenumber: p, first_name: f, last_name: l } = X;
    if (tL(e, k) || tL(l, k) || tL(f, k) || tL(p, k)) search.push(X);
  });
  if (k === "") return admins;
  return search;
};
