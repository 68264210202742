import React from "react";

import { Auth } from "../../../../context/admin/Authentication";
import Screen from "../Screen";
import Overview from "./Overview";

import { PaymentProvider } from "../../../../context/Payment/PaymentContext.js";

export const Payment = () => {
  return (
    <PaymentProvider>
      <Auth children={<Screen component={<Overview />} title="Payment" />} />
    </PaymentProvider>
  );
};
