import React from "react";

const TextField = ({ onChange, value, title, type, defaultValue }) => {
  return (
    <div>
      <label htmlFor={title} className="flex w-auto relative text-gray-800">
        <input
          type={type}
          name={title}
          value={value}
          onChange={onChange}
          placeholder={title}
          defaultValue={defaultValue}
          autoComplete="off"
          className="w-full border-[1px] px-4 bg-white focus:border-logo text-sm focus:outline-none py-4 placeholder:text-xs placeholder:text-gray-600 rounded-md"
        />
      </label>
    </div>
  );
};

export default TextField;
