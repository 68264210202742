import React, { useState } from "react";
import { getOneTourPackage } from "../../../middlewares/sysNavigation/SysNavigation";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { codeOk } from "../../../middlewares";
import { GoBack } from "../../home/component/GoBackUtils";
import { MyMessage } from "../../home/component/messageUtils";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { Title } from "../../home/component/TitleUtils";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { InputField, Select, TextArea } from "../../home/component/FormUtils";
import { MyActionButton } from "../../home/component/ActionButtonUtils";
import { useGeoPositionContext } from "../utils/geoPosition";
import { DashboardIntro } from "../../home/component/DashboardIntroUtils";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { _TYPES_ } from "../utils/types";
import { MyButton } from "../../home/component/ButtonUtils";
import BestTimeToVisit from "../../home/component/BestTimeToVisit";
import { useAdminContext } from "../../../context/admin/context/AdminContext";
import Pricing from "../../home/component/Pricing";
import TourImagesModal from "./addTourImages";
import GenerateDescription from "../utils/GenerateDescription";

const Edit = () => {
  const { sysAdmin } = useAdminContext();
  const { updateOneContent } = useDoSeeDataContext();
  const {
    tags,
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const tour = getOneTourPackage();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const tour_package_id = tour?.tour_package_id;
  const [thumbnail, setThumbnail] = useState(tour?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(tour?.image_cdn_id);
  const [title, setTitle] = useState(tour?.title);
  const [description, setDescription] = useState(tour?.description);
  const [highlights, setHighlights] = useState(tour?.highlights);
  const [itineraryFocus, setItineraryFocus] = useState(tour?.itinerary_focus);
  const [groupSize, setGroupSize] = useState(tour?.group_size);
  const [ageRange, setAgeRange] = useState(tour?.age_range);
  const [flightTransport, setFlightTransport] = useState(
    tour?.flight_transport
  );
  const [startCity, setStartCity] = useState(tour?.start_city);
  const [endCity, setEndCity] = useState(tour?.end_city);
  const [theme, setTheme] = useState(tour?.theme);
  const [type, setType] = useState(tour?.type);
  const [destination, setDestination] = useState(tour?.destination);
  const [tripIncludes, setTripIncludes] = useState(tour?.trip_includes);
  const [tripExcludes, setTripExcludes] = useState(tour?.trip_excludes);
  const [priceFrom, setPriceFrom] = useState(tour?.price_from);
  const [pricePerDay, setPricePerDay] = useState(tour?.price_per_day);
  const [citiesIncluded, setCitiesIncluded] = useState(tour?.cities_included);
  const [duration, setDuration] = useState(tour?.duration);
  const [showTop, setShowTop] = useState(tour?.show_top);
  const [tourStatus, setTourStatus] = useState(tour?.status);
  const [hashtags, setHashtags] = useState(tour?.hashtag);
  const [itineraries, setItineraries] = useState(
    tour
      ? tour?.Itineraries?.map((x) => {
          return { title: x?.title, description: x?.description };
        })
      : [{ title: null, description: null }]
  );
  const [safetyInfos, setSafetyInfos] = useState(
    tour
      ? tour?.SafetyInfos?.map((x) => {
          return { title: x?.title, description: x?.description };
        })
      : [{ title: null, description: null }]
  );

  const [bestTime, setBestTime] = useState(
    tour?.best_time_to_visit ? tour?.best_time_to_visit : []
  );
  const [pricing, setPricing] = useState(tour?.Pricings);

  const [showTourImages, setShowTourImages] = useState(false);
  const [tourImages, setTourImages] = useState(tour.TourPackageImages);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const tourRoutes = "/data/tour/package";

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneTourHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) responses(true, "Image Required");
    else await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      title: title,
      description: description,
      highlights: highlights,
      itinerary_focus: itineraryFocus,
      group_size: groupSize,
      age_range: ageRange,
      flight_transport: flightTransport,
      start_city: startCity,
      end_city: endCity,
      theme: theme,
      destination: destination,
      trip_includes: tripIncludes,
      trip_excludes: tripExcludes,
      price_from: String(priceFrom),
      price_per_day: String(pricePerDay),
      cities_included: citiesIncluded,
      duration: String(duration),
      type: type ? type : "Regional Tourism",
      show_top: showTop,
      hashtag: `${tags}, ${hashtags}`,
      company_id: tour?.company_id || sysAdmin?.company_id,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "...",
      status: tourStatus,
      itineraries: itineraries
        ? itineraries?.filter((val) => val.title && val.description)
        : [],
      safety_infos: safetyInfos
        ? safetyInfos?.filter((val) => val.title && val.description)
        : [],
      best_time_to_visit: bestTime?.length > 0 ? String(bestTime) : null,
      pricing: pricing,
      tour_images: tourImages?.filter((x) => x?.thumbnail !== null),
    };

    const { message, status } = await updateOneContent({
      data_id: tour_package_id,
      data: data,
      content: _TYPES_.TOURPACKAGE,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });
    if (status === codeOk) responses(false, message);
    else responses(true, message);
  };

  const addToType = (value) => setType((type ? type + "," : "") + value);

  const removeFromType = (value) => {
    const arr = type?.split(",").filter((pos) => value !== pos && value !== "");
    let stream = "";
    arr.map(
      (v, i) => (stream = i < arr.length - 1 ? stream + v + "," : stream + v)
    );
    setType(stream);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: tourRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-5">
        <DashboardIntro
          props={{ intro: `Edit Tour Package`, name: entry_label }}
        />
      </div>
      <form onSubmit={updateOneTourHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.TOUR_PACKAGES)}
          />
          <Title props={{ title: "Tour Details" }} />
          <div className="py-5 w-full space-y-2">
            <p className="text-sm font-bold">Tour Package Type </p>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4">
              {[
                "Regional Tourism",
                "National Tourism",
                "Adventure Tourism",
                "Beach Tourism",
                "Cultural Tourism",
                "Ecotourism",
                "Heritage Tourism",
                "Green Tourism",
                "Sustainable tourism",
                "Leisure",
                "Culinary",
                "Religious",
                "Music and Dance",
                "Traditional Craft",
              ].map((val, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center capitalize w-full h-full"
                >
                  <p className="text-xs min-w-fit w-full h-full">{val}</p>
                  {type?.toString()?.split(",")?.includes(val) ? (
                    <MdCheckBox
                      onClick={() => removeFromType(val)}
                      className="text-xl cursor-pointer"
                    />
                  ) : (
                    <MdCheckBoxOutlineBlank
                      onClick={() => addToType(val)}
                      className="text-xl cursor-pointer"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="w-full h-full">
            <p className="text-xs font-bold">Best Time To Visit</p>
            <BestTimeToVisit bestTime={bestTime} setBestTime={setBestTime} />
          </div>

          <Title props={{ title: "Pricing" }} />

          <Pricing pricing={pricing} setPricing={setPricing} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Tour Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "top",
                title: "Show In Top Tours",
                option: ["no", "yes"],
                preselect:
                  showTop !== null
                    ? [{ name: showTop === 0 ? "no" : "yes" }]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setShowTop(0);
                  else setShowTop(1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "groupSize",
                title: "Group Size",
                type: "text",
                value: groupSize ? groupSize : "",
                onChange: (e) => {
                  setGroupSize(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "flightTransport",
                title: "Flight fee included?",
                type: "text",
                value: flightTransport ? flightTransport : "",
                onChange: (e) => {
                  setFlightTransport(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "startCity",
                title: "Start City",
                type: "text",
                value: startCity ? startCity : "",
                onChange: (e) => {
                  setStartCity(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "endCity",
                title: "End City",
                type: "text",
                value: endCity ? endCity : "",
                onChange: (e) => {
                  setEndCity(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "priceFrom",
                title: "Price From (USD)",
                type: "text",
                value: priceFrom ? parseInt(priceFrom) : "",
                onChange: (e) => {
                  setPriceFrom(parseInt(e.target.value));
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "pricePerDay",
                title: "Price Per Day (USD)",
                type: "text",
                value: pricePerDay ? parseInt(pricePerDay) : "",
                onChange: (e) => {
                  setPricePerDay(parseInt(e.target.value));
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "citiesIncluded",
                title: "Cities Included (comma separated)",
                type: "text",
                value: citiesIncluded ? citiesIncluded : "",
                onChange: (e) => {
                  setCitiesIncluded(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "duration",
                title: "duration",
                type: "text",
                value: duration ? parseInt(duration) : "",
                onChange: (e) => {
                  const amount = parseInt(e.target.value);
                  setDuration(amount);
                  const array = Array.from({ length: amount }).map((x, key) => {
                    return {
                      title: `Day ${key + 1} Itinerary`,
                      description: null,
                    };
                  });
                  setItineraries(array);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "itineraryFocus",
                title: "Itinerary Focus",
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setItineraryFocus(1);
                  else setItineraryFocus(0);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "highlights",
                title: "Highlights (comma separated)",
                type: "text",
                value: highlights ? highlights : "",
                onChange: (e) => {
                  setHighlights(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "ageRange",
                title: "Age Range (comma separated)",
                type: "text",
                value: ageRange ? ageRange : "",
                onChange: (e) => {
                  setAgeRange(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <TextArea
              props={{
                id: "theme",
                title: "Theme (comma separated)",
                type: "text",
                value: theme ? theme : "",
                onChange: (e) => {
                  setTheme(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "destination",
                title: "Destination (comma separated)",
                type: "text",
                value: destination ? destination : "",
                onChange: (e) => {
                  setDestination(e?.target?.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "tripIncludes",
                title: "Trip Includes (comma separated)",
                type: "text",
                value: tripIncludes ? tripIncludes : "",
                onChange: (e) => {
                  setTripIncludes(e?.target?.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "tripExcludes",
                title: "Trip Excludes (comma separated)",
                type: "text",
                value: tripExcludes ? tripExcludes : "",
                onChange: (e) => {
                  setTripExcludes(e?.target?.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Itineraries" }} />
          <div className="flex flex-col justify-start items-center w-full">
            {itineraries?.map((itinerary, index) => (
              <div
                key={index}
                className="border-b-[1px] py-2 border-gray-200 w-full pb-4 space-y-2"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                  <InputField
                    props={{
                      id: "title",
                      title: "Title",
                      type: "text",
                      disabled: true,
                      value: itinerary?.title ? itinerary?.title : "",
                      onChange: (e) => {
                        setItineraries([
                          ...itineraries?.map((_e_, i) => {
                            if (i === index) _e_.title = e?.target?.value;
                            return _e_;
                          }),
                        ]);
                        setError(null);
                        setSuccess(null);
                      },
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                  <TextArea
                    props={{
                      id: "description",
                      title: "Description",
                      type: "text",
                      value: itinerary.description ? itinerary.description : "",
                      onChange: (e) => {
                        setItineraries([
                          ...itineraries?.map((_e_, i) => {
                            if (i === index) _e_.description = e.target.value;
                            return _e_;
                          }),
                        ]);
                        setError(null);
                        setSuccess(null);
                      },
                    }}
                  />
                </div>
              </div>
            ))}
          </div>

          <Title props={{ title: "Safety Infos" }} />
          <div className="flex flex-col justify-start items-center w-full">
            {safetyInfos?.map((safe, index) => (
              <div className="border-b-[1px] py-2 space-y-2 border-gray-200 w-full pb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                  <InputField
                    props={{
                      id: "title",
                      title: "Title",
                      type: "text",
                      value: safe.title ? safe.title : "",
                      onChange: (e) => {
                        setSafetyInfos([
                          ...safetyInfos?.map((_e_, i) => {
                            if (i === index) _e_.title = e.target.value;
                            return _e_;
                          }),
                        ]);
                        setError(null);
                        setSuccess(null);
                      },
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                  <TextArea
                    props={{
                      id: "description",
                      title: "Description",
                      type: "text",
                      value: safe.description ? safe.description : "",
                      onChange: (e) => {
                        setSafetyInfos([
                          ...safetyInfos?.map((_e_, i) => {
                            if (i === index) _e_.description = e.target.value;
                            return _e_;
                          }),
                        ]);
                        setError(null);
                        setSuccess(null);
                      },
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="flex flex-row justify-start items-center my-4">
              <MyButton
                type="button"
                title="Add Safety Info"
                className="py-3 px-8 bg-blue-900 hover:bg-blue-600"
                onClick={() =>
                  setSafetyInfos((it) => [
                    ...it,
                    { title: null, description: null },
                  ])
                }
              />
            </div>
          </div>

          <div className="">
            <TourImagesModal
              showModal={showTourImages}
              onClick={() => setShowTourImages(false)}
              setTourImages={setTourImages}
              tourImages={tourImages}
            />
          </div>

          <div className="py-5 w-full">
            <Title props={{ title: "Change or More Images" }} />
            <MyButton
              className="bg-black p-5 rounded-xl"
              onClick={() => setShowTourImages(true)}
            >
              Click to change or add more Images
            </MyButton>
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 w-full">
            <Select
              props={{
                id: "tour_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  tourStatus !== null
                    ? [
                        {
                          name: tourStatus === 1 ? "visible" : "invisible",
                        },
                      ]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setTourStatus(1);
                  else setTourStatus(0);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
