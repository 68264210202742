import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { API_URL } from "../config";
import { useAdminContext } from "../admin/context/AdminContext";

const BannerContext = createContext();

export const useBannerContext = () => useContext(BannerContext);

export const BannerProvider = (props) => {
  const [banner, setBanner] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateBannerState = async (data) => {
    // await fetchBanners();
    return setBanner(data);
  };

  /**
   * Fetch all banner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchBanners = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/banner/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setBanner(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new banner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneBanner = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/banner/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a banner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateBanner = async (tour_package_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/banner/update/${tour_package_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a banner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteBanner = async (tour_package_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/admin/banner/delete/${tour_package_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <BannerContext.Provider
      value={{
        banner,
        count,
        createOneBanner,
        fetchBanners,
        updateBanner,
        deleteBanner,
        updateBannerState,
      }}
    >
      {props.children}
    </BannerContext.Provider>
  );
};
