import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const BestTimeToVisit = ({ bestTime, setBestTime }) => {
  const bests = String(bestTime).toLowerCase()?.split(",");

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const bestTimeToVisit = [];

  bests.map((b) => {
    if (months.includes(b)) {
      bestTimeToVisit.push(b);
    }
    return true;
  });

  const removeFromBest = (m) => {
    setBestTime(
      bestTime?.filter(
        (b) => String(b).toLowerCase() !== String(m).toLowerCase()
      )
    );
  };

  const addToBest = (m) => {
    setBestTime([...bestTimeToVisit, m]);
  };

  return (
    <div className="w-full h-full pb-5">
      <div className="flex h-full space-x-4 overflow-x-auto w-full text-sm py-2 no-srollbars">
        {months?.map((m, key) => {
          return (
            <div key={key} className="flex flex-col items-start capitalize">
              <p className="text-xs capitalize">{m}</p>
              {bestTimeToVisit?.includes(String(m).toLowerCase()) ? (
                <MdCheckBox
                  onClick={() => removeFromBest(m)}
                  className="text-xl cursor-pointer"
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  onClick={() => addToBest(m)}
                  className="text-xl cursor-pointer"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BestTimeToVisit;
