import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  MyActionButton,
} from "../../component/Component";
import { FOLDERS } from "../../../doseeData/utils/Utils";
import { usePartnerContext } from "../../../../context/partner/PartnerContext";
import { getOnePartner } from "../../../../middlewares/sysNavigation/SysNavigation";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";

const Edit = () => {
  const { updatePartnerState, updatePartner } = usePartnerContext();
  const partner = getOnePartner();
  const partner_id = partner.partner_id;
  const [thumbnail, setThumbnail] = useState(partner.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(partner.image_cdn_id);
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(partner.name);
  const [partnerStatus, setPartnerStatus] = useState(partner.status);
  const [description, setDescription] = useState(partner.description);
  const partnerRoutes = "/partner";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
  };

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOnePartnerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      name: title,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      description: description,
      status: partnerStatus,
    };

    const { status, message } = await updatePartner(partner_id, data);
    if (status === 200) {
      updatePartnerState({ partner_id, ...data });
      cleanFields();
      responses(false, message);
    } else {
      setLoading(false);
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: partnerRoutes }} />
      <form onSubmit={updateOnePartnerHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.PARTNERS}
          />

          <Title props={{ title: "Partner Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "partner_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  partnerStatus !== null
                    ? [
                        {
                          name: partnerStatus === 1 ? "visible" : "invisible",
                        },
                      ]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setPartnerStatus(1);
                  else setPartnerStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
