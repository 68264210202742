import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { ClimateProvider } from "../../../../context/climate/ClimateContext";

export const Climate = () => {
  return (
    <ClimateProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Climate"
          />
        }
      />
    </ClimateProvider>
  );
};

export const AddClimate = () => {
  return (
    <ClimateProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Climate"
          />
        }
      />
    </ClimateProvider>
  );
};

export const EditClimate = () => {
  return (
    <ClimateProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Climate"
          />
        }
      />
    </ClimateProvider>
  );
};
