import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { pushOneBanner } from "../../../../middlewares/sysNavigation/SysNavigation";
import { useBannerContext } from "../../../../context/banner/BannerContext";
import { Modal, Search, Button, Pagination } from "../../component/Component";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";

// const addRoute = "/banner/add";
const editRoute = "/banner/edit";

const Overview = () => {
  let [bannerList, setBannerList] = useState([]);
  const { banner, fetchBanners, count } = useBannerContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    // console.log(data_request_body);
    if (!banner) fetchBanners(page, pageSize);
    setBannerList(banner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (banner !== null && banner !== undefined)
      banner.map((_banner_) => {
        if (
          _banner_.title.toString().toLowerCase().includes(key) ||
          _banner_.description.toString().toLowerCase().includes(key)
        )
          return searchArray.push(_banner_);
        return true;
      });

    setBannerList(searchArray);
    if (key === "") setBannerList(banner);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Banner",
            onChange: (e) => SearchList(e),
          }}
        />
        {/* <div className='w-full flex justify-end my-5'>
          <Link to={addRoute}>
            <Button
              props={{
                type: 'button',
                title: 'Add Banner',
                category: 'default',
                action: 'add',
                onClick: null,
              }}
            />
          </Link>
        </div> */}

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/2">Title</th>
                <th className="border p-2 lg:px-4 font-bold w-1/2">
                  Description
                </th>
                <th className="border text-center">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {banner ? (
                (() => {
                  if (!bannerList) bannerList = [...banner];
                  return bannerList.map((_banner_) => {
                    return (
                      <tr key={_banner_.banner_id}>
                        <td className="border px-2 lg:px-4 w-1/2">
                          {_banner_.title}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/2">
                          {_banner_.description}
                        </td>
                        <td className="border">
                          <ActionButton props={_banner_} />
                        </td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/2">...</td>
                  <td className="border px-2 lg:px-4 w-1/2">...</td>
                  <td className="border">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchBanners(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { banner, updateBannerState, deleteBanner } = useBannerContext();

  const [showModal, setShowModal] = useState(false);

  const banner_id = props.banner_id;
  const banner_name = props.title;
  const _banner_ = props;

  const deleteOneBannerById = async (banner_id) => {
    const response = await deleteBanner(banner_id);
    if (response.data.status === 200) {
      if (banner) {
        // Update the banner data
        const update = banner.filter(
          (_banner_) => _banner_.banner_id !== banner_id
        );
        // Update the Global State of Regions
        updateBannerState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: banner_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneBannerById(banner_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <Button
            props={{
              type: "button",
              title: "Edit",
              category: "blue",
              action: "edit",
              onClick: () => {
                pushOneBanner(_banner_);
              },
            }}
          />
        </Link>
        <Button
          props={{
            type: "button",
            title: "Delete",
            category: "red",
            action: "delete",
            onClick: () => {
              setShowModal(true);
            },
          }}
        />
      </div>
    </>
  );
};

export default Overview;
