import React from "react";
import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { CompanyProvider } from "../../../../context/company/companyContext";
import CompanyDash from "./CompanyDashboard";
import View from "./View";

export const Company = () => {
  return (
    <CompanyProvider>
      <Auth children={<Screen title="Company" component={<Overview />} />} />
    </CompanyProvider>
  );
};

export const AddCompany = () => {
  return (
    <CompanyProvider>
      <Auth children={<Screen title="Add Company" component={<Add />} />} />
    </CompanyProvider>
  );
};

export const ViewCompany = () => {
  return (
    <CompanyProvider>
      <Auth children={<Screen title="View Company" component={<View />} />} />
    </CompanyProvider>
  );
};

export const EditCompany = () => {
  return (
    <CompanyProvider>
      <Auth children={<Screen title="Edit Company" component={<Edit />} />} />
    </CompanyProvider>
  );
};

export const CompanyDashboard = () => {
  return (
    <CompanyProvider>
      <Auth
        children={
          <Screen title="Company Dashboard" component={<CompanyDash />} />
        }
      />
    </CompanyProvider>
  );
};
