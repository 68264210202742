/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { useUserContext } from "../../../../context/user/UserContext";
import { pushOneUser } from "../../../../middlewares/sysNavigation/SysNavigation";
import { Modal, Search, MyButton, Pagination } from "../../component/Component";

// const addRoute = '/user/add';
const editRoute = "/user/edit";

const Overview = () => {
  const [usersList, setUsersList] = useState([]);
  const { users, fetchUsers, count } = useUserContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!users) fetchUsers(page, pageSize);
    else setUsersList(users);
  }, [users]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (users !== null && users !== undefined)
      users.map((_user_) => {
        if (
          _user_.email.toString().toLowerCase().includes(key) ||
          _user_.phonenumber.toString().toLowerCase().includes(key)
        )
          return searchArray.push(_user_);
        return true;
      });

    setUsersList(searchArray);
    if (key === "") setUsersList(users);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "User",
            onChange: (e) => SearchList(e),
          }}
        />

        <div className="mb-5 overflow-y-auto w-full py-5">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">
                  Full Name
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">Email</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">Acion</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {users ? (
                usersList.map((_user_) => {
                  return (
                    <tr key={_user_.user_id}>
                      <td className="border px-2 lg:px-4 w-1/3">
                        {_user_.first_name} {"-"} {_user_.last_name}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/3">
                        {_user_.email}
                      </td>
                      <td className="border">
                        <ActionButton props={_user_} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchUsers(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const [showModal, setShowModal] = useState(false);
  const { users, updateUserState, deleteUser } = useUserContext();

  const user_id = props.user_id;
  const user_name = props.fullname;
  const _user_ = props;

  const deleteOneUserById = async (user_id) => {
    const { status } = await deleteUser(user_id);
    if (status === 200) {
      if (users) {
        // Update the users data
        const update = users.filter((_user_) => _user_.user_id !== user_id);
        updateUserState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: user_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneUserById(user_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600"
            onClick={() => pushOneUser(_user_)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-2 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
