import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  InputField,
  MyMessage,
  MyActionButton,
} from "../../component/Component";
import { usePartnerContext } from "../../../../context/partner/PartnerContext";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS } from "../../../doseeData/utils/Utils";

const Add = () => {
  const { createOnePartner } = usePartnerContext();
  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [partnerStatus, setPartnerStatus] = useState(1);
  const [description, setDescription] = useState(null);
  const partnerRoutes = "/partner";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setThumbnail(null);
    setThumbnailId(null);
    setTitle(null);
    setDescription(null);
  };

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const addOnePartnerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      return responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      name: title,
      description: description,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      status: partnerStatus,
    };

    const { status, message } = await createOnePartner(data);
    if (status === 200) {
      cleanFields();
      responses(false, message);
    } else {
      setLoading(false);
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: partnerRoutes }} />
      <form onSubmit={addOnePartnerHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.PARTNERS}
          />

          <Title props={{ title: "Partner Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                required: true,
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "event_status",
                title: "Status",
                required: true,
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setPartnerStatus(1);
                  else setPartnerStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                required: true,
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Partner"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
