import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { API_URL } from "../config";
import { useAdminContext } from "../admin/context/AdminContext";

const TourPackageContext = createContext();

export const useTourPackageContext = () => useContext(TourPackageContext);

export const TourPackageProvider = (props) => {
  const [tourPackages, setTourPackages] = useState(null);
  const [tourPackage, setTourPackage] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateTourPackageState = async (data) => {
    // await fetchTourPackages();
    return setTourPackages(data);
  };

  /**
   * Fetch all tourPackage
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchTourPackages = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/tour/package/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setTourPackages(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch one tourPackage
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchOneTour = async (id) => {
    try {
      const fetch_url = API_URL + `/api/v1/tour/package/${id}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setTourPackage(response.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new tour/package
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneTourPackage = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/tour/package/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a tour/package
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateTourPackage = async (tour_package_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/tour/package/update/${tour_package_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a tour/package
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteTourPackage = async (tour_package_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/admin/tour/package/delete/${tour_package_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <TourPackageContext.Provider
      value={{
        tourPackages,
        createOneTourPackage,
        fetchTourPackages,
        updateTourPackage,
        deleteTourPackage,
        updateTourPackageState,
        count,
        fetchOneTour,
        tourPackage,
        setTourPackage,
      }}
    >
      {props.children}
    </TourPackageContext.Provider>
  );
};
