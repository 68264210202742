import React, { useState } from "react";
import { useContinentRegionContext } from "../../../../../context/continentRegion/context/ContinentRegionContext";
import {
  Title,
  GoBack,
  TextArea,
  InputField,
  MyMessage,
  MyActionButton,
  MyButton,
} from "../../../component/Component";
import { FOLDERS } from "../../../../doseeData/utils/Utils";
import { ImageKitUpload } from "../../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { defaultImage } from "../../../../../middlewares/libraries/imageKit/ImageKit";
import GenerateDescription from "../../../../doseeData/utils/GenerateDescription";

const regionRoute = "/region/manage";
const addTilte = "Region Details";

const AddRegion = () => {
  const { addRegion } = useContinentRegionContext();

  const [name, setName] = useState(null);
  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [description, setDescription] = useState(null);
  const [numOfCountry, setNumOfCountry] = useState(null);
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setLoading(false);
    setThumbnail(null);
    setThumbnailId(null);
    setName(null);
    setNumOfCountry(null);
    setDescription(null);
  };

  const responses = (error, message) => {
    setError(error);
    setLoading(false);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const sendAddRegionRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) responses(true, "Image Required");
    else await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      name: name,
      slug: name.toLowerCase().replace(/\s/g, "_"),
      continent_id: 1,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      description: description,
      country_number: numOfCountry,
    };

    const { status, message } = await addRegion(data);
    if (status === 200) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: regionRoute }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <form onSubmit={sendAddRegionRequest}>
        <div className=" flex flex-col items-center font-semibold text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: addTilte }} />

          <div className="w-full mb-10">
            <ImageKitUpload
              thumbnail={thumbnail}
              thumbnailId={thumbnailId}
              setThumbnail={setThumbnail}
              setThumbnailId={setThumbnailId}
              folder={FOLDERS.CONTINENT_REGIONS}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "name",
                title: "Name",
                type: "text",
                required: true,
                value: name ? name : "",
                onChange: (e) => {
                  setName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "number_of_countries",
                title: "Number of Countries",
                type: "number",
                required: true,
                value: numOfCountry ? numOfCountry : "",
                onChange: (e) => {
                  setNumOfCountry(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
        </div>

        <div className=" w-full pt-8">
          <MyButton
            onClick={() => setShowDescriptionModal(true)}
            className="bg-blue-700 py-3"
          >
            Generate Description
          </MyButton>
          <TextArea
            props={{
              id: "description",
              title: "Description",
              type: "text",
              value: description ? description : "",
              onChange: (e) => {
                setDescription(e.target.value);
                setError(null);
                setSuccess(null);
              },
            }}
          />
        </div>

        <div className="flex justify-center w-auto">
          <MyActionButton
            loading={loading}
            title="Add Region"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default AddRegion;
