import React from "react";
import { Title } from "../../component/TitleUtils";
import { PasswordField } from "../../component/FormUtils";
import { MyActionButton } from "../../component/ActionButtonUtils";
import { getOneAdmin } from "../../../../middlewares/sysNavigation/SysNavigation";
import { validatePassword } from "../../../../controllers/admin/adminControllers";
import { useAdminsContext } from "../../../../context/admin/context/AdminManageContext";

const PasswordSettings = ({
  loading,
  setError,
  password,
  setAction,
  setSuccess,
  setPassword,
  setErrorMessage,
  confirm_password,
  setConfirmPassword,
  setLoading,
  responses,
}) => {
  const { updatePassword } = useAdminsContext();

  const admin = getOneAdmin();
  const admin_id = admin?.admin_id;

  const updatePasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { isError, message } = validatePassword(password, confirm_password);
    if (isError) {
      responses(true, message);
    } else if (password !== confirm_password) {
      responses(true, "Passwords Should Match");
    } else {
      await sendPasswordRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendPasswordRequest = async () => {
    const data = { password: password };
    const { message, status } = await updatePassword(admin_id, data);
    if (status === 200 || status === 201) {
      setPassword(null);
      setConfirmPassword(null);
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <form onSubmit={updatePasswordHandler}>
      <div className="mt-2 flex flex-col items-center justify-center font-medium text-sm md:text-base">
        <Title props={{ title: "2. Update Password" }} />
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
          <PasswordField
            value={password}
            required={true}
            onChange={(e) => {
              setPassword(e.target.value);
              setError(null);
              setSuccess(null);
            }}
          />

          <PasswordField
            value={confirm_password}
            isConfirmPassword={true}
            required={true}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setError(null);
              setSuccess(null);
            }}
          />
        </div>

        <MyActionButton
          loading={loading}
          title="Update Password"
          onClick={() => {
            setError(false);
            setSuccess(false);
            setAction("password");
            setErrorMessage(null);
          }}
        />
      </div>
    </form>
  );
};

export default PasswordSettings;
