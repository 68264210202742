import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const AdvertisementContext = createContext();

export const useAdvertisementContext = () => useContext(AdvertisementContext);

export const AdvertisementProvider = (props) => {
  const [Advertisement, setAdvertisement] = useState(null);
  const { sysAdmin } = useAdminContext();

  const updateAdvertisementState = async (data) => {
    return setAdvertisement(data);
  };

  /**
   * Fetch all Advertisement
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchAdvertisements = async () => {
    try {
      const fetch_url = API_URL + '/api/v1/advertisement';
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setAdvertisement(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new Advertisement
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneAdvertisement = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/advertisement/create';
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) setAdvertisement(response.data.data);

      return { response, errorResponse };
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a Advertisement
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateAdvertisement = async (advertisement_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/advertisement/update/${advertisement_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a Advertisement
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteAdvertisement = async (advertisement_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/advertisement/delete/${advertisement_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <AdvertisementContext.Provider
      value={{
        Advertisement,
        createOneAdvertisement,
        fetchAdvertisements,
        updateAdvertisement,
        deleteAdvertisement,
        updateAdvertisementState,
      }}
    >
      {props.children}
    </AdvertisementContext.Provider>
  );
};
