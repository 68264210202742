/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { useAgentContext } from "../../../../context/agent/AgentContext";
import { Modal, Search, Pagination, MyButton } from "../../component/Component";
import { pushOneAgent } from "../../../../middlewares/sysNavigation/SysNavigation";

const addRoute = "/agent/add";
const editRoute = "/agent/edit";

const Overview = () => {
  const [agentList, setAgentList] = useState([]);
  const { agent, fetchAgents, count } = useAgentContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!agent) fetchAgents(page, pageSize);
    else setAgentList(agent);
  }, [agent]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (agent !== null && agent !== undefined)
      agent.map((_agent_) => {
        if (_agent_.name.toString().toLowerCase().includes(key))
          return searchArray.push(_agent_);
        return true;
      });

    setAgentList(searchArray);
    if (key === "") setAgentList(agent);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Agent",
            onChange: (e) => SearchList(e),
          }}
        />
        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <MyButton
              title="Add Agent"
              className="bg-gray-800 hover:bg-gray-600 min-w-fit"
              onClick={() => {}}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/4">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  Biz. Reg. No.
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  Date Of Joining
                </th>
                <th className="border text-center w-1/4">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {agent ? (
                agentList.map((agt) => {
                  const date = new Date(agt?.createdAt);
                  const joinDate =
                    date.getFullYear() +
                    "-" +
                    date.getMonth().toString().padStart(2, "0") +
                    "-" +
                    date.getDate().toString().padStart(2, "0");

                  return (
                    <tr key={agt?.dsa_agents_id}>
                      <td className="border px-2 lg:px-4 w-1/4">{agt?.name}</td>
                      <td className="border px-2 lg:px-4 w-1/4">
                        {agt?.agent_biz_reg_no}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/4">{joinDate}</td>
                      <td className="border w-1/4">
                        <ActionButton props={agt} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchAgents(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { agent, updateAgentState, deleteAgent } = useAgentContext();

  const [showModal, setShowModal] = useState(false);

  const dsa_agents_id = props.dsa_agents_id;
  const agent_name = props.name;
  const _agent_ = props;

  const deleteOneAgentById = async (dsa_agents_id) => {
    const response = await deleteAgent(dsa_agents_id);
    if (response.data.status === 200) {
      if (agent) {
        updateAgentState(
          agent.filter((_agent_) => _agent_.dsa_agents_id !== dsa_agents_id)
        );
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: agent_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneAgentById(dsa_agents_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => pushOneAgent(_agent_)}
          />
        </Link>
        <MyButton
          title="Delete"
          className="bg-red-800 hover:bg-red-600 min-w-fit disabled:bg-red-300"
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
