import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const TeamContext = createContext();

export const useTeamContext = () => useContext(TeamContext);

export const TeamProvider = (props) => {
  const [team, setTeam] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateTeamState = async (data) => {
    // await fetchTeams();
    return setTeam(data);
  };

  /**
   * Fetch all team
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchTeams = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/team/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setTeam(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new team
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneTeam = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/admin/team/create';
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a team
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateTeam = async (team_id, data) => {
    try {
      const update_url = API_URL + `/api/v1/admin/team/update/${team_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a team
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteTeam = async (team_id) => {
    try {
      const delete_url = API_URL + `/api/v1/admin/team/delete/${team_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <TeamContext.Provider
      value={{
        team,
        createOneTeam,
        fetchTeams,
        updateTeam,
        deleteTeam,
        updateTeamState,
        count,
      }}
    >
      {props.children}
    </TeamContext.Provider>
  );
};
