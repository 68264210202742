import React from "react";

import Overview from "./manage/Overview";
import DashboardLevels from "./DashboardLevels";
import Add from "./manage/Add";
import Edit from "./manage/Edit";
import DashboardLevel from "./manage/DashboardLevel";

import Screen from "../Screen";

import { Auth } from "../../../../context/admin/Authentication";

import { DashboardProvider } from "../../../../context/dashboard/context/DashboardContext";

import { ClimateProvider } from "../../../../context/climate/ClimateContext";
import { EthnicGroupProvider } from "../../../../context/ethnicGroup/EthnicGroupContext";
import { LanguageProvider } from "../../../../context/language/LanguageContext";
import { ReligionProvider } from "../../../../context/religion/ReligionContext";

import { CountryProvider } from "../../../../context/country/countryContext/CountryContext";

import {
  FirstLevelProvider,
  SecondLevelProvider,
  ThirdLevelProvider,
  FourthLevelProvider,
  DashboardLevelProvider,
} from "../../../../context/levels/levelsContext";

export const LevelsDashboard = () => {
  return (
    <DashboardProvider>
      <Auth
        children={
          <Screen
            component={<DashboardLevels />}
            title="Levels Dashboard"
          />
        }
      />
    </DashboardProvider>
  );
};

export const Levels = () => {
  return (
    <FirstLevelProvider>
      <SecondLevelProvider>
        <ThirdLevelProvider>
          <FourthLevelProvider>
            <Auth
              children={
                <Screen
                  component={<Overview />}
                  title="All Levels"
                />
              }
            />
          </FourthLevelProvider>
        </ThirdLevelProvider>
      </SecondLevelProvider>
    </FirstLevelProvider>
  );
};

export const LevelDashboard = () => {
  return (
    <DashboardLevelProvider>
      <Auth
        children={
          <Screen
            component={<DashboardLevel />}
            title="Level Dashboard"
          />
        }
      />
    </DashboardLevelProvider>
  );
};

export const AddLevel = () => {
  return (
    <ClimateProvider>
      <EthnicGroupProvider>
        <ReligionProvider>
          <LanguageProvider>
            <CountryProvider>
              <FirstLevelProvider>
                <SecondLevelProvider>
                  <ThirdLevelProvider>
                    <FourthLevelProvider>
                      <Auth
                        children={
                          <Screen
                            component={<Add />}
                            title="Add Level"
                          />
                        }
                      />
                    </FourthLevelProvider>
                  </ThirdLevelProvider>
                </SecondLevelProvider>
              </FirstLevelProvider>
            </CountryProvider>
          </LanguageProvider>
        </ReligionProvider>
      </EthnicGroupProvider>
    </ClimateProvider>
  );
};

export const EditLevel = () => {
  return (
    <ClimateProvider>
      <EthnicGroupProvider>
        <ReligionProvider>
          <LanguageProvider>
            <CountryProvider>
              <FirstLevelProvider>
                <SecondLevelProvider>
                  <ThirdLevelProvider>
                    <FourthLevelProvider>
                      <Auth
                        children={
                          <Screen
                            component={<Edit />}
                            title="Edit Level"
                          />
                        }
                      />
                    </FourthLevelProvider>
                  </ThirdLevelProvider>
                </SecondLevelProvider>
              </FirstLevelProvider>
            </CountryProvider>
          </LanguageProvider>
        </ReligionProvider>
      </EthnicGroupProvider>
    </ClimateProvider>
  );
};
