import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  PhoneInput,
  DashboardIntro,
  MyActionButton,
} from "../../home/component/Component";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";

const Add = () => {
  const { createOneContent } = useDoSeeDataContext();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [price, setPrice] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [email, setEmail] = useState(null);
  const [website, setWebsite] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [adStatus, setAdStatus] = useState(1);
  const [description, setDescription] = useState(null);

  const eventRoutes = "/data/advertisement";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setTitle(null);
    setThumbnail(null);
    setThumbnailId(null);
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setWebsite(null);
    setPrice(0);
    setEmail(null);
    setPhoneNumber(null);
    setCurrency(null);
    setDescription(null);
  };

  const responses = (error, message) => {
    setError(error);
    setLoading(false);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const addOneAdHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      title: title,
      slug: title?.toLowerCase()?.replace(/\s/g, "_"),
      description: description,
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      price: price,
      website: website,
      currency: currency,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId,
      phone_number: phoneNumber,
      email_address: email,
      status: adStatus,
    };

    const { status, message } = await createOneContent({
      data: data,
      content: _TYPES_.ADV,
    });
    if (status === 200 || status === 201) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: eventRoutes }} />
      <div className="my-2">
        <DashboardIntro props={{ intro: `Add Ad`, name: entry_label }} />
      </div>
      <form onSubmit={addOneAdHandler}>
        <div className="flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.ADV)}
          />

          <Title props={{ title: "Ad Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                required: true,
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Date & Time" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "start_date",
                title: "Start Date",
                type: "date",
                value: startDate ? startDate : "",
                required: true,
                onChange: (e) => {
                  setStartDate(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "end_date",
                title: "End Date",
                type: "date",
                value: endDate ? endDate : "",
                required: true,
                onChange: (e) => {
                  setEndDate(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "start_time",
                title: "Start Time",
                type: "time",
                value: startTime ? startTime : "",
                onChange: (e) => {
                  setStartTime(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "end_time",
                title: "End Time",
                type: "time",
                value: endTime ? endTime : "",
                onChange: (e) => {
                  setEndTime(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Entrance" }} />

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "price",
                title: "Price (enter figures without separators)",
                type: "number",
                value: price ? price : "",
                onChange: (e) => {
                  setPrice(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "currency",
                title: "Currency",
                type: "text",
                value: currency ? currency : "",
                onChange: (e) => {
                  setCurrency(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "website",
                title: "Website",
                type: "text",
                required: true,
                value: website ? website : "",
                onChange: (e) => {
                  setWebsite(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phoneNumber}
                required={false}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "event_status",
                title: "Status",
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setAdStatus(1);
                  else setAdStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                required: true,
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Ad"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
