/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { FaTimes } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { Pagination } from "../../home/component/Component";
import { _PAGE_SIZE_ } from "../../../middlewares/other/tools";

const RestaurantModal = ({ setShowModal, setLists, lists }) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [restaurants, setRestaurants] = useState(null);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  const fetchRestaurant = async (p, s) => {
    const response = await axios.get(
      `https://api.doseeafrica.com/api/v1/restaurant/?page=${
        p ? p : page
      }&pageSize=${s ? s : pageSize}`
    );

    if (response) {
      setRestaurants(response?.data?.data?.data);
      setCount(response?.data?.data?.count);
    }
  };

  useEffect(() => {
    if (!restaurants) fetchRestaurant();
  }, [restaurants]);

  return (
    <div className="relative flex justify-center items-center">
      <div className="fixed left-auto right-auto w-11/12 md:mx-4 lg:mx-0 md:w-auto top-3 md:top-8 items-center bottom-3 h-auto overflow-x-hidden overflow-y-auto shadow inset-0 z-[150] outline-none focus:outline-none">
        <div className="w-full h-auto p-6 pt-0 text-center flex flex-col outline-none focus:outline-none bg-white">
          <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
            <div className="flex flex-col justify-center">
              <div
                onClick={() => setShowModal(false)}
                className="w-full flex justify-end items-center cursor-pointer"
              >
                <FaTimes className="text-red-700 text-4xl" />
              </div>
              <div className="flex py-5 text-xs font-bold capitalize justify-center items-center">
                <p>
                  Please Check/Uncheck to Add/Remove a Restaurant that provides
                  this food
                </p>
              </div>
              <div className="mb-5 overflow-y-auto w-full py-2">
                <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
                  <thead>
                    <tr className="space-x-2">
                      <th className="border p-2 lg:px-4 font-bold w-1/2">
                        Name
                      </th>
                      <th className="border text-center">Check / Uncheck</th>
                    </tr>
                  </thead>
                  <tbody className="space-x-2 text-left border-gray-300">
                    {restaurants &&
                      restaurants?.map((restau) => {
                        return (
                          <tr key={restau.restaurant_id}>
                            <td className="border px-2 lg:px-4 w-1/2">
                              {restau.name}
                            </td>
                            <td className="border flex justify-center">
                              <div className="flex flex-row justify-end items-center px-2 py-3 space-x-4 text-xs">
                                <input
                                  type="checkbox"
                                  className="w-6 h-6 cursor-pointer"
                                  checked={
                                    lists?.find(
                                      (v) =>
                                        v.restaurant_id === restau.restaurant_id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // add to list
                                      setLists([
                                        ...lists,
                                        {
                                          restaurant_id: restau.restaurant_id,
                                          name: restau.name,
                                          thumbnail: restau.thumbnail,
                                          description: restau.description,
                                        },
                                      ]);
                                    } else {
                                      // remove from list
                                      setLists([
                                        ...lists?.filter(
                                          (v) =>
                                            v.restaurant_id !==
                                            restau?.restaurant_id
                                        ),
                                      ]);
                                    }
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <Pagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total_items={count}
                callbackFunction={(_page_, _pageSize_) => {
                  fetchRestaurant(_page_, _pageSize_);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed w-full h-full inset-0 z-[100] bg-black"></div>
    </div>
  );
};

export default RestaurantModal;
