import React from "react";
import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { TeamProvider } from "../../../../context/team/TeamContext";
// import { useAdminContext } from "../../../../context/admin/context/AdminContext";
// import { Navigate } from "react-router-dom";

// Redirect to home
// const ReturnHome = () => {
//   const { sysAdmin } = useAdminContext();
//   if (sysAdmin.role !== "admin")
//     return (
//       <Navigate
//         replace
//         to="/"
//       />
//     );
//   else return null;
// };

export const Team = () => {
  return (
    <TeamProvider>
      {/* <ReturnHome /> */}
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Team"
          />
        }
      />
    </TeamProvider>
  );
};

export const AddTeam = () => {
  return (
    <TeamProvider>
      {/* <ReturnHome /> */}
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Team"
          />
        }
      />
    </TeamProvider>
  );
};

export const EditTeam = () => {
  return (
    <TeamProvider>
      {/* <ReturnHome /> */}
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Team"
          />
        }
      />
    </TeamProvider>
  );
};
