import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const PositionContext = createContext();
export const usePositionContext = () => useContext(PositionContext);

export const PositionProvider = (props) => {
  const [position, setPosition] = useState(null);
  const { sysAdmin } = useAdminContext();

  /**
   * Fetch all position
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchPosition = async () => {
    try {
      const fetch_url = API_URL + `/api/v1/position`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });
      if (response) {
        setPosition(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new position
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createPosition = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/position/add';
      const token = sysAdmin.token;

      let error = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          error = error.response;
        });

      return { response, error };
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <PositionContext.Provider
      value={{
        position,
        createPosition,
        fetchPosition,
      }}
    >
      {props.children}
    </PositionContext.Provider>
  );
};
