/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { pushOneThingsToDo } from "../../../middlewares/sysNavigation/SysNavigation";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import {
  Modal,
  GoBack,
  MyButton,
  MySearch,
  Pagination,
  DashboardIntro,
} from "../../home/component/Component";
import { _TYPES_ } from "../utils/types";
import { _PAGE_SIZE_ } from "../../../middlewares/other/tools";
import { useGeoPositionContext } from "../utils/geoPosition";
import { useAdminContext } from "../../../context/admin/context/AdminContext";
import PromoteContent from "../utils/PromoteContent";

const addRoute = "/data/things/todo/add";
const editRoute = "/data/things/todo/edit";
const doseeDataRoutes = "/dosee/data";

const Overview = () => {
  const [thingsToDosList, setThingsToDosList] = useState([]);
  const { thingsToDos, thingsToDosCount, fetchDoSeeData } =
    useDoSeeDataContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  const [localSearch, setLocalSearch] = useState([]);

  const {
    country_id,
    entry_label,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const data_request_body = {
    country_id,
    first_level_id,
    second_level_id,
    third_level_id,
    fourth_level_id,
    content: _TYPES_.THINGSTODO,
  };

  useEffect(() => {
    if (!thingsToDos) fetchDoSeeData(data_request_body, page, pageSize);
    setThingsToDosList(thingsToDos);
  }, [thingsToDos]);

  const reloadData = () => fetchDoSeeData(data_request_body, page, pageSize);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    thingsToDos.map((ttd) => {
      if (ttd.title.toString().toLowerCase().includes(key))
        return searchArray.push(ttd);
      return true;
    });

    setThingsToDosList(searchArray);
    if (key === "") setThingsToDosList(thingsToDos);
  };

  const LocalData = localSearch?.length > 0 ? localSearch : thingsToDosList;

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: doseeDataRoutes }} />
      <div className="my-3">
        <DashboardIntro props={{ intro: `Things To Do`, name: entry_label }} />
      </div>

      <div className="flex flex-col justify-center">
        <MySearch
          type={_TYPES_.THINGSTODO}
          value={localSearch}
          showGlobalSearch={true}
          setValue={setLocalSearch}
          onChange={(e) => SearchList(e)}
        />

        <div className="w-full flex justify-end my-5">
          {country_id ? (
            <Link to={addRoute}>
              <MyButton
                title="Add Things To DO"
                className="py-3 px-8 bg-blue-900 hover:bg-blue-600"
                onClick={() => {}}
              />
            </Link>
          ) : null}
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">Title</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">Slug</th>
                <th className="border text-center w-1/3">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {thingsToDos || LocalData ? (
                LocalData?.map((ttd, key) => (
                  <tr key={key}>
                    <td className="border px-2 lg:px-4 w-1/3">{ttd?.title}</td>
                    <td className="border px-2 lg:px-4 w-1/3">{ttd?.slug}</td>
                    <td className="border w-1/3">
                      <ActionButton props={ttd} reloadData={reloadData} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={thingsToDosCount}
          callbackFunction={(p, size) => {
            fetchDoSeeData(data_request_body, p, size);
          }}
        />
      </div>
    </div>
  );
};

/**
 * @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props, reloadData }) => {
  const { sysAdmin } = useAdminContext();
  const isSuperAdmin = ["super_admin", "admin"].includes(sysAdmin?.role);
  const { thingsToDos, deleteOneContent, updateOneContent } =
    useDoSeeDataContext();

  const [showModal, setShowModal] = useState(false);

  const [promote, setPromote] = useState(false);
  const [promoteLoader, setPromoteLoader] = useState(false);

  const things_to_do_id = props.things_to_do_id;
  const thingstodo_title = props.title;
  const thingstodo = props;

  const deleteOneThingsToDoById = async (things_to_do_id) => {
    const { status } = await deleteOneContent({
      data_id: things_to_do_id,
      content: _TYPES_.THINGSTODO,
    });

    if (status === 200) {
      reloadData();
    }
  };

  const buildData = (object, position) => {
    return {
      promoted: `${position}`,
      country_id: object?.country_id,
      description: object?.description,
      first_level_id: object?.first_level_id,
      fourth_level_id: object?.fourth_level_id,
      second_level_id: object?.second_level_id,
      slug: object?.slug,
      status: object?.status,
      third_level_id: object?.third_level_id,
      thumbnail: object?.thumbnail,
      title: object?.title,
    };
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} thingstodo
   */
  const promotion = async (position) => {
    const previousPromoted = thingsToDos?.find(
      (thingstodo) => thingstodo?.promoted === String(position)
    );

    setPromoteLoader(true);

    const { status } = await updateOneContent({
      data_id: thingstodo?.things_to_do_id,
      data: buildData(props, position),
      content: _TYPES_.THINGSTODO,
    });

    if (previousPromoted) {
      await updateOneContent({
        data_id: previousPromoted?.things_to_do_id,
        data: buildData(previousPromoted, "no"),
        content: _TYPES_.THINGSTODO,
      });
    }

    if (status === 200) {
      reloadData();
    }
  };

  const suspension = async (thingstodo) => {
    const data = {
      country_id: thingstodo?.country_id,
      description: thingstodo?.description,
      first_level_id: thingstodo?.first_level_id,
      fourth_level_id: thingstodo?.fourth_level_id,
      second_level_id: thingstodo?.second_level_id,
      slug: thingstodo?.slug,
      status: thingstodo?.status === 1 ? 0 : 1,
      third_level_id: thingstodo?.third_level_id,
      thumbnail: thingstodo?.thumbnail,
      title: thingstodo?.title,
    };

    const { status } = await updateOneContent({
      data_id: thingstodo?.things_to_do_id,
      data: data,
      content: _TYPES_.THINGSTODO,
    });

    if (status === 200) {
      reloadData();
    }
  };

  return (
    <>
      <Modal
        props={{
          name: thingstodo_title,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneThingsToDoById(things_to_do_id),
        }}
      />

      <PromoteContent
        visible={promote}
        setVisible={setPromote}
        loading={promoteLoader}
        ids={thingsToDos
          ?.filter(
            (things_todo) =>
              !["yes", "no", null, undefined].includes(things_todo?.promoted)
          )
          .map((things_todo) => ({
            id: things_todo?.things_todo_id,
            title: things_todo?.title,
            position: parseInt(things_todo?.promoted),
          }))}
        onClick={(position) => promotion(position)}
      />

      <div className="flex flex-row justify-end items-center px-2 py-3 space-x-4 text-xs">
        {isSuperAdmin ? (
          <>
            <MyButton
              title={thingstodo?.status === 1 ? "Suspend" : "Unsuspend"}
              className={`py-3 px-8 ${
                thingstodo?.status === 1
                  ? "bg-gray-900 hover:bg-gray-600"
                  : "bg-orange-900 hover:bg-orange-600"
              }`}
              onClick={() => suspension(thingstodo)}
            />
            <MyButton
              title={
                ["yes", "no", null, undefined].includes(thingstodo?.promoted)
                  ? "Promote"
                  : "Unpromote"
              }
              className={`py-3 px-8 ${
                ["yes", "no", null, undefined].includes(thingstodo?.promoted)
                  ? "bg-gray-900 hover:bg-gray-600"
                  : "bg-orange-900 hover:bg-orange-600"
              }`}
              onClick={() => setPromote(true)}
            />
          </>
        ) : null}
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-3 px-8 bg-blue-800 hover:bg-blue-600`}
            onClick={() => pushOneThingsToDo(thingstodo)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-3 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />{" "}
      </div>
    </>
  );
};

export default Overview;
