import React from "react";
import { CityProvider } from "./app/context/levels/levelsContext";
import { StaticProvider } from "./app/context/static/StaticContext";
import { CompanyProvider } from "./app/context/company/companyContext";
import { AdminProvider } from "./app/context/admin/context/AdminContext";
import { AdminsProvider } from "./app/context/admin/context/AdminManageContext";
import { CountryProvider } from "./app/context/country/countryContext/CountryContext";
import { DoSeeDataProvider } from "./app/context/doSeeData/DoSeeDataContext";
import { BaseLevelProvider } from "./app/context/levels/BaseLevelContext";

const Providers = ({ children }) => {
  return (
    <AdminProvider>
      <BaseLevelProvider>
        <CompanyProvider>
          <AdminsProvider>
            <StaticProvider>
              <DoSeeDataProvider>
                <CountryProvider>
                  <CityProvider>{children}</CityProvider>
                </CountryProvider>
              </DoSeeDataProvider>
            </StaticProvider>
          </AdminsProvider>
        </CompanyProvider>
      </BaseLevelProvider>
    </AdminProvider>
  );
};

export default Providers;
