import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { API_URL } from "../../config";
import { useAdminContext } from "../../admin/context/AdminContext";
import Service from "../../../api/service";

const CountryContext = createContext();

export const useCountryContext = () => useContext(CountryContext);

export const CountryProvider = (props) => {
  const [countries, setCountries] = useState(null);
  const [country, setCountry] = useState(null);
  const [count, setCount] = useState(0);
  const [countryStats, setCountryStats] = useState(null);

  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateCountryState = async (data) => {
    return setCountries(data);
  };

  /**
   * Fetch all countries
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchCountries = async (page = 1, pageSize = 100) => {
    const url = `/api/v1/admin/country/all/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setCountries(data);
      setCount(count);
    });
  };

  /**
   * Add a new country
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addCountry = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/country/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a country
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateCountry = async (country_id, data) => {
    const url = `/api/v1/admin/country/update/${country_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a country
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteCountry = async (country_id) => {
    const url = `/api/v1/admin/country/delete/${country_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  /**
   * Get a single country with metadata
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */

  const fetchCountry = async (country_id) => {
    try {
      const fetch_url = API_URL + `/api/v1/country/${country_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });
      if (response) {
        setCountry(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Get Single Country Stats
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchCountryStats = async (country_id) => {
    try {
      const country_stats_url = API_URL + `/api/v1/stats/country/${country_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .get(country_stats_url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) {
        setCountryStats(response.data.data);
        return response;
      } else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country codes
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addCodes = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/code/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country codes
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateCodes = async (code_id, data) => {
    try {
      const update_url = API_URL + `/api/v1/admin/code/update/${code_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country geographical position
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addGeoPosition = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/geo/position/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country geo position
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateGeoPosition = async (geo_position_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/geo/position/update/${geo_position_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country language
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addLanguage = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/language/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Language
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateLanguage = async (language_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/language/update/${language_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country currency
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addCurrency = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/currency/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Currency
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateCurrency = async (currency_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/currency/update/${currency_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country ethnic group
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addEthnicGroup = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/ethnic/group/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Ethnic Group
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateEthnicGroup = async (ethnic_group_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/ethnic/group/update/${ethnic_group_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country religion
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addReligion = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/religion/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Religion
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateReligion = async (religion_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/religion/update/${religion_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country transportation
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addTransportation = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/transportation/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Transportation
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateTransportation = async (transportation_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/transportation/update/${transportation_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country stock market
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addStockMarket = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/stock/market/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Stock Market
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateStockMarket = async (stock_market_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/stock/market/update/${stock_market_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country trade organization
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addTradeOrganization = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/trade/organization/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Trade Organization
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateTradeOrganization = async (trade_organization_id, data) => {
    try {
      const update_url =
        API_URL +
        `/api/v1/admin/trade/organization/update/${trade_organization_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new country climate
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addClimate = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/climate/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update country Climate
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateClimate = async (trade_organization_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/climate/update/${trade_organization_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <CountryContext.Provider
      value={{
        countries,
        fetchCountries,
        addCountry,
        deleteCountry,
        updateCountryState,
        addCodes,
        addGeoPosition,
        addLanguage,
        addCurrency,
        addEthnicGroup,
        addReligion,
        addTransportation,
        addStockMarket,
        addTradeOrganization,
        addClimate,
        updateCountry,
        updateCodes,
        updateGeoPosition,
        updateLanguage,
        updateCurrency,
        updateEthnicGroup,
        updateReligion,
        updateTransportation,
        updateStockMarket,
        updateTradeOrganization,
        updateClimate,
        country,
        setCountry,
        fetchCountry,
        countryStats,
        setCountryStats,
        fetchCountryStats,
        count,
      }}
    >
      {props.children}
    </CountryContext.Provider>
  );
};
