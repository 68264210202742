import React from "react";
import { MyButton } from "./ButtonUtils";
import { SelectInput } from "./FormUtils";

export const Pagination = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  total_items = 8,
  callbackFunction,
}) => {
  total_items = !total_items ? 0 : total_items;
  // pagination options
  const options = [
    50, 100, 200, 300, 400, 500, 600, 7000, 800, 900, 1000, 1500, 2000,
  ];

  // Check if moving forward is possible
  const ceiling = () => Math.ceil(total_items / pageSize);

  return (
    <div className="flex flex-col space-y-4 mt-3 mb-5">
      <div className="font-medium text-sm flex flex-row justify-between items-center space-x-4">
        <div>
          <SelectInput
            title={"Page Size"}
            options={options}
            defaultValue={pageSize}
            onChange={(e) => {
              setPageSize(e?.target?.value);
              setPage(1);
              callbackFunction(1, e?.target?.value);
            }}
          />
        </div>
        <div className="flex flex-col space-y-1">
          <p className="text-xs capitalize">Page</p>
          <div className="text-xs text-white w-auto py-2 bg-gray-800 flex flex-row justify-center items-center px-4">
            {String(page?.toString().padStart(2, "0"))} /{" "}
            {total_items / page === 0
              ? "01"
              : String(ceiling().toString().padStart(2, "0"))}
          </div>
        </div>
      </div>
      <hr />
      <div className="flex flex-row space-x-5 justify-center items-center">
        <MyButton
          title="Back Page"
          className="bg-gray-800 hover:bg-gray-600"
          onClick={() => {
            // const p = page - 1 < 1 ? 1 : page - 1;
            if (page - 1 >= 1) {
              setPage(page - 1);
              callbackFunction(page - 1, pageSize);
            }
          }}
        />
        <MyButton
          title="Next Page"
          className="bg-gray-800 hover:bg-gray-600"
          onClick={() => {
            // const p = page + 1 > ceiling() ? page : page + 1;
            if (page + 1 <= ceiling()) {
              setPage(page + 1);
              callbackFunction(page + 1, pageSize);
            }
          }}
        />
      </div>
    </div>
  );
};
