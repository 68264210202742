import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import TextField from "./components/TextField";
import MessageBox from "./components/MessageBox";
import { AiOutlineLoading } from "react-icons/ai";
import PasswordField from "./components/PasswordField";
import dosee_logo from "../../../assets/images/dosee.png";
import { useAdminContext } from "../../context/admin/context/AdminContext";
import { defaultImage } from "../../middlewares/libraries/imageKit/ImageKit";
import PhoneInput from "react-phone-input-2";
import { allowedCountries, countriesList } from "../home/component/data";

const SignUp = () => {
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phonenumber, setPhonenumber] = useState(null);
  const [country, setCountry] = useState("Uganda");
  const [countryCode, setCountryCode] = useState("ug");

  const [name, setName] = useState(null);
  const [error, setError] = useState(false);
  const [succes, setSuccess] = useState(false);
  const [password, setPassword] = useState(null);
  const [cPassword, setCPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const { adminSignUp, sysAdmin } = useAdminContext();

  const responses = (err, message) => {
    setError(err);
    setSuccess(!err);
    setLoading(false);
    setErrorMessage(message);
  };

  const sendLoginRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(null);
    setError(null);

    if (!name || name === "") {
      responses(true, "company name is required");
      return;
    } else if (!firstName || firstName === "") {
      responses(true, "first name is required");
      return;
    } else if (!lastName || lastName === "") {
      responses(true, "last name is required");
      return;
    } else if (!email || email === "") {
      responses(true, "email is required");
      return;
    } else if (!phonenumber || phonenumber === "") {
      responses(true, "phone number is required");
      return;
    } else if (!country || country === "") {
      responses(true, "country is required");
      return;
    } else if (!password || password === "") {
      responses(true, "password is required");
      return;
    } else if (String(password).length < 6) {
      responses(true, "Min. Password size is 6");
      return;
    } else if (String(password) !== String(cPassword)) {
      responses(true, "passwords do not match");
      return;
    }

    const { message, status } = await adminSignUp({
      name: name,
      thumbnail: defaultImage,
      description: name,
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      phonenumber: phonenumber,
      country: country,
    });
    if (status !== 200 || status !== 201) responses(true, message);
  };

  if (sysAdmin !== null) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className="h-screen w-screen overflow-y-auto">
      <div className="w-full h-full grid grid-cols-1 md:grid-cols-2">
        <div className="flex-col items-center justify-center col-span-1 h-full bg-logo hidden lg:flex">
          <img className="object-cover" src={dosee_logo} alt="dosee logo" />
        </div>
        <div className="flex flex-col mx-auto lg:w-8/12 justify-center items-center w-full h-full col-span-1">
          <form onSubmit={sendLoginRequest}>
            <div className="w-full px-6 lg:px-10 xl:px-20">
              {/* Display on tablets and phones only */}
              <div className="flex flex-col items-center justify-center w-full h-full bg-white lg:hidden">
                <img
                  className="bg-white outline-none w-5/6 object-cover"
                  src={dosee_logo}
                  alt="dosee logo"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <h4 className="font-bold lg:text-3xl text-gray-800 uppercase">
                  Account Creation
                </h4>
                <p className="text-xs text-gray-800 select-none">
                  Provide required details to create a Company
                </p>
              </div>
              <div className="flex flex-col space-y-4 py-5 w-full h-full">
                <TextField
                  type="text"
                  value={name}
                  title="Company Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField
                    type="text"
                    value={firstName}
                    title="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <TextField
                    type="text"
                    value={lastName}
                    title="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <TextField
                  value={email}
                  type="email"
                  title="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="w-full space-y-2">
                  <div className="w-full">
                    <label className="mb-1 block font-normal text-xs text-gray-600">
                      Country
                    </label>
                    <select
                      value={country ? country : ""}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val !== "Select Country") {
                          const pickedCountry = countriesList?.find(
                            (_c) => _c.name === val
                          );
                          setCountry(pickedCountry.name);
                          setCountryCode(pickedCountry.code);
                        } else {
                          setCountry("Uganda");
                          setCountryCode("ug");
                        }
                      }}
                      className="block w-full text-xs rounded-md border bg-white px-4 py-4 text-gray-700 focus:border-secondary focus:outline-none"
                      type="email"
                    >
                      <option value="Select Country">Select Country</option>
                      {countriesList?.map((ctry, index) => (
                        <option key={index} value={ctry.name}>
                          {ctry.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label className="mb-1 block font-normal text-gray-600 text-xs">
                    Telephone
                  </label>
                  <PhoneInput
                    country={countryCode ? countryCode : ""}
                    preferredCountries={["ug", "cd", "ke"]}
                    onlyCountries={allowedCountries}
                    enableSearch={true}
                    autoFormat={true}
                    disableDropdown={true}
                    value={phonenumber}
                    enableLongNumbers={false}
                    onChange={(phone) => setPhonenumber(phone)}
                    inputStyle={{
                      width: "100%",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      height: "48px",
                      borderColor: "rgb(229,231,235)",
                      fontSize: "12px",
                    }}
                    buttonStyle={{
                      borderColor: "rgb(229,231,235)",
                      backgroundColor: "rgb(243,244,246)",
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-xs font-light text-gray-500">
                    Password must be 6 or more characters
                  </p>
                  <PasswordField
                    title="Password"
                    password={password}
                    setError={setError}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setError(false);
                      setSuccess(false);
                    }}
                  />
                </div>
                <PasswordField
                  title="Confirm Password"
                  password={cPassword}
                  setError={setError}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  onChange={(e) => {
                    setCPassword(e.target.value);
                    setError(false);
                    setSuccess(false);
                  }}
                />
                <div className="border border-gray-200 px-2 py-4 rounded-md">
                  <p className="text-xs font-normal text-gray-800 italic">
                    An email with additional steps to follow will be sent to
                    your email address
                  </p>
                </div>
                <MessageBox
                  error={error}
                  success={succes}
                  message={message}
                  setError={setError}
                  setSuccess={setSuccess}
                  setErrorMessage={setErrorMessage}
                  onClick={() => {
                    setError(false);
                    setSuccess(false);
                  }}
                />
              </div>
              <div className="flex justify-end items-center py-4">
                <Link
                  to="/login"
                  className="text-blue-800 underline italic text-xs hover:text-blue-600 capitalize font-bold"
                >
                  Have an Account Already? Login Instead
                </Link>
              </div>
              <div className="my-4">
                <button
                  type={loading ? "button" : "submit"}
                  className="text-white bg-gray-800 w-full py-5 text-xs font-bold flex justify-center items-center"
                >
                  {loading ? (
                    <AiOutlineLoading className="text-sm animate-spin" />
                  ) : (
                    <span>SIGN UP</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
