export const getCustomCity = ({
  first_level_id,
  second_level_id,
  third_level_id,
  fourth_level_id,
  city,
}) => {
  return {
    first_level_id,
    second_level_id,
    third_level_id,
    fourth_level_id,
    country_id: city?.country_id,
    climate_id: city?.climate_id,
    code_id: city?.code_id,
    createdAt: city?.createdAt,
    description: city?.description,
    ethnic_group_id: city?.ethnic_group_id,
    fourth_level_label: city?.fourth_level_label,
    gdp: city?.gdp,
    geo_position_id: city?.geo_position_id,
    image_cdn_id: city?.image_cdn_id,
    is_city: city?.is_city,
    level: city?.level,
    main_city: city?.main_city,
    managed_by: city?.managed_by,
    name: city?.name,
    population: city?.population,
    religion_id: city?.religion_id,
    safety_indicator: city?.safety_indicator,
    slug: city?.slug,
    status: city?.status,
    thumbnail: city?.thumbnail,
    tourism: city?.tourism,
    transportation_id: city?.transportation_id,
    updatedAt: city?.updatedAt,
  };
};

export const getLevelIds = ({ city }) => {
  let first = null,
    second = null,
    third = null,
    fourth = null,
    level = null;

  if (city?.level === "First") {
    first = city?.first_level_id;
    level = city?.first_level_id;
  } else if (city?.level === "Second") {
    second = city?.second_level_id;
    level = city?.second_level_id;
  } else if (city?.level === "Third") {
    third = city?.third_level_id;
    level = city?.third_level_id;
  } else if (city?.level === "Fourth") {
    fourth = city?.fourth_level_id;
    level = city?.fourth_level_id;
  }

  return {
    first_level_id: first,
    second_level_id: second,
    third_level_id: third,
    fourth_level_id: fourth,
    level_id: level,
  };
};

export const searchCity = ({ key, cities }) => {
  if (!cities) return;
  key = key.target.value;
  if (key === "") return cities;
  const tL = (e) => e.toLowerCase().includes(String(key).toLowerCase());
  let results = [];
  cities?.map((city) => (tL(city?.name) ? results.push(city) : null));
  return results;
};
