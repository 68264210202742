import React, { useState } from "react";
import {
  Title,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  MyActionButton,
} from "../../component/Component";
import { useStaticContext } from "../../../../context/static/StaticContext";
import { getOneService } from "../../../../middlewares/sysNavigation/SysNavigation";

const Edit = () => {
  const { updateStaticContent } = useStaticContext();
  const footer = getOneService();
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const service_id = footer.service_id;
  const [title, setTitle] = useState(footer.title);
  const [description, setDescription] = useState(footer.description);
  const footerRoutes = "/service";

  const responses = (error, message) => {
    setError(error);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const updateOneFooterHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      title: title,
      description: description,
    };

    const { status, message } = await updateStaticContent(
      "service",
      service_id,
      data
    );
    if (status === 200) responses(false, message);
    else responses(true, status, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: footerRoutes }} />
      <form onSubmit={updateOneFooterHandler}>
        <div className="mt-4 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Footer Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            title="Save Changes"
            loading={loading}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
