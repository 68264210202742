import React from "react";
import Content from "./manage/Overview";
import Screen from "../Screen";
import AllCountriesDashboard from "./CountriesDashboard";
import CountryDashboardComponent from "./manage/CountryDashboardComponent";
import Add from "./manage/Add";
import Edit from "./manage/Edit";
import { Auth } from "../../../../context/admin/Authentication";
import { DashboardProvider } from "../../../../context/dashboard/context/DashboardContext";
import { CountryProvider } from "../../../../context/country/countryContext/CountryContext";
import { ContinentRegionProvider } from "../../../../context/continentRegion/context/ContinentRegionContext";

import { TradeOrganizationProvider } from "../../../../context/tradeOrganization/TradeOrganizationContext";
import { PlugImageProvider } from "../../../../context/plugImage/PlugImageContext";
import { RegionalBlocksProvider } from "../../../../context/regionalBlocks/RegionalBlocksContext";

export const Countries = () => {
  return (
    <CountryProvider>
      <Auth
        children={
          <Screen
            component={<Content />}
            title="Countries"
          />
        }
      />
    </CountryProvider>
  );
};

export const CountriesDashboard = () => {
  return (
    <DashboardProvider>
      <Auth
        children={
          <Screen
            component={<AllCountriesDashboard />}
            title="Countries Dashboard"
          />
        }
      />
    </DashboardProvider>
  );
};

export const CountryDashboard = () => {
  return (
    <CountryProvider>
      <Auth
        children={
          <Screen
            component={<CountryDashboardComponent />}
            title="Dashboard"
          />
        }
      />
    </CountryProvider>
  );
};

export const AddCountry = () => {
  return (
    <TradeOrganizationProvider>
      <PlugImageProvider>
        <RegionalBlocksProvider>
          <ContinentRegionProvider>
            <CountryProvider>
              <Auth
                children={
                  <Screen
                    component={<Add />}
                    title="Add Country"
                  />
                }
              />
            </CountryProvider>
          </ContinentRegionProvider>
        </RegionalBlocksProvider>
      </PlugImageProvider>
    </TradeOrganizationProvider>
  );
};

export const EditCountry = () => {
  return (
    <TradeOrganizationProvider>
      <PlugImageProvider>
        <RegionalBlocksProvider>
          <ContinentRegionProvider>
            <CountryProvider>
              <Auth
                children={
                  <Screen
                    component={<Edit />}
                    title="Edit Country"
                  />
                }
              />
            </CountryProvider>
          </ContinentRegionProvider>
        </RegionalBlocksProvider>
      </PlugImageProvider>
    </TradeOrganizationProvider>
  );
};
