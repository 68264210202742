// Will hold the current region selected
const our_region_array = [];
// Will hold the current country selected
const our_country_array = [];
// Will hold the current level selected
const our_levels_array = [];

// Will add a new region data
export const addOneRegion = (data) => {
  our_region_array.pop();
  our_region_array.push(data);
};

// Will return a region data
export const getOneRegion = () => {
  if (our_region_array.length <= 0) return [];
  else return our_region_array;
};

// Will clean our region data
export const cleanOneRegion = () => {
  if (our_region_array.length > 0) our_region_array.pop();
};

// Will add a new country data
export const addOneCountry = (data) => {
  our_country_array.pop();
  our_country_array.push(data);
};

// Will return a region data
export const getOneCountry = () => {
  if (our_country_array.length <= 0) return [];
  else return our_country_array;
};

// Will clean our country data
export const cleanOneCountry = () => {
  if (our_country_array.length > 0) our_country_array.pop();
};

// Will add a new level data
export const addOneLevel = (data) => {
  our_levels_array.pop();
  our_levels_array.push(data);
};

// Will return a level data
export const getOneLevel = () => {
  if (our_levels_array.length <= 0) return [];
  else return our_levels_array;
};

// Will clean our level data
export const cleanOneLevel = () => {
  if (our_levels_array.length > 0) our_levels_array.pop();
};

export const clearBreadCumb = () => {
  our_region_array.pop();
  our_country_array.pop();
  our_levels_array.pop();
};

export const resetBreadCumb = () => {
  cleanOneLevel();
  cleanOneRegion();
  cleanOneCountry();
};
