import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const PaymentContext = createContext();

export const usePaymentContext = () => useContext(PaymentContext);

export const PaymentProvider = (props) => {
  const [count, setCount] = useState(0);
  // const [total, setTotal] = useState(0);
  const [payment, setPayment] = useState(null);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updatePaymentState = async (data) => {
    return setPayment(data);
  };

  /**
   * Fetch all payment
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchPayments = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/payment/company/${sysAdmin?.company_id}/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { rows, count } }) => {
      setPayment(rows);
      setCount(count);
    });
  };

  return (
    <PaymentContext.Provider
      value={{
        count,
        payment,
        fetchPayments,
        updatePaymentState,
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};
