import React, { createContext, useContext, useState } from "react";

import axios from "axios";
import { API_URL } from "../config";
import { useAdminContext } from "../admin/context/AdminContext";

const AgentContext = createContext();
export const useAgentContext = () => useContext(AgentContext);

export const AgentProvider = (props) => {
  const [agent, setAgent] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateAgentState = async (data) => {
    return setAgent(data);
  };

  /**
   * Fetch all agent
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchAgents = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/dosee/agent/?page=${page}&pageSize=${pageSize}`;
      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) {
        setAgent(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new agent
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneAgent = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/dosee/agent/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a agent
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateAgent = async (agent_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/dosee/agent/update/${agent_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a agent
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteAgent = async (agent_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/admin/dosee/agent/delete/${agent_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <AgentContext.Provider
      value={{
        agent,
        createOneAgent,
        fetchAgents,
        updateAgent,
        deleteAgent,
        updateAgentState,
        count,
      }}
    >
      {props.children}
    </AgentContext.Provider>
  );
};
