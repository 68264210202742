import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { ReligionProvider } from "../../../../context/religion/ReligionContext";

export const Religion = () => {
  return (
    <ReligionProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Religion"
          />
        }
      />
    </ReligionProvider>
  );
};

export const AddReligion = () => {
  return (
    <ReligionProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Religion"
          />
        }
      />
    </ReligionProvider>
  );
};

export const EditReligion = () => {
  return (
    <ReligionProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Religion"
          />
        }
      />
    </ReligionProvider>
  );
};
