import React from "react";
import DropDown from "./DropDown";
import { FaTimes } from "react-icons/fa";
import { useNavigationContext } from "../../../context/navigation/navigationContext";

const SideBar = () => {
  const { showSideBar, setShowSideBar } = useNavigationContext();
  return (
    <>
      <div
        className={
          `h-full text-gray-700 bg-white md:mr-2 absolute w-full md:w-1/2 lg:pb-0 md:pr-0 xl:block xl:relative lg:w-80 xl:w-[350px] select-none z-50 ` +
          (showSideBar ? "block" : "hidden")
        }
      >
        <div className="flex flex-col justify-between items-center w-full h-full">
          <div className="flex flex-col w-full h-full overflow-auto pb-10">
            <div
              onClick={() => setShowSideBar(false)}
              className="flex flex-row border-b items-center w-full justify-end py-4 px-4 cursor-pointer xl:hidden"
            >
              <div className="w-auto bg-red-800 rounded-md py-2 px-4 text-white font-bold flex flex-col justify-center items-center">
                <FaTimes className="text-3xl" />
                <p className="text-xs">Close</p>
              </div>
            </div>
            <div className="px-4 w-full">
              <p className="text-xl text-gray-800 font-thin uppercase py-4">
                Dashboard
              </p>
            </div>
            <DropDown />
          </div>
        </div>
      </div>
      {showSideBar ? (
        <div
          onClick={() => setShowSideBar(false)}
          className="w-full h-full bg-gray-600 bg-opacity-30 absolute z-10 xl:hidden"
        />
      ) : null}
    </>
  );
};

export default SideBar;
