import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../../config';

import { useAdminContext } from '../../admin/context/AdminContext';

const FourthLevelContext = createContext();

export const useFourthLevelContext = () => useContext(FourthLevelContext);

export const FourthLevelProvider = (props) => {
  const [fourthLevels, setFourthLevels] = useState(null);
  const [fourthLevel, setFourthLevel] = useState(null);
  const [countFourth, setCountFourth] = useState(0);
  const { sysAdmin } = useAdminContext();

  // Update the state
  const updateFourthLevelState = async (data) => {
    return setFourthLevels(data);
  };

  /**
   * Fetch all fourth levels
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchFourthLevels = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/fourth/level/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });
      if (response) {
        setFourthLevels(response.data.data.data);
        setCountFourth(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch by country id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchFourthByCountryId = async ({
    country_id,
    page = 1,
    pageSize = 20,
  }) => {
    try {
      const fetch_url =
        API_URL +
        `/api/v1/fourth/level/location/?page=${page}&pageSize=${pageSize}&country_id=${country_id}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setFourthLevels(response.data.data.data);
        setCountFourth(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Get a single fourth_level with metadata
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */

  const fetchFourthLevel = async (fourth_level_id) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/fourth/level/metadata/${fourth_level_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });
      if (response) {
        setFourthLevel(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new fourth level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addFourthLevel = async (data) => {
    try {
      const token = sysAdmin.token;
      const fetch_url = API_URL + `/api/v1/admin/fourth/level/create`;
      let errorResponse = null;

      const response = await axios
        .post(fetch_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update Fourth level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateFourthLevel = async (fourth_level_id, data) => {
    try {
      const token = sysAdmin.token;
      const update_url =
        API_URL + `/api/v1/admin/fourth/level/update/${fourth_level_id}`;
      let errorResponse = null;

      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a single fourth level by id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteFourthLevel = async (fourth_level_id) => {
    try {
      const token = sysAdmin.token;
      const fetch_url =
        API_URL + `/api/v1/admin/fourth/level/delete/${fourth_level_id}`;
      let errorResponse = null;

      const response = await axios
        .delete(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <FourthLevelContext.Provider
      value={{
        fourthLevels,
        fetchFourthLevels,
        deleteFourthLevel,
        updateFourthLevelState,
        addFourthLevel,
        fourthLevel,
        fetchFourthLevel,
        updateFourthLevel,
        countFourth,
        fetchFourthByCountryId,
      }}
    >
      {props.children}
    </FourthLevelContext.Provider>
  );
};
