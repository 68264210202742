import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { AgentProvider } from "../../../../context/agent/AgentContext";
import { CountryProvider } from "../../../../context/country/countryContext/CountryContext";
import { TourPackageProvider } from "../../../../context/tourPackage/TourPackageContext";
// import { useAdminContext } from "../../../../context/admin/context/AdminContext";
// import { Navigate } from "react-router-dom";

// Redirect to home
// const ReturnHome = () => {
//   const { sysAdmin } = useAdminContext();
//   if (sysAdmin.role !== "admin")
//     return (
//       <Navigate
//         replace
//         to="/"
//       />
//     );
//   else return null;
// };

export const Agent = () => {
  return (
    <AgentProvider>
      <TourPackageProvider>
        {/* <ReturnHome /> */}
        <Auth
          children={
            <Screen
              component={<Overview />}
              title="Agent"
            />
          }
        />
      </TourPackageProvider>
    </AgentProvider>
  );
};

export const AddAgent = () => {
  return (
    <AgentProvider>
      <TourPackageProvider>
        {/* <ReturnHome /> */}
        <CountryProvider>
          <Auth
            children={
              <Screen
                component={<Add />}
                title="Add Agent"
              />
            }
          />
        </CountryProvider>
      </TourPackageProvider>
    </AgentProvider>
  );
};

export const EditAgent = () => {
  return (
    <AgentProvider>
      <TourPackageProvider>
        {/* <ReturnHome /> */}
        <CountryProvider>
          <Auth
            children={
              <Screen
                component={<Edit />}
                title="Edit Agent"
              />
            }
          />
        </CountryProvider>
      </TourPackageProvider>
    </AgentProvider>
  );
};
