import { _TYPES_ } from "../../../doseeData/utils/types";

export const getType = ({ field }) => {
  if (field === _TYPES_?.EVENT) return _TYPES_?.EVENT;
  else if (field === _TYPES_?.ATTRACTION) return _TYPES_?.ATTRACTION;
  else if (field === _TYPES_?.BESTOFAFRICA) return _TYPES_?.BESTOFAFRICA;
  else if (field === _TYPES_?.CINEMA) return _TYPES_?.CINEMA;
  else if (field === _TYPES_?.MUSIC) return _TYPES_?.MUSIC;
  else if (field === _TYPES_?.NEWS) return _TYPES_?.NEWS;
  else if (field === _TYPES_?.RESTAURANT) return _TYPES_?.RESTAURANT;
  else if (field === _TYPES_?.SAFARI) return _TYPES_?.SAFARI;
  else if (field === _TYPES_?.TOURPACKAGE) return _TYPES_?.TOURPACKAGE;
  else if (field === _TYPES_?.CITYGUIDE) return _TYPES_?.CITYGUIDE;
  else if (field === _TYPES_?.GETTINGAROUND) return _TYPES_?.GETTINGAROUND;
  else if (field === _TYPES_?.CLASSES) return _TYPES_?.CLASSES;
  else if (field === _TYPES_?.FOOD) return _TYPES_?.FOOD;
  else if (field === _TYPES_?.HOTEL) return _TYPES_?.HOTEL;
  else if (field === _TYPES_?.MUSEUM) return _TYPES_?.MUSEUM;
  else if (field === _TYPES_?.NIGHTLIFE) return _TYPES_?.NIGHTLIFE;
  else if (field === _TYPES_?.PARTY) return _TYPES_?.PARTY;
  else if (field === _TYPES_?.SHOPPING) return _TYPES_?.SHOPPING;
  else if (field === _TYPES_?.THINGSTODO) return _TYPES_?.THINGSTODO;
  else if (field === _TYPES_?.TIPSANDADVICE) return _TYPES_?.TIPSANDADVICE;
  else if (field === _TYPES_?.ART) return _TYPES_?.ART;
  else if (field === _TYPES_?.BOOK) return _TYPES_?.BOOK;
  else if (field === _TYPES_?.DANCE) return _TYPES_?.DANCE;
  else if (field === _TYPES_?.MINISTRY) return _TYPES_?.MINISTRY;
  else if (field === _TYPES_?.MOVIE) return _TYPES_?.MOVIE;
  else if (field === _TYPES_?.NEIGHBORHOOD) return _TYPES_?.NEIGHBORHOOD;
  else if (field === _TYPES_?.THEATER) return _TYPES_?.THEATER;
  else if (field === _TYPES_?.PRODUCT) return _TYPES_?.PRODUCT;
  else if (field === _TYPES_?.COMPANY) return _TYPES_?.COMPANY;
  else if (field === _TYPES_?.EMBASSY) return _TYPES_?.EMBASSY;
  return _TYPES_?.EVENT;
};
