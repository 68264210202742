import React, { useState } from "react";
import { Button } from "../../home/component/ButtonUtils";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";

const ExteriorImages = ({
  showModal,
  onClick,
  exteriorImages,
  setExteriorImages,
}) => {
  return (
    <div
      className={
        showModal ? "block w-full h-full overflow-y-auto mx-auto" : "hidden"
      }
    >
      <div className="justify-center w-fit p-10 md:w-full h-full items-center flex absolute inset-0 z-[9999999] outline-none focus:outline-none">
        <div className="relative w-full max-h-screen h-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full overflow-y-auto h-full max-h-max bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative rounded-lg shadow p-4 w-full">
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={onClick}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={onClick}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="flex flex-col justify-start items-start p-6 space-y-5 pt-0 text-center w-full">
                <p className="text-xl font-bold">
                  Add Interior / Exterior Images
                </p>

                {exteriorImages?.map((m, key) => {
                  return (
                    <ImageWidget
                      key={key}
                      index={key}
                      menu={exteriorImages}
                      setMenu={setExteriorImages}
                    />
                  );
                })}
              </div>
              <div className="px-6 mt-5 w-full border-t-2 border-gray-200">
                <div className="w-full flex justify-start items-center py-8">
                  <Button
                    props={{
                      type: "button",
                      title: "Click To Add Image",
                      category: "orange",
                      action: "add",
                      onClick: () => {
                        setExteriorImages([
                          ...exteriorImages,
                          { thumbnail: null, image_cdn_id: null },
                        ]);
                      },
                    }}
                  />
                </div>
                <div className="w-full flex justify-center items-center py-8">
                  <Button
                    props={{
                      type: "button",
                      title: "Finish",
                      category: "default",
                      action: "default",
                      onClick: () => onClick(),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed w-full h-full inset-0 z-[100] bg-black"></div>
    </div>
  );
};

export default ExteriorImages;

const removeExtImage = ({ menu, setMenu, index }) => {
  const data = menu?.filter((_, i) => i !== index);
  setMenu([...data]);
};

const ImageWidget = ({ index, setMenu, menu }) => {
  const [thumbnail, setThumbnail] = useState(
    menu[index]?.thumbnail ? menu[index]?.thumbnail : null
  );
  const [thumbnailId, setThumbnailId] = useState(
    menu[index]?.image_cdn_id ? menu[index]?.image_cdn_id : null
  );

  return (
    <div className="flex flex-col w-full space-y-2 border p-4">
      <div className="flex flex-col justify-start items-start w-full space-y-4">
        <div className="flex flex-col space-y-2 justify-start items-start">
          <p className="text-xs font-bold">Ext. Image No.{index + 1}</p>
          <Button
            props={{
              type: "button",
              title: `Click To Remove image No.${index + 1}`,
              category: "red",
              action: "",
              onClick: () => {
                removeExtImage({ setMenu, menu, index: index });
              },
            }}
          />
        </div>
        <ImageKitUpload
          thumbnail={thumbnail || defaultImage}
          thumbnailId={thumbnailId}
          setThumbnail={setThumbnail}
          setThumbnailId={setThumbnailId}
          folder={image_kit_folder("dosee/", FOLDERS.HOTELS)}
          isRestaurantModal={true}
          onSuccess={(url, id) => {
            const dt = menu.map((x) => x);
            dt[index] = {
              thumbnail: url,
              image_cdn_id: id,
            };
            setMenu(dt);
          }}
        />
      </div>
    </div>
  );
};
