import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const PartnerContext = createContext();
export const usePartnerContext = () => useContext(PartnerContext);

export const PartnerProvider = (props) => {
  const [partner, setPartner] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updatePartnerState = async (data) => {
    return setPartner(data);
  };

  /**
   * Fetch all partner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchPartners = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/partner/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setPartner(data);
      setCount(count);
    });
  };

  /**
   * Add a new partner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOnePartner = async (data) => {
    const url = "/api/v1/admin/partner/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a partner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updatePartner = async (partner_id, data) => {
    const url = `/api/v1/admin/partner/update/${partner_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a partner
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deletePartner = async (partner_id) => {
    const url = `/api/v1/admin/partner/delete/${partner_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <PartnerContext.Provider
      value={{
        partner,
        createOnePartner,
        fetchPartners,
        updatePartner,
        deletePartner,
        updatePartnerState,
        count,
      }}
    >
      {props.children}
    </PartnerContext.Provider>
  );
};
