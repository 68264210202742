/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { useNewsletterContext } from "../../../../context/newsletter/NewsletterContext";
import { Search, Pagination, MyActionButton } from "../../component/Component";
import NewsletterModal from "./NewsletterModal";

const Overview = () => {
  const [newsletterList, setNewsletterList] = useState([]);
  const { newsletter, fetchNewsletter, sendNotification, count } =
    useNewsletterContext();

  const [page, setPage] = useState(1);

  const [contentTitle, setContentTitle] = useState("");
  const [dataIds, setDataIds] = useState([]);

  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!newsletter) fetchNewsletter(page, pageSize);
    else setNewsletterList(newsletter);
  }, [newsletter, newsletterList]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (newsletter !== null && newsletter !== undefined)
      newsletter.map((_newsletter_) => {
        if (_newsletter_.email.toString().toLowerCase().includes(key))
          return searchArray.push(_newsletter_);
        return true;
      });

    setNewsletterList(searchArray);
    if (key === "") setNewsletterList(newsletter);
  };

  const sendDailyDigest = async () => {
    setLoading(true);

    const data = {
      eventIds: contentTitle === "Event" ? dataIds : [],
      attractionIds: contentTitle === "Attraction" ? dataIds : [],
      partyIds: contentTitle === "Party" ? dataIds : [],
      restaurantIds: contentTitle === "Restaurant" ? dataIds : [],
      nightLifeIds: contentTitle === "Nightlife" ? dataIds : [],
      musicIds: contentTitle === "Music" ? dataIds : [],
      safariIds: contentTitle === "Safari" ? dataIds : [],
      cityGuideIds: contentTitle === "City Guide" ? dataIds : [],
      tourPackageIds: contentTitle === "Tour Package" ? dataIds : [],
      hotelIds: contentTitle === "Hotel" ? dataIds : [],
      classIds: contentTitle === "Classes" ? dataIds : [],
      museumIds: contentTitle === "Museum" ? dataIds : [],
      shoppingIds: contentTitle === "Shopping" ? dataIds : [],
      foodIds: contentTitle === "Food" ? dataIds : [],
      bookIds: contentTitle === "Book" ? dataIds : [],
      neighborhoodIds: contentTitle === "Neighborhood" ? dataIds : [],
      ministryIds: contentTitle === "Ministries" ? dataIds : [],
      embassyIds: contentTitle === "Embassy" ? dataIds : [],
      companyIds: contentTitle === "Companies" ? dataIds : [],
      gettingAroundIds: contentTitle === "Getting Around" ? dataIds : [],
      artIds: contentTitle === "Art" ? dataIds : [],
      movieIds: contentTitle === "Movies" ? dataIds : [],
      danceIds: contentTitle === "Dance" ? dataIds : [],
      cinemaIds: contentTitle === "Cinema" ? dataIds : [],
      newsIds: contentTitle === "News" ? dataIds : [],
      theaterIds: contentTitle === "Theater" ? dataIds : [],
      tipsAndAdviceIds: contentTitle === "Tips And Advice" ? dataIds : [],
      thingsToDoIds: contentTitle === "Things To Do" ? dataIds : [],
      BestOfAfricaIds: contentTitle === "Best Of Africa" ? dataIds : [],
    };

    const response = await sendNotification(data);

    if (response?.status === 200) {
      setLoading(false);
      setShowModal(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      {showModal ? (
        <NewsletterModal
          loading={loading}
          dataIds={dataIds}
          contentTitle={contentTitle}
          setDataIds={setDataIds}
          onSubmit={sendDailyDigest}
          setContentTitle={setContentTitle}
          onClose={() => {
            setShowModal(false);
            setLoading(false);
          }}
        />
      ) : null}

      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Email Address",
            onChange: (e) => SearchList(e),
          }}
        />
        <div className="w-full flex flex-col space-y-2 justify-start my-5">
          <p className="text-xs">
            <b>Note</b>: Latest Content will be sent to subcribers
          </p>
          <MyActionButton
            loading={loading}
            title="Select Daily Digest"
            onClick={() => setShowModal(true)}
            className="py-4 px-10 bg-gray-900 hover:bg-gray-700"
          />
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2 space-y-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">
                  Email Address ({count})
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">
                  Date Joined
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">
                  Time Joined
                </th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300 space-y-7">
              {newsletter && newsletterList?.length > 0 ? (
                newsletterList?.map((_newsletter_) => {
                  return (
                    <tr key={_newsletter_?.newsletter_id}>
                      <td className="border px-2 py-4 lg:px-4 w-1/3">
                        {_newsletter_?.email}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/3">
                        {new Date(_newsletter_?.createdAt).toDateString()}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/3">
                        {new Date(_newsletter_?.createdAt).toTimeString()}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchNewsletter(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

export default Overview;
