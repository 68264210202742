import React, { useState } from "react";
import { FOLDERS } from "../../../doseeData/utils/Utils";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { useRegionalBlocksContext } from "../../../../context/regionalBlocks/RegionalBlocksContext";
import {
  Title,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  MyActionButton,
} from "../../component/Component";

const Add = () => {
  const { createOneRegionalBlocks } = useRegionalBlocksContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [name, setName] = useState(null);
  const [acronym, setAcronym] = useState(null);
  const [headquarters, setHeadquarters] = useState(null);
  const [objectives, setObjectives] = useState(null);
  const [debuted_on, setDebutedOn] = useState(null);
  const [mission, setMission] = useState(null);
  const [vision, setVision] = useState(null);
  const [description, setDescription] = useState(null);

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [website, setWebsite] = useState(null);

  const regionalBlocksRoutes = "/regional/blocks";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setName(null);
    setAcronym(null);
    setHeadquarters(null);
    setObjectives(null);
    setDebutedOn(null);
    setMission(null);
    setVision(null);
    setDescription(null);
    setThumbnail(defaultImage);
    setThumbnailId("image_cdn_id");
    setWebsite(null);
  };

  const responses = (isError, message) => {
    setError(isError);
    setSuccess(!isError);
    setLoading(false);
    setErrorMessage(message);
  };

  const addOneRegionalBlocksHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      return responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      name: name,
      acronym: acronym,
      headquarters: headquarters,
      chief_officer: "...",
      objectives: objectives,
      debuted_on: debuted_on,
      mission: mission,
      vision: vision,
      description: description,
      thumbnail: thumbnail,
      image_cdn_id: thumbnailId,
      website: website,
    };

    const { status } = await createOneRegionalBlocks(data);
    if (status === 200) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: regionalBlocksRoutes }} />
      <form onSubmit={addOneRegionalBlocksHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.REGIONAL_BLOCKS}
          />

          <Title props={{ title: "Regional Block Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                required: true,
                value: name ? name : "",
                onChange: (e) => {
                  setName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "acronym",
                title: "Acronym",
                type: "text",
                value: acronym ? acronym : "",
                onChange: (e) => {
                  setAcronym(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "website",
                title: "Website",
                type: "text",
                value: website ? website : "",
                onChange: (e) => {
                  setWebsite(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "headquarters",
                title: "Headquarters",
                type: "text",
                value: headquarters ? headquarters : "",
                onChange: (e) => {
                  setHeadquarters(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "debuted_on",
                title: "Debuted On",
                type: "text",
                value: debuted_on ? debuted_on : "",
                onChange: (e) => {
                  setDebutedOn(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "objectives",
                title: "Objectives",
                type: "text",
                value: objectives ? objectives : "",
                onChange: (e) => {
                  setObjectives(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "mission",
                title: "Mission",
                type: "text",
                value: mission ? mission : "",
                onChange: (e) => {
                  setMission(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "vision",
                title: "Vision",
                type: "text",
                value: vision ? vision : "",
                onChange: (e) => {
                  setVision(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                required: true,
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Regional Block"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
