/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { usePartnerContext } from "../../../../context/partner/PartnerContext";
import { pushOnePartner } from "../../../../middlewares/sysNavigation/SysNavigation";
import { Modal, Search, MyButton, Pagination } from "../../component/Component";

const addRoute = "/partner/add";
const editRoute = "/partner/edit";

const Overview = () => {
  const [partnerList, setPartnerList] = useState([]);
  const { partner, fetchPartners, count } = usePartnerContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!partner) fetchPartners();
    else setPartnerList(partner);
  }, [partner]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (partner !== null && partner !== undefined)
      partner.map((ptner) => {
        if (ptner?.name.toString().toLowerCase().includes(key))
          return searchArray.push(ptner);
        return true;
      });

    setPartnerList(searchArray);
    if (key === "") setPartnerList(partner);
  };

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Partner",
            onChange: (e) => SearchList(e),
          }}
        />

        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <MyButton
              title="Add Partner"
              onClick={() => {}}
              className="bg-gray-800 hover:bg-gray-600"
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">
                  Description
                </th>
                <th className="border text-center w-1/3">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 border-gray-300">
              {partner ? (
                partnerList.map((ptner) => {
                  return (
                    <tr key={ptner?.partner_id}>
                      <td className="border px-2 lg:px-4 w-1/3">
                        {ptner?.name}
                      </td>
                      <td className="border px-2 lg:px-4 w-1/3">
                        {ptner?.description}
                      </td>
                      <td className="border w-1/3">
                        <ActionButton props={ptner} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchPartners(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { partner, updatePartnerState, deletePartner } = usePartnerContext();

  const [showModal, setShowModal] = useState(false);

  const partner_id = props.partner_id;
  const partner_name = props.name;
  const ptner = props;

  const deleteOnePartnerById = async (partner_id) => {
    const { status } = await deletePartner(partner_id);
    if (status === 200) {
      if (partner) {
        // Update the partner data
        const update = partner.filter(
          (ptner) => ptner?.partner_id !== partner_id
        );
        // Update the Global State of Regions
        updatePartnerState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: partner_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOnePartnerById(partner_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600"
            onClick={() => pushOnePartner(ptner)}
          />
        </Link>
        <MyButton
          title="Delete"
          className="bg-red-800 hover:bg-red-600"
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
