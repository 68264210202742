import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const ReligionContext = createContext();

export const useReligionContext = () => useContext(ReligionContext);

export const ReligionProvider = (props) => {
  const [religion, setReligion] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateReligionState = async (data) => {
    // await fetchReligions();
    return setReligion(data);
  };

  /**
   * Fetch all religion
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchReligions = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/religion/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setReligion(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new religion
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneReligion = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/admin/religion/create';
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a religion
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateReligion = async (religion_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/religion/update/${religion_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a religion
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteReligion = async (religion_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/admin/religion/delete/${religion_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ReligionContext.Provider
      value={{
        religion,
        createOneReligion,
        fetchReligions,
        updateReligion,
        deleteReligion,
        updateReligionState,
        count,
      }}
    >
      {props.children}
    </ReligionContext.Provider>
  );
};
