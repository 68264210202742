import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const CurrencyContext = createContext();
export const useCurrencyContext = () => useContext(CurrencyContext);

export const CurrencyProvider = (props) => {
  const [currency, setCurrency] = useState(null);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateCurrencyState = async (data) => {
    return setCurrency(data);
  };

  /**
   * Fetch all currency
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchCurrencies = async () => {
    // const url = `/api/v1/currency/?page=${page}&pageSize=${pageSize}`;
    const url = `/api/v1/country/currency`;
    await Service.get(url, token).then(({ data }) => {
      setCurrency(data);
    });
  };

  /**
   * Add a new currency
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneCurrency = async (data) => {
    const url = "/api/v1/admin/currency/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a currency
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateCurrency = async (currency_id, data) => {
    const url = `/api/v1/admin/currency/update/${currency_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a currency
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteCurrency = async (currency_id) => {
    const url = `/api/v1/admin/currency/delete/${currency_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        createOneCurrency,
        fetchCurrencies,
        updateCurrency,
        deleteCurrency,
        updateCurrencyState,
      }}
    >
      {props.children}
    </CurrencyContext.Provider>
  );
};
