import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const InfoContext = createContext();

export const useInfoContext = () => useContext(InfoContext);

export const InfoProvider = (props) => {
  const [contacts, setContacts] = useState(null);
  const [about, setAbout] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateInfoState = async (data) => setContacts(data);

  /**
   * Fetch all about
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchContacts = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/contact/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setContacts(data);
      setCount(count);
    });
  };

  /**
   * Fetch one about
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchAbout = async () => {
    const url = `/api/v1/about`;
    await Service.get(url, token).then(({ data }) => {
      setAbout(data);
    });
  };

  /**
   * Update about
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateAbout = async (about_us_id, data) => {
    const url = `/api/v1/about/${about_us_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Update contact
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateContact = async (contact_us_id, data) => {
    const url = `/api/v1/contact/${contact_us_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  return (
    <InfoContext.Provider
      value={{
        count,
        about,
        contacts,
        fetchAbout,
        updateAbout,
        updateContact,
        fetchContacts,
        updateInfoState,
      }}
    >
      {props.children}
    </InfoContext.Provider>
  );
};
