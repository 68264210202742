/* eslint-disable react-hooks/exhaustive-deps */
import { codeOk, codeCreate } from "../../../middlewares/index";

import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";

export const Message = ({ props }) => {
  const error = props.error;
  const success = props.success;
  const status = props.status;
  const message = props.message;
  if (error === true || success === true) {
    return (
      <div
        className={
          "absolute z-[999999] top-[70px] right-auto xl:right-8 flex flex-row items-center justify-between w-full xl:w-1/2 font-bold shadow-xl h-16 cursor-pointer mb-4 " +
          (status === codeOk || status === codeCreate
            ? " bg-green-800/70"
            : "bg-red-800/70")
        }
        onClick={props.onClick}
      >
        <div className="flex flex-row items-center justify-start space-x-3 px-2 md:px-4 w-full h-full">
          {status === codeOk || status === codeCreate ? (
            <BsCheckCircle className="w-9 h-9 text-white" />
          ) : (
            <AiOutlineCloseCircle className="w-9 h-9 text-white" />
          )}

          <div className="pr-3">
            <p className="text-white text-base font-bold">
              {status === codeOk || status === codeCreate
                ? "Success".toUpperCase()
                : "Error".toUpperCase()}
            </p>
            <p className="text-sm text-white font-bold text-justify">
              {message}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center border-l-[2px] border-white w-[80px] h-full cursor-pointer text-white hover:text-gray-200">
          <p className="text-sm uppercase font-bold text-white">Close</p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const MyMessage = ({ error, success, onClick, message, onExpired }) => {
  let timer = undefined;
  useEffect(() => {
    if (error || success) (timer = () => setTimeout(onExpired, 4000))();
    return () => clearTimeout(timer);
  }, [error, success, timer]);

  if (!error && !success) return null;
  return (
    <div
      onClick={onClick}
      className={`absolute z-[99999] top-36 right-6 flex flex-row items-center justify-between w-full xl:w-auto font-bold shadow-2xl cursor-pointer h-12 rounded-lg ${
        success ? " bg-green-800" : "bg-red-900"
      }`}
    >
      <div className="flex flex-row font-bold items-center justify-start space-x-2 px-4 w-full h-full text-white text-2xl">
        {success ? <BsCheckCircle /> : <AiOutlineCloseCircle />}
        <p className="text-xs">{success ? "Success" : `Error: ${message}`}</p>
      </div>
      <div
        className={`flex justify-center items-center border-l-[2px] border-white px-5 min-h-full h-full rounded-r-lg ${
          success ? "bg-green-800" : "bg-red-900"
        }`}
      >
        <FaTimes className="text-white hover:text-gray-400 cursor-pointer" />
      </div>
    </div>
  );
};
