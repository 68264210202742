import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { EthnicGroupProvider } from "../../../../context/ethnicGroup/EthnicGroupContext";

export const EthnicGroup = () => {
  return (
    <EthnicGroupProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Ethnic Group"
          />
        }
      />
    </EthnicGroupProvider>
  );
};

export const AddEthnicGroup = () => {
  return (
    <EthnicGroupProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Ethnic Group"
          />
        }
      />
    </EthnicGroupProvider>
  );
};

export const EditEthnicGroup = () => {
  return (
    <EthnicGroupProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Ethnic Group"
          />
        }
      />
    </EthnicGroupProvider>
  );
};
