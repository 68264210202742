import React from "react";
import { Auth } from "../../../../context/admin/Authentication";
import Screen from "../Screen";
import Overview from "./Overview";
import { NewsletterProvider } from "../../../../context/newsletter/NewsletterContext";

export const Newsletter = () => {
  return (
    <NewsletterProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="News Letter"
          />
        }
      />
    </NewsletterProvider>
  );
};
