export const SearchList = (search_key, key, array) => {
  const searchArray = [];
  array?.map((cmp) => {
    if (cmp?.[key]?.toString()?.toLowerCase()?.includes(search_key)) {
      searchArray?.push(cmp);
    }
    return true;
  });

  if (search_key === "") return array;
  return searchArray;
};

export const BuildDate = ({ ref }) => {
  const date = new Date(ref);
  const y = String(date.getFullYear()),
    m = String(date.getMonth() + 1).padStart(2, "0"),
    d = String(date.getDate()).padStart(2, "0");
  return y + "-" + m + "-" + d;
};
