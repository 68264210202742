import React, { createContext, useContext, useState } from "react";
import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const StaticContext = createContext();
export const useStaticContext = () => useContext(StaticContext);
export const StaticProvider = (props) => {
  const { sysAdmin } = useAdminContext();
  const [footer, setFooter] = useState(null);
  const [terms, setTerms] = useState(null);
  const [privacy, setPrivacy] = useState(null);
  const [service, setService] = useState(null);
  const [stats, setStats] = useState(null);
  const [report, setReport] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState(null);

  const token = sysAdmin?.token;

  /**
   * Fetch all footer
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchFooter = async () => {
    await Service.get("/api/v1/footer", token).then(({ data }) => {
      setFooter(data);
    });
  };

  /**
   * Fetch terms
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchTerms = async () => {
    await Service.get("/api/v1/terms", token).then(({ data }) => {
      setTerms(data);
    });
  };

  /**
   * Fetch privacy
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchPrivacy = async () => {
    await Service.get("/api/v1/privacy", token).then(({ data }) => {
      setPrivacy(data);
    });
  };

  /**
   * Fetch all services
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchService = async () => {
    await Service.get("/api/v1/service", token).then(({ data }) => {
      setService(data);
    });
  };

  /**
   * Update a static
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateStaticContent = async (type, id, data) => {
    const url = `/api/v1/${type}/${id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Get stats
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getStats = async ({ year, month, data }) => {
    const url = `/api/v1/stats/content/admin/?year=${year}&month=${month}`;
    await Service.post(url, data, token).then(({ data }) => {
      setStats(data);
    });
  };

  /**
   * Get Custom Search
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getCustomSearch = async ({ search_key, type }) => {
    const url = `/api/v1/search/custom/admin/?search_key=${search_key}&page=1&pageSize=100&type=${type}`;
    const { data } = await Service.get(url, token);
    return { data };
  };

  /**
   * Get Company stats
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getCompanyStats = async ({ data }) => {
    const url = `/api/v1/stats/content/company`;
    await Service.post(url, data, token).then(({ data }) => {
      setStats(data);
    });
  };

  /**
   * Get Full Report
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getFullReport = async ({ year, month }) => {
    const url = `/api/v1/stats/report/${year}/${month}`;
    await Service.get(url, token).then(({ data }) => {
      setReport(data);
    });
  };

  /**
   * Get Templates
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getEmailTemplates = async () => {
    const url = `/api/v1/templates`;
    await Service.get(url, token).then(({ data: { data } }) => {
      setEmailTemplates(data);
    });
  };

  /**
   * Update One Templates
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateOneEmailTemplate = async ({ data, id }) => {
    const url = `/api/v1/admin/templates/update/${id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Update Terms
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateTerms = async ({ data, id }) => {
    const url = `/api/v1/terms/${id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Update Privacy
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updatePrivacy = async ({ data, id }) => {
    const url = `/api/v1/privacy/${id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  return (
    <StaticContext.Provider
      value={{
        stats,
        footer,
        service,
        report,
        emailTemplates,
        fetchFooter,
        fetchService,
        getFullReport,
        updateStaticContent,
        getStats,
        getEmailTemplates,
        updateOneEmailTemplate,
        setStats,
        getCompanyStats,
        getCustomSearch,
        terms,
        privacy,
        fetchTerms,
        fetchPrivacy,
        updateTerms,
        updatePrivacy,
      }}
    >
      {props.children}
    </StaticContext.Provider>
  );
};
