import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { pushOneTeam } from "../../../../middlewares/sysNavigation/SysNavigation";

import { useTeamContext } from "../../../../context/team/TeamContext";

import { Modal, Search, Button, Pagination } from "../../component/Component";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";

const addRoute = "/team/add";
const editRoute = "/team/edit";

const Overview = () => {
  let [teamList, setTeamList] = useState([]);
  const { team, fetchTeams, count } = useTeamContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!team) fetchTeams(page, pageSize);
    setTeamList(team);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (team !== null && team !== undefined)
      team.map((_team_) => {
        if (
          _team_.firstname.toString().toLowerCase().includes(key) ||
          _team_.lastname.toString().toLowerCase().includes(key) ||
          _team_.email_address.toString().toLowerCase().includes(key) ||
          _team_.phone_number.toString().toLowerCase().includes(key)
        )
          return searchArray.push(_team_);
        return true;
      });

    setTeamList(searchArray);
    if (key === "") setTeamList(team);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Team",
            onChange: (e) => SearchList(e),
          }}
        />
        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <Button
              props={{
                type: "button",
                title: "Add Team",
                category: "default",
                action: "add",
                onClick: null,
              }}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  First Name
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  Last Name
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Phone</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Email</th>
                <th className="border text-center">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {team ? (
                (() => {
                  if (!teamList) teamList = [...team];
                  return teamList.map((_team_) => {
                    return (
                      <tr key={_team_.team_id}>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_team_.firstname}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_team_.lastname}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_team_.phone_number}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_team_.email_address}
                        </td>
                        <td className="border">
                          <ActionButton props={_team_} />
                        </td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">Loading...</td>
                  <td className="border px-2 lg:px-4 w-1/4">Loading...</td>
                  <td className="border px-2 lg:px-4 w-1/4">Loading...</td>
                  <td className="border px-2 lg:px-4 w-1/4">Loading...</td>
                  <td className="border">Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchTeams(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { team, updateTeamState, deleteTeam, updateTeam } = useTeamContext();

  const [showModal, setShowModal] = useState(false);

  const team_id = props.team_id;
  const team_name = props.name;
  const _team_ = props;

  const deleteOneTeamById = async (team_id) => {
    const response = await deleteTeam(team_id);
    if (response.data.status === 200) {
      if (team) {
        // Update the team data
        const update = team.filter((_team_) => _team_.team_id !== team_id);
        // Update the Global State of Regions
        updateTeamState(update);
        setShowModal(false);
      }
    }
  };

  const suspension = async (__team__) => {
    const data = {
      email_address: __team__.email_address,
      firstname: __team__.firstname,
      lastname: __team__.lastname,
      password: "000000",
      country: __team__.country,
      city: __team__.city,
      address: __team__.address,
      phone_number: __team__.phone_number,
      role: __team__.role,
      status: __team__.status === 1 ? 0 : 1,
    };

    const response = await updateTeam(__team__.team_id, data);

    if (response.data.status === 200) {
      if (team) {
        const array = [];
        // Update the team data
        team.map((___team___) => {
          if (___team___.team_id === team_id)
            return array.push({
              team_id: __team__.team_id,
              added_by: __team__.added_by,
              createdAt: __team__.createdAt,
              updatedAt: ___team___.updatedAt,
              ...data,
            });
          else return array.push(___team___);
        });
        // Update the Global State of TipsAndAdvice
        updateTeamState(array);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: team_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneTeamById(team_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Button
          props={{
            type: "button",
            title: _team_.status === 1 ? "Suspend" : "Unsuspend",
            category: _team_.status === 1 ? "orange" : "black",
            action: "suspend",
            onClick: () => suspension(_team_),
          }}
        />
        <Link to={editRoute}>
          <Button
            props={{
              type: "button",
              title: "Edit",
              category: "blue",
              action: "edit",
              onClick: () => {
                pushOneTeam(_team_);
              },
            }}
          />
        </Link>
        <Button
          props={{
            type: "button",
            title: "Delete",
            category: "red",
            action: "delete",
            onClick: () => {
              setShowModal(true);
            },
          }}
        />
      </div>
    </>
  );
};

export default Overview;
