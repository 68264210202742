export const FOLDERS = Object.freeze({
  ART: "arts",
  NEWS: "news",
  TEAM: "team",
  BOOK: "books",
  FOODS: "foods",
  MUSIC: "music",
  PARTY: "party",
  BLOGS: "blogs",
  PLUGS: "plugs",
  AGENTS: "/agents",
  CINEMA: "cinema",
  EVENTS: "events",
  DANCES: "dances",
  HOTELS: "hotels",
  MOVIES: "movies",
  SAFARI: "safari",
  LEVELS: "levels",
  MUSEUMS: "museums",
  COMPANY: "company",
  CLASSES: "classes",
  PAGES: "dosee/page",
  PARTNERS: "partners",
  THEATERS: "theaters",
  NIGHTLIFE: "nighlife",
  SHOPPINGS: "shoppings",
  COMPANIES: "companies",
  EMBASSIES: "embassies",
  BANNER: "dosee/banner",
  MINISTRIES: "ministries",
  CITY_GUIDE: "city_guide",
  ATTRACTION: "attractions",
  RESTAURANTS: "restaurant",
  PROCUCTS: "dosee/product",
  ADV: "dosee/advertisement",
  COUNTRIES: "dosee/countries",
  THINGS_TO_DO: "things_to_do",
  NEIGHBORHOODS: "neighborhoods",
  BEST_OF_AFRICA: "best_of_africa",
  GETTING_AROUND: "getting_around",
  TIPS_AND_ADVICES: "tips_and_advice",
  TOUR_PACKAGES: "dosee/tour_package",
  REGIONAL_BLOCKS: "dosee/regional_blocks",
  TRADE_ORGANIZATIONS: "dosee/trade_organizations",
  CONTINENT_REGIONS: "dosee/continent_region",
});

export const image_kit_folder = (country_name, folder_section) => {
  return `/dosee/countries/${String(country_name)
    .toLowerCase()
    .replace(/\s/g, "_")
    .replace(/,/g, "_")
    .replace(/;/g, "_")}/${folder_section}`;
};

export const isKeyHasAnEmptyValue = (value, placeHolder) => {
  if (value === "none" || value === "" || !value) {
    return placeHolder || "none";
  }
  return value;
};
