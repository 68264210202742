import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  Button,
  TextArea,
  MyMessage,
  InputField,
  PhoneInput,
  DashboardIntro,
  MyActionButton,
  MyButton,
} from "../../home/component/Component";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import ModalDetails from "../utils/hotelRestauDetails/ModalDetails";
import ExteriorImages from "./exteriorImages";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import Pricing from "../../home/component/Pricing";
import GenerateDescription from "../utils/GenerateDescription";

const Add = () => {
  const { createOneContent } = useDoSeeDataContext();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
    tags,
  } = useGeoPositionContext();

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [type, setType] = useState("african");
  const [openedDays, setOpenedDays] = useState(
    "Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday"
  );
  const [openedAt, setOpenedAt] = useState("00:00");
  const [closedAt, setClosedAt] = useState("00:00");
  const [rating, setRating] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [whatsapp, setWhatsapp] = useState("none");
  const [facebook, setFacebook] = useState("none");
  const [twitter, setTwitter] = useState("none");
  const [instagram, setInstagram] = useState("none");
  const [youtube, setYoutube] = useState("none");
  const [hashtags, setHashtags] = useState("");
  const [googleMapCode, setGoogleMapCode] = useState("none");
  const [latitude, setLatitude] = useState("none");
  const [longitude, setLongitude] = useState("none");
  const [formattedAddress, setFormattedAddress] = useState(null);
  const [promoted, setPromoted] = useState("no");
  const [restaurantStatus, setRestaurantStatus] = useState(1);
  const [description, setDescription] = useState(null);
  const [collection, setCollection] = useState([]);
  const [menu, setMenu] = useState(null);
  const [menu_id, setMenuId] = useState(null);
  // const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [exteriorImages, setExteriorImages] = useState([]);
  const [showExtImages, setShowExtImages] = useState(false);
  const [expo, setExpo] = useState(0);
  const [pricing, setPricing] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const eventRoutes = "/data/restaurant";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setThumbnail(null);
    setThumbnailId(null);
    setTitle(null);
    setType(null);
    setOpenedDays(
      "Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday"
    );
    setOpenedAt("00:00");
    setClosedAt("00:00");
    setRating(null);
    setEmail(null);
    setExpo(0);
    setPhoneNumber(null);
    setWhatsapp("none");
    setFacebook("none");
    setTwitter("none");
    setInstagram("none");
    setYoutube("none");
    setHashtags("none");
    setGoogleMapCode("none");
    setLatitude("none");
    setLongitude("none");
    setPromoted("no");
    setFormattedAddress(null);
    setRestaurantStatus(1);
    setDescription(null);
    setCollection([]);
    setMenu(null);
    setMenuId(null);
    setExteriorImages([]);
    setPricing([]);
  };

  const responses = (error, message) => {
    setError(error);
    setLoading(false);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const addOneRestaurantHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      name: title,
      type: type,
      slug: title?.toLowerCase().replace(/\s/g, "_"),
      description: description,
      city_level: "level",
      opened_days: openedDays,
      opened_at: openedAt,
      closed_at: closedAt,
      rating: rating,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      phone_number: phoneNumber,
      email_address: email,
      whatsapp: whatsapp,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
      hashtag: `${tags}, ${hashtags}`,
      audio_ad: "none",
      google_map_code: googleMapCode,
      latitude: latitude,
      longitude: longitude,
      formatted_address: formattedAddress,
      country_short: "none",
      status: restaurantStatus,
      promoted: promoted,
      subdata: collection?.length > 0 ? collection : [],
      menu: menu
        ? {
            name: "...",
            category: "...",
            description: "...",
            thumbnail: menu,
            image_cdn_id: menu_id,
          }
        : null,
      expo: expo,
      exterior_images: exteriorImages?.filter((x) => x?.thumbnail !== null),
      pricing: pricing,
    };

    const { status, message } = await createOneContent({
      data: data,
      content: _TYPES_.RESTAURANT,
    });
    if (status === 200 || status === 201) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: eventRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-3">
        <DashboardIntro
          props={{ intro: `Add Restaurant`, name: entry_label }}
        />
      </div>

      <ModalDetails
        onClick={() => setShowModal(false)}
        showModal={showModal}
        collection={collection}
        setCollection={setCollection}
      />

      {/* <MenuModal
        onClick={() => setShowMenu(false)}
        showModal={showMenu}
        menu={menu}
        setMenu={setMenu}
      /> */}

      <ExteriorImages
        exteriorImages={exteriorImages}
        setExteriorImages={setExteriorImages}
        showModal={showExtImages}
        onClick={() => setShowExtImages(false)}
      />

      <form onSubmit={addOneRestaurantHandler}>
        <div className="mt-3 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <div className="flex space-x-5 w-full justify-around items-center">
            <div className="">
              <p className="text-xs font-bold">Profile Image</p>
              <ImageKitUpload
                thumbnail={thumbnail}
                thumbnailId={thumbnailId}
                setThumbnail={setThumbnail}
                setThumbnailId={setThumbnailId}
                folder={image_kit_folder(country_name, FOLDERS.RESTAURANTS)}
              />
            </div>
            <div>
              <p className="text-xs font-bold">Menu PDF</p>
              <ImageKitUpload
                thumbnail={menu}
                thumbnailId={menu_id}
                setThumbnail={setMenu}
                setThumbnailId={setMenuId}
                folder={image_kit_folder(country_name, FOLDERS.RESTAURANTS)}
              />
            </div>
          </div>

          <Title props={{ title: "Restaurant Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                required: true,
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "type",
                title: "Type",
                type: "text",
                option: [
                  "african",
                  "american",
                  "south american",
                  "european",
                  "asian",
                  "oceanian",
                  "intercontinental",
                ],
                onChange: (e) => {
                  setType(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "formatted_address",
                title: "Formatted Address",
                type: "address",
                value: formattedAddress ? formattedAddress : "",
                onChange: (e) => {
                  setFormattedAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Date & Time" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "opened_days",
                title: "Open Days",
                type: "text",
                value: openedDays ? openedDays : "",
                onChange: (e) => {
                  setOpenedDays(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "opened_at",
                title: "Opened At",
                type: "time",
                value: openedAt ? openedAt : "",
                onChange: (e) => {
                  setOpenedAt(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "closed_at",
                title: "Closed At",
                type: "time",
                value: closedAt ? closedAt : "",
                onChange: (e) => {
                  setClosedAt(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "rating",
                title: "Rating",
                type: "text",
                value: rating ? rating : "",
                onChange: (e) => {
                  setRating(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Pricing" }} />

          <Pricing pricing={pricing} setPricing={setPricing} />

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phoneNumber}
                required={false}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "whatsapp",
                title: "Whatsapp",
                type: "text",
                value: whatsapp ? whatsapp : "",
                onChange: (e) => {
                  setWhatsapp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                value: facebook ? facebook : "",
                onChange: (e) => {
                  setFacebook(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                value: twitter ? twitter : "",
                onChange: (e) => {
                  setTwitter(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                value: instagram ? instagram : "",
                onChange: (e) => {
                  setInstagram(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                value: youtube ? youtube : "",
                onChange: (e) => {
                  setYoutube(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Geo Position" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "google_map_code",
                title: "Google Map Code",
                type: "text",
                value: googleMapCode ? googleMapCode : "",
                onChange: (e) => {
                  setGoogleMapCode(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "latitude",
                title: "Latitude",
                type: "text",
                value: latitude ? latitude : "",
                onChange: (e) => {
                  setLatitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "longitude",
                title: "Longitude",
                type: "text",
                value: longitude ? longitude : "",
                onChange: (e) => {
                  setLongitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "event_status",
                title: "Status",
                required: true,
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setRestaurantStatus(1);
                  else setRestaurantStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "promoted",
                title: "Promoted",
                option: ["no", "yes"],
                onChange: (e) => {
                  setPromoted(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "gallery",
                title: "Show in Gallery",
                option: ["no", "yes"],
                onChange: (e) => {
                  const choice = e.target.value;
                  setExpo(choice === "no" ? 0 : 1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="w-full space-x-2 flex justify-start items-center py-8">
            <Button
              props={{
                type: "button",
                title: "Add Inter. / Exter. Images",
                category: "default",
                action: "add",
                onClick: () => setShowExtImages(true),
              }}
            />
            {/* <Button
              props={{
                type: "button",
                title: "Add Restaurant Menu",
                category: "black",
                action: "add",
                onClick: () => setShowMenu(true),
              }}
            /> */}
            <Button
              props={{
                type: "button",
                title: "Add More Details",
                category: "default",
                action: "add",
                onClick: () => setShowModal(true),
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Restaurant"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
