import React, { useState } from "react";

import { Title } from "../../component/TitleUtils";
import { InputField } from "../../component/FormUtils";
import { MyMessage } from "../../component/messageUtils";
import { MyActionButton } from "../../component/ActionButtonUtils";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";

const Overview = () => {
  const { updateAdminPassword, logOut } = useAdminContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const addOnePartnerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!password || !confirmPassword) {
      setLoading(false);
      return responses(true, "Password Required");
    } else if (password !== confirmPassword) {
      setLoading(false);
      return responses(true, "Password should match");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const { status } = await updateAdminPassword({ password });
    if (status === 200) {
      responses(false, message);
      setTimeout(() => logOut(), 1000);
    } else {
      setLoading(false);
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <form onSubmit={addOnePartnerHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Update Your Password" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "password",
                title: "Password",
                type: "password",
                required: true,
                value: password ? password : "",
                onChange: (e) => {
                  setPassword(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "confirmPassword",
                title: "Confirm Password",
                type: "password",
                required: true,
                value: confirmPassword ? confirmPassword : "",
                onChange: (e) => {
                  setConfirmPassword(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Overview;
