import React from "react";

import { Link } from "react-router-dom";

import { clearRoutingHistory } from "../../../middlewares/sysNavigation/SysNavigation";

import { useAdminContext } from "../../../context/admin/context/AdminContext";

import { FiUsers, FiMapPin, FiLogOut } from "react-icons/fi";
// import { RiTeamLine } from 'react-icons/ri';
// import { HiOutlineDocument } from 'react-icons/hi';
import { VscFileSubmodule } from "react-icons/vsc";
import { BsHouseDoor, BsPinMap } from "react-icons/bs";

let homeRoute = "/";
let regionRoute = "/region";
let countriesRoute = "/countries/dashboard";
let levelRoute = "/levels/dashboard";
// let pageRoute = '/page';
// let teamRoute = '/team';
let adminRoute = "/admin";
let userRoute = "/user";

const BottomNavigation = () => {
  // Get the current Admin
  const { logOut } = useAdminContext();

  return (
    <div className="flex flex-row items-center justify-between py-2 overflow-auto h-[80px] mt-1 text-gray-700 bg-gray-100 rounded-t-xl lg:hidden">
      <Link to={homeRoute}>
        <div className="flex flex-col px-3 text-black">
          <span className="flex w-full items-center justify-center m-auto">
            <BsHouseDoor className="w-7 h-7" />
          </span>
          <span className="text-xs font-bold pt-1">Home</span>
        </div>
      </Link>
      <Link to={regionRoute}>
        <div
          className="flex flex-col px-3 text-black"
          onClick={() => {
            clearRoutingHistory();
          }}
        >
          <span className="flex w-full items-center justify-center m-auto">
            <FiMapPin className="w-7 h-7" />
          </span>
          <span className="text-xs font-bold pt-1">Regions</span>
        </div>
      </Link>
      <Link to={countriesRoute}>
        <div
          className="flex flex-col px-3 text-black"
          onClick={() => {
            clearRoutingHistory();
          }}
        >
          <span className="flex w-full items-center justify-center m-auto">
            <BsPinMap className="w-7 h-7" />
          </span>
          <span className="text-xs font-bold pt-1">Countries</span>
        </div>
      </Link>
      <Link to={levelRoute}>
        <div
          className="flex flex-col px-3 text-black"
          onClick={() => {
            clearRoutingHistory();
          }}
        >
          <span className="flex w-full items-center justify-center m-auto">
            <VscFileSubmodule className="w-7 h-7" />
          </span>
          <span className="text-xs font-bold pt-1">Levels</span>
        </div>
      </Link>
      {/* <Link to={pageRoute}>
        <div className='flex flex-col px-3 text-black'>
          <span className='flex w-full items-center justify-center m-auto'>
            <HiOutlineDocument className='w-7 h-7' />
          </span>
          <span className='text-xs font-bold pt-1'>Pages</span>
        </div>
      </Link>
      <Link to={teamRoute}>
        <div className='flex flex-col px-3 text-black'>
          <span className='flex w-full items-center justify-center m-auto'>
            <RiTeamLine className='w-7 h-7' />
          </span>
          <span className='text-xs font-bold pt-1'>Team</span>
        </div>
      </Link> */}
      <Link to={adminRoute}>
        <div className="flex flex-col px-3 text-black">
          <span className="flex w-full items-center justify-center m-auto">
            <FiUsers className="w-7 h-7" />
          </span>
          <span className="text-xs font-bold pt-1">Admins</span>
        </div>
      </Link>
      <Link to={userRoute}>
        <div className="flex flex-col px-3 text-black">
          <span className="flex w-full items-center justify-center m-auto">
            <FiUsers className="w-7 h-7" />
          </span>
          <span className="text-xs font-bold pt-1">Users</span>
        </div>
      </Link>
      <div
        className="flex flex-col px-3 text-red-700"
        onClick={() => logOut()}
      >
        <span className="flex w-full items-center justify-center m-auto">
          <FiLogOut className="w-7 h-7" />
        </span>
        <span className="text-xs font-bold pt-1">Logout</span>
      </div>
    </div>
  );
};

export default BottomNavigation;
