/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { BuildDate } from "../../component/Functions";
import { SearchMatchingAdmins } from "./SearchFunction";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { pushOneAdmin } from "../../../../middlewares/sysNavigation/SysNavigation";
import { useAdminsContext } from "../../../../context/admin/context/AdminManageContext";
import {
  Modal,
  MyButton,
  MySearch,
  Pagination,
} from "../../component/Component";

const addRoute = "/admin/add";
const editRoute = "/admin/edit";

const Overview = () => {
  const { admins, getAllByAdminCompanyId, count } = useAdminsContext();
  const [page, setPage] = useState(1);
  const [adminsList, setAdminsList] = useState(null);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!admins) getAllByAdminCompanyId(page, pageSize);
    else setAdminsList(admins);
  }, [admins]);

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <MySearch
          onChange={(e) => setAdminsList(SearchMatchingAdmins(e, admins))}
        />
        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <MyButton
              title="Add Admin"
              className="bg-gray-800 hover:bg-gray-600 min-w-fit"
              onClick={() => {}}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-5">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border py-3 px-4 font-bold w-1/4">First Name</th>
                <th className="border py-3 px-4 font-bold w-1/4">Last Name</th>
                <th className="border py-3 px-4 font-bold w-1/4">
                  Date Of Joining
                </th>
                <th className="border text-center py-3 px-4 w-1/4">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {adminsList ? (
                adminsList?.map((admin, key) => {
                  const { first_name, last_name, createdAt } = admin;
                  return (
                    <tr key={key}>
                      <td className="border py-0 px-4 w-1/4">{first_name}</td>
                      <td className="border py-0 px-4 w-1/4">{last_name}</td>
                      <td className="border py-0 px-4 w-1/4">
                        {BuildDate({ ref: createdAt })}
                      </td>
                      <td className="border py-0 px-4">
                        <CallToAction admin={admin} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border py-0 px-4 w-1/4">-</td>
                  <td className="border py-0 px-4 w-1/4">-</td>
                  <td className="border py-0 px-4 w-1/4">-</td>
                  <td className="border w-1/4">-</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          total_items={count}
          pageSize={pageSize}
          setPageSize={setPageSize}
          callbackFunction={(p, size) => getAllByAdminCompanyId(p, size)}
        />
      </div>
    </div>
  );
};

/**
 * @author Ntavigwa Bashombe
 * @param {*} admin
 * @returns JSX.Element
 */
const CallToAction = ({ admin }) => {
  const { admin_id, first_name, last_name } = admin;
  const { sysAdmin } = useAdminContext();
  const { admins, setAdmins, deleteAdmin } = useAdminsContext();

  const [showModal, setShowModal] = useState(false);

  const deleteById = async (admin_id) => {
    const { status } = await deleteAdmin(admin_id);
    if (status === 200 || status === 201) {
      setAdmins(admins?.filter((x) => x?.admin_id !== admin_id) || null);
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        props={{
          name: `${first_name} ${last_name}`,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteById(admin_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => pushOneAdmin(admin)}
          />
        </Link>
        <MyButton
          title="Delete"
          disabled={admin_id !== sysAdmin?.admin_id ? false : true}
          className="bg-red-800 hover:bg-red-600 min-w-fit disabled:bg-red-300"
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
