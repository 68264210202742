import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const RegionalBlocksCard = ({
  blocks,
  blocksID,
  setError,
  setBlocks,
  setSuccess,
  setBlocksID,
  setIsBlockChanges,
  localRegionalBlocks,
}) => {
  return (
    <div className="flex flex-col space-y-4 h-full w-full">
      <p className="font-bold text-lg">Regional Blocks</p>
      <div className="w-full h-auto text-sm py-2 grid grid-cols-1 md:grid-cols-2 gap-4">
        {localRegionalBlocks?.map((m, key) => {
          return (
            <div
              key={key}
              className="flex flex-col items-start justify-center capitalize space-y-2 w-full h-full"
            >
              <p className="text-sm font-bold">{m?.name}</p>
              <p className="text-xs">{m?.description}</p>
              {blocks?.includes(String(m?.name)) ? (
                <MdCheckBox
                  className="text-3xl cursor-pointer"
                  onClick={() => {
                    setBlocks(blocks?.filter((trName) => trName !== m?.name));
                    setBlocksID(
                      blocksID?.filter((trID) => trID !== m?.regional_blocks_id)
                    );
                    setIsBlockChanges(1);
                    setError(null);
                    setSuccess(null);
                  }}
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  className="text-3xl cursor-pointer"
                  onClick={() => {
                    const pNames = blocks ? [...blocks] : [];
                    const pIDs = blocksID ? [...blocksID] : [];

                    pNames.push(m?.name);
                    pIDs.push(m?.regional_blocks_id);
                    setBlocks(pNames);
                    setBlocksID(pIDs);
                    setIsBlockChanges(1);
                    setError(null);
                    setSuccess(null);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RegionalBlocksCard;
