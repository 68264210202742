import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  PhoneInput,
  InputField,
  DashboardIntro,
  MyActionButton,
  MyButton,
} from "../../home/component/Component";
import { codeOk } from "../../../middlewares/index";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { getOneNews } from "../../../middlewares/sysNavigation/SysNavigation";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import GenerateDescription from "../utils/GenerateDescription";

const Edit = () => {
  const { updateOneContent } = useDoSeeDataContext();
  const news = getOneNews();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const news_id = news?.news_id;
  const [title, setTitle] = useState(news?.title);
  const [thumbnail, setThumbnail] = useState(news?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(news?.image_cdn_id);
  const [publishedOn, setPublishedOn] = useState(news?.published_on);
  const [email, setEmail] = useState(news?.email_address);
  const [category, setCategory] = useState(news?.category);
  const [phoneNumber, setPhoneNumber] = useState(news?.phone_number);
  const [whatsapp, setWhatsapp] = useState(news?.whatsapp);
  const [facebook, setFacebook] = useState(news?.facebook);
  const [twitter, setTwitter] = useState(news?.twitter);
  const [instagram, setInstagram] = useState(news?.instagram);
  const [youtube, setYoutube] = useState(news?.youtube);
  const [hashtags, setHashtags] = useState(news?.hashtag);
  const [featured, setFeatured] = useState(news?.featured);
  const [promoted, setPromoted] = useState(
    news?.promoted ? news?.promoted : "no"
  );
  const [newsStatus, setEventStatus] = useState(news?.status);
  const [description, setDescription] = useState(news?.description);
  const [expo, setExpo] = useState(news?.expo);
  const newsRoutes = "/data/news";
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id ? country_id : news?.country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      title: title,
      slug: title?.toLowerCase().replace(/\s/g, "_"),
      description: description,
      city_level: "level",
      published_on: publishedOn,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      phone_number: phoneNumber,
      email_address: email,
      category: category.toString().toLowerCase().replace(/\s/g, "_"),
      whatsapp: whatsapp,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
      hashtag: hashtags,
      audio_ad: "none",
      country_short: "none",
      status: newsStatus,
      featured: featured,
      promoted: promoted,
      expo: expo,
    };

    const { message, status } = await updateOneContent({
      data_id: news_id,
      data: data,
      content: _TYPES_.NEWS,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });

    if (status === codeOk) responses(false, message);
    else responses(true, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: newsRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-3">
        <DashboardIntro props={{ intro: `Edit News`, name: entry_label }} />
      </div>
      <form onSubmit={updateOneEventHandler}>
        <div className="mt-3 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.NEWS)}
          />

          <Title props={{ title: "Event Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "start_date",
                title: "Start Date",
                type: "date",
                value: publishedOn ? publishedOn : "",
                onChange: (e) => {
                  setPublishedOn(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phoneNumber}
                required={false}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "category",
                title: "Category",
                required: true,
                option: [
                  "event",
                  "attraction",
                  "art",
                  "best of africa",
                  "book",
                  "cinema",
                  "city guide",
                  "classes",
                  "dance",
                  "food",
                  "getting around",
                  "hotel",
                  "ministry",
                  "movie",
                  "museum",
                  "music",
                  "neighborhood",
                  "nightlife",
                  "party",
                  "restaurant",
                  "safari",
                  "shopping",
                  "theater",
                  "things to do",
                  "tips and advice",
                ],
                preselect: category
                  ? [
                      {
                        name: category
                          .toString()
                          .toLowerCase()
                          .replace("_", " "),
                      },
                    ]
                  : [],
                onChange: (e) => {
                  setCategory(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "whatsapp",
                title: "Whatsapp",
                type: "text",
                value: whatsapp ? whatsapp : "",
                onChange: (e) => {
                  setWhatsapp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                value: facebook ? facebook : "",
                onChange: (e) => {
                  setFacebook(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                value: twitter ? twitter : "",
                onChange: (e) => {
                  setTwitter(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                value: instagram ? instagram : "",
                onChange: (e) => {
                  setInstagram(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                value: youtube ? youtube : "",
                onChange: (e) => {
                  setYoutube(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "news_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  newsStatus !== null
                    ? [{ name: newsStatus === 1 ? "visible" : "invisible" }]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setEventStatus(1);
                  else setEventStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "featured",
                title: "Featured",
                option: ["no", "yes"],
                preselect: featured !== null ? [{ name: featured }] : null,
                onChange: (e) => {
                  setFeatured(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "promoted",
                title: "Promoted",
                option: ["no", "yes"],
                preselect: promoted !== null ? [{ name: promoted }] : null,
                onChange: (e) => {
                  setPromoted(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "gallery",
                title: "Show in Gallery",
                option: ["no", "yes"],
                preselect:
                  expo !== null ? [{ name: expo === 0 ? "no" : "yes" }] : null,
                onChange: (e) => {
                  const choice = e.target.value;
                  setExpo(choice === "no" ? 0 : 1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
