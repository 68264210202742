/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Title,
  GoBack,
  Message,
  InputField,
  ActionButton,
} from "../../component/Component";
import {
  codeOk,
  codeCreate,
  errorMessage,
} from "../../../../middlewares/index";
import EventsList from "./EventsList";
import { FaTimes } from "react-icons/fa";
import AttractionsList from "./AttractionsList";
import { usePageContext } from "../../../../context/page/PageContext";
import { getOnePage } from "../../../../middlewares/sysNavigation/SysNavigation";
import { BreadCrumbComponent } from "../../../../middlewares/breadCrumb/BreadCrumbComponent";

const Edit = () => {
  const memory = getOnePage();
  const { updatePage } = usePageContext();
  const [category, setCategory] = useState(memory.category);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setErrorMessage] = useState(null);
  const [menu, setMenu] = useState("event");
  const [pages, setPages] = useState([]);
  const pageRoutes = "/page";

  useEffect(() => {
    setPages(
      ...memory.localPages.map((content) =>
        content.filter((val) => val.category === memory.category)
      )
    );
  }, []);

  const cleanFields = () => {
    setSuccess(true);
    setLoading(false);
    setCategory(null);
  };

  const responses = (isError, status, message) => {
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  const addOnePageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!category) {
      setLoading(false);
      return responses(true, 400, "Page Title is Required");
    } else if (pages.length === 0) {
      setLoading(false);
      return responses(true, 400, "Add Content To Page");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const filter = pages.map((val) => {
      return {
        description: val.description,
        hashtag: val.hashtag,
        image_cdn_id: val.image_cdn_id,
        slug: val.slug,
        status: val.status,
        thumbnail: val.thumbnail,
        title: val.title,
      };
    });
    const data = {
      category: category,
      old_category: memory.category,
      data: filter,
    };

    const response = await updatePage(data);
    if (response) {
      if (
        response.data.status === codeOk ||
        response.data.status === codeCreate
      ) {
        cleanFields();
        return responses(true, response.data.status, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.status, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: pageRoutes }} />

      <form onSubmit={addOnePageHandler}>
        <div className="flex flex-col items-center justify-center font-medium text-sm md:text-base my-3 w-full">
          <Message
            props={{
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full my-5">
            <div className="w-full">
              <div className="w-full">
                <InputField
                  props={{
                    id: "page",
                    title: "Page Title",
                    type: "text",
                    required: true,
                    value: category ? category : "",
                    onChange: (e) => {
                      setCategory(e.target.value);
                      setError(null);
                      setSuccess(null);
                    },
                  }}
                />
              </div>

              <Title props={{ title: "Page Content" }} />

              <div className="flex flex-col space-y-8">
                {pages.length === 0 ? (
                  <span className="text-xs font-bold text-gray-600">
                    This Page is Empty, <br /> Click on Events or Attractions to
                    add them
                  </span>
                ) : (
                  pages.map((value, index) => (
                    <PageContent
                      slug={value.slug}
                      index={index}
                      title={value.title}
                      key={index}
                      setPages={setPages}
                      pages={pages}
                    />
                  ))
                )}
              </div>

              <div className="w-full my-8">
                <ActionButton
                  props={{
                    title: "Save Changes",
                    error: error,
                    success: success,
                    message: message,
                    loading: loading,
                    status: status,
                    onClick: () => {
                      setError(false);
                      setSuccess(false);
                      setErrorMessage("");
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-8">
              <p className="text-xs font-bold text-gray-600 text-center">
                To add an event or an attraction to this page, <br /> Please
                click on "Add" to add or <br />
                To "Remove" to remove from page
              </p>
              <div className="flex w-full justify-start space-x-8 text-base font-bold text-black hover:text-black/80">
                <p
                  onClick={() => setMenu("event")}
                  className={
                    `cursor-pointer py-2 px-5 ` +
                    (menu === "event"
                      ? "border-4 border-green-800 text-green-800 "
                      : "border-none")
                  }
                >
                  Events List
                </p>
                <p
                  onClick={() => setMenu("attraction")}
                  className={
                    `cursor-pointer py-2 px-5 ` +
                    (menu === "attraction"
                      ? "border-4 border-green-800 text-green-800 "
                      : "border-none")
                  }
                >
                  Attractions List
                </p>
              </div>

              {menu === "event" ? (
                <EventsList
                  setPages={setPages}
                  pages={pages}
                />
              ) : (
                <AttractionsList
                  setPages={setPages}
                  pages={pages}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Edit;

const PageContent = ({ index, title, slug, setPages, pages }) => {
  return (
    <div className="w-full flex flex-col space-y-4 border-b border-gray-200 pb-5 text-xs">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <p>
          {index + 1}. {title}
        </p>
        <p className="line-clamp-1 w-full">{slug}</p>
      </div>
      <div className="flex w-full justify-start">
        <button
          type="button"
          onClick={() => setPages(pages.filter((val) => val.slug !== slug))}
          className="bg-red-500 w-fit px-4 hover:bg-red-700 text-white font-bold py-3 text-xs flex justify-center items-center space-x-2"
        >
          <FaTimes />
          <span>Remove</span>
        </button>
      </div>
    </div>
  );
};
