import axios from "axios";
import { API_URL } from "../config";
import { useAdminContext } from "../admin/context/AdminContext";
import React, { createContext, useContext, useState } from "react";

const BlogContext = createContext();
export const useBlogContext = () => useContext(BlogContext);

export const BlogProvider = (props) => {
  const [blogs, setBlogs] = useState(null);
  const [blog, setBlog] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const updateBlogState = async (data) => setBlogs(data);

  /**
   * Fetch all blog
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchBlogs = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/blog/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setBlogs(response?.data?.data?.data);
        setCount(response?.data?.data?.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch one blog
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchOneBlog = async (id) => {
    try {
      const fetch_url = API_URL + `/api/v1/blog/${id}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setBlog(response?.data?.data);
        setCount(response?.data?.data?.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new blog
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneBlog = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/blog/create";
      const token = sysAdmin?.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a blog
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateBlog = async (blog_id, data) => {
    try {
      const update_url = API_URL + `/api/v1/blog/update/${blog_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error?.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a blog
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteBlog = async (blog_id) => {
    try {
      const delete_url = API_URL + `/api/v1/blog/delete/${blog_id}`;

      const token = sysAdmin?.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error?.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <BlogContext.Provider
      value={{
        blog,
        count,
        blogs,
        fetchBlogs,
        updateBlog,
        deleteBlog,
        fetchOneBlog,
        createOneBlog,
        updateBlogState,
      }}
    >
      {props.children}
    </BlogContext.Provider>
  );
};
