import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../../config';

import { useAdminContext } from '../../admin/context/AdminContext';

const ThirdLevelContext = createContext();

export const useThirdLevelContext = () => useContext(ThirdLevelContext);

export const ThirdLevelProvider = (props) => {
  const [thirdLevels, setThirdLevels] = useState(null);
  const [thirdLevel, setThirdLevel] = useState(null);
  const [countThird, setCountThird] = useState(0);

  const { sysAdmin } = useAdminContext();

  // Update the state
  const updateThirdLevelState = async (data) => {
    return setThirdLevels(data);
  };

  /**
   * Fetch all third levels
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchThirdLevels = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/third/level/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });
      if (response) {
        setThirdLevels(response.data.data.data);
        setCountThird(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch by country id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchThirdByCountryId = async ({
    country_id,
    page = 1,
    pageSize = 20,
  }) => {
    try {
      const fetch_url =
        API_URL +
        `/api/v1/third/level/location/?page=${page}&pageSize=${pageSize}&country_id=${country_id}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setThirdLevels(response.data.data.data);
        setCountThird(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Get a single third_level with metadata
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */

  const fetchThirdLevel = async (third_level_id) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/third/level/metadata/${third_level_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });
      if (response) {
        setThirdLevel(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new third level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addThirdLevel = async (data) => {
    try {
      const token = sysAdmin.token;
      const fetch_url = API_URL + `/api/v1/admin/third/level/create`;
      let errorResponse = null;

      const response = await axios
        .post(fetch_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update Third level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateThirdLevel = async (third_level_id, data) => {
    try {
      const token = sysAdmin.token;
      const update_url =
        API_URL + `/api/v1/admin/third/level/update/${third_level_id}`;
      let errorResponse = null;

      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a single third level by id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteThirdLevel = async (third_level_id) => {
    try {
      const token = sysAdmin.token;
      const fetch_url =
        API_URL + `/api/v1/admin/third/level/delete/${third_level_id}`;
      let errorResponse = null;

      const response = await axios
        .delete(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ThirdLevelContext.Provider
      value={{
        thirdLevels,
        fetchThirdLevels,
        deleteThirdLevel,
        updateThirdLevelState,
        addThirdLevel,
        thirdLevel,
        fetchThirdLevel,
        updateThirdLevel,
        countThird,
        fetchThirdByCountryId,
      }}
    >
      {props.children}
    </ThirdLevelContext.Provider>
  );
};
