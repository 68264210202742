export const _PAGE_SIZE_ = 50;

export const ContentTypes = Object.freeze({
  Art: "Art",
  Attraction: "Attraction",
  "Best of Africa": "Best of Africa",
  Book: "Book",
  Cinema: "Cinema",
  "City Guide": "City Guide",
  Class: "Class",
  Dance: "Dance",
  Embassies: "Embassies",
  Event: "Event",
  Food: "Food",
  "Getting around": "Getting around",
  Hotels: "Hotels",
  Ministry: "Ministry",
  Movie: "Movie",
  Museum: "Museum",
  Music: "Music",
  Neighborhood: "Neighborhood",
  News: "News",
  Nightlife: "Nightlife",
  Party: "Party",
  Product: "Product",
  Restaurant: "Restaurant",
  Safari: "Safari",
  Shopping: "Shopping",
  Theater: "Theater",
  "Things to do": "Things to do",
  "Tips and Advice": "Tips and Advice",
  "Tour package": "Tour package",
});
