/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { pushOneNeighborhood } from "../../../middlewares/sysNavigation/SysNavigation";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import {
  Modal,
  GoBack,
  MyButton,
  MySearch,
  Pagination,
  ModalPosition,
  DashboardIntro,
} from "../../home/component/Component";
import { _TYPES_ } from "../utils/types";
import { _PAGE_SIZE_ } from "../../../middlewares/other/tools";
import { useGeoPositionContext } from "../utils/geoPosition";
import { useAdminContext } from "../../../context/admin/context/AdminContext";

const addRoute = "/data/neighborhood/add";
const editRoute = "/data/neighborhood/edit";
const doseeDataRoutes = "/dosee/data";

const Overview = () => {
  const [neighborhoodsList, setNeighborhoodsList] = useState([]);
  const { neighborhoods, neighborhoodsCount, fetchDoSeeData } =
    useDoSeeDataContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  const [localSearch, setLocalSearch] = useState([]);

  const {
    country_id,
    entry_label,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const data_request_body = {
    country_id: country_id,
    first_level_id: first_level_id,
    second_level_id: second_level_id,
    third_level_id: third_level_id,
    fourth_level_id: fourth_level_id,
    content: _TYPES_.NEIGHBORHOOD,
  };

  useEffect(() => {
    if (!neighborhoods) fetchDoSeeData(data_request_body, page, pageSize);
    setNeighborhoodsList(neighborhoods);
  }, [neighborhoods]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    neighborhoods.map((ngh) => {
      if (ngh?.name.toString().toLowerCase().includes(key))
        return searchArray.push(ngh);
      return true;
    });

    setNeighborhoodsList(searchArray);
    if (key === "") setNeighborhoodsList(neighborhoods);
  };

  const LocalData = localSearch?.length > 0 ? localSearch : neighborhoodsList;

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: doseeDataRoutes }} />
      <div className="my-3">
        <DashboardIntro props={{ intro: `Neighborhoods`, name: entry_label }} />
      </div>

      <div className="flex flex-col justify-center">
        <MySearch
          type={_TYPES_.NEIGHBORHOOD}
          value={localSearch}
          showGlobalSearch={true}
          setValue={setLocalSearch}
          onChange={(e) => SearchList(e)}
        />

        <div className="w-full flex justify-end my-5">
          {country_id ? (
            <Link to={addRoute}>
              <MyButton
                title="Add Neighborhood"
                className="py-3 px-8 bg-blue-900 hover:bg-blue-600"
                onClick={() => {}}
              />
            </Link>
          ) : null}
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">Slug</th>
                <th className="border text-center w-1/3">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {neighborhoods || LocalData ? (
                LocalData?.map((ngh, key) => (
                  <tr key={key}>
                    <td className="border px-2 lg:px-4 w-1/3">{ngh?.name}</td>
                    <td className="border px-2 lg:px-4 w-1/3">{ngh?.slug}</td>
                    <td className="border w-1/3">
                      <ActionButton props={ngh} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={neighborhoodsCount}
          callbackFunction={(p, size) => {
            fetchDoSeeData(data_request_body, p, size);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { sysAdmin } = useAdminContext();
  const isSuperAdmin = ["super_admin", "admin"].includes(sysAdmin?.role);
  const { neighborhoods, setDataState, deleteOneContent, updateOneContent } =
    useDoSeeDataContext();

  const [showModal, setShowModal] = useState(false);

  const neighborhood_id = props.neighborhood_id;
  const neighborhood_name = props.title;
  const neighborhood = props;

  const deleteOneNeighborhoodById = async (neighborhood_id) => {
    const { status } = await deleteOneContent({
      data_id: neighborhood_id,
      content: _TYPES_.NEIGHBORHOOD,
    });
    if (status === 200) {
      if (neighborhoods) {
        setDataState({
          data: neighborhoods.filter(
            (ngh) => ngh?.neighborhood_id !== neighborhood_id
          ),
          content: _TYPES_.NEIGHBORHOOD,
        });
        setShowModal(false);
      }
    }
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} neighborhood
   */

  const suspension = async (neighborhood) => {
    const data = {
      country_id: neighborhood?.country_id,
      description: neighborhood?.description,
      first_level_id: neighborhood?.first_level_id,
      fourth_level_id: neighborhood?.fourth_level_id,
      second_level_id: neighborhood?.second_level_id,
      slug: neighborhood?.slug,
      status: neighborhood?.status === 1 ? 0 : 1,
      third_level_id: neighborhood?.third_level_id,
      thumbnail: neighborhood?.thumbnail,
      safety_indicator: neighborhood?.safety_indicator,
      name: neighborhood?.name,
    };

    const { status } = await updateOneContent({
      data_id: neighborhood?.neighborhood_id,
      data: data,
      content: _TYPES_.NEIGHBORHOOD,
    });

    if (status === 200) {
      if (neighborhoods) {
        const array = [];
        neighborhoods.map((ngh) => {
          if (ngh?.neighborhood_id === neighborhood_id)
            return array.push({
              neighborhood_id: neighborhood?.neighborhood_id,
              createdAt: neighborhood?.createdAt,
              updatedAt: neighborhood?.updatedAt,
              ...data,
            });
          else return array.push(ngh);
        });
        setDataState({ data: array, content: _TYPES_.NEIGHBORHOOD });
      }
    }
  };

  const [showPosition, setShowPosition] = useState(false);

  return (
    <>
      {showPosition ? (
        <ModalPosition
          name={neighborhood_name}
          type={_TYPES_.NEIGHBORHOOD}
          id={neighborhood_id}
          setShowPosition={setShowPosition}
          showPosition={showPosition}
        />
      ) : null}

      <Modal
        props={{
          name: neighborhood_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneNeighborhoodById(neighborhood_id),
        }}
      />

      <div className="flex flex-row justify-end items-center px-2 py-3 space-x-4 text-xs">
        {/* <Button
          props={{
            type: 'button',
            title: 'Promote',
            category: 'black',
            action: null,
            onClick: () => {
              setShowPosition(true);
            },
          }}
        /> */}
        {isSuperAdmin ? (
          <MyButton
            title={neighborhood?.status === 1 ? "Suspend" : "Unsuspend"}
            className={`py-3 px-8 ${
              neighborhood?.status === 1
                ? "bg-gray-900 hover:bg-gray-600"
                : "bg-orange-900 hover:bg-orange-600"
            }`}
            onClick={() => suspension(neighborhood)}
          />
        ) : null}

        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-3 px-8 bg-blue-800 hover:bg-blue-600`}
            onClick={() => pushOneNeighborhood(neighborhood)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-3 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
