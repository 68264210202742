import React from 'react';

export const InfoSection = () => {
  return (
    <div className='flex flex-col gap-2'>
      <div className='w-full md:w-auto'>
        <p className='text-xs my-4 w-auto text-center font-normal'>
          Notice:{' '}
          <span className='font-medium text-gray-600'>
            If you place an event at a taken position, it will override that
            current event with the new event at that position.
          </span>
        </p>
      </div>
      <div className='flex flex-col my-2'>
        <p className='text-xs font-mono flex text-gray-900 capitalize'>
          <span className='w-4 h-4 bg-gray-600/60 mr-1 rounded-full shadow-lg'></span>
          Position Empty
        </p>
        <p className='text-xs font-mono  text-gray-900 flex my-2 capitalize'>
          <span className='w-4 h-4 bg-red-700/70 mr-1 rounded-full shadow-lg'></span>
          Taken by another event.
        </p>
        <p className='text-xs font-mono  text-gray-900 flex capitalize'>
          <span className='w-4 h-4 bg-green-700/70 mr-1 rounded-full shadow-lg'></span>
          Current Position of this event.
        </p>
      </div>
    </div>
  );
};

export default InfoSection;
