import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const PlugImageCard = ({
  plugs,
  plugsID,
  setError,
  setPlugs,
  setSuccess,
  setPlugsID,
  localPlugImages,
  setIsPlugChanges,
}) => {
  return (
    <div className="flex flex-col h-full w-full py-4">
      <p className="font-bold text-lg">Plug Images</p>
      <div className="w-full h-auto text-sm py-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {localPlugImages && localPlugImages?.length > 0 ? (
          localPlugImages?.map((m, key) => {
            return (
              <div
                key={key}
                className="flex flex-col space-y-2 items-start justify-center capitalize w-full h-full"
              >
                <p className="text-sm font-bold">Type {m?.type}</p>
                <div className="w-auto h-12">
                  <img
                    src={m?.photo_url}
                    alt="plug"
                    className="w-full h-full object-cover"
                  />
                </div>
                {plugs?.includes(String(m?.type)) ? (
                  <MdCheckBox
                    onClick={() => {
                      setPlugs(plugs?.filter((pType) => pType !== m?.type));
                      setPlugsID(
                        plugsID?.filter((pID) => pID !== m?.plug_image_id)
                      );
                      setIsPlugChanges(1);
                      setError(null);
                      setSuccess(null);
                    }}
                    className="text-3xl cursor-pointer"
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    onClick={() => {
                      const pTypes = plugs ? [...plugs] : [];
                      const pIDs = plugsID ? [...plugsID] : [];

                      pTypes.push(m?.type);
                      pIDs.push(m?.plug_image_id);
                      setPlugs(pTypes);
                      setPlugsID(pIDs);
                      setIsPlugChanges(1);
                      setError(null);
                      setSuccess(null);
                    }}
                    className="text-3xl cursor-pointer"
                  />
                )}
              </div>
            );
          })
        ) : (
          <div className="text-xs font-light">No Plug Image Found</div>
        )}
      </div>
    </div>
  );
};

export default PlugImageCard;
