import { useState } from "react";
import createDOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { FaEdit, FaPause, FaPlay, FaTrashAlt } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

import { MyButton } from "../../component/ButtonUtils";
import { pushCountry } from "../../../../middlewares/sysNavigation/SysNavigation";
import { useDoSeeDataContext } from "../../../../context/doSeeData/DoSeeDataContext";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { getType } from "../contentStats/helper";

const DOMPurify = createDOMPurify(window);

export const addTextNewLines = (val = "") => {
  return (
    <div
      className="line-clamp-3"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(val) }}
    />
  );
};

export const SeachContent = ({
  data,
  route,
  title,
  idLabel,
  setLocalSearch,
  titleLabel = "title",
  collection,
  type,
  pushMethod,
  onDeleteAction,
}) => {
  return (
    <div
      className={
        data && data?.length !== 0 ? "block w-full space-y-1 py-2" : "hidden"
      }
    >
      <p className="text-sm font-bold uppercase text-gray-600">
        {title} <span className="text-xs">({data?.length})</span>
      </p>
      <div className="flex flex-col space-y-5 border-gray-200">
        {data?.map((item, idx) => (
          <SeachListItem
            key={idx}
            index={idx + 1}
            route={route}
            id={item[`${idLabel}`]}
            title={item[`${titleLabel}`]}
            setLocalSearch={setLocalSearch}
            description={item?.description}
            status={item?.status}
            collection={collection}
            type={type}
            pushMethod={pushMethod}
            content={item}
            onDeleteAction={onDeleteAction}
          />
        ))}
      </div>
    </div>
  );
};

const SeachListItem = ({
  id,
  index,
  title,
  route,
  description,
  setLocalSearch,
  collection,
  type,
  pushMethod,
  content,
  status,
  onDeleteAction,
}) => {
  const { deleteOneContent, updateOneContentStatus } = useDoSeeDataContext();
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const [contentStatus, setContentStatus] = useState(status);

  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    const { status } = await deleteOneContent({
      data_id: id,
      content: collection,
    });
    setLoading(false);

    if (status === 200) {
      setLocalSearch(null);
      onDeleteAction();
    }
  };

  const onEdit = () => {
    pushMethod(content);
    pushCountry({
      country: {
        id: 0,
        name: "",
        slug: "",
        route: "/search",
      },
    });
    setSingleAdmin({
      ids: [sysAdmin?.admin_id],
      type: getType({ field: collection }),
      route: "/search",
    });
  };

  const updateStatus = async ({ statusState }) => {
    setLoadingStatus(true);

    const { status } = await updateOneContentStatus({
      id,
      type,
      statusState,
    });

    setLoadingStatus(false);

    if (status === 200) {
      setContentStatus(statusState);
    }
  };

  return (
    <div
      className={`space-y-2 border-gray-200 pt-4 text-gray-900 hover:text-gray-600 border-b`}
    >
      <p className="text-sm font-semibold underline">
        {index}. {title}
      </p>

      <div
        className="text-xs capitalize line-clamp-4 font-normal"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      ></div>

      <div className="flex items-center space-x-2 border-t py-2">
        <MyButton
          onClick={onDelete}
          className="text-white text-base bg-black hover:bg-black/80"
        >
          {loading ? (
            <AiOutlineLoading className="animate-spin" />
          ) : (
            <div className="flex space-x-1 items-center justify-center">
              <FaTrashAlt className="text-white" />
              <p className="text-xs">Delete</p>
            </div>
          )}
        </MyButton>
        <Link to={route} onClick={onEdit}>
          <MyButton className="text-white text-base bg-black hover:bg-black/80">
            <div className="flex space-x-1 items-center justify-center">
              <FaEdit className="text-white" />
              <p className="text-xs">Edit</p>
            </div>
          </MyButton>
        </Link>
        <MyButton
          onClick={() =>
            updateStatus({ statusState: contentStatus === 1 ? 0 : 1 })
          }
          className={`text-white text-base ${
            contentStatus === 1
              ? "bg-red-700 hover:bg-red-700/80"
              : "bg-orange-700 hover:bg-orange-700/80"
          }`}
        >
          {loadingStatus ? (
            <AiOutlineLoading className="animate-spin" />
          ) : (
            <>
              {contentStatus === 1 ? (
                <div className="flex space-x-1 items-center justify-center">
                  <FaPause className="text-white" />
                  <p className="text-xs">Suspend</p>
                </div>
              ) : (
                <div className="flex space-x-1 items-center justify-center">
                  <FaPlay className="text-white" />
                  <p className="text-xs">Unsuspend</p>
                </div>
              )}
            </>
          )}
        </MyButton>
      </div>
    </div>
  );
};

export const Placeholder = () => {
  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="h-full w-full animate-pulse cursor-pointer space-y-2">
        <div className="h-12 w-full bg-gray-100" />
        <div className="h-8 w-full bg-gray-100" />
      </div>
      <div className="h-full w-full animate-pulse cursor-pointer space-y-2">
        <div className="h-20 w-full bg-gray-100" />
        <div className="h-10 w-full bg-gray-100" />
      </div>
    </div>
  );
};
