import {
  codeOk,
  Responses,
  emptyFieldMessage,
  invalidFieldMessage,
} from '../../middlewares/index';

export const validateFields = (
  first_name,
  last_name,
  email,
  phone,
  password,
  address,
  country,
  city,
  role
) => {
  if (first_name === null || first_name === '' || first_name === undefined) {
    return Responses(true, 400, emptyFieldMessage('First Name'));
  } else if (
    last_name === null ||
    last_name === '' ||
    last_name === undefined
  ) {
    return Responses(true, 400, emptyFieldMessage('Last Name'));
  } else if (phone === null || phone === '' || phone === undefined) {
    return Responses(true, 400, emptyFieldMessage('Phone'));
  } else if (email === null || email === '' || email === undefined) {
    return Responses(true, 400, emptyFieldMessage('Email'));
  } else if (password === null || password === '' || password === undefined) {
    return Responses(true, 400, emptyFieldMessage('Password'));
  } else if (password.toString().length < 6) {
    return Responses(true, 400, invalidFieldMessage('Password'));
  } else if (address === null || address === '' || address === undefined) {
    return Responses(true, 400, emptyFieldMessage('Address'));
  } else if (country === null || country === '' || country === undefined) {
    return Responses(true, 400, emptyFieldMessage('Country'));
  } else if (city === null || city === '' || city === undefined) {
    return Responses(true, 400, emptyFieldMessage('City'));
  } else if (role === null || role === '' || role === undefined) {
    return Responses(true, 400, emptyFieldMessage('role'));
  } else {
    return Responses(false, codeOk, 'validation passed');
  }
};

export const validateUpdateFields = (
  first_name,
  last_name,
  email,
  phone,
  address,
  country,
  city
) => {
  if (first_name === null || first_name === '' || first_name === undefined) {
    return Responses(true, 400, emptyFieldMessage('First Name'));
  } else if (
    last_name === null ||
    last_name === '' ||
    last_name === undefined
  ) {
    return Responses(true, 400, emptyFieldMessage('Last Name'));
  } else if (phone === null || phone === '' || phone === undefined) {
    return Responses(true, 400, emptyFieldMessage('Phone'));
  } else if (email === null || email === '' || email === undefined) {
    return Responses(true, 400, emptyFieldMessage('Email'));
  } else if (address === null || address === '' || address === undefined) {
    return Responses(true, 400, emptyFieldMessage('Address'));
  } else if (country === null || country === '' || country === undefined) {
    return Responses(true, 400, emptyFieldMessage('Country'));
  } else if (city === null || city === '' || city === undefined) {
    return Responses(true, 400, emptyFieldMessage('City'));
  } else {
    return Responses(false, codeOk, 'validation passed');
  }
};

export const validatePassword = (password) => {
  if (
    password === null ||
    password === undefined ||
    password === '' ||
    password.toString().length < 6
  ) {
    return Responses(true, 400, invalidFieldMessage('Password'));
  } else {
    return Responses(false, codeOk, 'validation passed');
  }
};
