import axios from "axios";

import API_URL from "./utils";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post.Accept = "application/json";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Token expired
        if (typeof window !== "undefined") {
          localStorage.removeItem("auth");
          window.location.href = "/";
        }
      }
    } else if (error.request) {
    } else {
    }
    return Promise.reject(error);
  }
);

export const driver = axios;
