import ImageKit from 'imagekit-javascript';

export const imagekit = new ImageKit({
  publicKey: 'public_G2L1zAjOch8vCsCKFfPkIcYyFfQ=',
  urlEndpoint: 'https://ik.imagekit.io/dosee',
  authenticationEndpoint: 'https://api.doseeafrica.com/api/v1/imagekit/auth',
});

export const defaultImage =
  'https://ik.imagekit.io/dosee/dosee/headers/placeholder_gV8ILKug1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659178491672';

export const urlEndpoint = 'https://ik.imagekit.io/dosee';
export const pubKeyImageKit = 'public_G2L1zAjOch8vCsCKFfPkIcYyFfQ=';
export const authEndpoint = 'https://api.doseeafrica.com/api/v1/imagekit/auth';

export const imagekitLib = new ImageKit({
  publicKey: pubKeyImageKit,
  urlEndpoint: urlEndpoint,
  authenticationEndpoint: authEndpoint,
});
