import { codeOk, Responses, emptyFieldMessage } from '../../middlewares/index';

export const validateFields = (
  level_gdp,
  level_name,
  level_population,
  tourism,
  safety_indicator,
  main_city,
  level_description,
  iso_2,
  iso_3,
  zip_code,
  phone_code,
  country_code,
  alpha_code_2,
  alpha_code_3,
  map,
  area,
  latitude,
  timezone,
  longitude,
  geographical_id,
  language_name,
  is_other_language,
  is_national_language,
  is_official_language,
  language_description,
  ethnic_gp_name,
  ethnic_gp_description,
  religion_name,
  religion_description,
  transport_name,
  transport_description,
  climate_name,
  climate_description
) => {
  if (level_name === null) {
    return Responses(true, 400, emptyFieldMessage('Name'));
  } else if (main_city === null) {
    return Responses(true, 400, emptyFieldMessage('Main City'));
  } else if (level_description === null) {
    return Responses(true, 400, emptyFieldMessage('Description'));
  } else if (level_population === null) {
    return Responses(true, 400, emptyFieldMessage('Population'));
  } else if (level_gdp === null) {
    return Responses(true, 400, emptyFieldMessage('GDP'));
  } else if (tourism === null) {
    return Responses(true, 400, emptyFieldMessage('Tourism'));
  } else if (safety_indicator === null) {
    return Responses(true, 400, emptyFieldMessage('Safety Indicator'));
  } else if (iso_2 === null) {
    return Responses(true, 400, emptyFieldMessage('Iso 2'));
  } else if (iso_3 === null) {
    return Responses(true, 400, emptyFieldMessage('Iso 3'));
  } else if (zip_code === null) {
    return Responses(true, 400, emptyFieldMessage('Zip Code'));
  } else if (phone_code === null) {
    return Responses(true, 400, emptyFieldMessage('Phone Code'));
  } else if (country_code === null) {
    return Responses(true, 400, emptyFieldMessage('Country Code'));
  } else if (alpha_code_2 === null) {
    return Responses(true, 400, emptyFieldMessage('Alpha Code 2'));
  } else if (alpha_code_3 === null) {
    return Responses(true, 400, emptyFieldMessage('Alpha Code 3'));
  } else if (map === null) {
    return Responses(true, 400, emptyFieldMessage('Map'));
  } else if (area === null) {
    return Responses(true, 400, emptyFieldMessage('Area'));
  } else if (latitude === null) {
    return Responses(true, 400, emptyFieldMessage('Latitude'));
  } else if (timezone === null) {
    return Responses(true, 400, emptyFieldMessage('Time Zone'));
  } else if (longitude === null) {
    return Responses(true, 400, emptyFieldMessage('Longitude'));
  } else if (geographical_id === null) {
    return Responses(true, 400, emptyFieldMessage('Geo ID'));
  } else if (language_name === null) {
    return Responses(true, 400, emptyFieldMessage('Language'));
  } else if (is_other_language === null) {
    return Responses(true, 400, emptyFieldMessage('Other Language'));
  } else if (is_national_language === null) {
    return Responses(true, 400, emptyFieldMessage('National Language'));
  } else if (is_official_language === null) {
    return Responses(true, 400, emptyFieldMessage('Offical Language'));
  } else if (language_description === null) {
    return Responses(true, 400, emptyFieldMessage('Language Description'));
  } else if (ethnic_gp_name === null) {
    return Responses(true, 400, emptyFieldMessage('Ethnic Groups'));
  } else if (ethnic_gp_description === null) {
    return Responses(true, 400, emptyFieldMessage('Ethnic Description'));
  } else if (religion_name === null) {
    return Responses(true, 400, emptyFieldMessage('Religion'));
  } else if (religion_description === null) {
    return Responses(true, 400, emptyFieldMessage('Religion Description'));
  } else if (transport_name === null) {
    return Responses(true, 400, emptyFieldMessage('Transportation'));
  } else if (transport_description === null) {
    return Responses(true, 400, emptyFieldMessage('Trans. Description'));
  } else if (climate_name === null) {
    return Responses(true, 400, emptyFieldMessage('Climate Name'));
  } else if (climate_description === null) {
    return Responses(true, 400, emptyFieldMessage('Climate Description'));
  } else {
    return Responses(false, codeOk, 'validation passed');
  }
};
