import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  PhoneInput,
  InputField,
  DashboardIntro,
  MyActionButton,
  MyButton,
} from "../../home/component/Component";
import { codeOk } from "../../../middlewares/index";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { getOneSafari } from "../../../middlewares/sysNavigation/SysNavigation";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import BestTimeToVisit from "../../home/component/BestTimeToVisit";
import Pricing from "../../home/component/Pricing";
import GenerateDescription from "../utils/GenerateDescription";

const Edit = () => {
  const { updateOneContent } = useDoSeeDataContext();
  const safari = getOneSafari();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const safari_id = safari?.safari_id;
  const [thumbnail, setThumbnail] = useState(safari?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(safari?.image_cdn_id);
  const [title, setTitle] = useState(safari?.title);
  const [venue, setVenue] = useState(safari?.venue);
  const [openDays, setOpenDays] = useState(safari?.opened_days);
  const [openedAt, setOpenedAt] = useState(safari?.opened_at);
  const [closedAt, setClosedAt] = useState(safari?.closed_at);
  const [email, setEmail] = useState(safari?.email_address);
  const [phoneNumber, setPhoneNumber] = useState(safari?.phone_number);
  const [whatsapp, setWhatsapp] = useState(safari?.whatsapp);
  const [facebook, setFacebook] = useState(safari?.facebook);
  const [twitter, setTwitter] = useState(safari?.twitter);
  const [instagram, setInstagram] = useState(safari?.instagram);
  const [youtube, setYoutube] = useState(safari?.youtube);
  const [hashtags, setHashtags] = useState(safari?.hashtag);
  const [formattedAddress, setFormattedAddress] = useState(
    safari?.formatted_address
  );
  const [featured, setFeatured] = useState(safari?.featured);
  const [promoted, setPromoted] = useState(
    safari?.promoted ? safari?.promoted : "no"
  );
  const [safariStatus, setEventStatus] = useState(safari?.status);
  const [description, setDescription] = useState(safari?.description);
  const [expo, setExpo] = useState(safari?.expo);
  const [showTop, setShowTop] = useState(safari?.show_top);
  const [bestTime, setBestTime] = useState(
    safari?.best_time_to_visit ? safari?.best_time_to_visit : []
  );
  const [pricing, setPricing] = useState(safari?.Pricings);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const safariRoutes = "/data/safari";

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id ? country_id : safari?.country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      title: title,
      slug: title?.toLowerCase().replace(/\s/g, "_"),
      description: description,
      city_level: "level",
      venue: venue,
      opened_days: openDays,
      opened_at: openedAt,
      closed_at: closedAt,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      phone_number: phoneNumber,
      email_address: email,
      whatsapp: whatsapp,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      youtube: youtube,
      hashtag: hashtags,
      audio_ad: "none",
      formatted_address: formattedAddress,
      country_short: "none",
      status: safariStatus,
      featured: featured,
      promoted: promoted,
      expo: expo,
      show_top: showTop,
      best_time_to_visit: bestTime?.length > 0 ? String(bestTime) : null,
      pricing: pricing,
    };

    const { message, status } = await updateOneContent({
      data_id: safari_id,
      data: data,
      content: _TYPES_.SAFARI,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });

    if (status === codeOk) responses(false, message);
    else responses(true, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: safariRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-3">
        <DashboardIntro props={{ intro: `Edit Safari`, name: entry_label }} />
      </div>
      <form onSubmit={updateOneEventHandler}>
        <div className="mt-3 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.SAFARI)}
          />

          <Title props={{ title: "Event Details" }} />

          <div className="w-full h-full">
            <p className="text-xs font-bold">Best Time To Visit</p>
            <BestTimeToVisit bestTime={bestTime} setBestTime={setBestTime} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "top",
                title: "Show In Top Safari",
                option: ["no", "yes"],
                preselect:
                  showTop !== null
                    ? [{ name: showTop === 0 ? "no" : "yes" }]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setShowTop(0);
                  else setShowTop(1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "venue",
                title: "Venue",
                type: "text",
                value: venue ? venue : "",
                onChange: (e) => {
                  setVenue(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "formatted_address",
                title: "Formatted Address",
                type: "address",
                value: formattedAddress ? formattedAddress : "",
                onChange: (e) => {
                  setFormattedAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Date & Time" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "opened_days",
                title: "Open Days",
                type: "text",
                value: openDays ? openDays : "",
                onChange: (e) => {
                  setOpenDays(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "opened_at",
                title: "Opened At",
                type: "time",
                value: openedAt ? openedAt : "",
                onChange: (e) => {
                  setOpenedAt(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "closed_at",
                title: "Closed At",
                type: "time",
                value: closedAt ? closedAt : "",
                onChange: (e) => {
                  setClosedAt(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Pricing" }} />

          <Pricing pricing={pricing} setPricing={setPricing} />

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phoneNumber}
                required={false}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "whatsapp",
                title: "Whatsapp",
                type: "text",
                value: whatsapp ? whatsapp : "",
                onChange: (e) => {
                  setWhatsapp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                value: facebook ? facebook : "",
                onChange: (e) => {
                  setFacebook(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                value: twitter ? twitter : "",
                onChange: (e) => {
                  setTwitter(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                value: instagram ? instagram : "",
                onChange: (e) => {
                  setInstagram(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                value: youtube ? youtube : "",
                onChange: (e) => {
                  setYoutube(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "safari_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  safariStatus !== null
                    ? [{ name: safariStatus === 1 ? "visible" : "invisible" }]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setEventStatus(1);
                  else setEventStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "featured",
                title: "Featured",
                option: ["no", "yes"],
                preselect: featured !== null ? [{ name: featured }] : null,
                onChange: (e) => {
                  setFeatured(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "promoted",
                title: "Promoted",
                option: ["no", "yes"],
                preselect: promoted !== null ? [{ name: promoted }] : null,
                onChange: (e) => {
                  setPromoted(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "gallery",
                title: "Show in Gallery",
                option: ["no", "yes"],
                preselect:
                  expo !== null ? [{ name: expo === 0 ? "no" : "yes" }] : null,
                onChange: (e) => {
                  const choice = e.target.value;
                  setExpo(choice === "no" ? 0 : 1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
