export const ROUTES = Object.freeze({
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  REGIONS: "/region",
  PARTNER: "/partner",
  PARTNER_ADD: "/partner/add",
  PARTNER_EDIT: "/partner/edit",
  REGION_MANAGE: "/region/manage",
  REGION_ADD: "/region/add",
  REGION_EDIT: "/region/edit",
  REGION_DASH: "/region/dashboard",
  COUNTRIES: "/countries",
  COUNTRIES_DASH: "/countries/dashboard",
  COUNTRIES_DASH_ID: "/country/dashboard/:country_id",
  COUNTRIES_ADD: "/countries/add",
  COUNTRIES_EDIT: "/countries/edit/:country_id",
  LEVELS_FIRST: "/levels/first",
  LEVELS_SECOND: "/levels/second",
  LEVELS_THIRD: "/levels/third",
  LEVELS_FOURTH: "/levels/fourth",
  LEVELS_DASH: "/levels/dashboard",
  LEVEL_ADD: "/level/add",
  LEVEL_DASH: "/level/dashboard",
  LEVEL_EDIT: "/level/edit",
  CITIES: "/cities",
  CITIES_DASH: "/cities/dashboard",
  CITY_DASH: "/city/dashboard",
  DATA: "/dosee/data",
  DATA_EVENT: "/data/event",
  DATA_EVENT_ADD: "/data/event/add",
  DATA_EVENT_EDIT: "/data/event/edit",
  DATA_ATTR: "/data/attraction",
  DATA_ATTR_ADD: "/data/attraction/add",
  DATA_ATTR_EDIT: "/data/attraction/edit",
  DATA_ATTR_VIEW: "/data/attraction/view",
  DATA_BEST: "/data/bestofafrica",
  DATA_BEST_ADD: "/data/bestofafrica/add",
  DATA_BEST_EDIT: "/data/bestofafrica/edit",
  DATA_BEST_VIEW: "/data/bestofafrica/view",
  DATA_CINEMA: "/data/cinema",
  DATA_CINEMA_ADD: "/data/cinema/add",
  DATA_CINEMA_EDIT: "/data/cinema/edit",
  DATA_CINEMA_VIEW: "/data/cinema/view",
  DATA_MUSIC: "/data/music",
  DATA_MUSIC_ADD: "/data/music/add",
  DATA_MUSIC_EDIT: "/data/music/edit",
  DATA_MUSIC_VIEW: "/data/music/view",
  DATA_NEWS: "/data/news",
  DATA_NEWS_ADD: "/data/news/add",
  DATA_NEWS_EDIT: "/data/news/edit",
  DATA_NEWS_VIEW: "/data/news/view",
  DATA_SAFARI: "/data/safari",
  DATA_SAFARI_ADD: "/data/safari/add",
  DATA_SAFARI_EDIT: "/data/safari/edit",
  DATA_SAFARI_VIEW: "/data/safari/view",
  DATA_RESTAU: "/data/restaurant",
  DATA_RESTAU_ADD: "/data/restaurant/add",
  DATA_RESTAU_EDIT: "/data/restaurant/edit",
  DATA_RESTAU_VIEW: "/data/restaurant/view",
  DATA_TDO: "/data/things/todo",
  DATA_TDO_ADD: "/data/things/todo/add",
  DATA_TDO_EDIT: "/data/things/todo/edit",
  DATA_PARTY: "/data/party",
  DATA_PARTY_ADD: "/data/party/add",
  DATA_PARTY_EDIT: "/data/party/edit",
  DATA_NLIFE: "/data/nightlife",
  DATA_NLIFE_ADD: "/data/nightlife/add",
  DATA_NLIFE_EDIT: "/data/nightlife/edit",
  DATA_CGUIDE: "/data/city/guide",
  DATA_CGUIDE_ADD: "/data/city/guide/add",
  DATA_CGUIDE_EDIT: "/data/city/guide/edit",
  DATA_HOTEL: "/data/hotel",
  DATA_HOTEL_ADD: "/data/hotel/add",
  DATA_HOTEL_EDIT: "/data/hotel/edit",
  DATA_CLASS: "/data/class",
  DATA_CLASS_ADD: "/data/class/add",
  DATA_CLASS_EDIT: "/data/class/edit",
  DATA_SHOP: "/data/shopping",
  DATA_SHOP_ADD: "/data/shopping/add",
  DATA_SHOP_EDIT: "/data/shopping/edit",
  DATA_FOOD: "/data/food",
  DATA_FOOD_ADD: "/data/food/add",
  DATA_FOOD_EDIT: "/data/food/edit",
  DATA_BOOK: "/data/book",
  DATA_BOOK_ADD: "/data/book/add",
  DATA_BOOK_EDIT: "/data/book/edit",
  DATA_NBOR: "/data/neighborhood",
  DATA_NBOR_ADD: "/data/neighborhood/add",
  DATA_NBOR_EDIT: "/data/neighborhood/edit",
  DATA_MINISTRY: "/data/ministry",
  DATA_MINISTRY_ADD: "/data/ministry/add",
  DATA_MINISTRY_EDIT: "/data/ministry/edit",
  DATA_GA: "/data/get/around",
  DATA_GA_ADD: "/data/get/around/add",
  DATA_GA_EDIT: "/data/get/around/edit",
  DATA_ART: "/data/art",
  DATA_ART_ADD: "/data/art/add",
  DATA_ART_EDIT: "/data/art/edit",
  DATA_MOVIE: "/data/movie",
  DATA_MOVIE_ADD: "/data/movie/add",
  DATA_MOVIE_EDIT: "/data/movie/edit",
  DATA_DANCE: "/data/dance",
  DATA_DANCE_ADD: "/data/dance/add",
  DATA_DANCE_EDIT: "/data/dance/edit",
  DATA_THEATER: "/data/theater",
  DATA_THEATER_ADD: "/data/theater/add",
  DATA_THEATER_EDIT: "/data/theater/edit",
  DATA_TIPS: "/data/tips/advice",
  DATA_TIPS_ADD: "/data/tips/advice/add",
  DATA_TIPS_EDIT: "/data/tips/advice/edit",
  DATA_MUSEUM: "/data/museum",
  DATA_MUSEUM_ADD: "/data/museum/add",
  DATA_MUSEUM_EDIT: "/data/museum/edit",
  DATA_TEAM: "/team",
  DATA_TEAM_ADD: "/team/add",
  DATA_TEAM_EDIT: "/team/edit",
  DATA_TO: "/trade/org",
  DATA_TO_ADD: "/trade/org/add",
  DATA_TO_EDIT: "/trade/org/edit",
  DATA_RBLOCKS: "/regional/blocks",
  DATA_RBLOCKS_ADD: "/regional/blocks/add",
  DATA_RBLOCKS_EDIT: "/regional/blocks/edit",
  DATA_PIMAGE: "/plug/image",
  DATA_PIMAGE_ADD: "/plug/image/add",
  DATA_PIMAGE_EDIT: "/plug/image/edit",
  DATA_CLIMATE: "/climate",
  DATA_CLIMATE_ADD: "/climate/add",
  DATA_CLIMATE_EDIT: "/climate/edit",
  DATA_EGROUP: "/ethnic/group",
  DATA_EGROUP_ADD: "/ethnic/group/add",
  DATA_EGROUP_EDIT: "/ethnic/group/edit",
  DATA_LANG: "/language",
  DATA_LANG_ADD: "/language/add",
  DATA_LANG_EDIT: "/language/edit",
  DATA_REL: "/religion",
  DATA_REL_ADD: "/religion/add",
  DATA_REL_EDIT: "/religion/edit",
  DATA_AGENT: "/agent",
  DATA_AGENT_ADD: "/agent/add",
  DATA_AGENT_EDIT: "/agent/edit",
  DATA_ADMIN: "/admin",
  DATA_ADMIN_ADD: "/admin/add",
  DATA_ADMIN_EDIT: "/admin/edit",
  DATA_USER: "/user",
  DATA_USER_ADD: "/user/add",
  DATA_USER_EDIT: "/user/edit",
  DATA_TOUR: "/data/tour/package",
  DATA_TOUR_ADD: "/data/tour/package/add",
  DATA_TOUR_EDIT: "/data/tour/package/edit",
  DATA_PROD: "/data/product",
  DATA_PROD_ADD: "/data/product/add",
  DATA_PROD_EDIT: "/data/product/edit",
  DATA_COMPANY: "/data/company",
  DATA_COMPANY_ADD: "/data/company/add",
  DATA_COMPANY_EDIT: "/data/company/edit",
  DATA_EMBASSY: "/data/embassy",
  DATA_EMBASSY_ADD: "/data/embassy/add",
  DATA_EMBASSY_EDIT: "/data/embassy/edit",
  DATA_BANNER: "/banner",
  DATA_BANNER_ADD: "/banner/add",
  DATA_BANNER_EDIT: "/banner/edit",
  DATA_PAGE: "/page",
  DATA_PAGE_ADD: "/page/add",
  DATA_PAGE_EDIT: "/page/edit",
  DATA_NEWSLETTER: "/newsletter",
  DATA_FOOTER: "/footer",
  DATA_FOOTER_EDIT: "/footer/edit",
  DATA_TERMS: "/terms",
  DATA_TERMS_EDIT: "/terms/edit",
  DATA_PRIVACY: "/privacy",
  DATA_PRIVACY_EDIT: "/privacy/edit",
  DATA_SERVICE: "/service",
  DATA_SERVICE_EDIT: "/service/edit",
  DATA_CONTACT: "/contact",
  DATA_ABOUT: "/about",
  DATA_ABOUT_EDIT: "/about/edit",
  DATA_BLOG: "/blog",
  DATA_BLOG_ADD: "/blog/add",
  DATA_BLOG_EDIT: "/blog/edit",
  DATA_EMAIL: "/email",
  DATA_EMAIL_EDIT: "/email/edit",
  CONTENT_STATS: "/content/stats",
  ADMINS_STATS: "/content/admin/stats",
  DATA_AD: "/data/advertisement",
  DATA_AD_ADD: "/data/advertisement/add",
  DATA_AD_EDIT: "/data/advertisement/edit",
  COMPANY: "/company",
  COMPANY_ADD: "/company/add",
  COMPANY_EDIT: "/company/edit",
  COMPANY_VIEW: "/company/view",
  COMPANY_DASHBOARD: "/company/dashboard",
  SEARCH: "/search",
  PASSWORD: "/password",
  PAYMENT: "/payment",
  CURRENCY: "/currency",
  CURRENCY_ADD: "/currency/add",
  CURRENCY_EDIT: "/currency/edit",
  GENERATED_EVENTS: "/generated",
});
