import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const ClimateContext = createContext();

export const useClimateContext = () => useContext(ClimateContext);

export const ClimateProvider = (props) => {
  const [climate, setClimate] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateClimateState = async (data) => {
    return setClimate(data);
  };

  /**
   * Fetch all climate
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchClimates = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/climate/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setClimate(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new climate
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneClimate = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/admin/climate/create';
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a climate
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateClimate = async (climate_id, data) => {
    try {
      const update_url = API_URL + `/api/v1/admin/climate/update/${climate_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a climate
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteClimate = async (climate_id) => {
    try {
      const delete_url = API_URL + `/api/v1/admin/climate/delete/${climate_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ClimateContext.Provider
      value={{
        climate,
        count,
        createOneClimate,
        fetchClimates,
        updateClimate,
        deleteClimate,
        updateClimateState,
      }}
    >
      {props.children}
    </ClimateContext.Provider>
  );
};
