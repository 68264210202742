export const isPositionSelected = (positions = [], index, type, id) => {
  let status = null;
  positions.map((_pos_) =>
    _pos_.type === type
      ? _pos_.id === id && _pos_.position === index
        ? (status = true)
        : null
      : null
  );

  if (status === null)
    positions.map((_pos_) =>
      _pos_.type === type
        ? _pos_.position === index
          ? (status = false)
          : null
        : null
    );

  return status;
};
