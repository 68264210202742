/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  PhoneInput,
  MyActionButton,
  SelectInput,
} from "../../component/Component";
import { codeOk, errorMessage } from "../../../../middlewares/index";
import { useCountryContext } from "../../../../context/country/countryContext/CountryContext";
import { useTourPackageContext } from "../../../../context/tourPackage/TourPackageContext";
import { useAgentContext } from "../../../../context/agent/AgentContext";
import { getOneAgent } from "../../../../middlewares/sysNavigation/SysNavigation";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS, image_kit_folder } from "../../../doseeData/utils/Utils";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";

const Edit = () => {
  const { updateAgent, updateAgentState } = useAgentContext();
  const { countries, fetchCountries } = useCountryContext();
  const { tourPackages, fetchTourPackages } = useTourPackageContext();

  const _agent_ = getOneAgent();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const dsa_agents_id = _agent_.dsa_agents_id;

  const [title, setTitle] = useState(_agent_.name);
  const [country_name, setCountryName] = useState(_agent_.country);
  const [agent_package_id, setAgentPackageId] = useState(
    _agent_.agent_package_id
  );
  const [agent_payment_id, setAgentPaymentId] = useState(
    _agent_.agent_payment_id
  );
  const [agent_email, setAgentEmail] = useState(_agent_.email_address);
  const [agent_phone, setAgentPhone] = useState(_agent_.phonenumber);
  const [agent_contact, setAgentContact] = useState(
    _agent_.contact_person_name
  );
  const [agent_transactions, setAgentTransactions] = useState(
    _agent_.number_of_transaction
  );
  const [agent_rating, setAgentRating] = useState(_agent_.rating);
  const [description, setDescription] = useState(_agent_.description);

  const [thumbnail, setThumbnail] = useState(_agent_?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(_agent_?.image_cdn_id);
  const [thumbnailLogo, setThumbnailLogo] = useState(_agent_?.logo);
  const [thumbnailIdLogo, setThumbnailIdLogo] = useState(_agent_?.logo_ref_id);

  const [tourName, setTourName] = useState(null);

  const agentRoutes = "/agent";

  useEffect(() => {
    if (!countries) fetchCountries();
    if (!tourPackages) fetchTourPackages();
    if (!tourName && tourPackages && agent_package_id) {
      setTourName(
        tourPackages.find((x) => (x.tour_package_id = agent_package_id))?.title
      );
    }
  }, [countries, tourPackages, _agent_, tourName]);

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
  };

  const responses = (isError, message) => {
    setLoading(false);
    setError(isError);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const addOneAgentHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId || !thumbnailLogo || !thumbnailIdLogo) {
      setLoading(false);
      return responses(true, "Image or Logo Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      agent_package_id: agent_package_id,
      agent_payment_id: agent_payment_id,
      agent_biz_reg_no: title?.replace(/ /g, "-") || "reg-no-agent",
      logo: thumbnailLogo ? thumbnailLogo : defaultImage,
      logo_ref_id: thumbnailIdLogo,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      email_address: agent_email,
      phonenumber: agent_phone,
      contact_person_name: agent_contact,
      country: country_name ? country_name : "Uganda",
      number_of_transaction: agent_transactions,
      rating: agent_rating,
      name: title,
      description: description,
    };

    const response = await updateAgent(dsa_agents_id, data);
    if (response) {
      if (response.data.status === codeOk) {
        updateAgentState({ dsa_agents_id, ...data });
        cleanFields();
        return responses(false, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: agentRoutes }} />
      <form onSubmit={addOneAgentHandler}>
        <div className="mt-3 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Basic Info" }} />

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-5">
            <div className="flex flex-col space-y-2">
              <p className="text-sm font-bold">Agent Profile</p>
              <ImageKitUpload
                thumbnail={thumbnail}
                thumbnailId={thumbnailId}
                setThumbnail={setThumbnail}
                setThumbnailId={setThumbnailId}
                folder={image_kit_folder("dosee", FOLDERS.AGENTS)}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <p className="text-sm font-bold">Agent Logo</p>
              <ImageKitUpload
                thumbnail={thumbnailLogo}
                thumbnailId={thumbnailIdLogo}
                setThumbnail={setThumbnailLogo}
                setThumbnailId={setThumbnailIdLogo}
                folder={image_kit_folder("dosee", FOLDERS.AGENTS)}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <SelectInput
              title="Country"
              value={country_name}
              defaultValue={country_name}
              options={
                countries ? [...countries.map((country) => country.name)] : []
              }
              onChange={(e) => {
                const index = e.target.selectedIndex;
                setCountryName(countries[index].name);
                setError(null);
                setSuccess(null);
              }}
            />
            <SelectInput
              title="Tour Package"
              value={tourName}
              defaultValue={tourName}
              options={
                tourPackages !== null
                  ? [...tourPackages.map((tour) => tour.title)]
                  : []
              }
              onChange={(e) => {
                const index = e.target.selectedIndex;
                setAgentPackageId(tourPackages[index]?.tour_package_id);
                setError(null);
                setSuccess(null);
              }}
            />
            <Select
              props={{
                id: "tour_package_id",
                title: "Tour Package",
                option:
                  tourPackages !== null
                    ? [...tourPackages.map((tour) => tour.title)]
                    : [],
                onChange: (e) => {
                  const index = e.target.selectedIndex;
                  setAgentPackageId(tourPackages[index]?.id);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Select
              props={{
                id: "agent_payment_id",
                title: "Payment Method",
                option: ["Visa", "Cash", "E-Wallet", "Mobile Money"],
                onChange: (e) => {
                  const index = e.target.selectedIndex;
                  setAgentPaymentId(index);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Agent Details" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "agent_email",
                title: "Agent Email",
                type: "text",
                value: agent_email ? agent_email : "",
                onChange: (e) => {
                  setAgentEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <PhoneInput
              setPhone={setAgentPhone}
              phone={agent_phone}
              required={true}
            />

            <InputField
              props={{
                id: "agent_contact",
                title: "Agent Contact Person",
                type: "text",
                value: agent_contact ? agent_contact : "",
                onChange: (e) => {
                  setAgentContact(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "agent_transaction",
                title: "Agent Transactions",
                type: "number",
                value: agent_transactions ? agent_transactions : "",
                onChange: (e) => {
                  setAgentTransactions(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "agent_rating",
                title: "Agent Rating",
                type: "number",
                value: agent_rating ? agent_rating : "",
                onChange: (e) => {
                  setAgentRating(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <TextArea
            props={{
              id: "description",
              title: "Description",
              type: "text",
              value: description ? description : "",
              onChange: (e) => {
                setDescription(e.target.value);
                setError(null);
                setSuccess(null);
              },
            }}
          />

          <MyActionButton
            title="Update Profile"
            loading={loading}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
