import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";
import Overview from "./Overview";

import { DoSeeDataProvider } from "../../../../context/doSeeData/DoSeeDataContext";
import { PositionProvider } from "../../../../context/position/PositionContext";

export const GeneratedDataEvents = () => {
  return (
    <DoSeeDataProvider>
      <PositionProvider>
        <Auth
          children={
            <Screen component={<Overview />} title="Generated Events" />
          }
        />
      </PositionProvider>
    </DoSeeDataProvider>
  );
};
