/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  pushCountry,
  pushSingleCountryDashboard,
} from "../../../../../middlewares/sysNavigation/SysNavigation";
import { useCountryContext } from "../../../../../context/country/countryContext/CountryContext";
import {
  Modal,
  Search,
  MyButton,
  Pagination,
} from "../../../component/Component";
import { BreadCrumbComponent } from "../../../../../middlewares/breadCrumb/BreadCrumbComponent";
import {
  getOneRegion,
  addOneCountry,
  cleanOneLevel,
} from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../../context/admin/context/AdminContext";

const addRoute = "/countries/add";
const editCountryRoute = "/countries/edit";
const singleCountryDashord = "/country/dashboard";
const addContent = "/dosee/data";

const levels = "/levels/first";

const Overview = () => {
  const [countriesList, setCountriesList] = useState([]);
  const { countries, fetchCountries, count } = useCountryContext();

  const { sysAdmin } = useAdminContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    if (!countries) fetchCountries(page, pageSize);
    else {
      setCountriesList(
        countries?.sort((a, b) => {
          if (a?.name > b?.name) return 1;
          if (a?.name < b?.name) return -1;
          return true;
        })
      );
    }
  }, [countries]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    countries?.map((country) => {
      if (String(country?.name).toLowerCase().includes(key)) {
        searchArray.push(country);
      }
      return true;
    });
    setCountriesList(searchArray);
    if (key === "") setCountriesList(countries);
  };

  const superAdminMode = () => {
    return ["super_admin", "admin"]?.includes(sysAdmin?.role);
  };

  // if (countries && countries?.length > 0 && localCities?.length === 0) {
  //   const priorities = countries
  //     ?.filter((city) => city?.priority === 1)
  //     .sort((a, b) => {
  //       if (a?.name > b?.name) return 1;
  //       if (a?.name < b?.name) return -1;
  //       return true;
  //     });

  //   const otherCities = cities
  //     ?.filter((city) => city?.priority === 0)
  //     .sort((a, b) => {
  //       if (a?.name > b?.name) return 1;
  //       if (a?.name < b?.name) return -1;
  //       return true;
  //     });

  //   setLocalCities([...priorities, ...otherCities]);
  // }

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <BreadCrumbComponent default_value={true} />
        <Search
          props={{
            title: "Country",
            onChange: (e) => SearchList(e),
          }}
        />

        {superAdminMode() ? (
          <div className="w-full flex justify-end mt-4 mb-3">
            <Link to={addRoute}>
              <MyButton
                title="Add Country"
                className="bg-gray-800 hover:bg-gray-600 min-w-fit"
                onClick={() => {}}
              />
            </Link>
          </div>
        ) : null}
        <div className="py-5 overflow-y-auto w-full">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 w-1/4">Name</th>
                <th className="border p-2 lg:px-4 w-1/2 text-center">Action</th>
                {superAdminMode() ? (
                  <th className="border p-2 lg:px-4 text-center w-1/4">
                    Levels & Cities
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {countriesList ? (
                countriesList
                  ?.filter((val) => {
                    if (getOneRegion().length > 0)
                      return val?.continent_region_id === getOneRegion()[0]?.id;
                    return val;
                  })
                  ?.map((country, key) => (
                    <tr key={key}>
                      <td className="border px-2 lg:px-4 w-1/4">
                        {country?.name}
                      </td>
                      <td className="border w-1/2">
                        <ActionButton
                          isSuperAdmin={superAdminMode()}
                          props={country}
                        />
                      </td>
                      {superAdminMode() ? (
                        <td className="border w-1/4 text-center">
                          <LevelsAction props={country} />
                        </td>
                      ) : null}
                    </tr>
                  ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/2">...</td>
                  <td className="border w-1/4">... </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchCountries(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

const LevelsAction = ({ props }) => {
  return (
    <Link to={levels}>
      <div className="flex flex-row justify-center px-2 py-3 space-x-4 text-xs">
        <MyButton
          title="All Levels"
          className="bg-gray-800 hover:bg-gray-600 min-w-fit"
          onClick={() => {
            // For BreadCrumb only
            addOneCountry({
              id: props.country_id,
              name: props.name,
              slug: props.slug,
              route: "/countries",
              data: props,
            });
            // For filtering and display
            pushCountry({
              country: {
                id: props.country_id,
                name: props.name,
                slug: props.slug,
                route: "/countries",
              },
            });
          }}
        />
      </div>
    </Link>
  );
};

const ActionButton = ({ props, isSuperAdmin }) => {
  const {
    countries,
    deleteCountry,
    updateCountryState,
    updateCountry,
    fetchCountries,
  } = useCountryContext();

  const [showModal, setShowModal] = useState(false);

  const country_id = props.country_id;
  const country_name = props.name;
  const country_slug = props.slug;
  const country = props;
  const status = country.status;

  const deleteOneCountry = async (country_id) => {
    const { status } = await deleteCountry(country_id);
    if (status === 200) {
      if (countries) {
        updateCountryState(
          countries.filter((_country) => _country.country_id !== country_id)
        );
      }
      setShowModal(false);
    }
  };

  const updateOneCountry = async (country_id) => {
    delete props.country_id;
    const { status, message } = await updateCountry(country_id, {
      ...props,
      status: props.status === 0 ? 1 : 0,
    });
    console.log(status, message);
    if (status === 200) {
      await fetchCountries(1, 100);
    }
  };

  return (
    <>
      <Modal
        props={{
          name: country_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneCountry(country_id),
        }}
      />
      <div className="flex flex-row justify-center px-2 py-3 space-x-2 md:space-x-8 text-xs">
        {isSuperAdmin ? (
          <MyButton
            title={status === 0 ? "Hidden" : "Visible"}
            className={`min-w-fit ${
              status === 0
                ? "bg-red-800 hover:bg-red-600"
                : "bg-green-800 hover:bg-green-600"
            }`}
            onClick={() => updateOneCountry(country_id)}
          />
        ) : null}

        <Link to={`${singleCountryDashord}/${country_id}`}>
          <MyButton
            title="Dashboard"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => pushSingleCountryDashboard(country_name)}
          />
        </Link>
        <Link to={addContent}>
          <MyButton
            title="Content"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => {
              // Push a country to be used as a reference in adding data levels
              // If we click on add content for country level,
              // We need to reset any level values added so far
              addOneCountry({
                id: country_id,
                name: country_name,
                slug: country_slug,
                route: "/countries",
                data: country,
              });

              // We erase any available level in the local area
              cleanOneLevel();
              pushCountry({
                country: {
                  id: country_id,
                  name: country_name,
                  slug: country_slug,
                  route: "/countries",
                },
              });
            }}
          />
        </Link>
        {isSuperAdmin ? (
          <Link to={`${editCountryRoute}/${country_id}`}>
            <MyButton
              title="Edit"
              className="bg-blue-800 hover:bg-blue-600 min-w-fit"
              onClick={() => {}}
            />
          </Link>
        ) : null}

        {isSuperAdmin ? (
          <MyButton
            title="Delete"
            className="bg-red-800 hover:bg-red-600 min-w-fit"
            onClick={() => {}}
          />
        ) : null}
      </div>
    </>
  );
};

export default Overview;
