import React from "react";
import { Link } from "react-router-dom";
import Screen from "../home/content/Screen";
import { SiDatabricks } from "react-icons/si";
import { Auth } from "../../context/admin/Authentication";
import { DashboardIntro, MyButton } from "../home/component/Component";
import { BreadCrumbComponent } from "../../middlewares/breadCrumb/BreadCrumbComponent";
import {
  getOneLevel,
  getOneCountry,
} from "../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../context/admin/context/AdminContext";
import { _TYPES_ } from "./utils/types";
import { getType } from "../home/content/contentStats/helper";

export const DataContent = () => {
  const { sysAdmin } = useAdminContext();
  const role = sysAdmin?.role;
  const location =
    getOneLevel()?.length > 0
      ? getOneLevel()[0]?.name
      : getOneCountry()[0]?.name;

  return (
    <Auth
      children={
        <Screen
          title={`${location}`}
          component={
            <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50 w-full">
              <BreadCrumbComponent />
              <DashboardIntro
                props={{ intro: `Data Content`, name: location }}
              />
              <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-6 gap-4 selection:text-white selection:bg-green-900 mb-5 pt-3">
                {getData(
                  location,
                  ["super_admin", "admin"].includes(role)
                )?.map((value, index) =>
                  value ? (
                    <Content
                      key={index}
                      name={value?.name}
                      link={value?.link}
                      type={value?.key}
                    />
                  ) : null
                )}
              </div>
            </div>
          }
        />
      }
    />
  );
};

const Content = ({ name, link, type }) => {
  const { sysAdmin, setSingleAdmin } = useAdminContext();

  return (
    <div className="w-full p-2 py-4 bg-white hover:bg-white/30 shadow text-gray-700 cursor-pointer hover:text-gray-800">
      <div className="flex flex-col space-y-2 items-start justify-start">
        <div className="flex flex-row items-start justify-start space-x-2">
          <SiDatabricks className="text-base" />
          <p className="text-sm font-bold uppercase">{name}</p>
        </div>
        <Link to={link} className="w-full">
          <MyButton
            title="Go To Settings"
            className="w-full min-w-full text-left py-3 bg-gray-900 hover:bg-gray-600"
            onClick={() => {
              setSingleAdmin({
                ids: [sysAdmin?.admin_id],
                type: getType({ field: type }),
                route: "/dosee/data",
              });
            }}
          />
        </Link>
      </div>
    </div>
  );
};

const getData = (location, isSuperAdmin) => {
  return [
    {
      name: "Events",
      link: "/data/event",
      key: _TYPES_.EVENT,
    },
    {
      name: "Attraction",
      link: "/data/attraction",
      key: _TYPES_.ATTRACTION,
    },
    {
      name: "Cinema",
      link: "/data/cinema",
      key: _TYPES_.CINEMA,
    },
    {
      name: "Music",
      link: "/data/music",
      key: _TYPES_.MUSIC,
    },
    {
      name: "News",
      link: "/data/news",
      key: _TYPES_.NEWS,
    },
    {
      name: "safaris",
      link: "/data/safari",
      key: _TYPES_.SAFARI,
    },
    {
      name: "Restaurant",
      link: "/data/restaurant",
      key: _TYPES_.RESTAURANT,
    },
    {
      name: "Things To Do",
      link: "/data/things/todo",
      key: _TYPES_.THINGSTODO,
    },
    {
      name: "Party",
      link: "/data/party",
      key: _TYPES_.PARTY,
    },
    {
      name: "Nightlife",
      link: "/data/nightlife",
      key: _TYPES_.NIGHTLIFE,
    },
    {
      name: "City Guide",
      link: "/data/city/guide",
      key: _TYPES_.CITYGUIDE,
    },
    {
      name: "Hotel",
      link: "/data/hotel",
      key: _TYPES_.HOTEL,
    },
    {
      name: "Classes",
      link: "/data/class",
      key: _TYPES_.CLASSES,
    },
    {
      name: "Shopping",
      link: "/data/shopping",
      key: _TYPES_.SHOPPING,
    },
    {
      name: "Food",
      link: "/data/food",
      key: _TYPES_.FOOD,
    },
    {
      name: "Book",
      link: "/data/book",
      key: _TYPES_.BOOK,
    },
    {
      name: "Neighborhood",
      link: "/data/neighborhood",
      key: _TYPES_.NEIGHBORHOOD,
    },
    {
      name: "Ministries",
      link: "/data/ministry",
      key: _TYPES_.MINISTRY,
    },
    {
      name: "Getting Around",
      link: "/data/get/around",
      key: _TYPES_.GETTINGAROUND,
    },
    {
      name: "Art",
      link: "/data/art",
      key: _TYPES_.ART,
    },
    {
      name: "Movies",
      link: "/data/movie",
      key: _TYPES_.MOVIE,
    },
    {
      name: "Dance",
      link: "/data/dance",
      key: _TYPES_.DANCE,
    },
    {
      name: "Theater",
      link: "/data/theater",
      key: _TYPES_.THEATER,
    },
    {
      name: "Museum",
      link: "/data/museum",
      key: _TYPES_.MUSEUM,
    },
    {
      name: "Tips And Advice",
      link: "/data/tips/advice",
      key: _TYPES_.TIPSANDADVICE,
    },
    // {
    //   name: `Best of The Place`,
    //   link: "/data/bestOfAfrica",
    //   key: _TYPES_.BESTOFAFRICA,
    // },
    isSuperAdmin
      ? {
          name: `Advertisement`,
          link: "/data/advertisement",
          key: _TYPES_.ADV,
        }
      : null,
    {
      name: `Tour Package`,
      link: "/data/tour/package",
      key: _TYPES_.TOURPACKAGE,
    },
    {
      name: `Product`,
      link: "/data/product",
      key: _TYPES_.PRODUCT,
    },
    {
      name: `Companies`,
      link: "/data/company",
      key: _TYPES_.COMPANY,
    },
    {
      name: `embassy`,
      link: "/data/embassy",
      key: _TYPES_.EMBASSY,
    },
  ];
};
