export const __ROUTES__ = Object.freeze({
  home: "/",
  regionDash: "/region",
  regionSettings: "/region/manage",
  countriesDash: "/countries/dashboard",
  countriesSettings: "/countries",
  levelDash: "/levels/dashboard",
  levelSettings: "/levels/first",
  page: "/page",
  team: "/team",
  newsletter: "/newsletter",
  admin: "/admin",
  user: "/user",
  tourPackage: "/tourpackage",
  product: "/product",
  agent: "/agent",
  banner: "/banner",
  citiesSettings: "/cities",
  citiesDasb: "/cities/dashboard",
  tradeOrganization: "/trade/org",
  regionalBlocks: "/regional/blocks",
  plugImage: "/plug/image",
  climate: "/climate",
  ethnicGroup: "/ethnic/group",
  language: "/language",
  religion: "/religion",
  footer: "/footer",
  contact: "/contact",
  about: "/about",
  service: "/service",
  partner: "/partner",
  blog: "/blog",
  email: "/email",
  company: "/company",
  search: "/search",
  terms: "/terms",
  privacy: "/privacy",
  contentStats: "/content/stats",
  adminsStats: "/content/admin/stats",
  password: "/password",
  payment: "/payment",
  currency: "/currency",
  generatedEvents: "/generated",
});
