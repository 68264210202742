import React from "react";
import { RiMenu2Line } from "react-icons/ri";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../../../context/admin/context/AdminContext";
import { useNavigationContext } from "../../../context/navigation/navigationContext";

const NavBar = (props) => {
  const { showSideBar, setShowSideBar } = useNavigationContext();
  const {
    logOut,
    setSelectedMenu,
    sysAdmin: { first_name, last_name, role },
  } = useAdminContext();

  const navigate = useNavigate();

  return (
    <div className="px-4 bg-white text-center shadow-2xl py-2 space-y-1">
      <p className="w-full font-light text-xs text-right">
        {`${first_name} ${last_name}`} [
        <span className="capitalize font-bold">
          {String(role)?.toLowerCase()?.replaceAll("_", " ")}
        </span>
        ]
      </p>
      <div className="flex w-full justify-end items-center">
        <button
          className="w-full flex justify-end items-center space-x-2 text-red-700 hover:text-red-500"
          onClick={() => {
            navigate("/login");
            setSelectedMenu(0);
            logOut();
          }}
        >
          <p className="text-sm uppercase font-bold">Sign Out</p>
          <FaSignOutAlt className="text-xl cursor-pointer" />
        </button>
      </div>
      <div className="flex flex-row items-center justify-start space-x-3">
        <RiMenu2Line
          className="w-7 h-7 text-gray-700 xl:hidden"
          onClick={() => setShowSideBar(!showSideBar)}
        />
        <div className="flex flex-row justify-start items-center">
          <p className="text-sm font-bold uppercase"> {props.title} </p>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
