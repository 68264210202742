/**
 *  The positions conttains ID of events at that position
 * @param {*} param0
 * @returns
 */
export const AdSection = ({
  id,
  index,
  title,
  adsList,
  event_id,
  position,
  setAdsList,
  setRefill,
}) => {
  // Pick a selection
  const pick = (index) => {
    const builder = adsList.map((ad) => {
      return {
        event_id: ad.event_id,
        position_number: ad.position_number,
        position_type: ad.position_type,
        status: ad.status,
      };
    });

    // check if added already
    let isSelected = false;
    builder.map((val) =>
      val.position_type === id && val.position_number === index
        ? (isSelected = true)
        : null
    );

    // filter out the clicked position
    const temp = builder.filter((val) => val.position_type !== id);
    if (!isSelected)
      temp.push({
        position_number: index,
        event_id: event_id,
        status: 1,
        position_type: id,
      });
    setRefill(false);
    setAdsList(temp);
  };

  return (
    <div className='flex flex-col text-sm' key={index}>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
        <p className='text-base font-mono  text-left'>{title}</p>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mt-2'>
        {[0, 1, 2, 3].map((val, index) => (
          <Position
            key={index}
            action={
              position[val] !== null
                ? position[val] === event_id
                  ? 'current'
                  : 'taken'
                : 'empty'
            }
            index={val + 1}
            onClick={() => pick(val + 1)}
          />
        ))}
      </div>
      <hr className='my-3' />
    </div>
  );
};

const Position = ({ action, index, onClick }) => {
  let color = 'bg-gray-800/70';
  if (action === 'taken') color = 'bg-red-800/70';
  if (action === 'current') color = 'bg-green-800/70';

  return (
    <div
      onClick={onClick}
      className={
        'h-10 w-fill flex justify-center items-center cursor-pointer ' + color
      }
    >
      <p className='font-bold text-white text-xs'>Position {index}</p>
    </div>
  );
};
