/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { FaPlus, FaPen, FaTrash, FaDatabase } from "react-icons/fa";

export const MyButton = ({
  type = "button",
  title,
  variant,
  onClick,
  children,
  disabled,
  className = "",
}) => {
  const ref = useCallback(
    (node) => {
      if (variant === "green-600" && node) {
        node.classList.replace("bg-green-800", "bg-green-600");
      }
    },
    [variant, className]
  );

  return (
    <button
      ref={ref}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`text-white rounded-md capitalize text-xs font-bold p-2 px-4 min-w-fit w-fit transition duration-150 ease-in-out disabled:cursor-not-allowed ${className}`}
    >
      {title || children}
    </button>
  );
};

export const Button = ({ props }) => {
  const category = props?.category ? props?.category : "default";

  let color1 = "brand";
  let color2 = "brand";

  if (
    category === "default" ||
    props.action === "add" ||
    props.action === "edit"
  ) {
    color1 = "bg-brand";
    color2 = "bg-brand";
  } else if (category === "red") {
    color1 = "bg-red-700/60";
    color2 = "bg-red-800/70";
  } else if (category === "blue") {
    color1 = "bg-blue-700/60";
    color2 = "bg-blue-800/70";
  } else if (category === "green") {
    color1 = "bg-green-700/60";
    color2 = "bg-green-800/70";
  } else if (category === "orange") {
    color1 = "bg-orange-700/60";
    color2 = "bg-orange-800/70";
  } else if (category === "black") {
    color1 = "bg-black/60";
    color2 = "bg-black/70";
  } else if (category === "purple") {
    color1 = "bg-purple-700/60";
    color2 = "bg-purple-800/70";
  }

  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={`text-xs lg:text-sm flex px-6 py-2 ${color1} text-white font-mono leading-tight capitalize hover:${color2} hover:shadow-lg focus:${color2} focus:shadow-lg focus:outline-none focus:ring-0 active:${color2} active:shadow-lg transition duration-150 ease-in-out`}
    >
      {props.action &&
      props.action.toString().toLowerCase() === "Edit".toLowerCase() ? (
        <FaPen className="w-3 h-3 mr-2 my-1" />
      ) : props.action &&
        props.action.toString().toLowerCase() === "Delete".toLowerCase() ? (
        <FaTrash className="w-3 h-3 mr-2 my-1" />
      ) : props.action &&
        props.action.toString().toLowerCase() === "add".toLowerCase() ? (
        <FaPlus className="w-3 h-3 mr-2 my-1" />
      ) : props.action &&
        props.action.toString().toLowerCase() === "level".toLowerCase() ? (
        <FaDatabase className="w-3 h-3 mr-2 my-1" />
      ) : (
        <span className="hidden"></span>
      )}

      {props.title}
    </button>
  );
};

export const DisabledButton = ({ props }) => {
  return (
    <button
      className={`text-xs lg:text-sm cursor-not-allowed flex px-6 py-2 ${props.color} text-white font-bold leading-tight capitalize hover:${props.color} hover:shadow-lg focus:${props.color} focus:shadow-lg focus:outline-none focus:ring-0 active:${props.color} active:shadow-lg transition duration-150 ease-in-out`}
    >
      {props.action === "delete" ? (
        <FaTrash className="w-3 h-3 mr-2 my-1" />
      ) : (
        <span className="hidden"></span>
      )}
      {props.title}
    </button>
  );
};
