/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Title,
  GoBack,
  Message,
  TextArea,
  InputField,
  ActionButton,
  MyButton,
} from "../../component/Component";
import { FOLDERS } from "../../../doseeData/utils/Utils";
import { codeOk, errorMessage } from "../../../../middlewares/index";
import { useBlogContext } from "../../../../context/blog/BlogContext";
import { getOneBlog } from "../../../../middlewares/sysNavigation/SysNavigation";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import GenerateDescription from "../../../doseeData/utils/GenerateDescription";

const Edit = () => {
  const { blog, fetchOneBlog, updateBlog, updateBlogState } = useBlogContext();

  const [localBlog, setLocalBlog] = useState(getOneBlog());

  const blog_id = localBlog.blog_id;
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(0);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [thumbnail, setThumbnail] = useState(localBlog.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(localBlog.image_cdn_id);
  const [title, setTitle] = useState(localBlog.title);
  const [description, setDescription] = useState(localBlog.description);
  const [author, setAuthor] = useState(localBlog.author);
  const localBlogRoutes = "/blog";
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
  };

  const responses = (isError, status, message) => {
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  useEffect(() => {
    if (!blog) fetchOneBlog(blog_id);
    else setLocalBlog(blog);
  }, [blog]);

  const updateOneBlogHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      return responses(true, 400, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      title: title,
      author: author,
      reading_time: 10,
      description: description,
      slug: title?.toLowerCase().replace(/\s/g, "_"),
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "...",
    };

    const response = await updateBlog(blog_id, data);
    if (response) {
      if (response.data.status === codeOk) {
        // We update our local state with the new values we just use
        updateBlogState({ blog_id, ...data });
        // Clean all filds that were holding data
        cleanFields();
        return responses(true, response.data.status, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.status, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: localBlogRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <form onSubmit={updateOneBlogHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Message
            props={{
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.TOUR_PACKAGES}
          />

          <Title props={{ title: "Blog Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Blog Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "author",
                title: "author",
                type: "text",
                value: author ? author : "",
                onChange: (e) => {
                  setAuthor(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <ActionButton
            props={{
              title: ` Save Changes`,
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
