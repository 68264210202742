import React, { createContext, useContext, useState } from "react";
import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const NewsletterContext = createContext();
export const useNewsletterContext = () => useContext(NewsletterContext);
export const NewsletterProvider = (props) => {
  const [newsletter, setNewsletter] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  /**
   * Fetch all newsletter
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchNewsletter = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/newsletter/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setNewsletter(data);
      setCount(count);
    });
  };

  /**
   * Send notification
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const sendNotification = async (data) => {
    const url = `/api/v1/newsletter/notification`;
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  return (
    <NewsletterContext.Provider
      value={{
        count,
        newsletter,
        fetchNewsletter,
        sendNotification,
      }}
    >
      {props.children}
    </NewsletterContext.Provider>
  );
};
