import React, { useState } from "react";

import {
  Title,
  GoBack,
  Message,
  TextArea,
  InputField,
  ActionButton,
} from "../../component/Component";

import { codeOk, errorMessage } from "../../../../middlewares/index";

import { useLanguageContext } from "../../../../context/language/LanguageContext";

import { getOneLanguage } from "../../../../middlewares/sysNavigation/SysNavigation";

const Edit = () => {
  const { updateLanguageState, updateLanguage } = useLanguageContext();

  const language = getOneLanguage();

  let [error, setError] = useState(false);
  let [status, setStatus] = useState(0);
  let [message, setErrorMessage] = useState("");
  let [loading, setLoading] = useState(false);
  let [success, setSuccess] = useState(false);

  const language_id = language.language_id;

  const [name, setName] = useState(language.name);
  const [description, setDescription] = useState(language.description);

  const languageRoutes = "/language";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
  };

  const responses = (isError, status, message) => {
    // Set Message and Status
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  const updateOneLanguageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      name: name,
      description: description,
    };

    const response = await updateLanguage(language_id, data);
    if (response) {
      if (response.data.status === codeOk) {
        // We update our local state with the new values we just use
        updateLanguageState({ language_id, ...data });
        // Clean all filds that were holding data
        cleanFields();
        return responses(true, response.data.status, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.status, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: languageRoutes }} />
      <form onSubmit={updateOneLanguageHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Message
            props={{
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />

          <Title props={{ title: "Language Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                required: true,
                value: name ? name : "",
                onChange: (e) => {
                  setName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                required: true,
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <ActionButton
            props={{
              title: `Save Changes`,
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
