import React from "react";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { StaticProvider } from "../../../../context/static/StaticContext";
import AllAdminsStats from "./AdminsStats";

export const ContentStats = () => {
  return (
    <StaticProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="System Statistics"
          />
        }
      />
    </StaticProvider>
  );
};

export const AdminsStats = () => {
  return (
    <StaticProvider>
      <Auth
        children={
          <Screen
            component={<AllAdminsStats />}
            title="All Admins Statistics"
          />
        }
      />
    </StaticProvider>
  );
};
