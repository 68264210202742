import React from "react";

import { Auth } from "../../../context/admin/Authentication";

import Screen from "../../home/content/Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { DoSeeDataProvider } from "../../../context/doSeeData/DoSeeDataContext";

import { CountryProvider } from "../../../context/country/countryContext/CountryContext";
import { AdvertisementProvider } from "../../../context/advertisement/advertisementContext";
import { PositionProvider } from "../../../context/position/PositionContext";

export const Events = () => {
  return (
    <DoSeeDataProvider>
      <PositionProvider>
        <Auth
          children={
            <Screen
              component={<Overview />}
              title="Events"
            />
          }
        />
      </PositionProvider>
    </DoSeeDataProvider>
  );
};

export const AddEvent = () => {
  return (
    <CountryProvider>
      <DoSeeDataProvider>
        <Auth
          children={
            <Screen
              component={<Add />}
              title="Add Event"
            />
          }
        />
      </DoSeeDataProvider>
    </CountryProvider>
  );
};

export const EditEvent = () => {
  return (
    <CountryProvider>
      <AdvertisementProvider>
        <DoSeeDataProvider>
          <Auth
            children={
              <Screen
                component={<Edit />}
                title="Edit Event"
              />
            }
          />
        </DoSeeDataProvider>
      </AdvertisementProvider>
    </CountryProvider>
  );
};
