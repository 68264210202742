/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PasswordSettings from "./PasswordSettings";
import { useCityContext } from "../../../../context/levels/levelsContext";
import { useCompanyContext } from "../../../../context/company/companyContext";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import {
  getOneAdmin,
  pushCountry,
} from "../../../../middlewares/sysNavigation/SysNavigation";
import { validateUpdateFields } from "../../../../controllers/admin/adminControllers";
import { useAdminsContext } from "../../../../context/admin/context/AdminManageContext";
import { useCountryContext } from "../../../../context/country/countryContext/CountryContext";
import {
  Title,
  GoBack,
  MyMessage,
  InputField,
  PhoneInput,
  SelectInput,
  MyActionButton,
  SearchSelectInput,
} from "../../component/Component";
import { roles } from "./roles";
import { countriesList } from "../../component/data";

const Edit = () => {
  const { sysAdmin } = useAdminContext();
  const currentRole = sysAdmin?.role;

  const { setCompany } = useCompanyContext();
  const { cities, fetchCities } = useCityContext();
  const { countries, fetchCountries } = useCountryContext();
  const { updateProfile, setAdmins } = useAdminsContext();

  const admin = getOneAdmin();
  const admin_id = admin?.admin_id;

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [first_name, setFirstName] = useState(admin?.first_name);
  const [last_name, setLastName] = useState(admin?.last_name);
  const [email, setEmail] = useState(admin?.email);
  const [phone, setPhone] = useState(admin?.phonenumber);
  const [country, setCountry] = useState(admin?.country);
  const [city, setCity] = useState(admin?.city);
  const [address, setAddress] = useState(admin?.address);
  const [role, setRole] = useState(admin?.role);
  const [adminStatus, setAdminStatus] = useState(admin?.active);
  const [password, setPassword] = useState(null);
  const [confirm_password, setConfirmPassword] = useState(null);

  const [action, setAction] = useState("profile");

  const adminRoutes = "/admin";

  useEffect(() => {
    if (!cities) fetchCities(1, 2000);
    if (!countries) fetchCountries();
  }, [cities, countries, country]);

  const responses = (error, message) => {
    setLoading(false);
    setError(error);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const updateAdminHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { isError, message } = validateUpdateFields(
      first_name,
      last_name,
      email,
      phone,
      address,
      country,
      city
    );
    if (isError) responses(true, message);
    else await sendProfileRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} {}
   * @returns
   */
  const sendProfileRequest = async () => {
    const data = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      phonenumber: phone,
      country: country,
      city: city,
      address: address,
      role: String(role)?.toLowerCase()?.replaceAll(" ", "_"),
      active: adminStatus,
    };

    const { message, status } = await updateProfile(admin_id, data);
    if (status === 200 || status === 201) {
      setAdmins(null);
      setCompany(null);
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  const citiesFilter = () => {
    const getId = ({ data, key, value, obj_id }) => {
      return data?.find((x) => x?.[key] === value)?.[obj_id] || null;
    };
    const id = getId({
      key: "name",
      value: country,
      data: countries,
      obj_id: "country_id",
    });
    return cities?.filter((x) => x?.country_id === id).map((y) => y?.name);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: adminRoutes }} />
      <form onSubmit={updateAdminHandler}>
        <div className="mt-2 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "1. Update Profile" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "fullname",
                title: "First Name",
                type: "text",
                value: first_name ? first_name : "",
                onChange: (e) => {
                  setFirstName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "lastname",
                title: "Last Name",
                type: "text",
                value: last_name ? last_name : "",
                onChange: (e) => {
                  setLastName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <PhoneInput
              setPhone={setPhone}
              phone={phone}
              required={true}
              country={country ? country : ""}
            />

            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <SearchSelectInput
              title="Country"
              value={country ? country : null}
              options={countries ? countries?.map((x) => x?.name) : []}
              onChange={(country) => {
                setCountry(country);

                const pickedCountry = countriesList?.find(
                  (_c) => _c.name === country
                );
                pushCountry({
                  country: {
                    id: pickedCountry.id,
                    name: pickedCountry.name,
                    slug: pickedCountry.name,
                    route: "/admin",
                  },
                });
                setCity(null);
              }}
            />

            <SearchSelectInput
              title="City"
              setValue={setCity}
              value={city ? city : null}
              options={
                cities
                  ? country && !city
                    ? citiesFilter()
                    : cities?.map((x) => x?.name)
                  : []
              }
              onChange={(city) => setCity(city)}
            />

            <InputField
              props={{
                id: "address",
                title: "Address",
                type: "text",
                value: address ? address : "",
                onChange: (e) => {
                  setAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <SelectInput
              title="Admin Status"
              options={["unblocked", "blocked"]}
              defaultValue={adminStatus === 1 ? "unblocked" : "blocked"}
              onChange={(e) => {
                setAdminStatus(e.target.value === "unblocked" ? 1 : 0);
                setError(null);
                setSuccess(null);
              }}
            />

            <SelectInput
              title="Admin Role"
              defaultValue={
                role
                  ? String(role)?.toLowerCase()?.replaceAll("_", " ")
                  : "content_creator"
              }
              options={roles(currentRole)}
              onChange={(e) => {
                setRole(e.target.value);
                setError(null);
                setSuccess(null);
              }}
            />
          </div>

          <MyActionButton
            title="Update Profile"
            loading={action === "profile" ? loading : false}
            onClick={() => {
              setAction("profile");
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>

      <PasswordSettings
        password={password}
        setError={setError}
        setAction={setAction}
        responses={responses}
        setSuccess={setSuccess}
        setLoading={setLoading}
        setPassword={setPassword}
        setErrorMessage={setErrorMessage}
        confirm_password={confirm_password}
        setConfirmPassword={setConfirmPassword}
        loading={action === "password" ? loading : false}
      />
    </div>
  );
};

export default Edit;
