import React, { useState } from "react";
import { useInfoContext } from "../../../../context/info/InfoContext";
import { getOneAbout } from "../../../../middlewares/sysNavigation/SysNavigation";
import {
  Title,
  GoBack,
  TextArea,
  MyMessage,
  MyActionButton,
} from "../../component/Component";

const Edit = () => {
  const { updateAbout } = useInfoContext();
  const about = getOneAbout();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const about_us_id = about.about_us_id;
  const [mission, setMission] = useState(about.mission);
  const [vision, setVision] = useState(about.vision);
  const [goal, setGoal] = useState(about.goal);
  const [objectives, setObjectives] = useState(about.objectives);

  const aboutRoutes = "/about";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
  };

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneAboutHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      mission: mission,
      vision: vision,
      goal: goal,
      objectives: objectives,
    };

    const { status } = await updateAbout(about_us_id, data);
    if (status === 200) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, status, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: aboutRoutes }} />
      <form onSubmit={updateOneAboutHandler}>
        <div className="mt-4 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "About Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <TextArea
              props={{
                id: "mission",
                title: "Mission",
                type: "text",
                value: mission ? mission : "",
                onChange: (e) => {
                  setMission(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "vision",
                title: "Vision",
                type: "text",
                value: vision ? vision : "",
                onChange: (e) => {
                  setVision(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "goal",
                title: "Goal",
                type: "text",
                value: goal ? goal : "",
                onChange: (e) => {
                  setGoal(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "objectives",
                title: "Objectives",
                type: "text",
                value: objectives ? objectives : "",
                onChange: (e) => {
                  setObjectives(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
