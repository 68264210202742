/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { FOLDERS } from "../../../doseeData/utils/Utils";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { getOneCompany } from "../../../../middlewares/sysNavigation/SysNavigation";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { useCountryContext } from "../../../../context/country/countryContext/CountryContext";
import {
  Title,
  TextArea,
  MyMessage,
  InputField,
  PhoneInput,
  GoBackTool,
  MyActionButton,
  SearchSelectInput,
} from "../../component/Component";

const Edit = () => {
  const { updateBySuperAdmin } = useAdminContext();
  const { countries, fetchCountries } = useCountryContext();

  const company = getOneCompany();
  const company_id = company?.company_id;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setErrorMessage] = useState(null);

  const admin = company?.Admins?.length > 0 ? company?.Admins[0] : null;

  const [firstName, setFirstName] = useState(admin?.first_name);
  const [lastName, setLastName] = useState(admin?.last_name);
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(admin?.email);
  const [phoneNumber, setPhoneNumber] = useState(admin?.phonenumber);

  const [name, setName] = useState(company?.name);
  const [country, setCountry] = useState(company?.country);
  const [website, setWebsite] = useState(company?.website);
  const [thumbnail, setThumbnail] = useState(company?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(company?.image_cdn_id);
  const [description, setDescription] = useState(company?.description);

  const companyRoutes = "/company";

  const responses = (isError, success, message) => {
    setLoading(false);
    setError(isError);
    setSuccess(success);
    setErrorMessage(message);
  };

  useEffect(() => {
    if (!countries) fetchCountries();
  }, [countries, country]);

  const isValid = ({ data, value, key }) => {
    return data?.find((x) => (x?.[key] === value ? true : false));
  };

  const addOneCompanyHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, false, "Image Required");
    } else if (!isValid({ data: countries, value: country, key: "name" })) {
      responses(true, false, "Invalid Country");
    } else await sendDBRequest();
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      admin_id: admin?.admin_id,
      name: name,
      thumbnail: thumbnail,
      image_cdn_id: thumbnailId,
      description: description,
      website: website,
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: password && password !== "" ? password : null,
      phonenumber: phoneNumber,
      country: country,
    };

    const { message, status } = await updateBySuperAdmin(company_id, data);

    if (status === 200 || status === 201) {
      setLoading(false);
      setSuccess(true);
      responses(false, true, message);
    } else {
      responses(true, false, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBackTool route={companyRoutes} />
      <form onSubmit={addOneCompanyHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.COMPANY}
          />

          <Title props={{ title: "Company Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "Name",
                title: "Company Name",
                type: "text",
                value: name ? name : "",
                onChange: (e) => {
                  setName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "Website",
                title: "Company Website",
                type: "text",
                value: website ? website : "",
                onChange: (e) => {
                  setWebsite(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <SearchSelectInput
              title="Country"
              value={country ? country : null}
              options={countries ? countries?.map((x) => x?.name) : []}
              onChange={(country) => {
                setCountry(country);
              }}
            />
          </div>

          <Title props={{ title: "Admin Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "FirstName",
                title: "First Name",
                type: "text",
                value: firstName ? firstName : "",
                onChange: (e) => {
                  setFirstName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "LastName",
                title: "Last Name",
                type: "text",
                value: lastName ? lastName : "",
                onChange: (e) => {
                  setLastName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "Email",
                title: "Email Address",
                type: "text",
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <PhoneInput
              setPhone={setPhoneNumber}
              phone={phoneNumber}
              required={false}
              country={country}
            />
            <InputField
              props={{
                id: "Password",
                title: "Password",
                type: "password",
                value: password ? password : "",
                onChange: (e) => {
                  setPassword(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <p className="text-xs pt-8">
            <span className="font-bold">Notice:</span> You will be able to add
            admins after you have created the company.
          </p>
          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
