/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Search } from "../../component/searchUtils";
import { Pagination } from "../../component/Pagination";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { usePageContext } from "../../../../context/page/PageContext";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";

const AttractionsList = ({ setPages, pages }) => {
  const { attractions, fetchAttractions, attractionsCount } = usePageContext();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);
  const [attractionsList, setAttractionsList] = useState(null);

  useEffect(() => {
    if (!attractions) fetchAttractions(page, pageSize);
    else setAttractionsList(attractions);
  }, [attractions]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    attractions.map((_attraction_) => {
      if (_attraction_.title.toString().toLowerCase().includes(key))
        return searchArray.push(_attraction_);
      return true;
    });

    setAttractionsList(searchArray);
    if (key === "") setAttractionsList(attractions);
  };

  return (
    <div className="mb-5 overflow-y-auto w-full py-2">
      <div className="w-full mb-8">
        <Search
          props={{
            title: "Attraction",
            onChange: (e) => SearchList(e),
          }}
        />
      </div>
      <table className="table-auto w-full border-collapse border border-gray-300 ext-sm text-sm text-left">
        <thead>
          <tr className="space-x-2">
            <th className="border p-2 font-bold">Title</th>
            <th className="border p-2 font-bold">Open Days</th>
            <th className="border text-center">Action</th>
          </tr>
        </thead>
        <tbody className="space-x-2 text-left border-gray-300">
          {attractions && attractionsList ? (
            (() => {
              return attractionsList.map((_attraction_) => {
                let isAdded = false;
                const slug = _attraction_.title
                  .toLowerCase()
                  .replace(/\s/g, "_");
                pages.map((val) =>
                  val.slug === slug ? (isAdded = true) : null
                );

                return (
                  <tr key={_attraction_.attraction_id}>
                    <td className="border px-2">{_attraction_.title}</td>
                    <td className="border px-2">{_attraction_.open_days}</td>
                    <td className="border min-w-fit">
                      {isAdded === true ? (
                        <button
                          type="button"
                          onClick={() =>
                            setPages(pages.filter((val) => val.slug !== slug))
                          }
                          className="min-w-fit bg-orange-700 px-2 py-2 w-full text-white text-xs font-bold"
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            setPages([
                              ...pages,
                              {
                                title: _attraction_.title,
                                hashtag: `attraction/${_attraction_.attraction_id}`,
                                thumbnail: _attraction_.thumbnail
                                  ? _attraction_.thumbnail
                                  : defaultImage,
                                image_cdn_id: _attraction_.thumbnailId
                                  ? _attraction_.thumbnailId
                                  : "...",
                                description: _attraction_.description,
                                slug: _attraction_.title
                                  ? _attraction_.title
                                      .toLowerCase()
                                      .replace(/\s/g, "_")
                                  : ".",
                                category: _attraction_.category,
                                status: 1,
                              },
                            ])
                          }
                          className="min-w-fit bg-green-700 px-2 py-2 w-full text-white text-xs font-bold"
                        >
                          Add
                        </button>
                      )}
                    </td>
                  </tr>
                );
              });
            })()
          ) : (
            <tr>
              <td className="border px-2">...</td>
              <td className="border px-2">...</td>
              <td className="border">...</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="my-8">
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={attractionsCount}
          callbackFunction={(_page_, _pageSize_) => {
            fetchAttractions(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

export default AttractionsList;
