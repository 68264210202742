/* eslint-disable react-hooks/exhaustive-deps */
import MonthPicker from "./MonthPicker";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getType } from "./helper";
import MyAdminComponent from "./MyAdminComponent";
import { getPageRouteFromKey } from "./routesHelper";
import { pushLevel, setAdminOption } from "../../../../middlewares";
import { useStaticContext } from "../../../../context/static/StaticContext";
import { addOneLevel } from "../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { useAdminsContext } from "../../../../context/admin/context/AdminManageContext";

const AllAdminsStats = () => {
  const { getStats, stats } = useStaticContext();
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const { admins, getAllByAdminCompanyId, count } = useAdminsContext();

  const [currentAdmin, setCurrentAdmin] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  // const fullName = `${sysAdmin?.first_name} - ${sysAdmin?.last_name}`;
  const [localStatistics, setLocalSetStats] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!admins) getAllByAdminCompanyId(page, pageSize);
    if (!stats && admins && admins?.length > 0) {
      loadStatistics({
        year: currentYear,
        month: currentMonth,
      });
    } else if (stats && admins && admins?.length > 0) {
      setLocalSetStats(buildArrayOfKeysAndValuesFromAdminObjectData(stats));
    }
  }, [stats, admins]);

  const buildArrayOfKeysAndValuesFromAdminObjectData = (obj) => {
    const result = [];
    let total_items = 0;
    for (const key in obj) {
      if (
        ![
          "email",
          "admin_id",
          "last_name",
          "first_name",
          "phonenumber",
        ].includes(String(key))
      ) {
        total_items += obj[key];
        result.push({
          title: key,
          value: obj[key],
          route: getPageRouteFromKey({ key }),
        });
      }
    }
    setTotal(total_items);
    return result;
  };

  const loadStatistics = async ({ year, month, admin }) => {
    if (admins && admins?.length > 0) {
      await getStats({
        year,
        month,
        data: { ids: admin ? [admin] : [admins[currentAdmin]?.admin_id] },
      });
    }
  };

  return (
    <div className="flex flex-col w-full space-y-8 p-4 bg-gray-50 overflow-y-auto">
      <div className="text-sm text-black uppercase flex flex-col justify-center space-y-2">
        <MonthPicker
          currentYear={currentYear}
          currentMonth={currentMonth}
          setTotal={setTotal}
          setCurrentYear={setCurrentYear}
          loadStatistics={loadStatistics}
          setCurrentMonth={setCurrentMonth}
          setLocalSetStats={setLocalSetStats}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <MyAdminComponent
          page={page}
          count={count}
          admins={admins}
          pageSize={pageSize}
          currentYear={currentYear}
          currentAdmin={currentAdmin}
          currentMonth={currentMonth}
          getAllByAdminCompanyId={getAllByAdminCompanyId}
          setPage={setPage}
          setTotal={setTotal}
          setPageSize={setPageSize}
          loadStatistics={loadStatistics}
          setCurrentAdmin={setCurrentAdmin}
          setLocalSetStats={setLocalSetStats}
        />

        <div className="flex flex-col w-full space-y-4 col-span-1 md:col-span-2 border-2 border-gray-200 p-4">
          <div className="flex flex-row justify-between items-start text-sm md:text-base font-bold uppercase">
            <p>
              {admins && admins?.length > 0
                ? `${admins[currentAdmin]?.first_name}'s`
                : "My"}{" "}
              Statistics
            </p>
            <p className="text-xs md:text-sm font-normal text-gray-600">
              Total Items:{" "}
              <span className="font-bold text-sm md:text-base text-blue-700">
                [ {total} Items ]
              </span>
            </p>
          </div>
          {localStatistics ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 w-full">
              {localStatistics?.map((stat, index) => {
                return (
                  <Link
                    key={index}
                    to={stat?.route}
                    onClick={() => {
                      setSingleAdmin({
                        ids: [admins[currentAdmin]?.admin_id],
                        year: currentYear,
                        month: currentMonth,
                        noLocation: true,
                        type: getType({ field: stat?.title }),
                        route: "/content/admin/stats",
                      });
                      // Breadcumb
                      addOneLevel({
                        id: 0,
                        name: `${admins[currentAdmin]?.first_name} - ${admins[currentAdmin]?.last_name}`,
                        route: "/levels/first",
                        level: "",
                        country_id: 0,
                        data: {
                          data: {
                            name: "",
                            country_id: 0,
                            first_level_id: 0,
                            second_level_id: 0,
                            third_level_id: 0,
                            fourth_level_id: 0,
                            level: "",
                          },
                        },
                      });
                      // Level For Add or Edit
                      pushLevel({
                        level: {
                          id: 0,
                          name: "",
                          level: "All",
                          route: "/levels/first",
                          data: null,
                        },
                      });
                      setAdminOption(sysAdmin?.admin_id);
                    }}
                  >
                    <div className="flex capitalize text-xs justify-between font-bold space-x-2 hover:underline items-center border border-gray-200 p-2">
                      <p>{stat?.title?.replace(/_/g, " ")}</p>
                      <p className="bg-gray-800 text-white flex justify-center items-center px-3 py-2">
                        {stat?.value}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center w-full py-5">
              <p className="text-xs font-bold">Loading...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllAdminsStats;
