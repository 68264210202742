import React, { useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";

import { useAdvertisementContext } from "../../../../context/advertisement/advertisementContext";
import { ArrayFill } from "./arrayInit";
import { AdSection } from "./AdSection";
import InfoSection from "./InfoSection";

import {
  news,
  arts,
  homeEv,
  events,
  safari,
  musics,
  foods,
  hotels,
  dances,
  cinema,
  movies,
  homeHot,
  homeSaf,
  museums,
  classes,
  parties,
  homeTour,
  homeBest,
  homeAttr,
  theaters,
  shoppings,
  cityGuide,
  nightlives,
  latestNews,
  thingsTodo,
  ministries,
  happenToday,
  attractions,
  tipsAdvices,
  restaurants,
  thingsToKnow,
  neighborhoods,
} from "./constants";

export const AdModal = ({ event_id, name, setShowModal }) => {
  // const event_id = event_id;
  const [loading, setLoading] = useState(false);
  const [adsList, setAdsList] = useState([]);
  const [refill, setRefill] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { Advertisement, fetchAdvertisements, createOneAdvertisement } =
    useAdvertisementContext();

  const [news_, setNews] = useState([]);
  const [arts_, setArts] = useState([]);
  const [foods_, setFoods] = useState([]);
  const [events_, setEvents] = useState([]);
  const [safari_, setSafari] = useState([]);
  const [musics_, setMusics] = useState([]);
  const [cinema_, setCinema] = useState([]);
  const [dances_, setDances] = useState([]);
  const [movies_, setMovies] = useState([]);
  const [hotels_, setHotels] = useState([]);
  const [parties_, setParties] = useState([]);
  const [museums_, setMuseums] = useState([]);
  const [classes_, setClasses] = useState([]);
  const [theaters_, setTheaters] = useState([]);
  const [shoppings_, setShoppings] = useState([]);
  const [homeHotels_, setHomeHotel] = useState([]);
  const [cityGuide_, setCityGuide] = useState([]);
  const [latestNews_, setLatestNews] = useState([]);
  const [ministries_, setMinistries] = useState([]);
  const [nightlives_, setNightlives] = useState([]);
  const [thingsTodo_, setThingsTodo] = useState([]);
  const [homeSafari_, setHomeSafari] = useState([]);
  const [homeEvents_, setHomeEvents_] = useState([]);
  const [restaurants_, setRestaurants] = useState([]);
  const [attractions_, setAttractions] = useState([]);
  const [thingsToKnow_, setThingsToKnow] = useState([]);
  const [neighborhoods_, setNeighborhoods] = useState([]);
  const [tipsAndAdvices_, setTipsAndAdvice] = useState([]);
  const [homeAttractions_, setHomeAttraction_] = useState([]);
  const [happenningToday_, setHappenningToday] = useState([]);
  const [homeTourPackages_, setHomeTourPackage] = useState([]);
  const [homeBestOfAfrica_, setHomeBestOfAfrica] = useState([]);

  useEffect(() => {
    if (!Advertisement) fetchAdvertisements();
    else if (Advertisement && adsList.length === 0 && refill === true) {
      setAdsList(Advertisement);
    }
    fillObjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Advertisement, adsList]);

  const fillObjectData = () => {
    // Loop to get exact data object
    setHomeAttraction_(ArrayFill(adsList, homeAttr));
    setHomeEvents_(ArrayFill(adsList, homeEv));
    setHomeTourPackage(ArrayFill(adsList, homeTour));
    setHomeSafari(ArrayFill(adsList, homeSaf));
    setHomeHotel(ArrayFill(adsList, homeHot));
    setHomeBestOfAfrica(ArrayFill(adsList, homeBest));
    setHappenningToday(ArrayFill(adsList, happenToday));
    setLatestNews(ArrayFill(adsList, latestNews));
    setThingsToKnow(ArrayFill(adsList, thingsToKnow));
    setEvents(ArrayFill(adsList, events));
    setAttractions(ArrayFill(adsList, attractions));
    setSafari(ArrayFill(adsList, safari));
    setRestaurants(ArrayFill(adsList, restaurants));
    setMusics(ArrayFill(adsList, musics));
    setCinema(ArrayFill(adsList, cinema));
    setNews(ArrayFill(adsList, news));
    setNeighborhoods(ArrayFill(adsList, neighborhoods));
    setDances(ArrayFill(adsList, dances));
    setFoods(ArrayFill(adsList, foods));
    setThingsTodo(ArrayFill(adsList, thingsTodo));
    setMovies(ArrayFill(adsList, movies));
    setCityGuide(ArrayFill(adsList, cityGuide));
    setHotels(ArrayFill(adsList, hotels));
    setArts(ArrayFill(adsList, arts));
    setParties(ArrayFill(adsList, parties));
    setShoppings(ArrayFill(adsList, shoppings));
    setTheaters(ArrayFill(adsList, theaters));
    setTipsAndAdvice(ArrayFill(adsList, tipsAdvices));
    setClasses(ArrayFill(adsList, classes));
    setNightlives(ArrayFill(adsList, nightlives));
    setMuseums(ArrayFill(adsList, museums));
    setMinistries(ArrayFill(adsList, ministries));
  };

  const updatePosition = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);
    // Upload Ads Bulk to the server
    const { response, errorResponse } = await createOneAdvertisement({
      ads: adsList,
    });

    if (response) {
      setSuccess(true);
      setError(null);
      setLoading(false);
      setRefill(true);
      setAdsList([]);
    } else if (errorResponse) {
      setError(true);
      setSuccess(null);
      setLoading(false);
    }
  };

  const _SECTION_MAP_ = [
    {
      id: homeAttr,
      title: "Home Attractions",
      position: homeAttractions_,
    },
    {
      id: homeEv,
      title: "Home Events",
      position: homeEvents_,
    },
    {
      id: homeTour,
      title: "Home Tour Package",
      position: homeTourPackages_,
    },
    {
      id: homeSaf,
      title: "Home Safari",
      position: homeSafari_,
    },
    {
      id: homeHot,
      title: "Home Hotel",
      position: homeHotels_,
    },
    {
      id: homeBest,
      title: "Home Best Of Africa",
      position: homeBestOfAfrica_,
    },
    {
      id: happenToday,
      title: "Happening Today",
      position: happenningToday_,
    },
    {
      id: latestNews,
      title: "Latest News",
      position: latestNews_,
    },
    {
      id: thingsToKnow,
      title: "Things To Know",
      position: thingsToKnow_,
    },
    {
      id: events,
      title: "Events Section",
      position: events_,
    },
    {
      id: attractions,
      title: "Attractions Section",
      position: attractions_,
    },
    {
      id: safari,
      title: "Safari Section",
      position: safari_,
    },
    {
      id: restaurants,
      title: "Restaurants Section",
      position: restaurants_,
    },
    {
      id: musics,
      title: "Musics Section",
      position: musics_,
    },
    {
      id: cinema,
      title: "Cinema Section",
      position: cinema_,
    },
    {
      id: news,
      title: "News Section",
      position: news_,
    },
    {
      id: neighborhoods,
      title: "Neighborhoods Section",
      position: neighborhoods_,
    },
    {
      id: dances,
      title: "Dances Section",
      position: dances_,
    },
    {
      id: foods,
      title: "Foods Section",
      position: foods_,
    },
    {
      id: thingsTodo,
      title: "Things To Do Section",
      position: thingsTodo_,
    },
    {
      id: movies,
      title: "Movies Section",
      position: movies_,
    },
    {
      id: cityGuide,
      title: "City Guide Section",
      position: cityGuide_,
    },
    {
      id: hotels,
      title: "Hotels Section",
      position: hotels_,
    },
    {
      id: arts,
      title: "Arts Section",
      position: arts_,
    },
    {
      id: parties,
      title: "Parties Section",
      position: parties_,
    },
    {
      id: shoppings,
      title: "Shopping Section",
      position: shoppings_,
    },
    {
      id: theaters,
      title: "Theater Section",
      position: theaters_,
    },
    {
      id: tipsAdvices,
      title: "Tips And Advices Section",
      position: tipsAndAdvices_,
    },
    {
      id: classes,
      title: "Classes Section",
      position: classes_,
    },
    {
      id: nightlives,
      title: "Night Lives Section",
      position: nightlives_,
    },
    {
      id: museums,
      title: "Museums Section",
      position: museums_,
    },
    {
      id: ministries,
      title: "Ministries and Companies Section",
      position: ministries_,
    },
  ];

  return (
    <div>
      <div className="relative flex justify-center items-center">
        <div className="fixed left-auto right-auto w-11/12 md:mx-4 lg:mx-0 md:w-auto top-3 md:top-8 items-center bottom-3 h-auto overflow-x-hidden overflow-y-auto shadow inset-0 z-[150] outline-none focus:outline-none">
          <div className="w-full h-auto p-6 pt-0 text-center flex flex-col outline-none focus:outline-none bg-white">
            {/*content*/}
            <div className="flex justify-end p-2">
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                onClick={() => {
                  setShowModal();
                }}
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    setShowModal();
                  }}
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="mt-2">
              <h3 className="mb-6 text-xs font-bold capitalize underline decoration-gray-600 text-gray-900 ">
                Event:{" "}
                <span className="text-gray-800 font-bold text-sm capitalize leading-loose">
                  {name}
                </span>
              </h3>
            </div>
            <form onSubmit={updatePosition}>
              <div className="text-base space-y-4 h-auto">
                <InfoSection />
                <div className="flex flex-col">
                  {_SECTION_MAP_.map((section, index) => (
                    <AdSection
                      index={index}
                      key={index}
                      id={section.id}
                      adsList={adsList}
                      event_id={event_id}
                      title={section.title}
                      position={section.position}
                      setAdsList={setAdsList}
                      setRefill={setRefill}
                    />
                  ))}
                </div>
              </div>

              <div
                className={
                  `w-auto h-10 my-3 flex justify-center items-center ` +
                  (error || success
                    ? error
                      ? "bg-red-900/80"
                      : "bg-green-900/80"
                    : "hidden")
                }
              >
                <p className="text-white text-xs">
                  {error ? "Unexpected Error" : "success"}
                </p>
              </div>

              <div className="mt-5 mb-5">
                {loading ? (
                  <button
                    type="button"
                    className="text-white w-full h-12 flex font-bold justify-center items-center bg-gray-900 hover:bg-gray-700 focus:ring-gray-800 capitalize text-sm px-5 py-2.5 text-center mr-2 cursor-pointer"
                  >
                    Saving...
                    <AiOutlineLoading className="w-4 h-4 mx-2 animate-spin" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="text-white w-full h-12 flex justify-center bg-gray-900 hover:bg-gray-700 focus:ring-gray-800 font-bold capitalize text-sm items-center px-5 py-2.5 text-center mr-2 cursor-pointer"
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed w-full h-full inset-0 z-[100] bg-black"></div>
    </div>
  );
};
