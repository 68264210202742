/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { VscFileSubmodule } from "react-icons/vsc";
import { BsBook, BsPeople } from "react-icons/bs";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import {
  MdMusicNote,
  MdOutlineMuseum,
  MdOutlineFoodBank,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
  MdShop,
} from "react-icons/md";
import { SiHotelsdotcom } from "react-icons/si";
import { AiOutlineShopping } from "react-icons/ai";
import { BiCameraMovie } from "react-icons/bi";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { Card, GoBack, DashboardIntro } from "../../../component/Component";
import {
  pushLevel,
  getSingleLevelDashboard,
} from "../../../../../middlewares/sysNavigation/SysNavigation";
import { useDashboardLevelContext } from "../../../../../context/levels/levelsContext";
import { addOneLevel } from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../../../context/admin/context/AdminContext";
import { getType } from "../../contentStats/helper";
import { _TYPES_ } from "../../../../doseeData/utils/types";

const levelsRoute = "/levels";
const DashboardLevel = () => {
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const { data, level } = getSingleLevelDashboard();
  const { statsLevel, fetchLevelData } = useDashboardLevelContext();

  let level_id = null;
  const level_name = data?.name;
  // Getting the corresponding level ID
  if (level === "First") level_id = data?.first_level_id;
  else if (level === "Second") level_id = data?.second_level_id;
  else if (level === "Third") level_id = data?.third_level_id;
  else if (level === "Fourth") level_id = data?.fourth_level_id;

  useEffect(() => {
    fetchLevelData(level_id, level);
  }, []);

  const List = [
    {
      name: "events",
      quantity: statsLevel ? statsLevel?.event : "-",
      route: "/data/event",
      key: _TYPES_.EVENT,
    },
    {
      name: "attractions",
      quantity: statsLevel ? statsLevel?.attraction : "-",
      route: "/data/attraction",
      key: _TYPES_.ATTRACTION,
    },
    {
      name: "restaurants",
      quantity: statsLevel ? statsLevel?.restaurant : "-",
      route: "/data/restaurant",
      key: _TYPES_.RESTAURANT,
    },
    {
      name: "best of africa",
      quantity: statsLevel ? statsLevel?.best_of_africa : "-",
      route: "/data/bestofafrica",
      key: _TYPES_.BESTOFAFRICA,
    },
    {
      name: "music",
      quantity: statsLevel ? statsLevel?.music : "-",
      route: "/data/music",
      key: _TYPES_.MUSIC,
    },
    {
      name: "news",
      quantity: statsLevel ? statsLevel?.news : "-",
      route: "/data/news",
      key: _TYPES_.NEWS,
    },
    {
      name: "safaris",
      quantity: statsLevel ? statsLevel?.safari : "-",
      route: "/data/safari",
      key: _TYPES_.SAFARI,
    },
    {
      name: "tour packages",
      quantity: statsLevel ? statsLevel?.tour_package : "-",
      route: "/data/tour/package",
      key: _TYPES_.TOURPACKAGE,
    },
    {
      name: "cinema",
      quantity: statsLevel ? statsLevel?.cinema : "-",
      route: "/data/cinema",
      key: _TYPES_.CINEMA,
    },
    {
      name: "museums",
      quantity: statsLevel ? statsLevel?.museum : "-",
      route: "/data/museum",
      key: _TYPES_.MUSEUM,
    },
    {
      name: "night life",
      quantity: statsLevel ? statsLevel?.nightlife : "-",
      route: "/data/nightlife",
      key: _TYPES_.NIGHTLIFE,
    },
    {
      name: "hotels",
      quantity: statsLevel ? statsLevel?.hotel : "-",
      route: "/data/hotel",
      key: _TYPES_.HOTEL,
    },
    {
      name: "classes",
      quantity: statsLevel ? statsLevel?.class : "-",
      route: "/data/class",
      key: _TYPES_.CLASSES,
    },
    {
      name: "shopping",
      quantity: statsLevel ? statsLevel?.shopping : "-",
      route: "/data/shopping",
      key: _TYPES_.SHOPPING,
    },
    {
      name: "food",
      quantity: statsLevel ? statsLevel?.food : "-",
      route: "/data/food",
      key: _TYPES_.FOOD,
    },
    {
      name: "books",
      quantity: statsLevel ? statsLevel?.book : "-",
      route: "/data/book",
      key: _TYPES_.BOOK,
    },
    {
      name: "neighborhoods",
      quantity: statsLevel ? statsLevel?.neighborhood : "-",
      route: "/data/neighborhood",
      key: _TYPES_.NEIGHBORHOOD,
    },
    {
      name: "arts",
      quantity: statsLevel ? statsLevel?.art : "-",
      route: "/data/art",
      key: _TYPES_.ART,
    },
    {
      name: "dance",
      quantity: statsLevel ? statsLevel?.dance : "-",
      route: "/data/dance",
      key: _TYPES_.DANCE,
    },
    {
      name: "theaters",
      quantity: statsLevel ? statsLevel?.theater : "-",
      route: "/data/theater",
      key: _TYPES_.THEATER,
    },
    {
      name: "City Guides",
      quantity: statsLevel ? statsLevel?.city_guide : "-",
      route: "/data/city/guide",
      key: _TYPES_.CITYGUIDE,
    },
    {
      name: "Parties",
      quantity: statsLevel ? statsLevel?.party : "-",
      route: "/data/party",
      key: _TYPES_.PARTY,
    },
    {
      name: "Things To Do",
      quantity: statsLevel ? statsLevel?.things_to_do : "-",
      route: "/data/things/todo",
      key: _TYPES_.THINGSTODO,
    },
    {
      name: "Tips And Advice",
      quantity: statsLevel ? statsLevel?.tips_and_advice : "-",
      route: "/data/tips/advice",
      key: _TYPES_.TIPSANDADVICE,
    },
    {
      name: "Ministries",
      quantity: statsLevel ? statsLevel?.ministry : "-",
      route: "/data/ministry",
      key: _TYPES_.MINISTRY,
    },
    {
      name: "Movies",
      quantity: statsLevel ? statsLevel?.movie : "-",
      route: "/data/movie",
      key: _TYPES_.MOVIE,
    },
    {
      name: "Getting Around",
      quantity: statsLevel ? statsLevel?.getting_around : "-",
      route: "/data/get/around",
      key: _TYPES_.GETTINGAROUND,
    },
    {
      name: "Products",
      quantity: statsLevel ? statsLevel?.product : "-",
      route: "/data/product",
      key: _TYPES_.PRODUCT,
    },
  ];

  return (
    <div className="overflow-y-auto p-3 lg:p-4 h-full rounded-b-xl bg-gray-50 pb-5">
      <GoBack
        props={{ route: `${levelsRoute}/${level.toString().toLowerCase()}` }}
      />
      <div className="flex flex-row justify-center items-center my-4">
        <DashboardIntro props={{ intro: `${level} Level`, name: level_name }} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900 mb-5 mt-4">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                // We are excluding the first index since they are not pointing to
                if (
                  ![
                    "regions",
                    "countries",
                    "First Level",
                    "Second Level",
                    "Third Level",
                    "Fourth Level",
                  ].includes(card.name)
                ) {
                  setSingleAdmin({
                    ids: [sysAdmin?.admin_id],
                    type: getType({ field: card?.key }),
                    route: "/level/dashboard",
                  });
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: `${sysAdmin?.first_name} ${sysAdmin?.last_name}`,
                    route: "/levels/first",
                    level: "",
                    country_id: 0,
                    data: {
                      data: {
                        name: "",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                }
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardLevel;

const Icon = [
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
  <MdShop />,
];
