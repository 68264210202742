/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { pushOneGettingAround } from "../../../middlewares/sysNavigation/SysNavigation";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import {
  Modal,
  GoBack,
  MyButton,
  MySearch,
  Pagination,
  ModalPosition,
  DashboardIntro,
} from "../../home/component/Component";
import { _TYPES_ } from "../utils/types";
import { _PAGE_SIZE_ } from "../../../middlewares/other/tools";
import { useGeoPositionContext } from "../utils/geoPosition";
import { useAdminContext } from "../../../context/admin/context/AdminContext";

const addRoute = "/data/get/around/add";
const editRoute = "/data/get/around/edit";
const doseeDataRoutes = "/dosee/data";

const Overview = () => {
  const { gettingArounds, gettingAroundsCount, fetchDoSeeData } =
    useDoSeeDataContext();
  const [gettingAroundsList, setGettingAroundsList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  const [localSearch, setLocalSearch] = useState([]);

  const {
    country_id,
    entry_label,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const data_request_body = {
    country_id,
    first_level_id,
    second_level_id,
    third_level_id,
    fourth_level_id,
    content: _TYPES_.GETTINGAROUND,
  };

  useEffect(() => {
    if (!gettingArounds) fetchDoSeeData(data_request_body, page, pageSize);
    setGettingAroundsList(gettingArounds);
  }, [gettingArounds]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    gettingArounds.map((gta) => {
      if (gta?.title.toString().toLowerCase().includes(key))
        return searchArray.push(gta);
      return true;
    });

    setGettingAroundsList(searchArray);
    if (key === "") setGettingAroundsList(gettingArounds);
  };

  const LocalData = localSearch?.length > 0 ? localSearch : gettingAroundsList;

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: doseeDataRoutes }} />
      <div className="my-3">
        <DashboardIntro
          props={{ intro: `Getting Around`, name: entry_label }}
        />
      </div>

      <div className="flex flex-col justify-center">
        <MySearch
          type={_TYPES_.GETTINGAROUND}
          value={localSearch}
          showGlobalSearch={true}
          setValue={setLocalSearch}
          onChange={(e) => SearchList(e)}
        />

        <div className="w-full flex justify-end my-5">
          {country_id ? (
            <Link to={addRoute}>
              <MyButton
                title="Add Getting Around"
                className="py-3 px-8 bg-blue-900 hover:bg-blue-600"
                onClick={() => {}}
              />
            </Link>
          ) : null}
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/3">Title</th>
                <th className="border p-2 lg:px-4 font-bold w-1/3">Slug</th>
                <th className="border text-center w-1/3">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {gettingArounds || LocalData ? (
                LocalData?.map((gta, key) => (
                  <tr key={key}>
                    <td className="border px-2 lg:px-4 w-1/3">{gta?.title}</td>
                    <td className="border px-2 lg:px-4 w-1/3">{gta?.slug}</td>
                    <td className="border w-1/3">
                      <ActionButton props={gta} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                  <td className="border px-2 lg:px-4 w-1/3">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={gettingAroundsCount}
          callbackFunction={(p, size) => {
            fetchDoSeeData(data_request_body, p, size);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { sysAdmin } = useAdminContext();
  const isSuperAdmin = ["super_admin", "admin"].includes(sysAdmin?.role);
  const { gettingArounds, setDataState, deleteOneContent, updateOneContent } =
    useDoSeeDataContext();

  const [showModal, setShowModal] = useState(false);

  const getting_around_id = props.getting_around_id;
  const getting_around_title = props.title;
  const getting_around = props;

  const deleteOneGettingAroundById = async (getting_around_id) => {
    const { status } = await deleteOneContent({
      data_id: getting_around_id,
      content: _TYPES_.GETTINGAROUND,
    });
    if (status === 200) {
      if (gettingArounds) {
        setDataState({
          data: gettingArounds.filter(
            (gta) => gta?.getting_around_id !== getting_around_id
          ),
          content: _TYPES_.GETTINGAROUND,
        });
        setShowModal(false);
      }
    }
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} getting_around
   */
  const suspension = async (getting_around) => {
    const data = {
      country_id: getting_around?.country_id,
      description: getting_around?.description,
      first_level_id: getting_around?.first_level_id,
      fourth_level_id: getting_around?.fourth_level_id,
      hashtag: getting_around?.hashtag,
      second_level_id: getting_around?.second_level_id,
      slug: getting_around?.slug,
      status: getting_around?.status === 1 ? 0 : 1,
      third_level_id: getting_around?.third_level_id,
      thumbnail: getting_around?.thumbnail,
      title: getting_around?.title,
    };

    const { status } = await updateOneContent({
      data_id: getting_around?.getting_around_id,
      data: data,
      content: _TYPES_.GETTINGAROUND,
    });

    if (status === 200) {
      if (gettingArounds) {
        const array = [];
        gettingArounds.map((gta) => {
          if (gta?.getting_around_id === getting_around_id)
            return array.push({
              getting_around_id: getting_around?.getting_around_id,
              created_by: getting_around?.created_by,
              managed_by: getting_around?.managed_by,
              createdAt: getting_around?.createdAt,
              updatedAt: getting_around?.updatedAt,
              ...data,
            });
          else return array.push(gta);
        });
        setDataState({ data: array, content: _TYPES_.GETTINGAROUND });
      }
    }
  };

  const [showPosition, setShowPosition] = useState(false);

  return (
    <>
      {showPosition ? (
        <ModalPosition
          name={getting_around_title}
          type={_TYPES_.GETTINGAROUND}
          id={getting_around_id}
          setShowPosition={setShowPosition}
          showPosition={showPosition}
        />
      ) : null}

      <Modal
        props={{
          name: getting_around_title,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneGettingAroundById(getting_around_id),
        }}
      />

      <div className="flex flex-row justify-end items-center px-2 py-3 space-x-4 text-xs">
        {/* <Button
          props={{
            type: 'button',
            title: 'Promote',
            category: 'black',
            action: null,
            onClick: () => {
              setShowPosition(true);
            },
          }}
        /> */}
        {isSuperAdmin ? (
          <MyButton
            title={getting_around?.status === 1 ? "Suspend" : "Unsuspend"}
            className={`py-3 px-8 ${
              getting_around?.status === 1
                ? "bg-gray-900 hover:bg-gray-600"
                : "bg-orange-900 hover:bg-orange-600"
            }`}
            onClick={() => suspension(getting_around)}
          />
        ) : null}
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className={`py-3 px-8 bg-blue-800 hover:bg-blue-600`}
            onClick={() => pushOneGettingAround(getting_around)}
          />
        </Link>
        <MyButton
          title="Delete"
          className={`py-3 px-8 bg-red-800 hover:bg-red-600`}
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
