import React from "react";
import { NavBar, SideBar } from "../../public/index";
import { StaticProvider } from "../../../context/static/StaticContext";
// import { useAdminContext } from "../../../context/admin/context/AdminContext";
import { NavigationProvider } from "../../../context/navigation/navigationContext";
import { CurrencyProvider } from "../../../context/currency/CurrencyContext";
import { GenerateContentProvider } from "../../../context/generateContent";

const Screen = ({ component, title }) => {
  // const { sysAdmin } = useAdminContext();
  // const companyTitle = `${sysAdmin?.company_name} ${
  //   sysAdmin?.company_code ? ` - ${sysAdmin?.company_code}` : ""
  // }`;
  // const selectedTitle = sysAdmin?.company_name ? companyTitle : title;

  return (
    <div className="flex flex-row relative bg-gradient-to-r bg-gray-50 justify-start w-screen h-screen max-w-full max-h-full overflow-hidden">
      <CurrencyProvider>
        <NavigationProvider>
          <GenerateContentProvider>
            <SideBar />
            <div className="flex flex-col w-full shadow-xl">
              <NavBar title={title} />
              <StaticProvider>{component}</StaticProvider>
            </div>
          </GenerateContentProvider>
        </NavigationProvider>
      </CurrencyProvider>
    </div>
  );
};

export default Screen;
