// System Navigation
// From Regions to Countries to levels
export const SysRegionNavigation = [];
export const SysCountryNavigation = [];
export const SysLevelNavigation = [];
const CurrentRegion = [];
const editLevel = [];
const addLevel = [];
// const CurrentCountry = [];
const SingleCountryDashboard = [];
const SingleLevelDashboard = [];

const cityDashboard = [];

/// Data Manipulation

// Event to be edited
const attraction_data = [];
const event_data = [];
const bestOfAfrica_data = [];
const cinema_data = [];
const music_data = [];
const news_data = [];
const safari_data = [];
const restaurant_data = [];
const tourPackage_data = [];
const product_data = [];
const agent_data = [];
const user_data = [];
const admin_data = [];
const things_to_do_data = [];
const party_data = [];
const nightlife_data = [];
const city_guide_data = [];
const hotel_data = [];
const classes_data = [];
const shopping_data = [];
const food_data = [];
const book_data = [];
const neighborhood_data = [];
const ministry_data = [];
const getting_around_data = [];
const art_data = [];
const movie_data = [];
const dance_data = [];
const theater_data = [];
const tips_and_advice_data = [];
const team_data = [];
const page_data = [];
const museum_data = [];
const banner_data = [];
const trade_organization_data = [];
const plug_image_data = [];
const climate_data = [];
const ethnic_group_data = [];
const language_data = [];
const religion_data = [];
const footer_data = [];
const service_data = [];
const about_data = [];
const partner_data = [];
const blog_data = [];
const email_data = [];
const advertisement_data = [];
const company_data = [];
const regional_blocks_data = [];
const terms_data = [];
const privacy_data = [];
const currency_data = [];
const companies_data = [];
const embassy_data = [];

export const pushOneCurrency = (data) => {
  currency_data.pop();
  currency_data.push(data);
};

export const getOneCurrency = () => {
  if (currency_data.length > 0) return currency_data[0];
  return null;
};

export const pushOnePrivacy = (data) => {
  privacy_data.pop();
  privacy_data.push(data);
};

export const getOnePrivacy = () => {
  if (privacy_data.length > 0) return privacy_data[0];
  return null;
};

export const pushOneTerms = (data) => {
  terms_data.pop();
  terms_data.push(data);
};

export const getOneTerms = () => {
  if (terms_data.length > 0) return terms_data[0];
  return null;
};

export const pushOneRegionalBlocks = (data) => {
  regional_blocks_data.pop();
  regional_blocks_data.push(data);
};

export const getOneRegionalBlocks = () => {
  if (regional_blocks_data.length > 0) return regional_blocks_data[0];
  return null;
};

export const pushOneCompany = (data) => {
  company_data.pop();
  company_data.push(data);
};

export const getOneCompany = () => {
  if (company_data.length > 0) return company_data[0];
  return null;
};

export const pushOneBanner = (data) => {
  banner_data.pop();
  banner_data.push(data);
};

export const getOneBanner = () => {
  if (banner_data.length > 0) return banner_data[0];
  return null;
};

export const pushOneEmail = (data) => {
  email_data.pop();
  email_data.push(data);
};

export const getOneEmail = () => {
  if (email_data.length > 0) return email_data[0];
  return null;
};

export const pushOneMuseum = (data) => {
  museum_data.pop();
  museum_data.push(data);
};

export const getOneMuseum = () => {
  if (museum_data.length > 0) return museum_data[0];
  return null;
};

export const pushOnePartner = (data) => {
  partner_data.pop();
  partner_data.push(data);
};

export const getOnePartner = () => {
  if (partner_data.length > 0) return partner_data[0];
  return null;
};

export const pushOnePage = (data) => {
  page_data.pop();
  page_data.push(data);
};

export const getOnePage = () => {
  if (page_data.length > 0) return page_data[0];
  return null;
};

export const pushOneTeam = (data) => {
  team_data.pop();
  team_data.push(data);
};

export const getOneTeam = () => {
  if (team_data.length > 0) return team_data[0];
  return null;
};

export const pushOneAdvertisement = (data) => {
  advertisement_data.pop();
  advertisement_data.push(data);
};

export const getOneAdvertisement = () => {
  if (advertisement_data.length > 0) return advertisement_data[0];
  return null;
};

export const pushOneTipsAndAdvice = (data) => {
  tips_and_advice_data.pop();
  tips_and_advice_data.push(data);
};

export const getOneTipsAndAdvice = () => {
  if (tips_and_advice_data.length > 0) return tips_and_advice_data[0];
  return null;
};

export const pushOneTheater = (data) => {
  theater_data.pop();
  theater_data.push(data);
};

export const getOneTheater = () => {
  if (theater_data.length > 0) return theater_data[0];
  return null;
};

export const pushOneDance = (data) => {
  dance_data.pop();
  dance_data.push(data);
};

export const getOneDance = () => {
  if (dance_data.length > 0) return dance_data[0];
  return null;
};

export const pushOneMovie = (data) => {
  movie_data.pop();
  movie_data.push(data);
};

export const getOneMovie = () => {
  if (movie_data.length > 0) return movie_data[0];
  return null;
};

export const pushOneArt = (data) => {
  art_data.pop();
  art_data.push(data);
};

export const getOneArt = () => {
  if (art_data.length > 0) return art_data[0];
  return null;
};

export const pushOneGettingAround = (data) => {
  getting_around_data.pop();
  getting_around_data.push(data);
};

export const getOneGettingAround = () => {
  if (getting_around_data.length > 0) return getting_around_data[0];
  return null;
};

export const pushOneMinistry = (data) => {
  ministry_data.pop();
  ministry_data.push(data);
};

export const getOneMinistry = () => {
  if (ministry_data.length > 0) return ministry_data[0];
  return null;
};

export const pushOneCompanies = (data) => {
  companies_data.pop();
  companies_data.push(data);
};

export const getOneCompanies = () => {
  if (companies_data.length > 0) return companies_data[0];
  return null;
};

export const pushOneEmbassy = (data) => {
  embassy_data.pop();
  embassy_data.push(data);
};

export const getOneEmbassy = () => {
  if (embassy_data.length > 0) return embassy_data[0];
  return null;
};

export const pushOneNeighborhood = (data) => {
  neighborhood_data.pop();
  neighborhood_data.push(data);
};

export const getOneNeighborhood = () => {
  if (neighborhood_data.length > 0) return neighborhood_data[0];
  return null;
};

export const pushOneBook = (data) => {
  book_data.pop();
  book_data.push(data);
};

export const getOneBook = () => {
  if (book_data.length > 0) return book_data[0];
  return null;
};

export const pushOneFood = (data) => {
  food_data.pop();
  food_data.push(data);
};

export const getOneFood = () => {
  if (food_data.length > 0) return food_data[0];
  return null;
};

export const pushOneShopping = (data) => {
  shopping_data.pop();
  shopping_data.push(data);
};

export const getOneShopping = () => {
  if (shopping_data.length > 0) return shopping_data[0];
  return null;
};

export const pushOneClasses = (data) => {
  classes_data.pop();
  classes_data.push(data);
};

export const getOneClasses = () => {
  if (classes_data.length > 0) return classes_data[0];
  return null;
};

export const pushOneHotel = (data) => {
  hotel_data.pop();
  hotel_data.push(data);
};

export const getOneHotel = () => {
  if (hotel_data.length > 0) return hotel_data[0];
  return null;
};

export const pushOneCityGuide = (data) => {
  city_guide_data.pop();
  city_guide_data.push(data);
};

export const getOneCityGuide = () => {
  if (city_guide_data.length > 0) return city_guide_data[0];
  return null;
};

export const pushOneNightlife = (data) => {
  nightlife_data.pop();
  nightlife_data.push(data);
};

export const getOneNightlife = () => {
  if (nightlife_data.length > 0) return nightlife_data[0];
  return null;
};

export const pushOneParty = (data) => {
  party_data.pop();
  party_data.push(data);
};

export const getOneParty = () => {
  if (party_data.length > 0) return party_data[0];
  return null;
};

export const pushOneThingsToDo = (data) => {
  things_to_do_data.pop();
  things_to_do_data.push(data);
};

export const getOneThingsToDo = () => {
  if (things_to_do_data.length > 0) return things_to_do_data[0];
  return null;
};

export const pushOneAdmin = (data) => {
  admin_data.pop();
  admin_data.push(data);
};

export const getOneAdmin = () => {
  if (admin_data.length > 0) return admin_data[0];
  return null;
};

export const pushOneUser = (data) => {
  user_data.pop();
  user_data.push(data);
};

export const getOneUser = () => {
  if (user_data.length > 0) return user_data[0];
  return null;
};

export const pushOneAgent = (data) => {
  agent_data.pop();
  agent_data.push(data);
};

export const getOneAgent = () => {
  if (agent_data.length > 0) return agent_data[0];
  return null;
};

export const pushOneProduct = (data) => {
  product_data.pop();
  product_data.push(data);
};

export const getOneProduct = () => {
  if (product_data.length > 0) return product_data[0];
  return null;
};

export const pushOneTourPackage = (data) => {
  tourPackage_data.pop();
  tourPackage_data.push(data);
};

export const getOneTourPackage = () => {
  if (tourPackage_data.length > 0) return tourPackage_data[0];
  return null;
};

export const pushOneBlog = (data) => {
  blog_data.pop();
  blog_data.push(data);
};

export const getOneBlog = () => {
  if (blog_data.length > 0) return blog_data[0];
  return null;
};

export const pushOneFooter = (data) => {
  footer_data.pop();
  footer_data.push(data);
};

export const getOneFooter = () => {
  if (footer_data.length > 0) return footer_data[0];
  return null;
};

export const pushOneAbout = (data) => {
  about_data.pop();
  about_data.push(data);
};

export const getOneAbout = () => {
  if (about_data.length > 0) return about_data[0];
  return null;
};

export const pushOneService = (data) => {
  service_data.pop();
  service_data.push(data);
};

export const getOneService = () => {
  if (service_data.length > 0) return service_data[0];
  return null;
};

export const pushOneRestaurant = (data) => {
  restaurant_data.pop();
  restaurant_data.push(data);
};

export const getOneRestaurant = () => {
  if (restaurant_data.length > 0) return restaurant_data[0];
  return null;
};

export const pushOneSafari = (data) => {
  safari_data.pop();
  safari_data.push(data);
};

export const getOneSafari = () => {
  if (safari_data.length > 0) return safari_data[0];
  return null;
};

export const pushOneNews = (data) => {
  news_data.pop();
  news_data.push(data);
};

export const getOneNews = () => {
  if (news_data.length > 0) return news_data[0];
  return null;
};

export const pushOneMusic = (data) => {
  music_data.pop();
  music_data.push(data);
};

export const getOneMusic = () => {
  if (music_data.length > 0) return music_data[0];
  return null;
};

export const pushOneCinema = (data) => {
  cinema_data.pop();
  cinema_data.push(data);
};

export const getOneCinema = () => {
  if (cinema_data.length > 0) return cinema_data[0];
  return null;
};

export const pushOneBestOfAfrica = (data) => {
  bestOfAfrica_data.pop();
  bestOfAfrica_data.push(data);
};

export const getOneBestOfAfrica = () => {
  if (bestOfAfrica_data.length > 0) return bestOfAfrica_data[0];
  return null;
};

export const pushOneEvent = (data) => {
  event_data.pop();
  event_data.push(data);
};

export const getOneEvent = () => {
  if (event_data.length > 0) return event_data[0];
  return null;
};

export const pushOneAttraction = (data) => {
  attraction_data.pop();
  attraction_data.push(data);
};

export const getOneAttraction = () => {
  if (attraction_data.length > 0) return attraction_data[0];
  return null;
};

export const pushOneTradeOrganization = (data) => {
  trade_organization_data.pop();
  trade_organization_data.push(data);
};

export const getOneTradeOrganization = () => {
  if (trade_organization_data.length > 0) return trade_organization_data[0];
  return null;
};

export const pushOneClimate = (data) => {
  climate_data.pop();
  climate_data.push(data);
};

export const getOneClimate = () => {
  if (climate_data.length > 0) return climate_data[0];
  return null;
};

export const pushOneEthnicGroup = (data) => {
  ethnic_group_data.pop();
  ethnic_group_data.push(data);
};

export const getOneEthnicGroup = () => {
  if (ethnic_group_data.length > 0) return ethnic_group_data[0];
  return null;
};

export const pushOneLanguage = (data) => {
  language_data.pop();
  language_data.push(data);
};

export const getOneLanguage = () => {
  if (language_data.length > 0) return language_data[0];
  return null;
};

export const pushOneReligion = (data) => {
  religion_data.pop();
  religion_data.push(data);
};

export const getOneReligion = () => {
  if (religion_data.length > 0) return religion_data[0];
  return null;
};

export const pushOnePlugImage = (data) => {
  plug_image_data.pop();
  plug_image_data.push(data);
};

export const getOnePlugImage = () => {
  if (plug_image_data.length > 0) return plug_image_data[0];
  return null;
};

// Other

export const pushEditLevel = (data) => {
  editLevel.pop();
  editLevel.push(data);
};

export const getEditLevel = () => {
  if (editLevel.length > 0) return editLevel[0];
  return null;
};

export const pushAddLevel = (data) => {
  addLevel.pop();
  addLevel.push(data);
};

export const getAddLevel = () => {
  if (addLevel.length > 0) return addLevel[0];
  return null;
};

export const pushSingleLevelDashboard = (data) => {
  SingleLevelDashboard.pop();
  SingleLevelDashboard.push(data);
};

export const getSingleLevelDashboard = () => {
  if (SingleLevelDashboard.length > 0) {
    return SingleLevelDashboard[0];
  }
  return "Level";
};

export const pushCityDashboard = (data) => {
  cityDashboard.pop();
  cityDashboard.push(data);
};

export const getCityDashboard = () => {
  if (cityDashboard.length > 0) {
    return cityDashboard[0];
  }
  return "city";
};

export const pushSingleCountryDashboard = (data) => {
  SingleCountryDashboard.pop();
  SingleCountryDashboard.push(data);
};

export const getSingleCountryDashboard = () => {
  if (SingleCountryDashboard.length > 0) {
    return SingleCountryDashboard[0];
  }
  return "Country";
};

export const pushCurrentRegion = (data) => {
  CurrentRegion.pop();
  CurrentRegion.push(data);
};

// export const pushCurrentCountry = (data) => {
//   CurrentCountry.pop();
//   CurrentCountry.push(data);
// };

export const getCurrentRegion = () => CurrentRegion;

// export const getCurrentCountry = () => CurrentCountry;

export const pushRegion = (data) => {
  SysRegionNavigation.pop();
  SysRegionNavigation.push(data);
};

export const pushCountry = (data) => {
  SysCountryNavigation.pop();
  SysCountryNavigation.push(data);
};

export const pushLevel = (data) => {
  SysLevelNavigation.pop();
  SysLevelNavigation.push(data);
};

export const getRegionName = () => {
  if (SysRegionNavigation.length > 0) {
    return SysRegionNavigation[0].name;
  }
  return "Regions";
};

export const getRegion = () => {
  return SysRegionNavigation[0];
};

export const getCountryName = () => {
  if (SysCountryNavigation.length > 0) {
    return SysCountryNavigation[0].country.name;
  }
  return "Countries";
};

export const getCountry = () => {
  if (SysCountryNavigation.length > 0) {
    return SysCountryNavigation[0];
  }
  return null;
};

export const getLevelName = () => {
  return SysLevelNavigation[0].level.name;
};

export const getLevelRoute = () => {
  return SysLevelNavigation[0].level.route;
};

export const displayCountry = () => {
  if (SysRegionNavigation.length > 0 && SysRegionNavigation[0] !== null) {
    return true;
  }
  return false;
};

export const displayRegion = () => {
  if (SysCountryNavigation.length > 0 && SysRegionNavigation.length > 0) {
    return true;
  }
  return false;
};

export const displayLevel = () => {
  if (SysLevelNavigation.length > 0) {
    return true;
  }
  return false;
};

export const clearRoutingHistory = () => {
  CurrentRegion.pop();
  SysLevelNavigation.pop();
  SysRegionNavigation.pop();
  SingleLevelDashboard.pop();
  SysCountryNavigation.pop();
  SingleCountryDashboard.pop();
};
