import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { BannerProvider } from "../../../../context/banner/BannerContext";

export const Banner = () => {
  return (
    <BannerProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Banner"
          />
        }
      />
    </BannerProvider>
  );
};

export const AddBanner = () => {
  return (
    <BannerProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Banner"
          />
        }
      />
    </BannerProvider>
  );
};

export const EditBanner = () => {
  return (
    <BannerProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Banner"
          />
        }
      />
    </BannerProvider>
  );
};
