import React from "react";
import Add from "./Add";
import Edit from "./Edit";
import { TourPackageProvider } from "../../../context/tourPackage/TourPackageContext";
import { Auth } from "../../../context/admin/Authentication";
import Overview from "./Overview";
import Screen from "../../home/content/Screen";
import { CountryProvider } from "../../../context/country/countryContext/CountryContext";

export const TourPackage = () => {
  return (
    <TourPackageProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Tour Package"
          />
        }
      />
    </TourPackageProvider>
  );
};

export const AddTourPackage = () => {
  return (
    <TourPackageProvider>
      <CountryProvider>
        <Auth
          children={
            <Screen
              component={<Add />}
              title="Add Tour Package"
            />
          }
        />
      </CountryProvider>
    </TourPackageProvider>
  );
};

export const EditTourPackage = () => {
  return (
    <TourPackageProvider>
      <CountryProvider>
        <Auth
          children={
            <Screen
              component={<Edit />}
              title="Edit Tour Package"
            />
          }
        />
      </CountryProvider>
    </TourPackageProvider>
  );
};
