import React from "react";

import { Auth } from "../../../../context/admin/Authentication";
import { DashboardProvider } from "../../../../context/dashboard/context/DashboardContext";
import { ContinentRegionProvider } from "../../../../context/continentRegion/context/ContinentRegionContext";

import Dashboard from "./Dashboard";
import Screen from "../Screen";
import Overview from "./manage/Overview";
import AddRegion from "./manage/AddRegion";
import EditRegion from "./manage/EditRegion";
import SingleRegionStats from "./manage/SingleRegionStats";

import { getCurrentRegion } from "../../../../middlewares/sysNavigation/SysNavigation";

export const Regions = () => {
  return (
    <DashboardProvider>
      <Auth
        children={
          <Screen
            component={<Dashboard />}
            title="Continent Regions"
          />
        }
      />
    </DashboardProvider>
  );
};

export const RegionOverview = () => {
  return (
    <ContinentRegionProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Overview"
          />
        }
      />
    </ContinentRegionProvider>
  );
};

export const AddRegionComp = () => {
  return (
    <ContinentRegionProvider>
      <Auth
        children={
          <Screen
            component={<AddRegion />}
            title="Add Region"
          />
        }
      />
    </ContinentRegionProvider>
  );
};

export const EditRegionComp = () => {
  return (
    <ContinentRegionProvider>
      <Auth
        children={
          <Screen
            component={<EditRegion />}
            title={getRegionName()}
          />
        }
      />
    </ContinentRegionProvider>
  );
};

export const SingleRegionDashboard = () => {
  return (
    <ContinentRegionProvider>
      <Auth
        children={
          <Screen
            component={<SingleRegionStats />}
            title={getRegionName()}
          />
        }
      />
    </ContinentRegionProvider>
  );
};

const getRegionName = () => {
  let regionName = "Region";
  if (getCurrentRegion().length > 0) regionName = getCurrentRegion()[0].name;
  return regionName;
};
