import React, { createContext, useContext, useState } from "react";
import { useAdminContext } from "../admin/context/AdminContext";
import Service from "../../api/service";

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = (props) => {
  const [users, setUsers] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateUserState = async (data) => {
    return setUsers(data);
  };

  /**
   * Fetch all users
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchUsers = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/auth/admin/user/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setUsers(data);
      setCount(count);
    });
  };

  /**
   * Update a user profile
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateUserProfile = async (user_id, data) => {
    const url = `/api/v1/auth/admin/user/update/profile/${user_id}`;
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a user password
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateUserPassword = async (user_id, data) => {
    const url = `/api/v1/auth/admin/user/update/password/${user_id}`;
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Delete a user
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteUser = async (user_id) => {
    const url = `/api/v1/auth/admin/user/delete/${user_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <UserContext.Provider
      value={{
        users,
        fetchUsers,
        updateUserProfile,
        updateUserPassword,
        deleteUser,
        updateUserState,
        count,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
