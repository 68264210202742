/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { _PAGE_SIZE_ } from "../../../../../middlewares/other/tools";
import { useBaseLevelContext } from "../../../../../context/levels/BaseLevelContext";
import {
  pushAddLevel,
  pushEditLevel,
  pushSingleLevelDashboard,
} from "../../../../../middlewares/sysNavigation/SysNavigation";
import {
  Modal,
  MyButton,
  MySearch,
  Pagination,
} from "../../../component/Component";

const Tabs = [
  { title: "First", option: true, route: "/levels/first" },
  { title: "Second", option: false, route: "/levels/second" },
  { title: "Third", option: false, route: "/levels/third" },
  { title: "Fourth", option: false, route: "/levels/fourth" },
];

const addRoute = "/level/add";
const editRoute = "/level/edit";
const contentRoute = "/dosee/data";
const dashboardRoute = "/level/dashboard";

const Overview = () => {
  const [localLevels, setLocalLevels] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("first");
  const { fetchLevels, allLevels, count, setTemporaryLevelName, setAllLevels } =
    useBaseLevelContext();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (searchKey === "") {
      if (allLevels) setLocalLevels(allLevels);
      else {
        fetchLevels({ page: 1, pageSize: 50, level: selectedLevel });
      }
    }
  }, [localLevels, allLevels]);

  const search = (key) => {
    if (key === "") {
      setLocalLevels(allLevels);
      setSearchKey("");
    } else if (allLevels) {
      const array = [];
      allLevels?.map((item) => {
        if (String(item?.name)?.toLowerCase()?.includes(key)) {
          return array?.push(item);
        }
        return true;
      });
      setLocalLevels(array);
    }
  };

  return (
    <div className="overflow-y-auto p-3 h-full rounded-b-xl bg-gray-50 w-full">
      <div className="flex flex-col w-full">
        <div className="w-full py-5 flex space-x-5 justify-center items-center">
          {Tabs?.map((tab, key) => (
            <Link
              to={tab.route}
              key={key}
              onClick={() => {
                setSelectedLevel(tab?.title);
                setAllLevels(null);
                setLocalLevels(null);
                setSearchKey("");
              }}
              className={`px-4 py-2 text-xs text-white font-bold capitalize ${
                selectedLevel === tab?.title ? "bg-primary" : "bg-black"
              }`}
            >
              {tab?.title}
            </Link>
          ))}
        </div>

        <div className="p-3 lg:p-4 h-full rounded-b-xl bg-gray-50">
          <div className="flex flex-col justify-center mt-2">
            <MySearch
              showGlobalSearch={false}
              onChange={(e) => {
                setSearchKey(e.target.value);
                search(e.target.value);
              }}
            />

            <div className="w-full flex justify-center md:justify-end mt-4 mb-3">
              <Link to={addRoute}>
                <MyButton
                  title={`Add ${selectedLevel} Level`}
                  className="bg-gray-800 hover:bg-gray-600 min-w-fit"
                  onClick={() => {
                    pushAddLevel(selectedLevel);
                    setTemporaryLevelName(selectedLevel);
                  }}
                />
              </Link>
            </div>

            <div className="py-5 overflow-y-auto w-full">
              <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
                <thead>
                  <tr className="space-x-2">
                    <th className="border p-2 lg:px-4 font-bold w-1/2">Name</th>
                    <th className="border text-center w-1/2">Action</th>
                  </tr>
                </thead>
                <tbody className="space-x-2 text-left border-gray-300">
                  {localLevels ? (
                    localLevels?.map((item, key) => (
                      <tr key={key}>
                        <td className="border px-2 lg:px-4 w-1/2">
                          {item?.name}
                        </td>
                        <td className="border w-1/2">
                          <CallToAction
                            item={item}
                            currentLevel={selectedLevel}
                            page={page}
                            pageSize={pageSize}
                            item_id={
                              selectedLevel === "first"
                                ? item?.first_level_id
                                : selectedLevel === "second"
                                ? item?.second_level_id
                                : selectedLevel === "third"
                                ? item?.third_level_id
                                : item?.fourth_level_id
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="border px-2 lg:px-4 w-1/2">...</td>
                      <td className="border px-2 lg:px-4 w-1/2">...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              total_items={count}
              callbackFunction={(_p, _pSize) => {
                fetchLevels({
                  page: _p,
                  pageSize: _pSize,
                  level: selectedLevel,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CallToAction = ({ item, item_id, currentLevel, page, pageSize }) => {
  const [showModal, setShowModal] = useState(false);
  const { deleteOne, fetchLevels, setTemporaryLevel } = useBaseLevelContext();

  const deleteOneLevel = async () => {
    const { status } = await deleteOne({
      level_id: item_id,
      level: currentLevel,
    });
    if (status === 200) {
      setShowModal(false);
      fetchLevels({
        page,
        pageSize,
        level: currentLevel,
      });
    }
  };

  return (
    <>
      <Modal
        props={{
          name: item?.name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneLevel(),
        }}
      />
      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={dashboardRoute}>
          <MyButton
            title="Dashboard"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => pushSingleLevelDashboard(item)}
          />
        </Link>
        <Link to={contentRoute}>
          <MyButton
            title="Content"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => {
              pushAddLevel(currentLevel);
              setTemporaryLevel({
                ...item,
                currentLevel,
              });
            }}
          />
        </Link>
        <Link to={editRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600 min-w-fit"
            onClick={() => {
              pushAddLevel(currentLevel);
              pushEditLevel({ ...item });
              setTemporaryLevel({
                ...item,
                currentLevel,
              });
            }}
          />
        </Link>
        <MyButton
          title="Delete"
          className="bg-red-800 hover:bg-red-600 min-w-fit"
          onClick={() => setShowModal(true)}
        />
      </div>
    </>
  );
};

export default Overview;
