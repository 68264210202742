import React from "react";
import AdminComponent from "./AdminComponent";

const Overview = () => {
  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <div className="mb-5 overflow-y-auto w-full py-2">
          <AdminComponent />
        </div>
      </div>
    </div>
  );
};

export default Overview;
