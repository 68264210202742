import React, { useState } from "react";
import { useUserContext } from "../../../../context/user/UserContext";
import { getOneUser } from "../../../../middlewares/sysNavigation/SysNavigation";
import { validatePassword } from "../../../../controllers/user/userControllers";
import {
  Title,
  GoBack,
  MyMessage,
  InputField,
  PhoneInput,
  MyActionButton,
} from "../../component/Component";

const Edit = () => {
  const { updateUserProfile, updateUserPassword, updateUserState } =
    useUserContext();

  const user = getOneUser();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error2, setError2] = useState(false);
  const [success2, setSuccess2] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [message2, setErrorMessage2] = useState("");

  const user_id = user.user_id;

  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phone, setPhone] = useState(user.phonenumber);
  const [email, setPrice] = useState(user.email);
  const [password, setPassword] = useState(null);
  const [confirm_password, setConfirmPassword] = useState(null);

  const userRoutes = "/user";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
  };

  const cleanPasswordFields = () => {
    setLoading2(false);
    setSuccess2(true);
  };

  const responsesPassword = (isError, status, message) => {
    // Set Message and Status
    setError2(isError);
    setErrorMessage2(message);
  };

  const responses = (isError, status, message) => {
    // Set Message and Status
    setError(isError);
    setErrorMessage(message);
  };

  // Update user password
  const updatePasswordHandler = async (e) => {
    e.preventDefault();
    setLoading2(true);
    const validation = validatePassword(password, confirm_password);

    if (validation.isError) {
      setLoading2(false);
      responsesPassword(
        validation.isError,
        validation.status,
        validation.message
      );
    } else if (password !== confirm_password) {
      setLoading2(false);
      responsesPassword(true, 400, "Passwords Should Match");
    } else {
      await sendDBPasswordRequest();
    }
  };

  const updateOneUserHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!firstName || firstName === "") {
      responses(true, 400, "First Name is required");
    } else if (!lastName || lastName === "") {
      responses(true, 400, "Last Name is required");
    } else if (!email || email === "") {
      responses(true, 400, "Email is required");
    } else if (!phone || phone === "") {
      responses(true, 400, "Phone is required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBPasswordRequest = async () => {
    const data = { password: password };
    const { status, message } = await updateUserPassword(user_id, data);
    if (status === 200) {
      cleanPasswordFields();
      responsesPassword(false, status, message);
    } else {
      setLoading2(false);
      responsesPassword(true, status, message);
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      phonenumber: phone,
      email: email,
    };

    const { status, message } = await updateUserProfile(user_id, data);
    if (status === 200) {
      updateUserState({ user_id, ...data });
      cleanFields();
      responses(true, status, message);
    } else {
      setLoading(false);
      responses(true, status, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: userRoutes }} />
      <MyMessage
        error={error || error2}
        message={message || message2}
        success={success || success2}
        onExpired={() => {
          setError(false);
          setSuccess(false);
          setError2(false);
          setSuccess2(false);
        }}
        onClick={() => {
          setError(false);
          setSuccess(false);
          setErrorMessage("");
          setError2(false);
          setSuccess2(false);
          setErrorMessage2("");
        }}
      />

      <form onSubmit={updateOneUserHandler}>
        <div className="mt-2 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Title props={{ title: "1. Update Profile" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "firstName",
                title: "First Name",
                type: "text",
                value: firstName ? firstName : "",
                onChange: (e) => {
                  setFirstName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "lastName",
                title: "Last Name",
                type: "text",
                value: lastName ? lastName : "",
                onChange: (e) => {
                  setLastName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <PhoneInput
              setPhone={setPhone}
              phone={phone}
              required={true}
            />

            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                value: email ? email : "",
                onChange: (e) => {
                  setPrice(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Update Profile"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
              setError2(false);
              setSuccess2(false);
              setErrorMessage2("");
            }}
          />
        </div>
      </form>

      <form onSubmit={updatePasswordHandler}>
        <div className="mt-2 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Title props={{ title: "2. Update Password" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "password",
                title: "Password",
                type: "text",
                value: password ? password : "",
                onChange: (e) => {
                  setPassword(e.target.value);
                  setError2(null);
                  setSuccess2(null);
                },
              }}
            />
            <InputField
              props={{
                id: "confirm_password",
                title: "Confirm Password",
                type: "text",
                value: confirm_password ? confirm_password : "",
                onChange: (e) => {
                  setConfirmPassword(e.target.value);
                  setError2(null);
                  setSuccess2(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading2}
            title="Update Password"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
              setError2(false);
              setSuccess2(false);
              setErrorMessage2("");
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
