import React from "react";
import { Pagination } from "../../component/Pagination";

const MyAdminComponent = ({
  page,
  count,
  admins,
  setPage,
  setTotal,
  pageSize,
  setPageSize,
  currentYear,
  currentMonth,
  currentAdmin,
  loadStatistics,
  setCurrentAdmin,
  setLocalSetStats,
  getAllByAdminCompanyId,
}) => {
  return (
    <div className="flex flex-col justify-between space-y-6 border-2 border-gray-200 p-4">
      <div className="flex flex-col space-y-2">
        <p className="text-sm md:text-base font-bold uppercase">My Admins</p>
        <div className="flex flex-col space-y-2">
          {admins ? (
            admins?.map((adm, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    setCurrentAdmin(key);
                    setLocalSetStats(null);
                    setTotal(0);
                    loadStatistics({
                      year: currentYear,
                      month: currentMonth,
                      admin: adm?.admin_id,
                    });
                  }}
                  className={`border border-gray-800 px-2 py-4 hover:bg-gray-800 hover:text-white cursor-pointer ${
                    key === currentAdmin ? "bg-gray-800 text-white" : "bg-white"
                  }`}
                >
                  <p className="font-bold text-xs md:text-xs">
                    {`${String(adm?.first_name)}  ${String(adm?.last_name)}`}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="w-full justify-center items-center text-xs text-gray-800">
              <p>Loading...</p>
            </div>
          )}
        </div>
      </div>

      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total_items={count}
        callbackFunction={(p, size) => {
          getAllByAdminCompanyId(p, size);
        }}
      />
    </div>
  );
};

export default MyAdminComponent;
