import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Button } from "../../../home/component/ButtonUtils";
import { InputField } from "../../../home/component/FormUtils";

const ModalDetails = ({ showModal, onClick, collection, setCollection }) => {
  //   const [collection, setCollection] = useState([]);

  return (
    <div
      className={
        showModal ? "block w-full h-full overflow-y-auto mx-auto" : "hidden"
      }
    >
      <div className="justify-center w-fit p-10 md:w-full h-full items-center flex absolute inset-0 z-[999999] outline-none focus:outline-none">
        <div className="relative w-full max-h-screen h-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full overflow-y-auto h-full max-h-max bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative rounded-lg shadow p-4 w-full">
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                  onClick={onClick}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={onClick}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="flex flex-col justify-start items-start p-6 pt-0 text-center w-full">
                <p className="text-xl font-bold">Surroundings</p>
                <div className="flex flex-col space-y-5 py-5 w-full">
                  <InputField
                    props={{
                      id: "What's nearby",
                      title: "What's nearby (Comma Separated)",
                      type: "text",
                      value: collection
                        ? collection?.find(
                            (value) => value.type === "What's nearby"
                          )?.description || ""
                        : "",
                      onChange: (e) => {
                        addSurrounding({
                          category: "surroundings",
                          collection,
                          setCollection,
                          type: "What's nearby",
                          value: e.target.value,
                        });
                      },
                    }}
                  />
                  <InputField
                    props={{
                      id: "Restaurants & cafes",
                      title: "Restaurants & cafes (Comma Separated)",
                      type: "text",
                      value: collection
                        ? collection?.find(
                            (value) => value.type === "Restaurants & cafes"
                          )?.description || ""
                        : "",
                      onChange: (e) => {
                        addSurrounding({
                          category: "surroundings",
                          collection,
                          setCollection,
                          type: "Restaurants & cafes",
                          value: e.target.value,
                        });
                      },
                    }}
                  />
                  <InputField
                    props={{
                      id: "Public transport",
                      title: "Public transport (Comma Separated)",
                      type: "text",
                      value: collection
                        ? collection?.find(
                            (value) => value.type === "Public transport"
                          )?.description || ""
                        : "",
                      onChange: (e) => {
                        addSurrounding({
                          category: "surroundings",
                          collection,
                          setCollection,
                          type: "Public transport",
                          value: e.target.value,
                        });
                      },
                    }}
                  />
                  <InputField
                    props={{
                      id: "Top attractions",
                      title: "Top attractions (Comma Separated)",
                      type: "text",
                      value: collection
                        ? collection?.find(
                            (value) => value.type === "Top attractions"
                          )?.description || ""
                        : "",
                      onChange: (e) => {
                        addSurrounding({
                          category: "surroundings",
                          collection,
                          setCollection,
                          type: "Top attractions",
                          value: e.target.value,
                        });
                      },
                    }}
                  />
                  <InputField
                    props={{
                      id: "Closest airports",
                      title: "Closest airports (Comma Separated)",
                      type: "text",
                      value: collection
                        ? collection?.find(
                            (value) => value.type === "Closest airports"
                          )?.description || ""
                        : "",
                      onChange: (e) => {
                        addSurrounding({
                          category: "surroundings",
                          collection,
                          setCollection,
                          type: "Closest airports",
                          value: e.target.value,
                        });
                      },
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start items-start p-6 pt-0 text-center w-full">
                <p className="text-xl font-bold">Facilities</p>
                <div className="flex flex-col justify-start items-start space-y-5 py-5 w-full">
                  <InputField
                    props={{
                      id: "Languages spoken",
                      title: "Languages spoken (Comma Separated)",
                      type: "text",
                      value: collection
                        ? collection?.find(
                            (value) => value.type === "Languages spoken"
                          )?.description || ""
                        : "",
                      onChange: (e) => {
                        addSurrounding({
                          category: "facilities",
                          collection,
                          setCollection,
                          type: "Languages spoken",
                          value: e.target.value,
                        });
                      },
                    }}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
                    {Facilities.map((facility, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full flex flex-col justify-start items-start"
                        >
                          <p className="text-black font-bold text-lg">
                            {facility.title}
                          </p>
                          <div className="flex flex-col space-y-2 my-2">
                            {facility.data.map((value, key) => {
                              return (
                                <div
                                  key={key}
                                  className="flex text-sm space-x-2"
                                >
                                  {collection.find(
                                    (val) => val?.description === value
                                  ) ? (
                                    <MdCheckBox
                                      onClick={() =>
                                        removeFacility({
                                          collection,
                                          setCollection,
                                          value,
                                        })
                                      }
                                      className="text-primary text-xl"
                                    />
                                  ) : (
                                    <MdCheckBoxOutlineBlank
                                      onClick={() =>
                                        addFacility({
                                          category: "facilities",
                                          collection,
                                          setCollection,
                                          type: facility.title,
                                          value,
                                        })
                                      }
                                      className="text-black text-xl"
                                    />
                                  )}

                                  <p>{value}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="w-full flex justify-center items-center py-8">
                  <Button
                    props={{
                      type: "button",
                      title: "Finish",
                      category: "default",
                      action: "default",
                      onClick: () => onClick(),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed w-full h-full inset-0 z-[100] bg-black"></div>
    </div>
  );
};

export default ModalDetails;

const removeFacility = ({ collection, setCollection, value }) => {
  const data = collection?.filter((input) => input?.description !== value);
  setCollection([...data]);
};

const addFacility = ({ collection, setCollection, value, category, type }) => {
  const data = collection?.filter((input) => input?.description !== value);

  const inputData = collection
    ? [...data, { category, type, description: value, status: 1 }]
    : [{ category, type, description: value, status: 1 }];

  const filteredData = inputData?.filter((coll) => coll?.description !== "");

  setCollection(filteredData);
};

const addSurrounding = ({
  collection,
  setCollection,
  value,
  category,
  type,
}) => {
  const data = collection?.filter(
    (coll) => coll?.type !== type && coll?.description !== ""
  );

  const inputData = collection
    ? [...data, { category, type, description: value, status: 1 }]
    : [{ category, type, description: value, status: 1 }];

  const filteredData = inputData?.filter((coll) => coll?.description !== "");

  setCollection(filteredData);
};

const Facilities = [
  {
    title: "Popular",
    data: [
      "Free WiFi",
      "Airport shuttle",
      "Fitness centre",
      "Restaurant",
      "Family rooms",
      "Spa and wellness centre",
      "Bar",
    ],
  },
  {
    title: "Outdoors",
    data: ["Outdoor furniture", "Sun terrace", "Terrace"],
  },
  {
    title: "Activities",
    data: [
      "Happy hour",
      "Themed dinner nights",
      "Walking tours",
      "Evening entertainment",
      "Squash",
      "Horse riding",
      "Children's playground",
      "Casino",
      "Fishing",
      "Golf course (within 3 km)",
      "Tennis court",
    ],
  },
  {
    title: "Food & Drink",
    data: [
      "Coffee house on site",
      "Fruits",
      "Wine/champagne",
      "Kid meals",
      "Special diet menus (on request)",
      "Snack bar",
      "Breakfast in the room",
      "Bar",
    ],
  },
  {
    title: "Internet",
    data: ["WiFi is available in all areas and is free of charge."],
  },
  {
    title: "Parking",
    data: ["No parking available.", "Parking available."],
  },
  {
    title: "Reception services",
    data: [
      "Invoice provided",
      "Lockers",
      "Private check-in/check-out",
      "Concierge service",
      "Luggage storage",
      "Express check-in/check-out",
      "24-hour front desk",
    ],
  },
  {
    title: "Cleaning services",
    data: [
      "Daily housekeeping",
      "Trouser press",
      "Ironing service",
      "Dry cleaning",
      "Laundry",
    ],
  },
  {
    title: "Business facilities",
    data: ["Fax/photocopying", "Business centre", "Meeting/banquet facilities"],
  },
  {
    title: "Safety & security",
    data: [
      "Fire extinguishers",
      "CCTV outside property",
      "CCTV in common areas",
      "Smoke alarms",
      "Key card access",
      "Key access",
      "24-hour security",
      "Safety deposit box",
    ],
  },
  {
    title: "General",
    data: [
      "Shuttle service",
      "Minimarket on site",
      "Shared lounge/TV area",
      "Designated smoking area",
      "Air conditioning",
      "Non-smoking throughout",
      "Car hire",
      "Packed lunches",
      "Lift",
      "Family rooms",
      "Barber/beauty shop",
      "Facilities for disabled guests",
      "Airport shuttle",
      "Non-smoking rooms",
      "Room service",
    ],
  },
  {
    title: "Accessibility",
    data: [
      "Lower bathroom sink",
      "Higher level toilet",
      "Toilet with grab rails",
      "Wheelchair accessible",
    ],
  },
  {
    title: "Wellness",
    data: [
      "Fitness",
      "Massage chair",
      "Body treatments",
      "Hair styling",
      "Hair colouring",
      "Hair cut",
      "Pedicure",
      "Manicure",
      "Hair treatments",
      "Beauty Services",
      "Sun loungers or beach chairs",
      "Massage",
      "Spa and wellness centre",
      "Fitness centre",
    ],
  },
];
