import React from "react";

import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { StaticProvider } from "../../../../context/static/StaticContext";

export const Terms = () => {
  return (
    <StaticProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Terms & Conditions"
          />
        }
      />
    </StaticProvider>
  );
};

export const EditTerms = () => {
  return (
    <StaticProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Terms & Conditions"
          />
        }
      />
    </StaticProvider>
  );
};
