import React, { useState } from "react";
import {
  Title,
  GoBack,
  InputField,
  MyMessage,
  MyActionButton,
} from "../../component/Component";
import { useCurrencyContext } from "../../../../context/currency/CurrencyContext";

const Add = () => {
  const { createOneCurrency } = useCurrencyContext();
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [title, setTitle] = useState(null);
  const [code, setCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [exchangeRateToUsd, setExchangeRateToUsd] = useState(null);

  const currencyRoutes = "/currency";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setTitle(null);
    setCountry(null);
    setExchangeRateToUsd(null);
    setCode(null);
  };

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const addOneCurrencyHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!title || title === "") {
      setLoading(false);
      return responses(true, "Currency Name is Required");
    } else if (!code || code === "") {
      setLoading(false);
      return responses(true, "Currency Code is Required");
    } else if (!exchangeRateToUsd || exchangeRateToUsd === "") {
      setLoading(false);
      return responses(true, "Exchange Rate is Required");
    } else if (!country || country === "") {
      setLoading(false);
      return responses(true, "Country is Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      name: title,
      code: code,
      exchange_rate_to_usd: exchangeRateToUsd,
      country: country,
      status: 1,
    };

    const { status, message } = await createOneCurrency(data);
    if (status === 200) {
      cleanFields();
      responses(false, message);
    } else {
      setLoading(false);
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: currencyRoutes }} />
      <form onSubmit={addOneCurrencyHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Currency Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Currency Name",
                type: "text",
                required: true,
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "title",
                title: "Currency Code",
                type: "text",
                required: true,
                value: code ? code : "",
                onChange: (e) => {
                  setCode(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "title",
                title: "Exchange Rate To USD",
                type: "number",
                required: true,
                value: exchangeRateToUsd ? exchangeRateToUsd : "",
                onChange: (e) => {
                  setExchangeRateToUsd(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "title",
                title: "Country",
                type: "text",
                required: true,
                value: country ? country : "",
                onChange: (e) => {
                  setCountry(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Currency"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
