/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { VscFileSubmodule } from "react-icons/vsc";
import { BsBook, BsPeople } from "react-icons/bs";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import {
  MdShop,
  MdMusicNote,
  MdOutlineMuseum,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFoodBank,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { SiHotelsdotcom } from "react-icons/si";
import { AiOutlineShopping } from "react-icons/ai";
import { BiCameraMovie } from "react-icons/bi";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { Card, GoBack, DashboardIntro } from "../../../component/Component";
import {
  pushLevel,
  getSingleCountryDashboard,
} from "../../../../../middlewares/sysNavigation/SysNavigation";
import { useCountryContext } from "../../../../../context/country/countryContext/CountryContext";
import { Link, useParams } from "react-router-dom";
import { addOneLevel } from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../../context/admin/context/AdminContext";
import { getType } from "../../contentStats/helper";
import { _TYPES_ } from "../../../../doseeData/utils/types";

const regionCountriesRoute = "/countries";

const CountryDashboardComponent = () => {
  const { country_id } = useParams();
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const { countryStats, fetchCountryStats } = useCountryContext();

  useEffect(() => {
    if (!countryStats) fetchCountryStats(country_id);
  }, [countryStats]);

  const List = [
    {
      name: "First Level",
      quantity: countryStats ? countryStats?.first_level : "-",
      route: "/levels/first",
    },
    {
      name: "Second Level",
      quantity: countryStats ? countryStats?.second_level : "-",
      route: "/levels/second",
    },
    {
      name: "Third Level",
      quantity: countryStats ? countryStats?.third_level : "-",
      route: "/levels/third",
    },
    {
      name: "Fourth Level",
      quantity: countryStats ? countryStats?.fourth_level : "-",
      route: "/levels/fourth",
    },
    {
      name: "events",
      quantity: countryStats ? countryStats?.event : "-",
      route: "/data/event",
      key: _TYPES_.EVENT,
    },
    {
      name: "attractions",
      quantity: countryStats ? countryStats?.attraction : "-",
      route: "/data/attraction",
      key: _TYPES_.ATTRACTION,
    },
    {
      name: "restaurants",
      quantity: countryStats ? countryStats?.restaurant : "-",
      route: "/data/restaurant",
      key: _TYPES_.RESTAURANT,
    },
    {
      name: "best of africa",
      quantity: countryStats ? countryStats?.best_of_africa : "-",
      route: "/data/bestofafrica",
      key: _TYPES_.BESTOFAFRICA,
    },
    {
      name: "music",
      quantity: countryStats ? countryStats?.music : "-",
      route: "/data/music",
      key: _TYPES_.MUSIC,
    },
    {
      name: "news",
      quantity: countryStats ? countryStats?.news : "-",
      route: "/data/news",
      key: _TYPES_.NEWS,
    },
    {
      name: "safaris",
      quantity: countryStats ? countryStats?.safari : "-",
      route: "/data/safari",
      key: _TYPES_.SAFARI,
    },
    {
      name: "tour packages",
      quantity: countryStats ? countryStats?.tour_package : "-",
      route: "/data/tour/package",
      key: _TYPES_.TOURPACKAGE,
    },
    {
      name: "cinema",
      quantity: countryStats ? countryStats?.cinema : "-",
      route: "/data/cinema",
      key: _TYPES_.CINEMA,
    },
    {
      name: "museums",
      quantity: countryStats ? countryStats?.museum : "-",
      route: "/data/museum",
      key: _TYPES_.MUSEUM,
    },
    {
      name: "night life",
      quantity: countryStats ? countryStats?.nightlife : "-",
      route: "/data/nightlife",
      key: _TYPES_.NIGHTLIFE,
    },
    {
      name: "hotels",
      quantity: countryStats ? countryStats?.hotel : "-",
      route: "/data/hotel",
      key: _TYPES_.HOTEL,
    },
    {
      name: "classes",
      quantity: countryStats ? countryStats?.class : "-",
      route: "/data/class",
      key: _TYPES_.CLASSES,
    },
    {
      name: "shopping",
      quantity: countryStats ? countryStats?.shopping : "-",
      route: "/data/shopping",
      key: _TYPES_.SHOPPING,
    },
    {
      name: "food",
      quantity: countryStats ? countryStats?.food : "-",
      route: "/data/food",
      key: _TYPES_.FOOD,
    },
    {
      name: "books",
      quantity: countryStats ? countryStats?.book : "-",
      route: "/data/book",
      key: _TYPES_.BOOK,
    },
    {
      name: "neighborhoods",
      quantity: countryStats ? countryStats?.neighborhood : "-",
      route: "/data/neighborhood",
      key: _TYPES_.NEIGHBORHOOD,
    },
    {
      name: "arts",
      quantity: countryStats ? countryStats?.art : "-",
      route: "/data/art",
      key: _TYPES_.ART,
    },
    {
      name: "dance",
      quantity: countryStats ? countryStats?.dance : "-",
      route: "/data/dance",
      key: _TYPES_.DANCE,
    },
    {
      name: "theaters",
      quantity: countryStats ? countryStats?.theater : "-",
      route: "/data/theater",
      key: _TYPES_.THEATER,
    },
    {
      name: "City Guides",
      quantity: countryStats ? countryStats?.city_guide : "-",
      route: "/data/city/guide",
      key: _TYPES_.CITYGUIDE,
    },
    {
      name: "Parties",
      quantity: countryStats ? countryStats?.party : "-",
      route: "/data/party",
      key: _TYPES_.PARTY,
    },
    {
      name: "Things To Do",
      quantity: countryStats ? countryStats?.things_to_do : "-",
      route: "/data/things/todo",
      key: _TYPES_.THINGSTODO,
    },
    {
      name: "Tips And Advice",
      quantity: countryStats ? countryStats?.tips_and_advice : "-",
      route: "/data/tips/advice",
      key: _TYPES_.TIPSANDADVICE,
    },
    {
      name: "Ministries",
      quantity: countryStats ? countryStats?.ministry : "-",
      route: "/data/ministry",
      key: _TYPES_.MINISTRY,
    },
    {
      name: "Movies",
      quantity: countryStats ? countryStats?.movie : "-",
      route: "/data/movie",
      key: _TYPES_.MOVIE,
    },
    {
      name: "Getting Around",
      quantity: countryStats ? countryStats?.getting_around : "-",
      route: "/data/get/around",
      key: _TYPES_.GETTINGAROUND,
    },
    {
      name: "Products",
      quantity: countryStats ? countryStats?.product : "-",
      route: "/data/product",
      key: _TYPES_.PRODUCT,
    },
  ];

  return (
    <div className="overflow-y-auto p-3 lg:p-4 h-full rounded-b-xl bg-gray-50 pb-5">
      <GoBack props={{ route: regionCountriesRoute }} />
      <div className="flex flex-row justify-center items-center my-4">
        <DashboardIntro
          props={{ intro: "Country", name: getSingleCountryDashboard() }}
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900 mb-5 mt-2">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                // We are excluding the first index since they are not pointing to
                if (
                  ![
                    "regions",
                    "countries",
                    "First Level",
                    "Second Level",
                    "Third Level",
                    "Fourth Level",
                  ].includes(card?.name)
                ) {
                  setSingleAdmin({
                    ids: [sysAdmin?.admin_id],
                    type: getType({ field: card?.key }),
                    route: `/country/dashboard/${country_id}`,
                  });
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: `${sysAdmin?.first_name} ${sysAdmin?.last_name}`,
                    route: "/levels/first",
                    level: "",
                    country_id: 0,
                    data: {
                      data: {
                        name: "",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                }
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CountryDashboardComponent;

const Icon = [
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
  <MdShop />,
];
