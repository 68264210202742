/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCityContext } from "../../../../context/levels/levelsContext";
import { useCompanyContext } from "../../../../context/company/companyContext";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { useAdminsContext } from "../../../../context/admin/context/AdminManageContext";
import { useCountryContext } from "../../../../context/country/countryContext/CountryContext";
import {
  Title,
  GoBack,
  MyMessage,
  InputField,
  PhoneInput,
  SelectInput,
  PasswordField,
  MyActionButton,
  SearchSelectInput,
} from "../../component/Component";
import { roles } from "./roles";
import { pushCountry } from "../../../../middlewares";
import { countriesList } from "../../component/data";

const Add = () => {
  const { sysAdmin } = useAdminContext();
  const { create, setAdmins } = useAdminsContext();
  const currentRole = sysAdmin?.role;

  const { cities, fetchCities } = useCityContext();
  const { company, setCompany } = useCompanyContext();
  const { countries, fetchCountries } = useCountryContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [country, setCountry] = useState("Uganda");
  const [city, setCity] = useState(company ? company?.city : null);
  const [role, setRole] = useState("Content Creator");
  const [address, setAddress] = useState(null);

  const adminRoutes = "/admin";

  useEffect(() => {
    if (!cities) fetchCities(1, 2000);
    if (!countries) fetchCountries();
  }, [cities, countries, country]);

  const cleanFields = () => {
    setLoading(false);
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPhone(null);
    setPassword(null);
    setAddress(null);
  };

  const responses = (error, message) => {
    setError(error);
    setLoading(false);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const addOneTourPackageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!first_name || first_name === "") {
      return responses(true, "first name is required");
    } else if (!last_name || last_name === "") {
      return responses(true, "last name is required");
    } else if (!email || email === "") {
      return responses(true, "email is required");
    } else if (!country || country === "") {
      return responses(true, "country is required");
    } else if (!phone || phone === "") {
      return responses(true, "phone number is required");
    } else if (!city || city === "") {
      return responses(true, "city is required");
    } else if (!address || address === "") {
      return responses(true, "address is required");
    } else if (!role || role === "") {
      return responses(true, "role is required");
    } else if (!password || password === "") {
      return responses(true, "password is required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} {}
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      phonenumber: phone,
      password: password,
      country: country,
      city: city,
      address: address,
      company_id: sysAdmin?.company_id,
      role: String(role)?.toLowerCase()?.replaceAll(" ", "_"),
    };

    const { message, status } = await create(data);
    if (status === 200 || status === 201) {
      cleanFields();
      setAdmins(null);
      setCompany(null);
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  const citiesFilter = () => {
    const getId = ({ data, key, value, obj_id }) => {
      return data?.find((x) => x?.[key] === value)?.[obj_id] || null;
    };
    const id = getId({
      key: "name",
      value: country,
      data: countries,
      obj_id: "country_id",
    });
    return cities?.filter((x) => x?.country_id === id).map((y) => y?.name);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: adminRoutes }} />
      <form onSubmit={addOneTourPackageHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <Title props={{ title: "Admin Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "first_name",
                title: "First Name",
                type: "text",
                required: true,
                value: first_name ? first_name : "",
                onChange: (e) => {
                  setFirstName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "last_name",
                title: "Last Name",
                type: "text",
                required: true,
                value: last_name ? last_name : "",
                onChange: (e) => {
                  setLastName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                required: true,
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <SelectInput
              title="Country"
              defaultValue={country ? country : null}
              required={true}
              value={country ? country : null}
              options={countries ? countries?.map((x) => x?.name) : []}
              onChange={(e) => {
                setCountry(e.target.value);
                setCity(null);

                const pickedCountry = countriesList?.find(
                  (_c) =>
                    String(_c.name).toLowerCase() ===
                    String(e.target.value).toLowerCase()
                );

                pushCountry({
                  country: {
                    id: pickedCountry?.id,
                    name: pickedCountry?.name,
                    slug: pickedCountry?.name,
                    route: "/admin",
                  },
                });
              }}
            />
            <PhoneInput
              phone={phone}
              required={true}
              setPhone={setPhone}
              country={country ? country : ""}
            />

            <SearchSelectInput
              title="City"
              setValue={setCity}
              value={city ? city : null}
              required={true}
              options={
                cities
                  ? country && !city
                    ? citiesFilter()
                    : cities?.map((x) => x?.name)
                  : []
              }
              onChange={(city) => setCity(city)}
            />

            <InputField
              props={{
                id: "address",
                title: "Address",
                type: "address",
                required: true,
                value: address ? address : "",
                onChange: (e) => {
                  setAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <SelectInput
              title="Admin Role"
              defaultValue={role}
              required={true}
              options={roles(currentRole)}
              onChange={(e) => {
                setRole(e.target.value);
                setError(null);
                setSuccess(null);
              }}
            />
            <PasswordField
              value={password}
              required={true}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
                setSuccess(null);
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add New Admin"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
