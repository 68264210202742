/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Search, Button } from "../../component/Component";
import { usePageContext } from "../../../../context/page/PageContext";
import { pushOnePage } from "../../../../middlewares/sysNavigation/SysNavigation";

const addRoute = "/page/add";
const editRoute = "/page/edit";
const Overview = () => {
  let [categoryList, setCategoryList] = useState([]);
  const { categories, fetchPages, page } = usePageContext();

  useEffect(() => {
    if (!categories) fetchPages(1, 1000);
    else setCategoryList(categories);
  }, [categories]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (categories !== null && categories !== undefined)
      categories.map((_cat_) => {
        if (_cat_.toString().toLowerCase().includes(key))
          return searchArray.push(_cat_);
        return true;
      });

    setCategoryList(searchArray);
    if (key === "") setCategoryList(categories);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Page Details",
            onChange: (e) => SearchList(e),
          }}
        />
        <div className="mb-5 overflow-y-auto w-full py-2">
          <div className="w-full flex justify-end my-5">
            <Link to={addRoute}>
              <Button
                props={{
                  type: "button",
                  title: "Add New Page",
                  category: "default",
                  action: "add",
                  onClick: null,
                }}
              />
            </Link>
          </div>

          <table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-full">
                  Category
                </th>
                <th className="border text-center px-1">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {categoryList !== null && categoryList !== undefined ? (
                categoryList.map((_page_, index) => (
                  <tr key={index}>
                    <td className="border px-2 lg:px-4 w-full">{_page_}</td>
                    <td className="border w-1/3">
                      <ActionButton
                        category={_page_}
                        pages={page}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4">...</td>
                  <td className="border px-2 lg:px-4">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ category, pages }) => {
  return (
    <>
      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <Button
            props={{
              type: "button",
              title: "Manage",
              category: "blue",
              action: "edit",
              onClick: () => {
                pushOnePage({ category, localPages: pages });
              },
            }}
          />
        </Link>
      </div>
    </>
  );
};

export default Overview;
