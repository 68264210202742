import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { PartnerProvider } from "../../../../context/partner/PartnerContext";

export const Partner = () => {
  return (
    <PartnerProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Partner"
          />
        }
      />
    </PartnerProvider>
  );
};

export const AddPartner = () => {
  return (
    <PartnerProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Partner"
          />
        }
      />
    </PartnerProvider>
  );
};

export const EditPartner = () => {
  return (
    <PartnerProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Partner"
          />
        }
      />
    </PartnerProvider>
  );
};
