import Service from "../../../api/service";
import React, { createContext, useContext, useState } from "react";
import { useAdminContext } from "../../admin/context/AdminContext";

const DashboardContext = createContext();
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardProvider = (props) => {
  const [stats, setStats] = useState(null);
  const [table, setTable] = useState(null);
  const { sysAdmin } = useAdminContext();

  const token = sysAdmin?.token;

  const fetchData = async () => {
    const url = "/api/v1/stats/country";
    await Service.get(url, token).then(({ data }) => {
      setStats(data);
    });
  };

  const getTableData = async () => {
    const url = `/api/v1/stats/table`;
    await Service.get(url, token).then(({ data }) => {
      setTable(data);
    });
  };

  return (
    <DashboardContext.Provider
      value={{ stats, fetchData, table, getTableData }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
