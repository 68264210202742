import React from 'react';

export const Title = ({ props }) => {
  return (
    <div className='mt-10 mb-4 w-full text-left'>
      <p className='text-base font-bold uppercase'>{props.title}</p>
      <hr className='border-[1px] border-gray-100 my-4' />
    </div>
  );
};

export const SubTitle = ({ props }) => {
  return (
    <div className='my-5 w-full text-center'>
      <p className='text-base font-bold uppercase'>{props.title}</p>
    </div>
  );
};
