import { driver } from "./http";

class Service {
  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} err
   * @returns
   * @memberof Service
   */
  static handleError = (err) => {
    if (!err?.response) {
      return {
        status: 400,
        message: "It seems you are offline. Try to check your Internet.",
      };
    }

    const { data } = err?.response;
    const { message, status } = data;

    if (message) return { message, status };
    if (typeof error !== "string") {
      return { message: "Unknown Error", status };
    }

    return { message, status };
  };

  /**
   *  @author Ntavigwa Bashombe
   * @param {*} response
   * @returns
   * @memberof Service
   */
  static resolveResponse = (response) => {
    const { data, message, status } = response.data;
    return { data, message, status };
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} token
   * @returns
   * @memberof Service
   */
  static requestHeaders = () => {
    const auth = localStorage.getItem("auth");
    let token = null;
    if (!auth || auth === "undefined") return null;
    else if (auth !== null && auth !== undefined) {
      token = JSON.parse(auth)?.token;
    }
    return { headers: { Authorization: `Bearer ${token ? token : ""}` } };
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} url
   * @returns
   * @memberof Service
   */
  static get = async (url) => {
    return await driver
      .get(url, this.requestHeaders())
      .then(this.resolveResponse)
      .catch(this.handleError);
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} url
   * @param {*} data
   * @returns
   * @memberof Service
   */
  static post = async (url, data) => {
    return await driver
      .post(url, data, this.requestHeaders())
      .then(this.resolveResponse)
      .catch(this.handleError);
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} url
   * @param {*} data
   * @returns
   * @memberof Service
   */
  static put = async (url, data) => {
    return await driver
      .put(url, data, this.requestHeaders())
      .then(this.resolveResponse)
      .catch(this.handleError);
  };

  /**
   *
   * @author Ntavigwa Bashombe
   * @param {*} url
   * @returns
   * @memberof Service
   */
  static delete = async (url) => {
    return await driver
      .delete(url, this.requestHeaders())
      .then(this.resolveResponse)
      .catch(this.handleError);
  };
}

export default Service;
