import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const EthnicGroupContext = createContext();

export const useEthnicGroupContext = () => useContext(EthnicGroupContext);

export const EthnicGroupProvider = (props) => {
  const [ethnicGroup, setEthnicGroup] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateEthnicGroupState = async (data) => {
    // await fetchEthnicGroups();
    return setEthnicGroup(data);
  };

  /**
   * Fetch all ethnicGroup
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchEthnicGroups = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/ethnic/group/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setEthnicGroup(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new ethnicGroup
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneEthnicGroup = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/admin/ethnic/group/create';
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a ethnicGroup
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateEthnicGroup = async (ethnicGroup_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/ethnic/group/update/${ethnicGroup_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a ethnicGroup
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteEthnicGroup = async (ethnicGroup_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/admin/ethnic/group/delete/${ethnicGroup_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <EthnicGroupContext.Provider
      value={{
        ethnicGroup,
        createOneEthnicGroup,
        fetchEthnicGroups,
        updateEthnicGroup,
        deleteEthnicGroup,
        updateEthnicGroupState,
        count,
      }}
    >
      {props.children}
    </EthnicGroupContext.Provider>
  );
};
