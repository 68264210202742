import React, { useEffect, useState } from "react";

import { useInfoContext } from "../../../../context/info/InfoContext";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";

import { Pagination } from "../../component/Component";

const Overview = () => {
  let [contactsList, setContactList] = useState([]);
  const { contacts, fetchContacts, count } = useInfoContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!contacts) fetchContacts(page, pageSize);
    setContactList(contacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/4">Fullname</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  Email/ Phone
                </th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Message</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  Date Sent
                </th>
                {/* <th className='border text-center'>Action</th> */}
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {contacts ? (
                (() => {
                  if (!contactsList) contactsList = [...contacts];
                  return contactsList.map((_contact_) => {
                    return (
                      <tr key={_contact_.contact_us_id}>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_contact_.fullname}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_contact_.email_address} <br />
                          {_contact_.phone_number}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {_contact_.subject}
                          <br /> {_contact_.message}
                        </td>
                        <td className="border px-2 lg:px-4 w-1/4">
                          {new Date(_contact_.createdAt).toUTCString()}
                        </td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchContacts(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

// /**
//  *  @author Ntavigwa Bashombe
//  * @param {*} props
//  * @returns
//  */
// const ActionButton = ({ props }) => {
//   const { setContact } = useInfoContext();

//   return (
//     <div className='flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs'>
//       <Button
//         props={{
//           type: 'button',
//           title: 'Mark as Read',
//           category: 'blue',
//           action: 'edit',
//           onClick: () => {
//             setContact(null);
//           },
//         }}
//       />
//     </div>
//   );
// };

export default Overview;
