import React from "react";

import Content from "./Content";
import Screen from "../Screen";
import TableStats from "./TableStats";

import { Auth } from "../../../../context/admin/Authentication";
import { DashboardProvider } from "../../../../context/dashboard/context/DashboardContext";

const DashBoard = () => {
  return (
    <DashboardProvider>
      <Auth
        children={
          <Screen
            component={<Content />}
            title="DASHBOARD"
          />
        }
      />
    </DashboardProvider>
  );
};

export const TableStatistics = () => {
  return (
    <DashboardProvider>
      <Auth children={<TableStats />} />
    </DashboardProvider>
  );
};

export default DashBoard;
