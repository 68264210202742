import React from "react";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { useAdminContext } from "../../../context/admin/context/AdminContext";

export const GoBack = ({ props }) => {
  const { singleAdmin, setSingleAdmin } = useAdminContext();
  const isBackToMain = String(props?.route) === "/dosee/data" && singleAdmin;
  return (
    <div className="w-full bg-white sticky -top-4 z-[99999]">
      <div className="flex flex-row bg-gray-800 px-2 py-5 my-1 sticky top-0 z-[99999] rounded-md">
        <Link
          to={isBackToMain ? singleAdmin?.route : props.route}
          onClick={() => {
            if (isBackToMain) {
              setSingleAdmin(null);
            }
          }}
        >
          <div className="flex items-center space-x-2 justify-center w-full py-1 text-white hover:text-gray-200">
            <MdArrowBackIosNew className="text-base" />
            <p className="text-xs font-bold capitalize hover:underline">
              Go Back
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export const GoBackTool = ({ route }) => {
  return (
    <div className="flex flex-row bg-gray-800 px-2 py-3 my-1 sticky top-0 z-[99999]">
      <Link to={route}>
        <div className="flex items-center space-x-2 justify-center w-full py-1 text-white hover:text-gray-200">
          <MdArrowBackIosNew className="text-base" />
          <p className="text-xs font-bold capitalize hover:underline">
            Go Back
          </p>
        </div>
      </Link>
    </div>
  );
};
