import React from "react";

import { Auth } from "../../../context/admin/Authentication";

import Screen from "../../home/content/Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";
import Detail from "./Details";

import { CountryProvider } from "../../../context/country/countryContext/CountryContext";
import { DoSeeDataProvider } from "../../../context/doSeeData/DoSeeDataContext";
import { PositionProvider } from "../../../context/position/PositionContext";

export const Attractions = () => {
  return (
    <DoSeeDataProvider>
      <PositionProvider>
        <Auth
          children={
            <Screen
              component={<Overview />}
              title="Attractions"
            />
          }
        />
      </PositionProvider>
    </DoSeeDataProvider>
  );
};

export const AddAttraction = () => {
  return (
    <CountryProvider>
      <DoSeeDataProvider>
        <Auth
          children={
            <Screen
              component={<Add />}
              title="Add Attraction"
            />
          }
        />
      </DoSeeDataProvider>
    </CountryProvider>
  );
};

export const EditAttraction = () => {
  return (
    <CountryProvider>
      <DoSeeDataProvider>
        <Auth
          children={
            <Screen
              component={<Edit />}
              title="Edit Attraction"
            />
          }
        />
      </DoSeeDataProvider>
    </CountryProvider>
  );
};

export const ViewAttraction = () => {
  return (
    <DoSeeDataProvider>
      <Auth
        children={
          <Screen
            component={<Detail />}
            title="Attraction Details"
          />
        }
      />
    </DoSeeDataProvider>
  );
};
