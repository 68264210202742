import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { pushOneEthnicGroup } from "../../../../middlewares/sysNavigation/SysNavigation";

import { useEthnicGroupContext } from "../../../../context/ethnicGroup/EthnicGroupContext";

import { Modal, Button, Search, Pagination } from "../../component/Component";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";

const addRoute = "/ethnic/group/add";
const editRoute = "/ethnic/group/edit";

const Overview = () => {
  let [ethnicGroupList, setEthnicGroupList] = useState([]);
  const { ethnicGroup, fetchEthnicGroups, count } = useEthnicGroupContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!ethnicGroup) fetchEthnicGroups(page, pageSize);
    setEthnicGroupList(ethnicGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethnicGroup]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (ethnicGroup !== null && ethnicGroup !== undefined)
      ethnicGroup.map((_ethnicGroup_) => {
        if (_ethnicGroup_.name.toString().toLowerCase().includes(key))
          return searchArray.push(_ethnicGroup_);
        return true;
      });

    setEthnicGroupList(searchArray);
    if (key === "") setEthnicGroupList(ethnicGroup);
  };

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Ethnic Group",
            onChange: (e) => SearchList(e),
          }}
        />

        <div className="w-full flex justify-end my-5">
          <Link to={addRoute}>
            <Button
              props={{
                type: "button",
                title: "Add Ethnic Group",
                category: "default",
                action: "add",
                onClick: null,
              }}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-base text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-full">Name</th>
                <th className="border text-center">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {ethnicGroup ? (
                (() => {
                  if (!ethnicGroupList) ethnicGroupList = [...ethnicGroup];
                  return ethnicGroupList.map((_ethnicGroup_) => {
                    return (
                      <tr key={_ethnicGroup_.ethnic_group_id}>
                        <td className="border px-2 lg:px-4 w-full">
                          {_ethnicGroup_.name}
                        </td>
                        <td className="border">
                          <ActionButton props={_ethnicGroup_} />
                        </td>
                      </tr>
                    );
                  });
                })()
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-full">Loading...</td>
                  <td className="border">Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchEthnicGroups(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

/**
 *  @author Ntavigwa Bashombe
 * @param {*} props
 * @returns
 */
const ActionButton = ({ props }) => {
  const { ethnicGroup, updateEthnicGroupState, deleteEthnicGroup } =
    useEthnicGroupContext();

  const [showModal, setShowModal] = useState(false);

  const ethnic_group_id = props.ethnic_group_id;
  const ethnicGroup_name = props.name;
  const _ethnicGroup_ = props;

  const deleteOneEthnicGroupById = async (ethnic_group_id) => {
    const response = await deleteEthnicGroup(ethnic_group_id);
    if (response.data.status === 200) {
      if (ethnicGroup) {
        // Update the ethnicGroup data
        const update = ethnicGroup.filter(
          (_ethnicGroup_) => _ethnicGroup_.ethnic_group_id !== ethnic_group_id
        );
        // Update the Global State of Regions
        updateEthnicGroupState(update);
        setShowModal(false);
      }
    }
  };

  return (
    <>
      <Modal
        props={{
          name: ethnicGroup_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneEthnicGroupById(ethnic_group_id),
        }}
      />

      <div className="flex flex-row justify-center items-center px-2 py-3 space-x-4 text-xs">
        <Link to={editRoute}>
          <Button
            props={{
              type: "button",
              title: "Edit",
              category: "blue",
              action: "edit",
              onClick: () => {
                pushOneEthnicGroup(_ethnicGroup_);
              },
            }}
          />
        </Link>
        <Button
          props={{
            type: "button",
            title: "Delete",
            category: "red",
            action: "delete",
            onClick: () => {
              setShowModal(true);
            },
          }}
        />
      </div>
    </>
  );
};

export default Overview;
