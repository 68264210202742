import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { BlogProvider } from "../../../../context/blog/BlogContext";
import { CountryProvider } from "../../../../context/country/countryContext/CountryContext";

export const Blog = () => {
  return (
    <BlogProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Blog"
          />
        }
      />
    </BlogProvider>
  );
};

export const AddBlog = () => {
  return (
    <BlogProvider>
      <CountryProvider>
        <Auth
          children={
            <Screen
              component={<Add />}
              title="Add Blog"
            />
          }
        />
      </CountryProvider>
    </BlogProvider>
  );
};

export const EditBlog = () => {
  return (
    <BlogProvider>
      <CountryProvider>
        <Auth
          children={
            <Screen
              component={<Edit />}
              title="Edit Blog"
            />
          }
        />
      </CountryProvider>
    </BlogProvider>
  );
};
