/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { VscFileSubmodule } from "react-icons/vsc";
import { BsBook, BsPeople } from "react-icons/bs";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import {
  MdMusicNote,
  MdOutlineMuseum,
  MdOutlineFoodBank,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { SiHotelsdotcom } from "react-icons/si";
import { AiOutlineShopping } from "react-icons/ai";
import { BiCameraMovie } from "react-icons/bi";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { Card, GoBack, DashboardIntro } from "../../../component/Component";
import {
  pushLevel,
  getCityDashboard,
} from "../../../../../middlewares/sysNavigation/SysNavigation";
import { useDashboardLevelContext } from "../../../../../context/levels/levelsContext";
import { setAdminOption } from "../../../../../middlewares";
import { addOneLevel } from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../../../context/admin/context/AdminContext";

const Icon = [
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
];

const citiesRoute = "/cities";

const DashboardCity = () => {
  const { sysAdmin } = useAdminContext();
  const data = getCityDashboard()[1];
  const city_level = getCityDashboard()[0];
  const { statsLevel, fetchLevelData } = useDashboardLevelContext();

  let level_id = null;
  const city_name = data.name;
  // Getting the corresponding level ID
  if (city_level === "First") level_id = data.first_level_id;
  else if (city_level === "Second") level_id = data.second_level_id;
  else if (city_level === "Third") level_id = data.third_level_id;
  else if (city_level === "Fourth") level_id = data.fourth_level_id;

  useEffect(() => {
    fetchLevelData(level_id, city_level);
  }, [statsLevel]);

  const List = [
    {
      name: "events",
      quantity: statsLevel ? statsLevel.event : "-",
      route: "/data/event",
    },
    {
      name: "attractions",
      quantity: statsLevel ? statsLevel.attraction : "-",
      route: "/data/attraction",
    },
    {
      name: "restaurants",
      quantity: statsLevel ? statsLevel.restaurant : "-",
      route: "/data/restaurant",
    },
    {
      name: "best of africa",
      quantity: statsLevel ? statsLevel.best_of_africa : "-",
      route: "/data/bestofafrica",
    },
    {
      name: "music",
      quantity: statsLevel ? statsLevel.music : "-",
      route: "/data/music",
    },
    {
      name: "news",
      quantity: statsLevel ? statsLevel.news : "-",
      route: "/data/news",
    },
    {
      name: "safaris",
      quantity: statsLevel ? statsLevel.safari : "-",
      route: "/data/safari",
    },
    {
      name: "tour packages",
      quantity: statsLevel ? statsLevel.tour_package : "-",
      route: "/data/tour/package",
    },
    {
      name: "cinema",
      quantity: statsLevel ? statsLevel.cinema : "-",
      route: "/data/cinema",
    },
    {
      name: "museums",
      quantity: statsLevel ? statsLevel.museum : "-",
      route: "/data/museum",
    },
    {
      name: "night life",
      quantity: statsLevel ? statsLevel.nightlife : "-",
      route: "/data/nightlife",
    },
    {
      name: "hotels",
      quantity: statsLevel ? statsLevel.hotel : "-",
      route: "/data/hotel",
    },
    {
      name: "classes",
      quantity: statsLevel ? statsLevel.class : "-",
      route: "/data/class",
    },
    {
      name: "shopping",
      quantity: statsLevel ? statsLevel.shopping : "-",
      route: "/data/shopping",
    },

    {
      name: "food",
      quantity: statsLevel ? statsLevel.food : "-",
      route: "/data/food",
    },

    {
      name: "books",
      quantity: statsLevel ? statsLevel.book : "-",
      route: "/data/book",
    },
    {
      name: "neighborhoods",
      quantity: statsLevel ? statsLevel.neighborhood : "-",
      route: "/data/neighborhood",
    },
    {
      name: "arts",
      quantity: statsLevel ? statsLevel.art : "-",
      route: "/data/art",
    },
    {
      name: "dance",
      quantity: statsLevel ? statsLevel.dance : "-",
      route: "/data/dance",
    },
    {
      name: "theaters",
      quantity: statsLevel ? statsLevel.theater : "-",
      route: "/data/theater",
    },
    {
      name: "City Guides",
      quantity: statsLevel ? statsLevel.city_guide : "-",
      route: "/data/city/guide",
    },
    {
      name: "Parties",
      quantity: statsLevel ? statsLevel.party : "-",
      route: "/data/party",
    },
    {
      name: "Things To Do",
      quantity: statsLevel ? statsLevel.things_to_do : "-",
      route: "/data/things/todo",
    },
    {
      name: "Tips And Advice",
      quantity: statsLevel ? statsLevel.tips_and_advice : "-",
      route: "/data/tips/advice",
    },

    {
      name: "Ministries",
      quantity: statsLevel ? statsLevel.ministry : "-",
      route: "/data/ministry",
    },
    {
      name: "Movies",
      quantity: statsLevel ? statsLevel.movie : "-",
      route: "/data/movie",
    },
    {
      name: "Getting Around",
      quantity: statsLevel ? statsLevel.getting_around : "-",
      route: "/data/get/around",
    },
  ];

  return (
    <div className="overflow-y-auto p-3 lg:p-4 h-full rounded-b-xl bg-gray-50 pb-5">
      <GoBack props={{ route: citiesRoute }} />
      <div className="flex flex-row justify-center items-center my-4">
        <DashboardIntro props={{ intro: `City`, name: city_name }} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900 mb-5 mt-4">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                // We are excluding the first index since they are not pointing to
                if (
                  ![
                    "regions",
                    "countries",
                    "First Level",
                    "Second Level",
                    "Third Level",
                    "Fourth Level",
                    "tour package",
                  ].includes(card.name)
                ) {
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: `${sysAdmin?.first_name} ${sysAdmin?.last_name}`,
                    route: "/levels/first",
                    level: "",
                    country_id: 0,
                    data: {
                      data: {
                        name: "",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                  setAdminOption(sysAdmin?.admin_id);
                } else {
                  setAdminOption(undefined);
                }
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardCity;
