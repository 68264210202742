import React, { createContext, useContext, useState } from "react";
import Service from "../../../api/service";
import { useAdminContext } from "./AdminContext";
import { useCompanyContext } from "../../company/companyContext";

const AdminsContext = createContext();
export const useAdminsContext = () => useContext(AdminsContext);
export const AdminsProvider = (props) => {
  const [count, setCount] = useState(0);
  const [admins, setAdmins] = useState(null);

  const { sysAdmin } = useAdminContext();
  const { company } = useCompanyContext();

  const token = sysAdmin?.token;
  /**
   * Fetch all admins
   *
   * @author Ntavigwa Bashombe
   * @returns any
   */
  const getAll = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/auth/admin/admins/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setAdmins(data);
      setCount(count);
    });
  };

  /**
   * Fetch all admins by admin id
   *
   * @author Ntavigwa Bashombe
   * @returns any
   */
  const getAllByAdminCompanyId = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/company/admins/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setAdmins(data);
      setCount(count);
    });
  };

  const parseQueryArray = () => {
    let IDs = "";
    const prep = "&fetch_by_admin[]=";
    const role = String(sysAdmin?.role);
    const currentAdminId = sysAdmin?.admin_id || undefined;

    const users = company?.Admins;

    if (["company_owner", "system_admin"].includes(role) && users) {
      users?.map(({ admin_id }) => (IDs += `${prep}${admin_id}`));
    } else if (users && ["content_admin"].includes(role)) {
      users?.map((x) =>
        x?.role === "content_creator" ? (IDs += `${prep}${x?.admin_id}`) : null
      );
    } else {
      IDs = `${prep}${currentAdminId}`;
    }

    return IDs;
  };

  /**
   * Add a new admin
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const create = async (data) => {
    const url = `/api/v1/auth/admin/signup`;
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update an admin profile
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateProfile = async (admin_id, data) => {
    const url = `/api/v1/auth/admin/update/profile/${admin_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Update an admin password
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updatePassword = async (admin_id, data) => {
    const url = `/api/v1/auth/admin/update/password/${admin_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete an admin
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteAdmin = async (admin_id) => {
    const url = `/api/v1/auth/admin/delete/${admin_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <AdminsContext.Provider
      value={{
        admins,
        count,
        setAdmins,
        getAll,
        deleteAdmin,
        create,
        updateProfile,
        updatePassword,
        parseQueryArray,
        getAllByAdminCompanyId,
      }}
    >
      {props.children}
    </AdminsContext.Provider>
  );
};
