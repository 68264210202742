/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import Service from "../../../../api/service";
import { Pagination } from "../../component/Pagination";
import { MyButton } from "../../component/ButtonUtils";
import { SiDatabricks } from "react-icons/si";
import { Data } from "./Data";
import { MdArrowBackIos } from "react-icons/md";

const NewsletterModal = ({
  loading,
  onSubmit,
  onClose,
  contentTitle,
  setContentTitle,
  dataIds,
  setDataIds,
}) => {
  const [data, setData] = useState(null);
  const [count, setCount] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [showMenu, setShowMenu] = useState(true);
  const [contentType, setContentType] = useState("");
  const [primaryKey, setPrimaryKey] = useState("");

  useEffect(() => {
    if (!data && contentType !== "") getData();
  }, [data, contentType, showMenu, contentTitle, dataIds]);

  const getData = async (currentPage, currentPageSize) => {
    setDataLoading(true);
    const response = await Service.get(
      `/api/v1${contentType}/?page=${currentPage || page}&pageSize=${
        currentPageSize || pageSize
      }&send_notification=0`
    );

    setDataLoading(false);

    if (response?.data?.data) {
      setData(response?.data?.data);
      setCount(response?.data?.count);
    }
  };

  const addToList = (content_id) => {
    if (!dataIds.includes(content_id)) {
      setDataIds([...dataIds, content_id]);
    }
  };

  const removeFromList = (content_id) => {
    setDataIds(dataIds?.filter((id) => id !== content_id));
  };

  return (
    <div className="bg-black/60 w-full h-full absolute z-[999999] top-0 left-0 flex justify-center items-start p-4 overflow-y-auto">
      <div className="bg-white p-5 rounded-md w-full md:w-1/2 lg:w-[500px] space-y-5 overflow-y-auto">
        <div className="flex items-center justify-between border-b pb-4">
          <div className="flex items-center space-x-2">
            {!showMenu ? (
              <button
                onClick={() => {
                  setContentType("");
                  setContentTitle("");
                  setPrimaryKey("");
                  setData(null);
                  setShowMenu(true);
                  setDataIds([]);
                }}
                className="bg-gray-500 text-white py-3 px-4 rounded-md cursor-pointer text-xs flex justify-center items-center"
              >
                <MdArrowBackIos />
              </button>
            ) : null}
            <span className="text-base font-bold">
              {contentTitle !== "" ? contentTitle : "Select Newsletter Content"}
            </span>
          </div>
          <button
            onClick={onClose}
            className="bg-black text-white p-2 rounded-md cursor-pointer text-2xl"
          >
            <FaTimes />
          </button>
        </div>

        {showMenu ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  w-full h-full text-white pb-2">
            {Data?.map((data, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    setShowMenu(false);
                    setContentType(data.link);
                    setContentTitle(data.name);
                    setPrimaryKey(data.primary_key);
                    setData(null);
                  }}
                  className="flex justify-center items-center w-full p-2 py-4 bg-white hover:bg-white/30 shadow text-gray-700 cursor-pointer hover:text-gray-800"
                >
                  <div className="flex flex-col space-y-2 items-start justify-start">
                    <div className="flex flex-row items-start justify-start space-x-2">
                      <SiDatabricks className="text-sm" />
                      <p className="text-xs font-bold">{data?.name}</p>
                    </div>
                    <MyButton
                      title="Manage"
                      className="w-full text-xs font-thin min-w-full text-left py-2 bg-gray-900 hover:bg-gray-600"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {!showMenu ? (
          <>
            <div className="w-full overflow-y-auto">
              <div className="mb-5 overflow-y-auto w-full py-2">
                <table className="table-auto w-full border-collapse border border-gray-300 ext-sm text-xs text-left">
                  <thead>
                    <tr className="space-x-2">
                      <th className="border p-2 lg:px-4 font-bold w-1/3">
                        Image
                      </th>
                      <th className="border p-2 lg:px-4 font-bold w-1/3">
                        Title
                      </th>
                      <th className="border text-center w-1/3">Action</th>
                    </tr>
                  </thead>
                  <tbody className="space-x-2 text-left border-gray-300 overflow-y-auto h-full">
                    {data ? (
                      data?.map((content, key) => (
                        <tr key={key}>
                          <td className="border px-2 lg:px-4 w-1/3">
                            <div className="w-full flex justify-center items-center">
                              <img
                                src={content?.thumbnail}
                                alt=""
                                className="w-20 h-12 object-cover"
                              />
                            </div>
                          </td>
                          <td className="border px-2 lg:px-4 w-1/3">
                            {content?.title ||
                              content?.name ||
                              content?.category}
                          </td>
                          <td className="border w-1/3">
                            <div className="w-full flex justify-end px-2">
                              {!dataIds?.includes(content[primaryKey]) ? (
                                <MyButton
                                  onClick={() => addToList(content[primaryKey])}
                                  className="text-white bg-black rounded-none"
                                >
                                  Select
                                </MyButton>
                              ) : (
                                <MyButton
                                  onClick={() =>
                                    removeFromList(content[primaryKey])
                                  }
                                  className="text-white bg-red-700 rounded-none"
                                >
                                  Remove
                                </MyButton>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="border px-2 lg:px-4 w-1/3">...</td>
                        <td className="border px-2 lg:px-4 w-1/3">...</td>
                        <td className="border px-2 lg:px-4 w-1/3">...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {dataLoading ? (
                  <div className="w-full py-4 flex justify-center items-center">
                    <AiOutlineLoading className="animate-spin text-xl text-black font-extrabold" />
                  </div>
                ) : null}
              </div>

              <Pagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total_items={count}
                callbackFunction={(page, size) => {
                  setData([]);
                  getData(page, size);
                }}
              />
            </div>
            <div className="py-4">
              <button
                onClick={onSubmit}
                className="flex justify-center items-center w-full bg-black text-xs text-white rounded-md py-4 px-2 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <AiOutlineLoading className="animate-spin text-base text-white font-extrabold" />
                ) : (
                  <span>Send Newsletter</span>
                )}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default NewsletterModal;
