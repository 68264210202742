import React from "react";

import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

import { MyButton } from "../../component/ButtonUtils";

const Preview = ({
  event,
  suspension,
  isSuperAdmin,
  editRoute,
  onEdit,
  setShowDeleteModal,
  setShowPreview,
}) => {
  return (
    <div className="bg-black/40 w-full h-full absolute z-[999999] overflow-y-auto top-0 left-0 flex justify-center items-start p-4">
      <div className="bg-white p-5 rounded-md w-full md:w-[450px] lg:w-[500px] space-y-4 overflow-y-auto">
        <div className="flex items-center justify-between border-b pb-4">
          <span className="text-base font-bold capitalize">Preview</span>
          <button
            onClick={() => setShowPreview(false)}
            className="bg-black text-white p-2 rounded-md cursor-pointer text-base"
          >
            <FaTimes />
          </button>
        </div>
        <div className="text-xs py-2 space-y-4">
          <div className="space-y-2 pb-2">
            <p>Image</p>
            <img
              src={event?.thumbnail}
              alt={event?.title}
              className="w-24 h-24 object-cover rounded-md"
            />
          </div>
          {[
            { label: "title", value: event?.title },
            { label: "venue", value: event?.venue },
            { label: "venue capacity", value: event?.venue_capacity || "-" },
            { label: "start date", value: event?.start_date || "-" },
            { label: "end date", value: event?.end_date || "-" },
            { label: "start time", value: event?.start_time || "-" },
            { label: "end time", value: event?.end_time || "-" },
            { label: "email", value: event?.email || "-" },
            { label: "phone number", value: event?.phone_number || "-" },
            { label: "facebook", value: event?.facebook || "-" },
            { label: "twitter", value: event?.twitter || "-" },
            { label: "instagram", value: event?.instagram || "-" },
            { label: "youtube", value: event?.youtube || "-" },
            { label: "hashtags", value: event?.hashtags || "-" },
            { label: "google map code", value: event?.google_map_code || "-" },
            { label: "latitude", value: event?.latitude || "-" },
            { label: "longitude", value: event?.longitude || "-" },
            {
              label: "formatted address",
              value: event?.formatted_address || "-",
            },
            { label: "description", value: event?.description || "-" },
          ].map((item, key) => {
            return (
              <div key={key} className="flex space-x-2">
                <p className="capitalize">{item?.label}: </p>
                <p>{item?.value}</p>
              </div>
            );
          })}
          <div className="pt-4">
            Price Info: {event?.Pricings?.length === 0 ? "-" : null}
            {event?.Pricings?.map((price, key) => {
              return (
                <div key={key} className="flex space-x-2">
                  <p className="capitalize">{price?.name}: </p>
                  <p>
                    {price?.currency} {price?.amount}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex py-4 space-x-2 justify-between items-center border-t">
          {isSuperAdmin ? (
            <>
              <MyButton
                title={event?.status === 1 ? "Make Private" : "Make Public"}
                className={`py-2 text-xs px-5 ${
                  event?.status === 1
                    ? "bg-gray-900 hover:bg-gray-600"
                    : "bg-red-700 hover:bg-red-600"
                }`}
                onClick={() => {
                  setShowPreview(false);
                  suspension(event);
                }}
              />
            </>
          ) : null}

          <Link to={editRoute}>
            <MyButton
              title="Edit"
              className={`py-2 text-xs px-5 bg-blue-800 hover:bg-blue-600`}
              onClick={() => onEdit(event)}
            />
          </Link>
          <MyButton
            title="Delete"
            className={`py-2 text-xs px-5 bg-red-800 hover:bg-red-600`}
            onClick={() => {
              setShowPreview(false);
              setShowDeleteModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
