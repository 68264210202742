export const Data = [
  {
    name: "Event",
    link: "/event",
    primary_key: "event_id",
  },
  {
    name: "Attraction",
    link: "/attraction",
    primary_key: "attraction_id",
  },
  {
    name: "Cinema",
    link: "/cinema",
    primary_key: "cinema_id",
  },
  {
    name: "Music",
    link: "/music",
    primary_key: "music_id",
  },
  {
    name: "News",
    link: "/news",
    primary_key: "news_id",
  },
  {
    name: "safaris",
    link: "/safari",
    primary_key: "safari_id",
  },
  {
    name: "Restaurant",
    link: "/restaurant",
    primary_key: "restaurant_id",
  },
  {
    name: "Things To Do",
    link: "/things/todo",
    primary_key: "things_to_do_id",
  },
  {
    name: "Party",
    link: "/party",
    primary_key: "party_id",
  },
  {
    name: "Nightlife",
    link: "/nightlife",
    primary_key: "nightlife_id",
  },
  {
    name: "City Guide",
    link: "/data/city/guide",
    primary_key: "city_guide_id",
  },
  {
    name: "Hotel",
    link: "/hotel",
    primary_key: "hotel_id",
  },
  {
    name: "Classes",
    link: "/class",
    primary_key: "class_id",
  },
  {
    name: "Shopping",
    link: "/shopping",
    primary_key: "shopping_id",
  },
  {
    name: "Food",
    link: "/food",
    primary_key: "food_id",
  },
  {
    name: "Book",
    link: "/book",
    primary_key: "book_id",
  },
  {
    name: "Neighborhood",
    link: "/neighborhood",
    primary_key: "neighborhood_id",
  },
  {
    name: "Ministries",
    link: "/ministry",
    primary_key: "ministry_id",
  },
  {
    name: "Getting Around",
    link: "/getting/around",
    primary_key: "getting_around_id",
  },
  {
    name: "Art",
    link: "/art",
    primary_key: "art_id",
  },
  {
    name: "Movies",
    link: "/movie",
    primary_key: "movie_id",
  },
  {
    name: "Dance",
    link: "/dance",
    primary_key: "dance_id",
  },
  {
    name: "Theater",
    link: "/theater",
    primary_key: "theater_id",
  },
  {
    name: "Museum",
    link: "/museum",
    primary_key: "museum_id",
  },
  {
    name: "Tips And Advice",
    link: "/tips/advice",
    primary_key: "tips_and_advice_id",
  },
  {
    name: `Tour Package`,
    link: "/tour/package",
    primary_key: "tour_package_id",
  },
  {
    name: `Companies`,
    link: "/companies",
    primary_key: "company_id",
  },
  {
    name: `Embassy`,
    link: "/embassy",
    primary_key: "embassy_id",
  },
  //   {
  //     name: `Best Of Africa`,
  //     link: "/best/africa",
  //     primary_key: "best_of_africa_id",
  //   },
];
