import Service from "../../../api/service";
import React, { createContext, useContext, useState } from "react";
import { useAdminContext } from "../../admin/context/AdminContext";

const ContinentRegionContext = createContext();

export const useContinentRegionContext = () =>
  useContext(ContinentRegionContext);

export const ContinentRegionProvider = (props) => {
  const [regions, setRegions] = useState(null);
  const [regionStats, setRegionsStats] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateRegionState = async (data) => {
    return setRegions(data);
  };

  /**
   * Fetch all continent regions
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchRegions = async () => {
    const url = "/api/v1/region/continent";
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setRegions(data);
      setCount(count);
    });
  };

  /**
   * Add a new region
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const addRegion = async (data) => {
    const url = "/api/v1/admin/region/continent/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a region
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateRegion = async (continent_region_id, data) => {
    const url = `/api/v1/admin/region/continent/update/${continent_region_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a region
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteRegion = async (continent_region_id) => {
    const url = `/api/v1/admin/region/continent/delete/${continent_region_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  /**
   * Get Single Continental region Id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const singleRegionStats = async (continent_region_id) => {
    const url = `/api/v1/stats/region/${continent_region_id}`;
    await Service.get(url, token).then(({ data }) => {
      setRegionsStats(data);
    });
  };

  return (
    <ContinentRegionContext.Provider
      value={{
        regions,
        fetchRegions,
        addRegion,
        deleteRegion,
        updateRegionState,
        regionStats,
        singleRegionStats,
        updateRegion,
        setRegions,
        count,
      }}
    >
      {props.children}
    </ContinentRegionContext.Provider>
  );
};
