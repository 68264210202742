import React from "react";

import { Link } from "react-router-dom";

import { FaCamera, FaMusic, FaPen, FaArrowRight } from "react-icons/fa";

import {
  Select,
  InputField,
  TextArea,
  GoBack,
  Title,
} from "../../home/component/Component";

const bestofafricaRoutes = "/data/bestofafrica";
const bestOfAfricaEditRoute = "/data/bestofafrica/edit";

const Details = () => {
  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: bestofafricaRoutes }} />
      <form action="">
        <div className="mt-8 flex flex-col items-center  font-medium text-sm md:text-base">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full my-3">
            <div className="flex flex-col my-5 items-center w-full px-2 text-gray-500">
              <FaCamera className="w-[80px] h-[80px]" />
              <p className="text-xs font-bold">Image </p>
            </div>
            <div className="flex flex-col my-5 items-center w-full px-2 text-gray-500">
              <FaMusic className="w-[80px] h-[80px]" />
              <p className="text-xs font-bold">Audio </p>
            </div>
          </div>

          <Title props={{ title: "Administrative Location" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full my-3">
            <Select
              props={{
                id: "country",
                title: "Country",
                option: ["Country One", "Country Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "FirstLevel",
                title: "First Level",
                option: ["First Level One", "First Level Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "SecondLevel",
                title: "Second Level",
                option: ["Second Level One", "Second Level Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "ThirdLevel",
                title: "Third Level",
                option: ["Third Level One", "Third Level Two"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "FourthLevel",
                title: "Fourth Level",
                option: ["Fourth Level One", "Fourth Level Two"],
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "Best Of Africa Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "slug",
                title: "Slug",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "Social Contact" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "Geo Position" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <InputField
              props={{
                id: "google_map_code",
                title: "Google Map Code",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "latitude",
                title: "Latitude",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "longitude",
                title: "Longitude",
                type: "text",
                default: "Default Value",
                disabled: true,
              }}
            />
            <InputField
              props={{
                id: "formatted_address",
                title: "Formatted Address",
                type: "address",
                default: "Default Value",
                disabled: true,
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <Select
              props={{
                id: "featured",
                title: "Featured",
                option: ["Yes", "No"],
                disabled: true,
              }}
            />
            <Select
              props={{
                id: "promoted",
                title: "Promoted",
                option: ["Yes", "No"],
                disabled: true,
              }}
            />
          </div>
          <TextArea
            props={{
              id: "description",
              title: "Description",
              type: "text",
              default:
                "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deserunt suscipit aspernatur accusamus commodi. Minus, quasi soluta fugiat reprehenderit doloremque reiciendis explicabo ratione quam amet beatae sapiente ducimus, perspiciatis, pariatur asperiores.",
              disabled: true,
            }}
          />
          <Link to={bestOfAfricaEditRoute}>
            <button
              type="button"
              className="flex flex-row items-center justify-between px-6 py-4 my-10 w-full bg-blue-500 text-white font-bold leading-tight uppercase rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out text-center"
            >
              <div className="flex flex-row items-center justify-start">
                <FaPen className="mx-3" />
                Edit Best Of Africa
              </div>
              <FaArrowRight className="mx-2" />
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Details;
