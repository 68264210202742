import React, { useState, useEffect } from "react";
import { usePlugImageContext } from "../../../../../context/plugImage/PlugImageContext";
import { useCountryContext } from "../../../../../context/country/countryContext/CountryContext";
import { useTradeOrganizationContext } from "../../../../../context/tradeOrganization/TradeOrganizationContext";
import { useContinentRegionContext } from "../../../../../context/continentRegion/context/ContinentRegionContext";
import {
  Title,
  GoBack,
  Select,
  SubTitle,
  TextArea,
  MyMessage,
  InputField,
  MyActionButton,
  MyButton,
} from "../../../component/Component";
import { codeOk, errorMessage } from "../../../../../middlewares/index";
import { defaultImage } from "../../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS } from "../../../../doseeData/utils/Utils";
import BestTimeToVisit from "../../../component/BestTimeToVisit";
import TradeOrganizationCard from "./TradeOrganizationCard";
import PlugImageCard from "./PlugImageCard";
import RegionalBlocksCard from "./RegionalBlocksCard";
import { useRegionalBlocksContext } from "../../../../../context/regionalBlocks/RegionalBlocksContext";
import GenerateDescription from "../../../../doseeData/utils/GenerateDescription";

const countriesRoute = "/countries";

const Add = () => {
  const { addCountry } = useCountryContext();
  const { regions, fetchRegions } = useContinentRegionContext();
  const { regionalBlocks, fetchRegionalBlocks } = useRegionalBlocksContext();
  const { plugImage, fetchPlugImages } = usePlugImageContext();
  const { tradeOrganization, fetchTradeOrganizations } =
    useTradeOrganizationContext();

  const [continentRegion, setContinentRegion] = useState(null);
  const [localPlugImages, setLocalPlugImages] = useState(null);
  const [localTradeOrganizations, setLocalTradeOrganizations] = useState(null);
  const [localRegionalBlocks, setLocalRegionalBlocks] = useState(null);
  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [thumbnailFlag, setThumbnailFlag] = useState(null);
  const [thumbnailIdFlag, setThumbnailIdFlag] = useState(null);

  const countryAudioURL = "no-audio";
  const [country_gdp, set_country_gdp] = useState(null);
  const [country_name, set_country_name] = useState(null);
  const [country_president, set_country_president] = useState(null);
  const [country_neighbours, set_country_neighbours] = useState(null);
  const [country_population, set_country_population] = useState(null);
  const [continent_region_id, set_continent_region_id] = useState(1);
  const [country_capital_city, set_country_capital_city] = useState(null);
  const [country_national_symbol, set_country_national_symbol] = useState(null);
  const [country_div_structure, set_country_div_structure] = useState(null);
  const [internet_users, set_internet_users] = useState(0);
  const [number_of_phones, set_number_of_phones] = useState(0);
  const [top_level_domain, set_top_level_domain] = useState(null);
  const [internet_providers, set_internet_providers] = useState("none");
  const [country_description, set_country_description] = useState(null);
  const [country_driving_side, set_country_driving_side] = useState("left");
  const [tourism, set_tourism] = useState("safe");
  const [safety_indicator, set_safety_indicator] = useState("safe");
  const [voltage, set_voltage] = useState(null);
  const [plug_note, set_plug_note] = useState("none");
  const [plug_type, set_plug_type] = useState(null);
  const [frequency, set_frequency] = useState(null);
  const [plug_standard, set_plug_standard] = useState("none");
  const [residential_voltage, set_residential_voltage] = useState("none");
  const [three_phase_voltage, set_three_phase_voltage] = useState("none");
  const [tiktok, set_tiktok] = useState("none");
  const [twitter, set_twitter] = useState("none");
  const [website, set_website] = useState("none");
  const [youtube, set_youtube] = useState("none");
  const [facebook, set_facebook] = useState("none");
  const [instagram, set_instagram] = useState("none");
  const [pinterest, set_pinterest] = useState("none");
  const [ease_of_doing_business_global, set_ease_of_doing_business_global] =
    useState(0);
  const [ease_of_doing_business_regional, set_ease_of_doing_business_regional] =
    useState(null);
  const [
    ease_of_doing_business_continent,
    set_ease_of_doing_business_continent,
  ] = useState(null);
  const [exports_goods, set_exports_goods] = useState("none");
  const [exports_increase, set_exports_increase] = useState("none");
  const [main_export_partners, set_main_export_partners] = useState("none");
  const [imports_goods, set_imports_goods] = useState("none");
  const [imports_increase, set_imports_increase] = useState("none");
  const [main_import_partners, set_main_import_partners] = useState("none");
  const [iso_2, set_iso_2] = useState("none");
  const [iso_3, set_iso_3] = useState("none");
  const [zip_code, set_zip_code] = useState(null);
  const [phone_code, set_phone_code] = useState(null);
  const [country_code, set_country_code] = useState(null);
  const [alpha_code_2, set_alpha_code_2] = useState("none");
  const [alpha_code_3, set_alpha_code_3] = useState("none");
  const [map, set_map] = useState(null);
  const [area, set_area] = useState(null);
  const [latitude, set_latitude] = useState("none");
  const [timezone, set_timezone] = useState(null);
  const [longitude, set_longitude] = useState("none");
  const [geographical_id, set_geographical_id] = useState("none");
  const [currency_name, set_currency_name] = useState(null);
  const [currency_symbol, set_currency_symbol] = useState(null);
  const [convertion_rate_to_usd, set_convertion_rate_to_usd] = useState(null);
  const [transport_name, set_transport_name] = useState("none");
  const [stock_market_name, set_stock_market_name] = useState("none");
  const [stock_market_info, set_stock_market_info] = useState("none");
  const [stock_market_index, set_stock_market_index] = useState("none");

  const [trades, setTrades] = useState([]);
  const [tradesID, setTradesID] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [blocksID, setBlocksID] = useState([]);
  const [plugs, setPlugs] = useState([]);
  const [plugsID, setPlugsID] = useState([]);
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [bestTime, setBestTime] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    set_country_gdp(null);
    set_country_name(null);
    set_country_president(null);
    set_country_neighbours(null);
    set_country_population(null);
    set_country_capital_city(null);
    set_country_div_structure(null);
    set_country_national_symbol(null);
    set_internet_users(0);
    set_number_of_phones(0);
    set_top_level_domain(null);
    set_internet_providers("none");
    set_country_description(null);
    set_voltage(null);
    set_plug_note("none");
    set_plug_type(null);
    set_frequency(null);
    set_plug_standard("none");
    set_residential_voltage("none");
    set_three_phase_voltage("none");
    set_tiktok("none");
    set_twitter("none");
    set_website("none");
    set_youtube("none");
    set_facebook("none");
    set_instagram("none");
    set_pinterest("none");
    set_ease_of_doing_business_global(0);
    set_ease_of_doing_business_regional(null);
    set_ease_of_doing_business_continent(null);
    set_exports_goods("none");
    set_exports_increase("none");
    set_main_export_partners("none");
    set_imports_goods("none");
    set_imports_increase("none");
    set_main_import_partners("none");
    set_iso_2("none");
    set_iso_3("none");
    set_zip_code(null);
    set_phone_code(null);
    set_country_code(null);
    set_alpha_code_2("none");
    set_alpha_code_3("none");
    set_map(null);
    set_area(null);
    set_latitude("none");
    set_timezone(null);
    set_longitude("none");
    set_geographical_id("none");
    set_currency_name(null);
    set_currency_symbol(null);
    set_transport_name("none");
    set_stock_market_name("none");
    set_stock_market_info("none");
    set_stock_market_index("none");
    setBestTime([]);
    set_convertion_rate_to_usd(null);
  };

  useEffect(() => {
    if (!regions) {
      fetchRegions();
    } else {
      const this_region_array = [];
      regions.map((region) =>
        this_region_array.push({
          name: region.name,
          id: region.continent_region_id,
        })
      );
      setContinentRegion(this_region_array);
    }

    if (!tradeOrganization) {
      fetchTradeOrganizations();
    } else {
      setLocalTradeOrganizations(tradeOrganization);
    }

    if (!regionalBlocks) {
      fetchRegionalBlocks(1, 50);
    } else {
      setLocalRegionalBlocks(regionalBlocks);
    }

    if (!plugImage) {
      fetchPlugImages();
    } else {
      setLocalPlugImages(plugImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions, tradeOrganization, plugImage, regionalBlocks]);

  const responses = (isError, status, message) => {
    // Set Message and Status
    setError(isError);
    setErrorMessage(message);
  };

  const sendAddCountryRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId || !thumbnailFlag || !thumbnailIdFlag) {
      setLoading(false);
      return responses(true, 400, "Image or Flag Missing");
    } else {
      await sendDBRequest();
    }
  };
  /**
   *  Sending DB request to DO SEE AFRICA Database
   * @returns
   */
  const sendDBRequest = async () => {
    /**
     * Country Data
     */
    const Country = {
      continent_region_id: continent_region_id,
      name: country_name,
      slug: country_name.toLowerCase().replace(/\s/g, "_"),
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      admin_division_structure: country_div_structure,
      flag: thumbnailFlag ? thumbnailFlag : defaultImage,
      flag_cdn_id: thumbnailIdFlag ? thumbnailIdFlag : "",
      president: country_president,
      national_symbol: country_national_symbol,
      country_neighbour: country_neighbours,
      population: country_population,
      capital_city: country_capital_city,
      tld: top_level_domain,
      internet_provider: internet_providers,
      internet_users: internet_users,
      mobile_phone: number_of_phones,
      audio: countryAudioURL,
      gdp: country_gdp,
      driving_side: country_driving_side,
      tourism: tourism,
      safety_indicator: safety_indicator,
      plug_type: plug_type,
      voltage: voltage,
      plug_standard: plug_standard,
      residential_voltage: residential_voltage,
      three_phase_voltage: three_phase_voltage,
      frequency: frequency,
      plug_note: plug_note,
      youtube: youtube,
      facebook: facebook,
      twitter: twitter,
      instagram: instagram,
      pinterest: pinterest,
      tiktok: tiktok,
      website: website,
      description: country_description,
      ease_of_doing_business_regional_ranking: ease_of_doing_business_regional,
      ease_of_doing_business_continent_ranking:
        ease_of_doing_business_continent,
      ease_of_doing_business_global_ranking: ease_of_doing_business_global,
      exports_increase: exports_increase,
      exports_goods: exports_goods,
      main_export_partners: main_export_partners,
      imports_increase: imports_increase,
      imports_goods: imports_goods,
      main_import_partners: main_import_partners,
      country_code: country_code,
      zip_code: zip_code,
      phone_code: phone_code,
      alpha_code_2: alpha_code_2,
      alpha_code_3: alpha_code_3,
      iso_2_code: iso_2,
      iso_3_code: iso_3,
      currency_name: currency_name,
      currency_symbol: currency_symbol,
      convertion_rate_to_usd: convertion_rate_to_usd,
      transportation: transport_name,
      stock_market_name: stock_market_name,
      stock_market_index: stock_market_index,
      stock_market_info: stock_market_info,
      latitude: latitude,
      longitude: longitude,
      area: area,
      geo_id: geographical_id,
      map: map,
      time_zone: timezone,
      plug_images: plugsID,
      trade_organizations: tradesID,
      regional_blocks: blocksID,
      best_time_to_visit: bestTime?.length > 0 ? String(bestTime) : null,
    };

    // Response
    const countryRespose = await addCountry(Country);
    if (countryRespose) {
      if (countryRespose.data.status === codeOk) {
        // Clean all filds that were holding data
        cleanFields();
        return responses(
          true,
          countryRespose.data.status,
          countryRespose.data.message
        );
      } else {
        setLoading(false);
        return responses(
          true,
          countryRespose.data.status,
          countryRespose.data.message
        );
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: countriesRoute }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={set_country_description}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <form onSubmit={sendAddCountryRequest}>
        <div className="mt-3 flex flex-col items-center  font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />
          <Title props={{ title: "Country Photo and Flag" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <div className="">
              <ImageKitUpload
                thumbnail={thumbnail}
                thumbnailId={thumbnailId}
                setThumbnail={setThumbnail}
                setThumbnailId={setThumbnailId}
                folder={FOLDERS.COUNTRIES}
              />
            </div>
            <div>
              <ImageKitUpload
                thumbnail={thumbnailFlag}
                thumbnailId={thumbnailIdFlag}
                setThumbnail={setThumbnailFlag}
                setThumbnailId={setThumbnailIdFlag}
                folder={FOLDERS.COUNTRIES}
              />
            </div>
          </div>

          <Title props={{ title: "Country Details" }} />

          <div className="w-full h-full">
            <p className="text-xs font-bold">Best Time To Visit</p>
            <BestTimeToVisit bestTime={bestTime} setBestTime={setBestTime} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <Select
              props={{
                id: "continent_region",
                title: "Continent Region",
                required: true,
                option: continentRegion
                  ? continentRegion.map((region) => region.name)
                  : [],
                onChange: (e) => {
                  set_continent_region_id(
                    regions[e.target.selectedIndex].continent_region_id
                  );
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "name",
                title: "Title",
                type: "text",
                required: true,
                value: country_name ? country_name : "",
                onChange: (e) => {
                  set_country_name(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "president",
                title: "President",
                type: "text",
                required: true,
                value: country_president ? country_president : "",
                onChange: (e) => {
                  set_country_president(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "national_symbol",
                title: "National Symbol",
                type: "text",
                required: true,
                value: country_national_symbol ? country_national_symbol : "",
                onChange: (e) => {
                  set_country_national_symbol(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "country_neighbour",
                title: "Neighboring Countries (Comma separated.)",
                type: "text",
                required: true,
                value: country_neighbours ? country_neighbours : "",
                onChange: (e) => {
                  set_country_neighbours(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "population",
                title: "Population",
                type: "number",
                required: true,
                value: country_population ? country_population : "",
                onChange: (e) => {
                  set_country_population(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "gdp",
                title: "GDP",
                type: "number",
                required: true,
                value: country_gdp ? country_gdp : "",
                onChange: (e) => {
                  set_country_gdp(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "capital_city",
                title: "Capital City",
                type: "text",
                required: true,
                value: country_capital_city ? country_capital_city : "",
                onChange: (e) => {
                  set_country_capital_city(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "administrative_division_structure",
                title: "Administrative Division Structure",
                type: "text",
                required: true,
                value: country_div_structure ? country_div_structure : "",
                onChange: (e) => {
                  set_country_div_structure(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          {/* <Title props={{ title: 'Country Desciption' }} /> */}
          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: country_description ? country_description : "",
                onChange: (e) => {
                  set_country_description(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Country's MetaData" }} />
          <SubTitle props={{ title: "Codes" }} />
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "country_code",
                title: "Country Code",
                type: "text",
                required: true,
                value: country_code ? country_code : "",
                onChange: (e) => {
                  set_country_code(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "zip_code",
                title: "Zip Code",
                type: "text",
                required: true,
                value: zip_code ? zip_code : "",
                onChange: (e) => {
                  set_zip_code(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "phone_code",
                title: "Phone Code",
                type: "text",
                required: true,
                value: phone_code ? phone_code : "",
                onChange: (e) => {
                  set_phone_code(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "alpha_code_2",
                title: "Alpha Code 2",
                type: "text",
                value: alpha_code_2 ? alpha_code_2 : "",
                onChange: (e) => {
                  set_alpha_code_2(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "alpha_code_3",
                title: "Alpha Code 3",
                type: "text",
                value: alpha_code_3 ? alpha_code_3 : "",
                onChange: (e) => {
                  set_alpha_code_3(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "iso_2",
                title: "ISO 2",
                type: "text",
                value: iso_2 ? iso_2 : "",
                onChange: (e) => {
                  set_iso_2(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "iso_3",
                title: "ISO 3",
                type: "text",
                value: iso_3 ? iso_3 : "",
                onChange: (e) => {
                  set_iso_3(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <SubTitle props={{ title: "Geographical Position" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "latitude",
                title: "Latitude",
                type: "text",
                value: latitude ? latitude : "",
                onChange: (e) => {
                  set_latitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "longitude",
                title: "Longitude",
                type: "text",
                value: longitude ? longitude : "",
                onChange: (e) => {
                  set_longitude(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "area",
                title: "Area",
                type: "text",
                required: true,
                value: area ? area : "",
                onChange: (e) => {
                  set_area(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "geo_id",
                title: "Geographical ID",
                type: "text",
                value: geographical_id ? geographical_id : "",
                onChange: (e) => {
                  set_geographical_id(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "map",
                title: "Map",
                type: "text",
                required: true,
                value: map ? map : "",
                onChange: (e) => {
                  set_map(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "time_zone",
                title: "Time Zone",
                type: "text",
                required: true,
                value: timezone ? timezone : "",
                onChange: (e) => {
                  set_timezone(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <SubTitle props={{ title: "Currency" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "currency_name",
                title: "Name Of Currency",
                type: "text",
                required: true,
                value: currency_name ? currency_name : "",
                onChange: (e) => {
                  set_currency_name(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "symbol",
                title: "Symbol Of Currency",
                type: "text",
                required: true,
                value: currency_symbol ? currency_symbol : "",
                onChange: (e) => {
                  set_currency_symbol(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "conversion",
                title: "Convertion Rate To US Dollars",
                type: "number",
                value: convertion_rate_to_usd ? convertion_rate_to_usd : "",
                onChange: (e) => {
                  set_convertion_rate_to_usd(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <SubTitle props={{ title: "Transportations" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "transport_name",
                title: "Names Of Transportations",
                type: "text",
                value: transport_name ? transport_name : "",
                onChange: (e) => {
                  set_transport_name(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <SubTitle props={{ title: "Stock Market" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "stock_market_name",
                title: "Stock Market Name",
                type: "text",
                value: stock_market_name ? stock_market_name : "",
                onChange: (e) => {
                  set_stock_market_name(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "stock_market_index",
                title: "Stock Market Index",
                type: "text",
                value: stock_market_index ? stock_market_index : "",
                onChange: (e) => {
                  set_stock_market_index(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "stock_market_info",
                title: "Stock Market Info",
                type: "text",
                value: stock_market_info ? stock_market_info : "",
                onChange: (e) => {
                  set_stock_market_info(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <Title props={{ title: "Connectivity" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "tld",
                title: "Top Level Domain",
                type: "text",
                required: true,
                value: top_level_domain ? top_level_domain : "",
                onChange: (e) => {
                  set_top_level_domain(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "internet_provider",
                title: "Internet Providers",
                type: "text",
                value: internet_providers ? internet_providers : "",
                onChange: (e) => {
                  set_internet_providers(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "internet_users",
                title: "Internet Users",
                type: "number",
                required: true,
                value: internet_users ? internet_users : "",
                onChange: (e) => {
                  set_internet_users(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "mobile_phone",
                title: "Number Of Phones",
                type: "number",
                required: true,
                value: number_of_phones ? number_of_phones : "",
                onChange: (e) => {
                  set_number_of_phones(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <Title props={{ title: "Driving" }} />
          <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <Select
              props={{
                id: "driving_side",
                title: "Driving Side",
                required: true,
                option: ["left", "right"],
                onChange: (e) => {
                  set_country_driving_side(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <Title props={{ title: "Safety and Security" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <Select
              props={{
                id: "tourism",
                title: "Tourism",
                required: true,
                option: ["safe", "intermediate", "danger"],
                onChange: (e) => {
                  set_tourism(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "safety_indicator",
                title: "Safety Indicator",
                required: true,
                option: ["safe", "intermediate", "danger"],
                onChange: (e) => {
                  set_safety_indicator(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <Title props={{ title: "Electricity" }} />
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "plug_type",
                title: "Plug Type",
                type: "text",
                required: true,
                value: plug_type ? plug_type : "",
                onChange: (e) => {
                  set_plug_type(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "voltage",
                title: "Voltage ( V )",
                type: "number",
                required: true,
                value: voltage ? voltage : "",
                onChange: (e) => {
                  set_voltage(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "plug_standard",
                title: "Plug Standard",
                type: "text",
                value: plug_standard ? plug_standard : "",
                onChange: (e) => {
                  set_plug_standard(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "residential_voltage",
                title: "Residential Voltage",
                type: "text",
                value: residential_voltage ? residential_voltage : "",
                onChange: (e) => {
                  set_residential_voltage(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "three_phase_voltage",
                title: "Three Phase Voltage",
                type: "text",
                value: three_phase_voltage ? three_phase_voltage : "",
                onChange: (e) => {
                  set_three_phase_voltage(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "frequency",
                title: "Frequency",
                type: "text",
                required: true,
                value: frequency ? frequency : "",
                onChange: (e) => {
                  set_frequency(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "plug_note",
                title: "Plug note",
                type: "text",
                value: plug_note ? plug_note : "",
                onChange: (e) => {
                  set_plug_note(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <Title props={{ title: "Social Contact" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "website",
                title: "Website",
                type: "text",
                value: website ? website : "",
                onChange: (e) => {
                  set_website(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "youtube",
                title: "YouTube",
                type: "text",
                value: youtube ? youtube : "",
                onChange: (e) => {
                  set_youtube(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "facebook",
                title: "Facebook",
                type: "text",
                value: facebook ? facebook : "",
                onChange: (e) => {
                  set_facebook(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "twitter",
                title: "Twitter",
                type: "text",
                value: twitter ? twitter : "",
                onChange: (e) => {
                  set_twitter(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "instagram",
                title: "Instagram",
                type: "text",
                value: instagram ? instagram : "",
                onChange: (e) => {
                  set_instagram(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "pinterest",
                title: "Pinterest",
                type: "text",
                value: pinterest ? pinterest : "",
                onChange: (e) => {
                  set_pinterest(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "tiktok",
                title: "TikTok",
                type: "text",
                value: tiktok ? tiktok : "",
                onChange: (e) => {
                  set_tiktok(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <Title props={{ title: "Ease Of Business" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "ease_of_doing_business_regional_ranking",
                title: "Ease Business Regional Ranking",
                type: "number",
                required: true,
                value: ease_of_doing_business_regional
                  ? ease_of_doing_business_regional
                  : "",
                onChange: (e) => {
                  set_ease_of_doing_business_regional(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "ease_of_doing_business_continent_ranking",
                title: "Ease Business Continent Ranking",
                type: "number",
                required: true,
                value: ease_of_doing_business_continent
                  ? ease_of_doing_business_continent
                  : "",
                onChange: (e) => {
                  set_ease_of_doing_business_continent(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "ease_of_doing_business_global_ranking",
                title: "Ease Business Global Ranking",
                type: "number",
                value: ease_of_doing_business_global
                  ? ease_of_doing_business_global
                  : "",
                onChange: (e) => {
                  set_ease_of_doing_business_global(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Imports & Exports" }} />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "exports_increase",
                title: "Exports Increase",
                type: "text",
                value: exports_increase ? exports_increase : "",
                onChange: (e) => {
                  set_exports_increase(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "exports_goods",
                title: "Exports Goods",
                type: "text",
                value: exports_goods ? exports_goods : "",
                onChange: (e) => {
                  set_exports_goods(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "main_export_partners",
                title: "Main Export Partners",
                type: "text",
                value: main_export_partners ? main_export_partners : "",
                onChange: (e) => {
                  set_main_export_partners(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "imports_increase",
                title: "Imports Increase",
                type: "text",
                value: imports_increase ? imports_increase : "",
                onChange: (e) => {
                  set_imports_increase(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "imports_goods",
                title: "Imports Goods",
                type: "text",
                value: imports_goods ? imports_goods : "",
                onChange: (e) => {
                  set_imports_goods(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <TextArea
              props={{
                id: "main_import_partners",
                title: "Main Import Partners",
                type: "text",
                value: main_import_partners ? main_import_partners : "",
                onChange: (e) => {
                  set_main_import_partners(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title
            props={{
              title: "Trade Organizations & Regional Blocks & Plug Types",
            }}
          />
          <div className="grid grid-cols-1 gap-8 w-full h-full">
            <TradeOrganizationCard
              trades={trades}
              tradesID={tradesID}
              setError={setError}
              setTrades={setTrades}
              setSuccess={setSuccess}
              setTradesID={setTradesID}
              setIsTradeChanges={() => {}}
              localTradeOrganizations={localTradeOrganizations}
            />

            <RegionalBlocksCard
              blocks={blocks}
              blocksID={blocksID}
              setError={setError}
              setBlocks={setBlocks}
              setSuccess={setSuccess}
              setBlocksID={setBlocksID}
              setIsBlockChanges={() => {}}
              localRegionalBlocks={localRegionalBlocks}
            />

            <PlugImageCard
              plugs={plugs}
              plugsID={plugsID}
              setError={setError}
              setPlugs={setPlugs}
              setPlugsID={setPlugsID}
              setSuccess={setSuccess}
              setIsPlugChanges={() => {}}
              localPlugImages={localPlugImages}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Country"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
