/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { BiCameraMovie } from "react-icons/bi";
import { SiHotelsdotcom } from "react-icons/si";
import { Card } from "../../component/CardUtils";
import { BsBook, BsPeople } from "react-icons/bs";
import { VscFileSubmodule } from "react-icons/vsc";
import { AiOutlineShopping } from "react-icons/ai";
import { HiOutlineDocument } from "react-icons/hi";
import { TableStatistics } from "../dashboard/DashBoard";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { pushLevel } from "../../../../middlewares";
import { addOneLevel } from "../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { useDashboardContext } from "../../../../context/dashboard/context/DashboardContext";
import {
  MdShop,
  MdMusicNote,
  MdOutlineMuseum,
  MdOutlineFoodBank,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { _TYPES_ } from "../../../doseeData/utils/types";
import { getType } from "../contentStats/helper";

const AllCountriesDashboard = () => {
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const { stats, fetchData } = useDashboardContext();

  useEffect(() => {
    if (!stats) fetchData();
  }, [stats]);

  const List = [
    {
      name: "regions",
      quantity: stats ? stats?.continent_region : "-",
      route: "/region/manage",
    },
    {
      name: "countries",
      quantity: stats ? stats?.country : "-",
      route: "/countries",
    },
    {
      name: "First Level",
      quantity: stats ? stats?.first_level : "-",
      route: "/levels/first",
    },
    {
      name: "Second Level",
      quantity: stats ? stats?.second_level : "-",
      route: "/levels/second",
    },
    {
      name: "Third Level",
      quantity: stats ? stats?.third_level : "-",
      route: "/levels/third",
    },
    {
      name: "Fourth Level",
      quantity: stats ? stats?.fourth_level : "-",
      route: "/levels/fourth",
    },
    {
      name: "events",
      quantity: stats ? stats?.event : "-",
      route: "/data/event",
      key: _TYPES_.EVENT,
    },
    {
      name: "attractions",
      quantity: stats ? stats?.attraction : "-",
      route: "/data/attraction",
      key: _TYPES_.ATTRACTION,
    },
    {
      name: "restaurants",
      quantity: stats ? stats?.restaurant : "-",
      route: "/data/restaurant",
      key: _TYPES_.RESTAURANT,
    },
    {
      name: "best of africa",
      quantity: stats ? stats?.best_of_africa : "-",
      route: "/data/bestofafrica",
      key: _TYPES_.BESTOFAFRICA,
    },
    {
      name: "music",
      quantity: stats ? stats?.music : "-",
      route: "/data/music",
      key: _TYPES_.MUSIC,
    },
    {
      name: "news",
      quantity: stats ? stats?.news : "-",
      route: "/data/news",
      key: _TYPES_.NEWS,
    },
    {
      name: "safaris",
      quantity: stats ? stats?.safari : "-",
      route: "/data/safari",
      key: _TYPES_.SAFARI,
    },
    {
      name: "tour packages",
      quantity: stats ? stats?.tour_package : "-",
      route: "/data/tour/package",
      key: _TYPES_.TOURPACKAGE,
    },
    {
      name: "cinema",
      quantity: stats ? stats?.cinema : "-",
      route: "/data/cinema",
      key: _TYPES_.CINEMA,
    },
    {
      name: "museums",
      quantity: stats ? stats?.museum : "-",
      route: "/data/museum",
      key: _TYPES_.MUSEUM,
    },
    {
      name: "night life",
      quantity: stats ? stats?.nightlife : "-",
      route: "/data/nightlife",
      key: _TYPES_.NIGHTLIFE,
    },
    {
      name: "hotels",
      quantity: stats ? stats?.hotel : "-",
      route: "/data/hotel",
      key: _TYPES_.HOTEL,
    },
    {
      name: "classes",
      quantity: stats ? stats?.class : "-",
      route: "/data/class",
      key: _TYPES_.CLASSES,
    },
    {
      name: "shopping",
      quantity: stats ? stats?.shopping : "-",
      route: "/data/shopping",
      key: _TYPES_.SHOPPING,
    },
    {
      name: "food",
      quantity: stats ? stats?.food : "-",
      route: "/data/food",
      key: _TYPES_.FOOD,
    },
    {
      name: "books",
      quantity: stats ? stats?.book : "-",
      route: "/data/book",
      key: _TYPES_.BOOK,
    },
    {
      name: "neighborhoods",
      quantity: stats ? stats?.neighborhood : "-",
      route: "/data/neighborhood",
      key: _TYPES_.NEIGHBORHOOD,
    },
    {
      name: "arts",
      quantity: stats ? stats?.art : "-",
      route: "/data/art",
      key: _TYPES_.ART,
    },
    {
      name: "dance",
      quantity: stats ? stats?.dance : "-",
      route: "/data/dance",
      key: _TYPES_.DANCE,
    },
    {
      name: "theaters",
      quantity: stats ? stats?.theater : "-",
      route: "/data/theater",
      key: _TYPES_.THEATER,
    },
    {
      name: "City Guides",
      quantity: stats ? stats?.city_guide : "-",
      route: "/data/city/guide",
      key: _TYPES_.CITYGUIDE,
    },
    {
      name: "Parties",
      quantity: stats ? stats?.party : "-",
      route: "/data/party",
      key: _TYPES_.PARTY,
    },
    {
      name: "Things To Do",
      quantity: stats ? stats?.things_to_do : "-",
      route: "/data/things/todo",
      key: _TYPES_.THINGSTODO,
    },
    {
      name: "Tips And Advice",
      quantity: stats ? stats?.tips_and_advice : "-",
      route: "/data/tips/advice",
      key: _TYPES_.TIPSANDADVICE,
    },

    {
      name: "Ministries",
      quantity: stats ? stats?.ministry : "-",
      route: "/data/ministry",
      key: _TYPES_.MINISTRY,
    },
    {
      name: "Movies",
      quantity: stats ? stats?.movie : "-",
      route: "/data/movie",
      key: _TYPES_.MOVIE,
    },
    {
      name: "Getting Around",
      quantity: stats ? stats?.getting_around : "-",
      route: "/data/get/around",
      key: _TYPES_.GETTINGAROUND,
    },
    {
      name: "Products",
      quantity: stats ? stats?.product : "-",
      route: "/data/product",
      key: _TYPES_.PRODUCT,
    },
  ];

  return (
    <div className="overflow-y-auto p-4 h-full rounded-b-xl bg-gray-50">
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                // We are excluding the first index since they are not pointing to
                if (
                  ![
                    "regions",
                    "countries",
                    "First Level",
                    "Second Level",
                    "Third Level",
                    "Fourth Level",
                  ].includes(card?.name)
                ) {
                  setSingleAdmin({
                    ids: [sysAdmin?.admin_id],
                    type: getType({ field: card?.key }),
                    route: "/countries/dashboard",
                  });
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: `${sysAdmin?.first_name} ${sysAdmin?.last_name}`,
                    route: "/levels/first",
                    level: "",
                    country_id: 0,
                    data: {
                      data: {
                        name: "",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                }
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>

      <TableStatistics />
    </div>
  );
};

export default AllCountriesDashboard;

const Icon = [
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
  <MdShop />,
];
