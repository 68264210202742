import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const CompanyContext = createContext();
export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
  const { sysAdmin } = useAdminContext();
  const [company, setCompany] = useState(null);
  const [companies, setCompanies] = useState(null);

  const token = sysAdmin?.token;
  const role = sysAdmin?.role;

  /**
   * Fetch all companies
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getAll = async (page = 1, pageSize = 50) => {
    const url = ["super_admin", "admin"].includes(role)
      ? "/api/v1/company/all/?page=1&pageSize=500"
      : `/api/v1/companies/admin/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data }) => {
      setCompanies(role === "super_admin" ? data?.data : data);
    });
  };

  /**
   * Fetch one company
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getOne = async (id) => {
    const url = `/api/v1/company/${id}`;
    await Service.get(url, token).then(({ data, count }) => {
      setCompany(data);
    });
  };

  /**
   * Add a new company
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const create = async (data) => {
    const url = "/api/v1/company/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Create a new admin for the company
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createNewAdmin = async (data) => {
    const url = `/api/v1/auth/admin/signup`;
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a company
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const update = async (company_id, data) => {
    const url = `/api/v1/company/update/${company_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Update an admin status In company
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateAdminStatus = async (company_id, admin_id, admin_status) => {
    const url = `/api/v1/company/status/admin/${company_id}/${admin_id}/${admin_status}`;
    const { message, status } = await Service.put(url, null, token);
    return { message, status };
  };

  /**
   * Delete a company
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteCompany = async (company_id, admin_id) => {
    const url = `/api/v1/company/delete/${company_id}/${admin_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <CompanyContext.Provider
      value={{
        company,
        companies,
        getOne,
        getAll,
        update,
        create,
        setCompany,
        setCompanies,
        deleteCompany,
        createNewAdmin,
        updateAdminStatus,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
