/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { VscFileSubmodule } from "react-icons/vsc";
import { BsBook, BsPeople } from "react-icons/bs";
import { GrAttraction, GrRestaurant } from "react-icons/gr";
import {
  MdShop,
  MdMusicNote,
  MdOutlineMuseum,
  MdOutlineNightlight,
  MdOutlineHotelClass,
  MdOutlineFoodBank,
  MdOutlineFormatPaint,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { SiHotelsdotcom } from "react-icons/si";
import { AiOutlineShopping } from "react-icons/ai";
import { BiCameraMovie } from "react-icons/bi";
import { GiTheater, GiBallerinaShoes } from "react-icons/gi";
import { Card, GoBack } from "../../../component/Component";
import {
  pushLevel,
  getCurrentRegion,
} from "../../../../../middlewares/sysNavigation/SysNavigation";
import { useContinentRegionContext } from "../../../../../context/continentRegion/context/ContinentRegionContext";
import { addOneLevel } from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../../../context/admin/context/AdminContext";
import { _TYPES_ } from "../../../../doseeData/utils/types";
import { getType } from "../../contentStats/helper";

const regionRoute = "/region/manage";

const SingleRegionStats = () => {
  const { sysAdmin, setSingleAdmin } = useAdminContext();
  const { regionStats, singleRegionStats } = useContinentRegionContext();

  useEffect(() => {
    if (!regionStats && getCurrentRegion().length > 0) {
      singleRegionStats(getCurrentRegion()[0].continent_region_id);
    }
  }, [regionStats]);

  const List = [
    {
      name: "countries",
      quantity: regionStats ? regionStats?.country : "-",
      route: "/countries",
    },
    {
      name: "First Level",
      quantity: regionStats ? regionStats?.first_level : "-",
      route: "/levels/first",
    },
    {
      name: "Second Level",
      quantity: regionStats ? regionStats?.second_level : "-",
      route: "/levels/second",
    },
    {
      name: "Third Level",
      quantity: regionStats ? regionStats?.third_level : "-",
      route: "/levels/third",
    },
    {
      name: "Fourth Level",
      quantity: regionStats ? regionStats?.fourth_level : "-",
      route: "/levels/fourth",
    },
    {
      name: "events",
      quantity: regionStats ? regionStats?.event : "-",
      route: "/data/event",
      key: _TYPES_.EVENT,
    },
    {
      name: "attractions",
      quantity: regionStats ? regionStats?.attraction : "-",
      route: "/data/attraction",
      key: _TYPES_.ATTRACTION,
    },
    {
      name: "restaurants",
      quantity: regionStats ? regionStats?.restaurant : "-",
      route: "/data/restaurant",
      key: _TYPES_.RESTAURANT,
    },
    {
      name: "best of africa",
      quantity: regionStats ? regionStats?.best_of_africa : "-",
      route: "/data/bestofafrica",
      key: _TYPES_.BESTOFAFRICA,
    },
    {
      name: "music",
      quantity: regionStats ? regionStats?.music : "-",
      route: "/data/music",
      key: _TYPES_.MUSIC,
    },
    {
      name: "news",
      quantity: regionStats ? regionStats?.news : "-",
      route: "/data/news",
      key: _TYPES_.NEWS,
    },
    {
      name: "safaris",
      quantity: regionStats ? regionStats?.safari : "-",
      route: "/data/safari",
      key: _TYPES_.SAFARI,
    },
    {
      name: "tour packages",
      quantity: regionStats ? regionStats?.tour_package : "-",
      route: "/data/tour/package",
      key: _TYPES_.TOURPACKAGE,
    },
    {
      name: "cinema",
      quantity: regionStats ? regionStats?.cinema : "-",
      route: "/data/cinema",
      key: _TYPES_.CINEMA,
    },
    {
      name: "museums",
      quantity: regionStats ? regionStats?.museum : "-",
      route: "/data/museum",
      key: _TYPES_.MUSEUM,
    },
    {
      name: "night life",
      quantity: regionStats ? regionStats?.nightlife : "-",
      route: "/data/nightlife",
      key: _TYPES_.NIGHTLIFE,
    },
    {
      name: "hotels",
      quantity: regionStats ? regionStats?.hotel : "-",
      route: "/data/hotel",
      key: _TYPES_.HOTEL,
    },
    {
      name: "classes",
      quantity: regionStats ? regionStats?.class : "-",
      route: "/data/class",
      key: _TYPES_.CLASSES,
    },
    {
      name: "shopping",
      quantity: regionStats ? regionStats?.shopping : "-",
      route: "/data/shopping",
      key: _TYPES_.SHOPPING,
    },

    {
      name: "food",
      quantity: regionStats ? regionStats?.food : "-",
      route: "/data/food",
      key: _TYPES_.FOOD,
    },

    {
      name: "books",
      quantity: regionStats ? regionStats?.book : "-",
      route: "/data/book",
      key: _TYPES_.BOOK,
    },
    {
      name: "neighborhoods",
      quantity: regionStats ? regionStats?.neighborhood : "-",
      route: "/data/neighborhood",
      key: _TYPES_.NEIGHBORHOOD,
    },
    {
      name: "arts",
      quantity: regionStats ? regionStats?.art : "-",
      route: "/data/art",
      key: _TYPES_.ART,
    },
    {
      name: "dance",
      quantity: regionStats ? regionStats?.dance : "-",
      route: "/data/dance",
      key: _TYPES_.DANCE,
    },
    {
      name: "theaters",
      quantity: regionStats ? regionStats?.theater : "-",
      route: "/data/theater",
      key: _TYPES_.THEATER,
    },
    {
      name: "City Guides",
      quantity: regionStats ? regionStats?.city_guide : "-",
      route: "/data/city/guide",
      key: _TYPES_.CITYGUIDE,
    },
    {
      name: "Parties",
      quantity: regionStats ? regionStats?.party : "-",
      route: "/data/party",
      key: _TYPES_.PARTY,
    },
    {
      name: "Things To Do",
      quantity: regionStats ? regionStats?.things_to_do : "-",
      route: "/data/things/todo",
      key: _TYPES_.THINGSTODO,
    },
    {
      name: "Tips And Advice",
      quantity: regionStats ? regionStats?.tips_and_advice : "-",
      route: "/data/tips/advice",
      key: _TYPES_.TIPSANDADVICE,
    },

    {
      name: "Ministries",
      quantity: regionStats ? regionStats?.ministry : "-",
      route: "/data/ministry",
      key: _TYPES_.MINISTRY,
    },
    {
      name: "Movies",
      quantity: regionStats ? regionStats?.movie : "-",
      route: "/data/movie",
      key: _TYPES_.MOVIE,
    },
    {
      name: "Getting Around",
      quantity: regionStats ? regionStats?.getting_around : "-",
      route: "/data/get/around",
      key: _TYPES_.GETTINGAROUND,
    },
    {
      name: "Products",
      quantity: regionStats ? regionStats?.product : "-",
      route: "/data/product",
      key: _TYPES_.PRODUCT,
    },
  ];

  return (
    <div className="overflow-y-auto p-4 h-full rounded-b-xl bg-gray-50">
      <div className="my-2">
        <GoBack props={{ route: regionRoute }} />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4 items-center selection:text-white selection:bg-blue-900">
        {List.map((card, index) => {
          return (
            <Link
              to={card?.route}
              key={index}
              onClick={() => {
                // We are excluding the first index since they are not pointing to
                if (
                  ![
                    "regions",
                    "countries",
                    "First Level",
                    "Second Level",
                    "Third Level",
                    "Fourth Level",
                  ].includes(card.name)
                ) {
                  setSingleAdmin({
                    ids: [sysAdmin?.admin_id],
                    type: getType({ field: card?.key }),
                    route: "/region/dashboard",
                  });
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: `${sysAdmin?.first_name} ${sysAdmin?.last_name}`,
                    route: "/levels/first",
                    level: "First",
                    country_id: 0,
                    data: {
                      data: {
                        name: "level",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "first",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                }
              }}
            >
              <Card
                card={{
                  name: card.name,
                  quantity: card.quantity,
                  icon: Icon[index],
                }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SingleRegionStats;

const Icon = [
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <MdOutlineMapsHomeWork />,
  <VscFileSubmodule />,
  <GrAttraction />,
  <GrRestaurant />,
  <MdOutlineHotelClass />,
  <MdMusicNote />,
  <BsBook />,
  <MdOutlineMuseum />,
  <GrAttraction />,
  <BiCameraMovie />,
  <MdOutlineMuseum />,
  <MdOutlineNightlight />,
  <SiHotelsdotcom />,
  <MdOutlineHotelClass />,
  <AiOutlineShopping />,
  <MdOutlineFoodBank />,
  <BsBook />,
  <BsPeople />,
  <MdOutlineFormatPaint />,
  <GiBallerinaShoes />,
  <GiTheater />,
  <GrAttraction />,
  <BsBook />,
  <HiOutlineDocument />,
  <BsBook />,
  <MdOutlineMapsHomeWork />,
  <GiTheater />,
  <MdOutlineMapsHomeWork />,
  <MdShop />,
];
