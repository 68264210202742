import React, { useState } from "react";
import {
  Title,
  Select,
  GoBack,
  TextArea,
  MyMessage,
  InputField,
  DashboardIntro,
  MyActionButton,
  MyButton,
} from "../../home/component/Component";
import { codeOk } from "../../../middlewares/index";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { getOneGettingAround } from "../../../middlewares/sysNavigation/SysNavigation";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { useGeoPositionContext } from "../utils/geoPosition";
import { _TYPES_ } from "../utils/types";
import Pricing from "../../home/component/Pricing";
import GenerateDescription from "../utils/GenerateDescription";

const Edit = () => {
  const { updateOneContent } = useDoSeeDataContext();
  const gtaround = getOneGettingAround();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const getting_around_id = gtaround?.getting_around_id;
  const [title, setTitle] = useState(gtaround?.title);
  const [thumbnail, setThumbnail] = useState(gtaround?.thumbnail);
  const [thumbnailId, setThumbnailId] = useState(gtaround?.image_cdn_id);
  const [hashtags, setHashtags] = useState(gtaround?.hashtag);
  const [gettingAroundStatus, setGettingAroundStatus] = useState(
    gtaround?.status
  );
  const [description, setDescription] = useState(gtaround?.description);
  const [expo, setExpo] = useState(gtaround?.expo);
  const [pricing, setPricing] = useState(gtaround?.Pricings);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const gtaroundRoutes = "/data/get/around";

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const updateOneGettingAroundHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      country_id: country_id ? country_id : gtaround?.country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      title: title,
      slug: title ? title.toLowerCase().replace(/\s/g, "_") : null,
      description: description,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "",
      hashtag: hashtags,
      status: gettingAroundStatus,
      expo: expo,
      pricing: pricing,
    };

    const { message, status } = await updateOneContent({
      data_id: getting_around_id,
      data: data,
      content: _TYPES_.GETTINGAROUND,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });

    if (status === codeOk) responses(false, message);
    else responses(true, message);
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: gtaroundRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-5">
        <DashboardIntro
          props={{ intro: `Edit Getting Around`, name: entry_label }}
        />
      </div>

      <form onSubmit={updateOneGettingAroundHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.GETTING_AROUND)}
          />

          <Title props={{ title: "Getting Around Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Title props={{ title: "Pricing" }} />

            <Pricing pricing={pricing} setPricing={setPricing} />

            <Select
              props={{
                id: "getting_around_status",
                title: "Status",
                option: ["visible", "invisible"],
                preselect:
                  gettingAroundStatus !== null
                    ? [
                        {
                          name:
                            gettingAroundStatus === 1 ? "visible" : "invisible",
                        },
                      ]
                    : null,
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setGettingAroundStatus(1);
                  else setGettingAroundStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <Select
              props={{
                id: "gallery",
                title: "Show in Gallery",
                option: ["no", "yes"],
                preselect:
                  expo !== null ? [{ name: expo === 0 ? "no" : "yes" }] : null,
                onChange: (e) => {
                  const choice = e.target.value;
                  setExpo(choice === "no" ? 0 : 1);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full pt-4">
            <InputField
              props={{
                id: "hashtag",
                title: "Tags (comma separated)",
                type: "text",
                value: hashtags ? hashtags : "",
                onChange: (e) => {
                  setHashtags(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Save Changes"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
