import React from "react";
import { Auth } from "../../../context/admin/Authentication";
import Screen from "../../home/content/Screen";
import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";
import { PositionProvider } from "../../../context/position/PositionContext";

export const Arts = () => {
  return (
    <PositionProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Arts"
          />
        }
      />
    </PositionProvider>
  );
};

export const AddArt = () => {
  return (
    <Auth
      children={
        <Screen
          component={<Add />}
          title="Add Art"
        />
      }
    />
  );
};

export const EditArt = () => {
  return (
    <Auth
      children={
        <Screen
          component={<Edit />}
          title="Edit Art"
        />
      }
    />
  );
};
