import React from "react";
import { Windows } from "./Menu";
import { Link } from "react-router-dom";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import {
  clearBreadCumb,
  resetBreadCumb,
} from "../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../context/admin/context/AdminContext";
import { clearRoutingHistory } from "../../../middlewares";

const DropDown = () => {
  const {
    sysAdmin: { role },
    selectedMenu,
    setSelectedMenu,
    setSingleAdmin,
  } = useAdminContext();

  return (
    <div className="w-full flex flex-col space-y-1 text-xs">
      {Windows?.map(({ title, menu, icon, roles }, key) => {
        if (!roles?.includes(role)) return null;
        return (
          <div
            key={key}
            className="w-full flex flex-col px-4 cursor-pointer bg-white"
          >
            <div
              onClick={() => setSelectedMenu(key)}
              className={`flex justify-between items-center rounded-t-md px-2 text-base ${
                selectedMenu === key
                  ? "bg-gray-800 hover:bg-gray-600 text-white "
                  : "bg-white hover:bg-gray-800 text-gray-800 hover:text-white hover:rounded-md"
              }`}
            >
              <div className="flex w-full space-x-2 items-center justify-start py-3">
                {icon}
                <p className="text-xs font-bold">{title}</p>
              </div>
              {selectedMenu === key ? <BsChevronDown /> : <BsChevronRight />}
            </div>
            <div
              className={`px-8 text-xs text-black bg-gray-300 py-2 ${
                selectedMenu === key ? "block" : "hidden"
              }`}
            >
              <div className="w-full border-l- flex flex-col space-y-2 py-1 border-gray-700">
                {menu?.map(({ ref, title, roles }, index) => {
                  if (roles && !roles?.includes(role)) return null;
                  return (
                    <Link
                      to={ref}
                      key={index}
                      onClick={() => {
                        clearBreadCumb();
                        resetBreadCumb();
                        setSingleAdmin(null);
                        clearRoutingHistory();
                      }}
                    >
                      <p className="hover:text-green-600">
                        <span className="font-bold">{`->`}</span> {title}
                      </p>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DropDown;
