import React, { useState } from "react";
import {
  Title,
  GoBack,
  MyMessage,
  InputField,
  MyActionButton,
} from "../../component/Component";
import { codeOk } from "../../../../middlewares/index";
import { FOLDERS } from "../../../doseeData/utils/Utils";
import { usePlugImageContext } from "../../../../context/plugImage/PlugImageContext";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";

const Add = () => {
  const { createOnePlugImage } = usePlugImageContext();
  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState(null);
  const plugImageRoutes = "/plug/image";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setThumbnail(null);
    setThumbnailId(null);
    setType(null);
  };

  const responses = (isError, message) => {
    setError(isError);
    setLoading(false);
    setSuccess(!isError);
    setErrorMessage(message);
  };

  const addOnePlugImageHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      responses(true, "Plug Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      type: type,
      photo_url: thumbnail,
      image_cdn_id: thumbnailId,
    };

    const { status, message } = await createOnePlugImage(data);
    if (status === codeOk) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: plugImageRoutes }} />
      <form onSubmit={addOnePlugImageHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <div>
            <ImageKitUpload
              thumbnail={thumbnail}
              thumbnailId={thumbnailId}
              setThumbnail={setThumbnail}
              setThumbnailId={setThumbnailId}
              folder={FOLDERS?.PLUGS}
            />
          </div>

          <Title props={{ title: "Plug Image Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Type",
                type: "text",
                value: type ? type : "",
                onChange: (e) => {
                  setType(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Plug Image"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
