import React, { useState } from "react";
import { FOLDERS } from "../../../doseeData/utils/Utils";
import { codeOk, errorMessage } from "../../../../middlewares/index";
import { useBlogContext } from "../../../../context/blog/BlogContext";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import {
  Title,
  GoBack,
  Message,
  TextArea,
  InputField,
  ActionButton,
  MyButton,
} from "../../component/Component";
import GenerateDescription from "../../../doseeData/utils/GenerateDescription";

const Add = () => {
  const blogRoutes = "/blog";
  const { createOneBlog } = useBlogContext();
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState(null);
  const [error, setError] = useState(false);
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [description, setDescription] = useState(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const cleanFields = () => {
    setTitle(null);
    setAuthor(null);
    setSuccess(true);
    setLoading(false);
    setThumbnail(null);
    setThumbnailId(null);
    setDescription(null);
  };

  const responses = (isError, status, message) => {
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  const addOneBlogHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      return responses(true, 400, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      title,
      author,
      description,
      reading_time: 10,
      slug: title?.toLowerCase().replace(/\s/g, "_"),
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "...",
    };

    const response = await createOneBlog(data);
    if (response) {
      if (response?.data?.status === codeOk || response?.data?.status === 201) {
        cleanFields();
        return responses(true, response?.data?.status, response?.data?.message);
      } else {
        setLoading(false);
        return responses(true, response?.data?.status, response?.data?.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: blogRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <form onSubmit={addOneBlogHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Message
            props={{
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.BLOGS}
          />

          <Title props={{ title: "Blog Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Blog Title",
                type: "text",
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "author",
                title: "Author",
                type: "text",
                value: author ? author : "",
                onChange: (e) => {
                  setAuthor(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <ActionButton
            props={{
              title: "Add Blog",
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
