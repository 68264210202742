/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Search, Pagination } from "../../component/Component";
import { _PAGE_SIZE_ } from "../../../../middlewares/other/tools";
import { usePaymentContext } from "../../../../context/Payment/PaymentContext";

const Overview = () => {
  const [paymentList, setPaymentList] = useState([]);
  const { payment, fetchPayments, count } = usePaymentContext();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(_PAGE_SIZE_);

  useEffect(() => {
    if (!payment) fetchPayments(page, pageSize);
    else setPaymentList(payment);
  }, [payment]);

  const SearchList = (e) => {
    const key = e.target.value.toString().toLowerCase();
    let searchArray = [];
    if (payment !== null && payment !== undefined)
      payment.map((pay) => {
        if (pay?.name.toString().toLowerCase().includes(key))
          return searchArray.push(pay);
        return true;
      });

    setPaymentList(searchArray);
    if (key === "") setPaymentList(payment);
  };

  return (
    <div className="p-3 lg:p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Payment",
            onChange: (e) => SearchList(e),
          }}
        />

        <div className="overflow-y-auto w-full py-8 space-y-5">
          <div className="flex">
            <p className="text-2xl">
              Your Current Total is: <span className="font-bold">USD 0.0</span>{" "}
            </p>
          </div>
          <table className="able-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead className="text-center">
              <tr className="space-x-2">
                <th className="w-1/5 border p-2 font-bold lg:px-4">Title</th>
                <th className="w-1/5 border p-2 font-bold lg:px-4">
                  Content Details
                </th>
                <th className="w-1/5 border p-2 font-bold lg:px-4">Amount</th>
                <th className="w-1/5 min-w-fit border p-2 font-bold lg:px-4">
                  Payment Info
                </th>
                <th className="w-1/5 border p-2 font-bold lg:px-4">Status</th>
              </tr>
            </thead>
            <tbody className="space-x-2 border-gray-300 text-left">
              {paymentList
                ? paymentList?.map((payment, key) => (
                    <tr key={key} className="text-center">
                      <td className="border p-2 px-2 font-bold capitalize lg:px-4">
                        <span className="uppercase text-gray-400">
                          {payment?.content_type?.replace(/-/g, " ")}
                          {" - "}
                        </span>
                        {payment?.content_name}
                      </td>
                      <td className="border p-2 px-2 lg:px-4">
                        <span>Start Date: {payment?.start_date}</span>
                        <br />
                        <span>Start Time: {payment?.start_time}</span>
                      </td>
                      <td className="border p-2 px-2 font-bold uppercase lg:px-4">
                        {payment?.currency} {Number(payment?.amount).toFixed(2)}
                      </td>
                      <td className="border p-2 px-2 font-bold  lg:px-4">
                        <span className="uppercase">
                          {payment?.card_type || "-"} -{" "}
                          {payment?.card_last_4digits || "-"} -{" "}
                          {payment?.card_expiry_date || "-"}
                        </span>
                        <br />
                        <span>{payment?.tx_ref}</span>
                      </td>
                      <td className="border p-2 px-2 lg:px-4">
                        {payment?.status_message === "successful" ? (
                          <span className="font-bold capitalize text-green-700">
                            {payment?.status_message}
                          </span>
                        ) : payment?.status_message === "pending" ? (
                          <span className="font-bold capitalize text-black">
                            {payment?.status_message}
                          </span>
                        ) : (
                          <span className="font-bold capitalize text-red-700">
                            {payment?.status_message}
                          </span>
                        )}
                        <p className="py-1">
                          {new Date(String(payment?.createdAt)).toUTCString()}
                        </p>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          total_items={count}
          callbackFunction={(_page_, _pageSize_) => {
            fetchPayments(_page_, _pageSize_);
          }}
        />
      </div>
    </div>
  );
};

export default Overview;
