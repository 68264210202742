import React from "react";
import {
  getOneCountry,
  getOneLevel,
} from "../../../middlewares/breadCrumb/BreadCrumb";
import { countriesList } from "./data";
import { useAdminContext } from "../../../context/admin/context/AdminContext";

export const DashboardIntro = ({ props }) => {
  const { sysAdmin } = useAdminContext();
  const { name, intro } = props;
  const country = getOneCountry();
  const level = getOneLevel();

  let levelName = null;

  if (level.length > 0 && level[0]?.name !== "") {
    const country_id = level[0]?.data?.data?.country_id;
    const userCountry = countriesList.find((u) => u?.id === country_id);
    country.push(userCountry);

    levelName = level[0]?.name;
  } else if (country.length === 0) {
    const userCountry = countriesList.find((u) => u.name === sysAdmin?.country);
    country.push(userCountry);
  }

  return (
    <div className="flex flex-row justify-center items-center">
      {name === "undefined undefined" ? null : (
        <p className="text-sm font-mono my-4 uppercase underline">
          {intro}{" "}
          {country ? (
            <span className="uppercase font-bold text-lg text-black">
              / {country[0]?.name} {levelName ? ` / ${levelName}` : null}
            </span>
          ) : null}
        </p>
      )}
    </div>
  );
};
