import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useGenerateContentContext } from "../../../context/generateContent";
import { AiOutlineLoading } from "react-icons/ai";

const GenerateDescription = ({ setShowDescriptionModal, setDescription }) => {
  const [prompt, setPrompt] = useState("");
  const [aiContent, setAiContent] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { generateContent } = useGenerateContentContext();

  const sendPrompt = async () => {
    if (!prompt || prompt === "") setIsError(true);

    setLoading(true);
    setIsError(false);
    setAiContent("");

    const response = await generateContent({ data: { message: prompt } });

    setLoading(false);

    if (response) {
      setAiContent(response?.content);
    }
  };

  return (
    <div className="bg-black/60 w-full h-full absolute z-[999999] overflow-y-auto top-0 left-0 flex justify-center items-start p-4">
      <div className="bg-white p-5 rounded-md w-full md:w-80 lg:w-[500px] space-y-4 overflow-y-auto">
        <div className="flex items-center justify-between border-b pb-4">
          <span className="text-base font-bold">Generate Description</span>
          <button
            onClick={() => setShowDescriptionModal(false)}
            className="bg-black text-white p-2 rounded-md cursor-pointer text-base"
          >
            <FaTimes />
          </button>
        </div>
        <div className="text-xs py-2 space-y-2">
          <p className="text-xs font-bold">Enter Your Prompt Here:</p>
          <textarea
            rows="10"
            value={prompt}
            onChange={(e) => {
              setIsError(false);
              setPrompt(e.target.value);
            }}
            className={`border w-full text-xs rounded-md outline-none p-4 ${
              isError ? "border border-red-700" : ""
            }`}
          />
        </div>
        <p className="text-xs font-bold">Generated Result:</p>
        <div className="border text-xs p-4 rounded-md space-y-4">
          <p>{aiContent && aiContent !== "" ? aiContent : "..."}</p>
          <div
            className={`flex w-full justify-end ${
              aiContent ? "border-t pt-4" : ""
            }`}
          >
            <button
              disabled={loading}
              onClick={sendPrompt}
              className="w-auto bg-black text-xs font-bold text-white rounded-md py-2 px-4 disabled:cursor-not-allowed disabled:bg-black/60"
            >
              {loading ? (
                <AiOutlineLoading className="animate-spin" />
              ) : aiContent === "" ? (
                "Generate"
              ) : (
                "Re-Generate"
              )}
            </button>
          </div>
        </div>

        <div className="py-4">
          <button
            disabled={loading || isError || aiContent === ""}
            onClick={() => {
              setDescription(aiContent);
              setShowDescriptionModal(false);
            }}
            className="w-full bg-black text-xs font-bold uppercase text-white rounded-md py-3 px-2 disabled:cursor-not-allowed disabled:bg-black/60"
          >
            Insert Description
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenerateDescription;
