import React, { useState } from "react";

import {
  Title,
  Select,
  GoBack,
  Message,
  InputField,
  PhoneInput,
  ActionButton,
  TextArea,
} from "../../component/Component";

import {
  codeOk,
  codeCreate,
  errorMessage,
} from "../../../../middlewares/index";

// import { validateFields } from '../../../../controllers/tourPackage/tourPackageControllers';

import { useTeamContext } from "../../../../context/team/TeamContext";
import { defaultImage } from "../../../../middlewares/libraries/imageKit/ImageKit";
import { ImageKitUpload } from "../../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS } from "../../../doseeData/utils/Utils";

const Add = () => {
  const { createOneTeam } = useTeamContext();

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(0);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [firstname, setFirstName] = useState(null);
  const [lastname, setLastName] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [address, setAddress] = useState(null);
  const [phonenumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [description, setDescription] = useState(null);
  const [teamStatus, setTeamStatus] = useState(1);

  const teamRoutes = "/team";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setFirstName(null);
    setLastName(null);
    setCountry(null);
    setCity(null);
    setAddress(null);
    setPhoneNumber(null);
    setThumbnail(null);
    setThumbnailId(null);
    setDescription(null);
  };

  const responses = (isError, status, message) => {
    // Set Message and Status
    setError(isError);
    setStatus(status);
    setErrorMessage(message);
  };

  const addOneTeamHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      return responses(true, 400, "Image Required");
    } else {
      // Sending Request
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      email_address: email,
      firstname: firstname,
      lastname: lastname,
      password: "000000",
      country: country,
      city: city,
      address: address,
      phone_number: phonenumber,
      role: role,
      description: description,
      status: teamStatus,
      thumbnail: thumbnail ? thumbnail : defaultImage,
      image_cdn_id: thumbnailId ? thumbnailId : "...",
    };

    const response = await createOneTeam(data);
    // console.log(response);
    if (response) {
      // console.log('success uploading');
      if (
        response.data.status === codeOk ||
        response.data.status === codeCreate
      ) {
        // Clean all filds that were holding data
        cleanFields();
        return responses(true, response.data.status, response.data.message);
      } else {
        setLoading(false);
        return responses(true, response.data.status, response.data.message);
      }
    } else {
      setLoading(false);
      return responses(true, 400, errorMessage);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <GoBack props={{ route: teamRoutes }} />
      <form onSubmit={addOneTeamHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <Message
            props={{
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={FOLDERS.TEAM}
          />

          <Title props={{ title: "Team Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "firstname",
                title: "First Name",
                type: "text",
                value: firstname ? firstname : "",
                onChange: (e) => {
                  setFirstName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "lastname",
                title: "Last Name",
                type: "text",
                value: lastname ? lastname : "",
                onChange: (e) => {
                  setLastName(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "role",
                title: "Role",
                type: "text",
                value: role ? role : "",
                onChange: (e) => {
                  setRole(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
            <InputField
              props={{
                id: "country",
                title: "Country",
                type: "text",
                value: country ? country : "",
                onChange: (e) => {
                  setCountry(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />{" "}
            <InputField
              props={{
                id: "city",
                title: "City",
                type: "text",
                value: city ? city : "",
                onChange: (e) => {
                  setCity(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />{" "}
            <InputField
              props={{
                id: "address",
                title: "Address",
                type: "text",
                value: address ? address : "",
                onChange: (e) => {
                  setAddress(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Contact Info" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
            <div className="flex flex-row justify-center items-center">
              <PhoneInput
                setPhone={setPhoneNumber}
                phone={phonenumber}
                required={true}
              />
            </div>
            <InputField
              props={{
                id: "email",
                title: "Email Address",
                type: "email",
                required: true,
                value: email ? email : "",
                onChange: (e) => {
                  setEmail(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "More Settings" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "event_status",
                title: "Status",
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setTeamStatus(1);
                  else setTeamStatus(0);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <ActionButton
            props={{
              title: "Add Member",
              error: error,
              success: success,
              message: message,
              loading: loading,
              status: status,
              onClick: () => {
                setError(false);
                setSuccess(false);
                setErrorMessage("");
              },
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
