/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { countriesList } from "../../home/component/data";
import { useAdminContext } from "../../../context/admin/context/AdminContext";
import { useCountryContext } from "../../../context/country/countryContext/CountryContext";
import {
  getOneLevel,
  getOneCountry,
} from "../../../middlewares/breadCrumb/BreadCrumb";
import { getCountry } from "../../../middlewares/sysNavigation/SysNavigation";
import { useCurrencyContext } from "../../../context/currency/CurrencyContext";

export const useGeoPositionContext = () => {
  const { sysAdmin } = useAdminContext();
  const { countries, fetchCountries } = useCountryContext();
  const { currency, fetchCurrencies } = useCurrencyContext();

  useEffect(() => {
    if (!currency) fetchCurrencies();
  }, [currency]);

  let defaultCurrency = undefined;

  if (getCountry()) {
    defaultCurrency = currency?.find(
      (x) =>
        String(x.name).toLowerCase() ===
        String(getCountry()?.country?.name).toLowerCase()
    )?.currency_symbol;
  } else if (getOneLevel().length > 0) {
    defaultCurrency = currency?.find(
      (x) => x?.country_id === getOneLevel()[0]?.data?.data?.country_id
    )?.currency_symbol;
  } else {
    defaultCurrency = currency[0]?.currency_symbol;
  }

  const our_level = getOneLevel();
  const our_country = getOneCountry();

  if (our_country.length === 0) {
    const userCountry = countriesList.find((u) => u.name === sysAdmin?.country);
    our_country.push(userCountry);
  }

  const country_name =
    our_country && our_country?.length > 0 ? our_country[0]?.slug : "uganda";

  // Getting the country name for levels
  useEffect(() => {
    if (!countries && our_country?.length === 0) fetchCountries();
  }, [countries]);

  let entry_label = undefined;
  let country_id = 0;
  let first_level_id = 0;
  let third_level_id = 0;
  let second_level_id = 0;
  let fourth_level_id = 0;
  // In case we have level data, we use it as entry point beacause
  // It has to be the last inserted
  if (our_level.length > 0 && our_level[0].level !== "") {
    entry_label = our_level[0]?.name;
    // Getting ids
    const label = our_level[0]?.level;
    const data = our_level[0]?.data?.data;
    if (label === "First") {
      country_id = data?.country_id;
      first_level_id = data?.first_level_id;
    }
    if (label === "Second") {
      country_id = data?.country_id;
      first_level_id = data?.first_level_id;
      second_level_id = data?.second_level_id;
    }
    if (label === "Third") {
      country_id = data?.country_id;
      first_level_id = data?.first_level_id;
      second_level_id = data?.second_level_id;
      third_level_id = data?.third_level_id;
    }
    if (label === "Fourth") {
      country_id = data?.country_id;
      first_level_id = data?.first_level_id;
      second_level_id = data?.second_level_id;
      third_level_id = data?.third_level_id;
      fourth_level_id = data?.fourth_level_id;
    }
  } else {
    // Otherwise if any level is detected then, we use the country data since
    // It is the only available choice remaining
    entry_label = our_country[0]?.name;
    country_id = our_country[0]?.id;
  }

  let tags = "";
  if (sysAdmin?.company_name) {
    tags = `${country_name}, ${entry_label}, ${sysAdmin?.company_name}`;
  } else {
    tags = `${country_name}, ${entry_label}`;
  }

  return {
    first_level_id,
    second_level_id,
    third_level_id,
    fourth_level_id,
    entry_label,
    country_id,
    country_name,
    tags,
    currency: defaultCurrency,
  };
};
