import React, { createContext, useContext, useState } from "react";
import Service from "../../../api/service";
import { useAdminContext } from "../../admin/context/AdminContext";

const DashboardLevelContext = createContext();
export const useDashboardLevelContext = () => useContext(DashboardLevelContext);

export const DashboardLevelProvider = (props) => {
  const [statsLevel, setStatsLevel] = useState(null);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const fetchLevelData = async (level_id, level) => {
    let url = "";
    if (level === "First") {
      url = `/api/v1/stats/first/level/${level_id}`;
    } else if (level === "Second") {
      url = `/api/v1/stats/second/level/${level_id}`;
    } else if (level === "Third") {
      url = `/api/v1/stats/third/level/${level_id}`;
    } else if (level === "Fourth") {
      url = `/api/v1/stats/fourth/level/${level_id}`;
    }

    await Service.get(url, token).then(({ data }) => {
      setStatsLevel(data);
    });
  };

  return (
    <DashboardLevelContext.Provider value={{ statsLevel, fetchLevelData }}>
      {props.children}
    </DashboardLevelContext.Provider>
  );
};
