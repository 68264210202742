import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const BaseLevelContext = createContext();
export const useBaseLevelContext = () => useContext(BaseLevelContext);
export const BaseLevelProvider = (props) => {
  const [count, setCount] = useState(0);
  const [allLevels, setAllLevels] = useState(null);
  const [singleLevel, setSingleLevel] = useState(null);
  const [temporaryLevel, setTemporaryLevel] = useState(null);
  const [temporaryLevelName, setTemporaryLevelName] = useState(null);

  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  /**
   * Fetch all base levels
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchLevels = async ({ page = 1, pageSize = 50, level }) => {
    const url = `/api/v1/${level}/level/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setAllLevels(data);
      setCount(count);
    });
  };

  /**
   * Get levels by country id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchBaseByCountryId = async ({
    level,
    country_id,
    page = 1,
    pageSize = 20,
  }) => {
    const url = `/api/v1/${level}/level/location/?page=${page}&pageSize=${pageSize}&country_id=${country_id}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setAllLevels(data);
      setCount(count);
    });
  };

  /**
   * Get levels by country id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getLevelsByCountryId = async ({
    level,
    country_id,
    page = 1,
    pageSize = 20,
  }) => {
    const url = `/api/v1/${level}/level/location/?page=${page}&pageSize=${pageSize}&country_id=${country_id}`;
    const { data, error } = await Service.get(url, token);
    return { data: data?.data, error };
  };

  /**
   * Get a single level with metadata
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchOneLevel = async ({ level_id, level }) => {
    const url = `/api/v1/${level}/level/metadata/${level_id}`;
    await Service.get(url, token).then(({ data }) => {
      setSingleLevel(data);
    });
  };

  /**
   * create one level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const create = async ({ data, level }) => {
    const url = `/api/v1/admin/${level}/level/create`;
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * update one level
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const update = async ({ level_id, data, level }) => {
    const url = `/api/v1/admin/${level}/level/update/${level_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete one level by id
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteOne = async ({ level_id, level }) => {
    const url = `/api/v1/admin/${level}/level/delete/${level_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <BaseLevelContext.Provider
      value={{
        count,
        allLevels,
        singleLevel,
        temporaryLevel,
        temporaryLevelName,
        create,
        update,
        fetchLevels,
        fetchOneLevel,
        fetchBaseByCountryId,
        deleteOne,
        setAllLevels,
        setTemporaryLevel,
        setTemporaryLevelName,
        getLevelsByCountryId,
      }}
    >
      {props.children}
    </BaseLevelContext.Provider>
  );
};
