import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { useAdminContext } from "../../../context/admin/context/AdminContext";

const PromoteContent = ({
  onClick,
  ids = [],
  visible,
  setVisible,
  loading,
}) => {
  // Exclude
  // 'Music',
  // 'Ministry',
  // 'TourPackage',
  // 'GettingAround',
  // 'Neighborhood',
  // 'Product',

  const { sysAdmin } = useAdminContext();
  const isSuperAdmin = ["super_admin", "admin"].includes(sysAdmin?.role);

  const [selectedPosition, setSelectedPosition] = useState(-1);

  if (!visible || !isSuperAdmin) return null;

  const Positions = [
    {
      position: "1",
      state: ids?.find((content) => parseInt(content?.position) === 1),
      title:
        ids?.find((content) => parseInt(content?.position) === 1)?.title ||
        null,
    },
    {
      position: "2",
      state: ids?.find((content) => parseInt(content?.position) === 2),
      title:
        ids?.find((content) => parseInt(content?.position) === 2)?.title ||
        null,
    },
    {
      position: "3",
      state: ids?.find((content) => parseInt(content?.position) === 3),
      title:
        ids?.find((content) => parseInt(content?.position) === 3)?.title ||
        null,
    },
    {
      position: "4",
      state: ids?.find((content) => parseInt(content?.position) === 4),
      title:
        ids?.find((content) => parseInt(content?.position) === 4)?.title ||
        null,
    },
    {
      position: "5",
      state: ids?.find((content) => parseInt(content?.position) === 5),
      title:
        ids?.find((content) => parseInt(content?.position) === 5)?.title ||
        null,
    },
    {
      position: "6",
      state: ids?.find((content) => parseInt(content?.position) === 6),
      title:
        ids?.find((content) => parseInt(content?.position) === 6)?.title ||
        null,
    },
    {
      position: "7",
      state: ids?.find((content) => parseInt(content?.position) === 7),
      title:
        ids?.find((content) => parseInt(content?.position) === 7)?.title ||
        null,
    },
    {
      position: "8",
      state: ids?.find((content) => parseInt(content?.position) === 8),
      title:
        ids?.find((content) => parseInt(content?.position) === 8)?.title ||
        null,
    },
    {
      position: "9",
      state: ids?.find((content) => parseInt(content?.position) === 9),
      title:
        ids?.find((content) => parseInt(content?.position) === 9)?.title ||
        null,
    },
    {
      position: "10",
      state: ids?.find((content) => parseInt(content?.position) === 10),
      title:
        ids?.find((content) => parseInt(content?.position) === 10)?.title ||
        null,
    },
    {
      position: "11",
      state: ids?.find((content) => parseInt(content?.position) === 11),
      title:
        ids?.find((content) => parseInt(content?.position) === 11)?.title ||
        null,
    },
    {
      position: "12",
      state: ids?.find((content) => parseInt(content?.position) === 12),
      title:
        ids?.find((content) => parseInt(content?.position) === 12)?.title ||
        null,
    },
    {
      position: "13",
      state: ids?.find((content) => parseInt(content?.position) === 13),
      title:
        ids?.find((content) => parseInt(content?.position) === 13)?.title ||
        null,
    },
    {
      position: "14",
      state: ids?.find((content) => parseInt(content?.position) === 14),
      title:
        ids?.find((content) => parseInt(content?.position) === 14)?.title ||
        null,
    },
    {
      position: "15",
      state: ids?.find((content) => parseInt(content?.position) === 15),
      title:
        ids?.find((content) => parseInt(content?.position) === 15)?.title ||
        null,
    },
    {
      position: "16",
      state: ids?.find((content) => parseInt(content?.position) === 16),
      title:
        ids?.find((content) => parseInt(content?.position) === 16)?.title ||
        null,
    },
  ];

  return (
    <div className="bg-black/20 w-full h-full absolute z-[999999] top-0 left-0 flex justify-center items-start p-4">
      <div className="bg-white p-5 rounded-md w-full md:w-80 lg:w-[500px] space-y-5 overflow-y-auto">
        <div className="flex items-center justify-between border-b pb-4">
          <span className="text-base font-bold">Promote Content</span>
          <button
            onClick={() => {
              setVisible(false);
              setSelectedPosition(-1);
            }}
            className="bg-black text-white p-2 rounded-md cursor-pointer text-2xl"
          >
            <FaTimes />
          </button>
        </div>
        <div className="">
          <p>Select Position</p>
          <div className="flex items-center space-x-2">
            <p>- Position Taken</p>
            <div className="w-2 h-2 bg-black"></div>
          </div>
          <div className="flex items-center space-x-2">
            <p>- Position Empty</p>
            <div className="w-2 h-2 border border-black"></div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 text-sm text-white">
          {Positions?.map((position, key) => (
            <div key={key} className="h-full">
              <div
                onClick={() => setSelectedPosition(key)}
                className={`${
                  selectedPosition === key
                    ? "bg-primary text-white border-primary"
                    : position.state
                    ? "bg-black border-black"
                    : "text-black border-black"
                } border-4 h-full w-full flex flex-col justify-center items-center px-4 py-3 rounded-md cursor-pointer`}
              >
                <span>{String(position.position).padStart(2, "0")}</span>
                <p className="text-xs line-clamp-1 text-center">
                  {position?.title || ""}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="py-4">
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <AiOutlineLoading className="animate-spin text-2xl text-primary font-extrabold" />
            </div>
          ) : (
            <button
              disabled={selectedPosition === -1}
              onClick={() =>
                selectedPosition !== -1 ? onClick(selectedPosition + 1) : null
              }
              className="w-full bg-primary text-white rounded-md py-4 px-2 border-4 border-primary disabled:cursor-not-allowed"
            >
              Promote{" "}
              {selectedPosition !== -1
                ? ` ( at Position: ${selectedPosition + 1} ) `
                : null}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoteContent;
