import React from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const TradeOrganizationCard = ({
  trades,
  tradesID,
  setError,
  setTrades,
  setSuccess,
  setTradesID,
  setIsTradeChanges,
  localTradeOrganizations,
}) => {
  return (
    <div className="flex flex-col space-y-4 h-full w-full">
      <p className="font-bold text-lg">Trade Organizations</p>
      <div className="w-full h-auto text-sm py-2 grid grid-cols-1 md:grid-cols-2 gap-4">
        {localTradeOrganizations?.map((m, key) => {
          return (
            <div
              key={key}
              className="flex flex-col items-start justify-center capitalize space-y-2 w-full h-full"
            >
              <p className="text-sm font-bold">{m?.name}</p>
              <p className="text-xs">{m?.description}</p>
              {trades?.includes(String(m?.name)) ? (
                <MdCheckBox
                  className="text-3xl cursor-pointer"
                  onClick={() => {
                    setTrades(trades?.filter((trName) => trName !== m?.name));
                    setTradesID(
                      tradesID?.filter(
                        (trID) => trID !== m?.trade_organization_id
                      )
                    );
                    setIsTradeChanges(1);
                    setError(null);
                    setSuccess(null);
                  }}
                />
              ) : (
                <MdCheckBoxOutlineBlank
                  className="text-3xl cursor-pointer"
                  onClick={() => {
                    const pNames = trades ? [...trades] : [];
                    const pIDs = tradesID ? [...tradesID] : [];

                    pNames.push(m?.name);
                    pIDs.push(m?.trade_organization_id);
                    setTrades(pNames);
                    setTradesID(pIDs);
                    setIsTradeChanges(1);
                    setError(null);
                    setSuccess(null);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TradeOrganizationCard;
