/* eslint-disable react-hooks/exhaustive-deps */
import MonthPicker from "./MonthPicker";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getPageRouteFromKey } from "./routesHelper";
import { pushLevel, setAdminOption } from "../../../../middlewares";
import { useStaticContext } from "../../../../context/static/StaticContext";
import { addOneLevel } from "../../../../middlewares/breadCrumb/BreadCrumb";
import { useAdminContext } from "../../../../context/admin/context/AdminContext";
import { getType } from "./helper";

const AdminComponent = () => {
  const { getStats, stats } = useStaticContext();
  const { sysAdmin, setSingleAdmin } = useAdminContext();

  const fullName = `${sysAdmin?.first_name} - ${sysAdmin?.last_name}`;
  const admin_id = sysAdmin?.admin_id;

  const [localStatistics, setLocalSetStats] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!stats) {
      loadStatistics({
        year: currentYear,
        month: currentMonth,
      });
    } else {
      setLocalSetStats(buildArrayOfKeysAndValuesFromAdminObjectData(stats));
    }
  }, [stats]);

  const buildArrayOfKeysAndValuesFromAdminObjectData = (obj) => {
    const result = [];
    let total_items = 0;
    for (const key in obj) {
      if (
        ![
          "email",
          "admin_id",
          "last_name",
          "first_name",
          "phonenumber",
        ].includes(String(key))
      ) {
        total_items += obj[key];
        result.push({
          title: key,
          value: obj[key],
          route: getPageRouteFromKey({ key }),
        });
      }
    }
    setTotal(total_items);
    return result;
  };

  const loadStatistics = async ({ year, month }) => {
    await getStats({
      year,
      month,
      data: { ids: [sysAdmin?.admin_id] },
    });
  };

  return (
    <div className="flex flex-col w-full space-y-4">
      <div className="text-sm text-black uppercase flex flex-col justify-center space-y-2">
        <MonthPicker
          currentYear={currentYear}
          currentMonth={currentMonth}
          setTotal={setTotal}
          setCurrentYear={setCurrentYear}
          loadStatistics={loadStatistics}
          setCurrentMonth={setCurrentMonth}
          setLocalSetStats={setLocalSetStats}
        />
      </div>
      <div className="flex flex-row items-center justify-between text-lg font-bold uppercase pt-4">
        <p>My Statistics</p>
        <p className="text-xs md:text-sm font-normal text-gray-600">
          Total Items:{" "}
          <span className="font-bold text-xs md:text-base text-blue-700">
            [ {total} Items ]
          </span>
        </p>
      </div>
      {localStatistics ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-5 w-full border border-gray-200 p-4">
          {localStatistics?.map((stat, index) => {
            return (
              <Link
                key={index}
                to={stat.route}
                onClick={() => {
                  setSingleAdmin({
                    ids: [admin_id],
                    year: currentYear,
                    month: currentMonth,
                    type: getType({ field: stat?.title }),
                    route: "/content/stats",
                  });
                  // Breadcumb
                  addOneLevel({
                    id: 0,
                    name: fullName,
                    route: "/levels/first",
                    level: "",
                    country_id: 0,
                    data: {
                      data: {
                        name: "",
                        country_id: 0,
                        first_level_id: 0,
                        second_level_id: 0,
                        third_level_id: 0,
                        fourth_level_id: 0,
                        level: "",
                      },
                    },
                  });
                  // Level For Add or Edit
                  pushLevel({
                    level: {
                      id: 0,
                      name: "",
                      level: "All",
                      route: "/levels/first",
                      data: null,
                    },
                  });
                  setAdminOption(sysAdmin?.admin_id);
                }}
              >
                <div className="flex capitalize text-xs justify-between font-bold space-x-2 hover:underline items-center border border-gray-200 p-2">
                  <p>{stat?.title?.replace(/_/g, " ")}</p>
                  <p className="bg-gray-800 text-white flex justify-center items-center px-3 py-2">
                    {stat?.value}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center items-center w-full py-5">
          <p className="text-xs font-bold">Loading...</p>
        </div>
      )}
    </div>
  );
};

export default AdminComponent;
