import React, { createContext, useContext, useState } from "react";
import Service from "../../../api/service";
import ENDPOINTS from "../../../api/endpoint";

const AdminContext = createContext();

export const useAdminContext = () => useContext(AdminContext);

export const AdminProvider = (props) => {
  const [sysAdmin, setSysAdmin] = useState(null);
  const [singleAdmin, setSingleAdmin] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [companyName, setCompanyName] = useState(null);

  const token = sysAdmin?.token;
  const admin_id = sysAdmin?.admin_id;

  /**
   * @author Ntavigwa Bashombe
   * @param {*} data
   * @returns
   */
  const adminLogin = async (requestData) => {
    const { data, message, status } = await Service.post(
      ENDPOINTS.LOGIN_URL,
      requestData,
      null
    );
    setSysAdmin(data ? data : null);
    if (data) localStorage.setItem("auth", JSON.stringify(data));
    setCompanyName(data ? data?.company_name : null);
    return { message, status };
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} data
   * @returns
   */
  const adminSignUp = async (requestData) => {
    const { data, message, status } = await Service.post(
      ENDPOINTS.SIGNUP_URL,
      requestData,
      null
    );
    setSysAdmin(data ? data : null);
    if (data) localStorage.setItem("auth", JSON.stringify(data));
    setCompanyName(data ? data?.company_name : null);
    return { message, status };
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} data
   * @returns
   */
  const superAdminCreateCompany = async (requestData) => {
    const { data, message, status } = await Service.post(
      ENDPOINTS.SIGNUP_URL,
      requestData,
      null
    );
    return { message, status, data };
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} data
   * @returns
   */
  const updateBySuperAdmin = async (company_id, requestData) => {
    const { data, message, status } = await Service.put(
      `${ENDPOINTS.UPDATE_BY_SUPER_ADMIN}/${company_id}`,
      requestData,
      token
    );
    return { message, status, data };
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} data
   * @returns
   */
  const updateAdminPassword = async (requestData) => {
    const { data, message, status } = await Service.put(
      `${ENDPOINTS.UPDATE_PASSWORD}/${admin_id}`,
      requestData,
      token
    );
    return { message, status, data };
  };

  /**
   * @author Ntavigwa Bashombe
   * Should log out the admin
   */
  const logOut = async () => {
    setSysAdmin(null);
    localStorage.removeItem("auth");
    localStorage.clear();
    window?.location?.reload();
  };

  return (
    <AdminContext.Provider
      value={{
        sysAdmin,
        singleAdmin,
        companyName,
        selectedMenu,
        logOut,
        adminLogin,
        adminSignUp,
        setSingleAdmin,
        setSelectedMenu,
        setSysAdmin,
        superAdminCreateCompany,
        updateBySuperAdmin,
        updateAdminPassword,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};
