import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { LanguageProvider } from "../../../../context/language/LanguageContext";

export const Language = () => {
  return (
    <LanguageProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Language"
          />
        }
      />
    </LanguageProvider>
  );
};

export const AddLanguage = () => {
  return (
    <LanguageProvider>
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add Language"
          />
        }
      />
    </LanguageProvider>
  );
};

export const EditLanguage = () => {
  return (
    <LanguageProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Language"
          />
        }
      />
    </LanguageProvider>
  );
};
