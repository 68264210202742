import React from "react";
import { MY_ROUTES_COLLECTION } from "./exportRoutes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const Routing = () => {
  return (
    <Router>
      <Routes>
        {MY_ROUTES_COLLECTION?.map((route, key) => {
          return (
            <Route
              key={key}
              path={route?.route}
              element={route?.component}
            />
          );
        })}
      </Routes>
    </Router>
  );
};

export default Routing;
