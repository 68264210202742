import React from "react";

import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";

export const Currency = () => {
  return (
    <Auth children={<Screen component={<Overview />} title="Currency" />} />
  );
};

export const AddCurrency = () => {
  return (
    <Auth children={<Screen component={<Add />} title="Add Currency" />} />
  );
};

export const EditCurrency = () => {
  return (
    <Auth children={<Screen component={<Edit />} title="Edit Currency" />} />
  );
};
