import React from "react";

import { Auth } from "../../../../context/admin/Authentication";
import Edit from "./Edit";

import Screen from "../Screen";
import Overview from "./Overview";
import { StaticProvider } from "../../../../context/static/StaticContext";

export const Footer = () => {
  return (
    <StaticProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Footer"
          />
        }
      />
    </StaticProvider>
  );
};

export const EditFooter = () => {
  return (
    <StaticProvider>
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit Footer"
          />
        }
      />
    </StaticProvider>
  );
};
