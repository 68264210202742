import React from "react";

import { Auth } from "../../../../context/admin/Authentication";

import Screen from "../Screen";

import Overview from "./Overview";
import Add from "./Add";
import Edit from "./Edit";

import { PlugImageProvider } from "../../../../context/plugImage/PlugImageContext";
import { CountryProvider } from "../../../../context/country/countryContext/CountryContext";

export const PlugImage = () => {
  return (
    <PlugImageProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="Plug Image"
          />
        }
      />
    </PlugImageProvider>
  );
};

export const AddPlugImage = () => {
  return (
    <PlugImageProvider>
      <CountryProvider>
        <Auth
          children={
            <Screen
              component={<Add />}
              title="Add Plug Image"
            />
          }
        />
      </CountryProvider>
    </PlugImageProvider>
  );
};

export const EditPlugImage = () => {
  return (
    <PlugImageProvider>
      <CountryProvider>
        <Auth
          children={
            <Screen
              component={<Edit />}
              title="Edit Plug Image"
            />
          }
        />
      </CountryProvider>
    </PlugImageProvider>
  );
};
