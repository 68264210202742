import React from "react";
import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { UserProvider } from "../../../../context/user/UserContext";
// import { useAdminContext } from "../../../../context/admin/context/AdminContext";
// import { Navigate } from "react-router-dom";

// Redirect to home
// const ReturnHome = () => {
//   const { sysAdmin } = useAdminContext();
//   if (sysAdmin.role !== "admin")
//     return (
//       <Navigate
//         replace
//         to="/"
//       />
//     );
//   else return null;
// };

export const User = () => {
  return (
    <UserProvider>
      {/* <ReturnHome />; */}
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="User"
          />
        }
      />
    </UserProvider>
  );
};

export const AddUser = () => {
  return (
    <UserProvider>
      {/* <ReturnHome />; */}
      <Auth
        children={
          <Screen
            component={<Add />}
            title="Add User"
          />
        }
      />
    </UserProvider>
  );
};

export const EditUser = () => {
  return (
    <UserProvider>
      {/* <ReturnHome />; */}
      <Auth
        children={
          <Screen
            component={<Edit />}
            title="Edit User"
          />
        }
      />
    </UserProvider>
  );
};
