export const homeAttr = 'home_attractions';
export const homeEv = 'home_events';
export const homeTour = 'home_tour_packages';
export const homeSaf = 'home_safari';
export const homeHot = 'home_hotels';
export const homeBest = 'home_best_of_africa';
export const happenToday = 'happenning_today';
export const latestNews = 'latest_news';
export const thingsToKnow = 'things_to_know';
export const events = 'events';
export const attractions = 'attractions';
export const safari = 'safari';
export const restaurants = 'restaurants';
export const musics = 'musics';
export const cinema = 'cinema';
export const news = 'news';
export const neighborhoods = 'neighborhoods';
export const dances = 'dances';
export const foods = 'foods';
export const thingsTodo = 'things_todo';
export const movies = 'movies';
export const cityGuide = 'city_guide';
export const hotels = 'hotels';
export const arts = 'arts';
export const parties = 'parties';
export const shoppings = 'shoppings';
export const theaters = 'theaters';
export const tipsAdvices = 'tips_advices';
export const classes = 'classes';
export const nightlives = 'nightlives';
export const museums = 'museums';
export const ministries = 'ministries';
