/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Modal, Search, MyButton } from "../../../component/Component";
import { addOneRegion } from "../../../../../middlewares/breadCrumb/BreadCrumb";
import { BreadCrumbComponent } from "../../../../../middlewares/breadCrumb/BreadCrumbComponent";
import { useContinentRegionContext } from "../../../../../context/continentRegion/context/ContinentRegionContext";
import {
  pushRegion,
  pushCurrentRegion,
} from "../../../../../middlewares/sysNavigation/SysNavigation";

const addRoute = "/region/add";
const editRegionRoute = "/region/edit";
const singleRegionDash = "/region/dashboard";
const regionCountries = "/countries";

const Overview = () => {
  const [regionsList, setRegionsList] = useState([]);
  const { regions, fetchRegions } = useContinentRegionContext();

  useEffect(() => {
    if (!regions) fetchRegions();
    else setRegionsList(regions);
  }, [regions]);

  const SearchList = (e) => {
    const key = String(e.target.value).toLowerCase();
    const searchArray = [];
    if (regions) {
      regions?.map((region) => {
        if (String(region?.name).toLowerCase().includes(key)) {
          searchArray.push(region);
        }
        return true;
      });
    }

    setRegionsList(searchArray);
    if (key === "") setRegionsList(regions);
  };

  return (
    <div className="p-3 lg:p-4  h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent default_value={true} />
      <div className="flex flex-col justify-center">
        <Search
          props={{
            title: "Region",
            onChange: (e) => SearchList(e),
          }}
        />
        <div className="w-full flex justify-end mt-4 mb-3">
          <Link to={addRoute}>
            <MyButton
              title="Add Region"
              className="bg-gray-800 hover:bg-gray-600 min-w-fit"
              onClick={() => {}}
            />
          </Link>
        </div>

        <div className="mb-5 overflow-y-auto w-full py-2">
          <table className="table-auto w-full border-collapse border border-gray-300 text-xs text-left">
            <thead>
              <tr className="space-x-2">
                <th className="border p-2 lg:px-4 font-bold w-1/4">Name</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">Slug</th>
                <th className="border p-2 lg:px-4 font-bold w-1/4">
                  No. Of Countries
                </th>
                <th className="border text-center w-1/4">Action</th>
              </tr>
            </thead>
            <tbody className="space-x-2 text-left border-gray-300">
              {regions ? (
                regionsList?.map((region, key) => (
                  <tr key={key}>
                    <td className="border px-2 lg:px-4 w-1/4">
                      {region?.name}
                    </td>
                    <td className="border px-2 lg:px-4 w-1/4">
                      {region?.slug}
                    </td>
                    <td className="border px-2 lg:px-4 w-1/4">
                      {region?.country_number}
                    </td>
                    <td className="border w-1/4">
                      <ActionButton props={region} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border px-2 lg:px-4 w-1/4">...</td>
                  <td className="border w-1/4">...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ActionButton = ({ props }) => {
  const { regions, deleteRegion, updateRegionState } =
    useContinentRegionContext();

  const [showModal, setShowModal] = useState(false);
  const continent_region_id = props?.continent_region_id;
  const continent_region_name = props?.name;
  const continent_region = props;

  const deleteOneRegion = async (region_id) => {
    const { status } = await deleteRegion(region_id);
    if (status === 200) {
      if (regions) {
        updateRegionState(
          regions.filter(
            (_region) => _region?.continent_region_id !== region_id
          )
        );
      }
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        props={{
          name: continent_region_name,
          showModal: showModal,
          setShowModal: () => setShowModal(false),
          delete: () => deleteOneRegion(continent_region_id),
        }}
      />

      <div className="flex flex-row justify-end px-2 py-3 space-x-4 text-xs">
        <Link to={singleRegionDash}>
          <MyButton
            title="Dashboard"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => pushCurrentRegion(continent_region)}
          />
        </Link>
        <Link to={regionCountries}>
          <MyButton
            title="Countries"
            className="bg-gray-800 hover:bg-gray-600 min-w-fit"
            onClick={() => {
              addOneRegion({
                id: continent_region_id,
                name: continent_region_name,
                route: "/region/manage",
                data: continent_region,
              });
              pushRegion(continent_region);
            }}
          />
        </Link>
        <Link to={editRegionRoute}>
          <MyButton
            title="Edit"
            className="bg-blue-800 hover:bg-blue-600 min-w-fit"
            onClick={() => pushCurrentRegion(continent_region)}
          />
        </Link>
        <MyButton
          title="Delete"
          className="bg-red-800 hover:bg-red-600 min-w-fit"
          onClick={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

export default Overview;
