import React, { useState } from "react";
import { defaultImage } from "../../../middlewares/libraries/imageKit/ImageKit";
import { GoBack } from "../../home/component/GoBackUtils";
import { MyMessage } from "../../home/component/messageUtils";
import { ImageKitUpload } from "../../../middlewares/libraries/imageKit/ImageKitLibrary";
import { FOLDERS, image_kit_folder } from "../utils/Utils";
import { Title } from "../../home/component/TitleUtils";
import { InputField, Select, TextArea } from "../../home/component/FormUtils";
import { MyActionButton } from "../../home/component/ActionButtonUtils";
import { useGeoPositionContext } from "../utils/geoPosition";
import { BreadCrumbComponent } from "../../../middlewares/breadCrumb/BreadCrumbComponent";
import { DashboardIntro } from "../../home/component/DashboardIntroUtils";
import { useDoSeeDataContext } from "../../../context/doSeeData/DoSeeDataContext";
import { useAdminContext } from "../../../context/admin/context/AdminContext";
import { _TYPES_ } from "../utils/types";
import Pricing from "../../home/component/Pricing";
import GenerateDescription from "../utils/GenerateDescription";
import { MyButton } from "../../home/component/ButtonUtils";

const Add = () => {
  const { sysAdmin } = useAdminContext();
  const { createOneContent } = useDoSeeDataContext();
  const {
    country_id,
    entry_label,
    country_name,
    first_level_id,
    fourth_level_id,
    second_level_id,
    third_level_id,
  } = useGeoPositionContext();

  const [thumbnail, setThumbnail] = useState(defaultImage);
  const [thumbnailId, setThumbnailId] = useState("img_cdn_id");
  const [error, setError] = useState(false);
  const [message, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState("Men");
  const [quantity, setQuantity] = useState(0);
  const [productStatus, setProductStatus] = useState(1);
  const [description, setDescription] = useState(null);
  const [pricing, setPricing] = useState([]);
  const [showDescriptionModal, setShowDescriptionModal] = useState(null);

  const productRoutes = "/data/product";

  const cleanFields = () => {
    setLoading(false);
    setSuccess(true);
    setThumbnail(null);
    setThumbnailId(null);
    setTitle(null);
    setCategory(null);
    setQuantity(null);
    setDescription(null);
    setPricing([]);
  };

  const responses = (error, message) => {
    setError(error);
    setLoading(false);
    setSuccess(!error);
    setErrorMessage(message);
  };

  const addOneProductHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!thumbnail || !thumbnailId) {
      setLoading(false);
      responses(true, 400, "Image Required");
    } else {
      await sendDBRequest();
    }
  };

  /**
   * @author Ntavigwa Bashombe
   * @param {*} thumbnail
   * @returns
   */
  const sendDBRequest = async () => {
    const data = {
      created_by: sysAdmin.admin_id,
      country_id: country_id,
      first_level_id: first_level_id ? first_level_id : 0,
      second_level_id: second_level_id ? second_level_id : 0,
      third_level_id: third_level_id ? third_level_id : 0,
      fourth_level_id: fourth_level_id ? fourth_level_id : 0,
      name: title,
      category: category,
      quantity: quantity,
      images_url: thumbnail ? thumbnail : defaultImage,
      images_cdn_ids: thumbnailId ? thumbnailId : "",
      description: description,
      status: productStatus,
      pricing: pricing,
    };

    const { message, status } = await createOneContent({
      data: data,
      content: _TYPES_.PRODUCT,
      position: {
        country_id,
        first_level_id,
        second_level_id,
        third_level_id,
        fourth_level_id,
      },
    });

    if (status === 200) {
      cleanFields();
      responses(false, message);
    } else {
      responses(true, message);
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto rounded-b-xl bg-gray-50">
      <BreadCrumbComponent />
      <GoBack props={{ route: productRoutes }} />

      {showDescriptionModal ? (
        <GenerateDescription
          setDescription={setDescription}
          setShowDescriptionModal={setShowDescriptionModal}
        />
      ) : null}

      <div className="my-5">
        <DashboardIntro props={{ intro: `Add Product`, name: entry_label }} />
      </div>
      <form onSubmit={addOneProductHandler}>
        <div className="mt-8 flex flex-col items-center justify-center font-medium text-sm md:text-base">
          <MyMessage
            error={error}
            message={message}
            success={success}
            onExpired={() => {
              setError(false);
              setSuccess(false);
            }}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage("");
            }}
          />

          <ImageKitUpload
            thumbnail={thumbnail}
            thumbnailId={thumbnailId}
            setThumbnail={setThumbnail}
            setThumbnailId={setThumbnailId}
            folder={image_kit_folder(country_name, FOLDERS.PROCUCTS)}
          />

          <Title props={{ title: "Product Details" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
            <InputField
              props={{
                id: "title",
                title: "Name",
                type: "text",
                required: true,
                value: title ? title : "",
                onChange: (e) => {
                  setTitle(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <Select
              props={{
                id: "category",
                title: "Category",
                option: [
                  "Men",
                  "Women",
                  "Teen",
                  "Kids",
                  "African shirts",
                  "African pants",
                  "Beads",
                  "Carvings",
                  "Handbags",
                ],
                onChange: (e) => {
                  setCategory(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />

            <InputField
              props={{
                id: "quantity",
                title: "Quantity",
                type: "number",
                required: true,
                value: quantity ? quantity : "",
                onChange: (e) => {
                  setQuantity(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <Title props={{ title: "Pricing" }} />

          <Pricing pricing={pricing} setPricing={setPricing} />

          <Title props={{ title: "Visibility & Description" }} />

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 w-full">
            <Select
              props={{
                id: "event_status",
                title: "Status",
                required: true,
                option: ["visible", "invisible"],
                onChange: (e) => {
                  if (e.target.selectedIndex === 0) setProductStatus(1);
                  else setProductStatus(0);

                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>
          <div className=" w-full pt-8">
            <MyButton
              onClick={() => setShowDescriptionModal(true)}
              className="bg-blue-700 py-3"
            >
              Generate Description
            </MyButton>
            <TextArea
              props={{
                id: "description",
                title: "Description",
                type: "text",
                value: description ? description : "",
                onChange: (e) => {
                  setDescription(e.target.value);
                  setError(null);
                  setSuccess(null);
                },
              }}
            />
          </div>

          <MyActionButton
            loading={loading}
            title="Add Product"
            onClick={() => {
              setError(false);
              setSuccess(false);
              setErrorMessage(null);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default Add;
