import React from "react";

import Overview from "./manage/Overview";
import Content from "./DashboardCities";
import DashboardCity from "./manage/DashboardCity";

import Screen from "../Screen";

import { Auth } from "../../../../context/admin/Authentication";

import { DashboardProvider } from "../../../../context/dashboard/context/DashboardContext";

import {
  CityProvider,
  DashboardLevelProvider,
} from "../../../../context/levels/levelsContext";

export const CitiesDashboard = () => {
  return (
    <DashboardProvider>
      <Auth
        children={
          <Screen
            component={<Content />}
            title="Cities Dashboard"
          />
        }
      />
    </DashboardProvider>
  );
};

export const Cities = () => {
  return (
    <CityProvider>
      <Auth
        children={
          <Screen
            component={<Overview />}
            title="All Cities"
          />
        }
      />
    </CityProvider>
  );
};

export const CityDashboard = () => {
  return (
    <DashboardLevelProvider>
      <Auth
        children={
          <Screen
            component={<DashboardCity />}
            title="City Dashboard"
          />
        }
      />
    </DashboardLevelProvider>
  );
};
