export const ArrayFill = (adsList, value) => {
  // compare if types matches then fill corresponding values
  for (let i = 0; i < adsList.length; i++) {
    const _type_ = adsList[i].position_type;
    const _pos_ = adsList[i].position_number;
    const _id_ = adsList[i].event_id;

    if (_type_ === value)
      return [
        _pos_ === 1 ? _id_ : null,
        _pos_ === 2 ? _id_ : null,
        _pos_ === 3 ? _id_ : null,
        _pos_ === 4 ? _id_ : null,
      ];
  }
  return [null, null, null, null];
};

export const defaultArray = () => [null, null, null, null];
