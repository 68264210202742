import React, { createContext, useContext, useState } from "react";

import Service from "../../api/service";
import { useAdminContext } from "../admin/context/AdminContext";

const RegionalBlocksContext = createContext();

export const useRegionalBlocksContext = () => useContext(RegionalBlocksContext);

export const RegionalBlocksProvider = (props) => {
  const [regionalBlocks, setRegionalBlocks] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();
  const token = sysAdmin?.token;

  const updateRegionalBlocksState = async (data) => {
    return setRegionalBlocks(data);
  };

  /**
   * Fetch all regionalBlocks
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchRegionalBlocks = async (page = 1, pageSize = 50) => {
    const url = `/api/v1/regional/blocks/?page=${page}&pageSize=${pageSize}`;
    await Service.get(url, token).then(({ data: { data, count } }) => {
      setRegionalBlocks(data);
      setCount(count);
    });
  };

  /**
   * Add a new regionalBlocks
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneRegionalBlocks = async (data) => {
    const url = "/api/v1/admin/regional/blocks/create";
    const { message, status } = await Service.post(url, data, token);
    return { message, status };
  };

  /**
   * Update a regionalBlocks
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateRegionalBlocks = async (regionalBlocks_id, data) => {
    const url = `/api/v1/admin/regional/blocks/update/${regionalBlocks_id}`;
    const { message, status } = await Service.put(url, data, token);
    return { message, status };
  };

  /**
   * Delete a regionalBlocks
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteRegionalBlocks = async (regionalBlocks_id) => {
    const url = `/api/v1/admin/regional/blocks/delete/${regionalBlocks_id}`;
    const { message, status } = await Service.delete(url, token);
    return { message, status };
  };

  return (
    <RegionalBlocksContext.Provider
      value={{
        regionalBlocks,
        createOneRegionalBlocks,
        fetchRegionalBlocks,
        updateRegionalBlocks,
        deleteRegionalBlocks,
        updateRegionalBlocksState,
        count,
      }}
    >
      {props.children}
    </RegionalBlocksContext.Provider>
  );
};
