import React from "react";
// import { Navigate } from "react-router-dom";
import Add from "./Add";
import Edit from "./Edit";
import Screen from "../Screen";
import Overview from "./Overview";
import { Auth } from "../../../../context/admin/Authentication";
import { useCompanyContext } from "../../../../context/company/companyContext";
// import { useAdminContext } from "../../../../context/admin/context/AdminContext";

// const ReturnHome = () => {
//   const { sysAdmin } = useAdminContext();
//   if (!sysAdmin || sysAdmin?.role !== "admin")
//     return (
//       <Navigate
//         replace
//         to="/"
//       />
//     );
//   else return null;
// };

export const Admin = () => {
  const { company } = useCompanyContext();
  return (
    <>
      {/* <ReturnHome /> */}
      <Auth
        children={
          <Screen
            component={<Overview />}
            title={
              company
                ? `${company?.name} - Admins Management`
                : "Admin Management"
            }
          />
        }
      />
    </>
  );
};

export const AddAdmin = () => {
  const { company } = useCompanyContext();
  return (
    <>
      {/* <ReturnHome /> */}
      <Auth
        children={
          <Screen
            component={<Add />}
            title={company ? `Add Admins in ${company?.name}` : "Add Admin"}
          />
        }
      />
    </>
  );
};

export const EditAdmin = () => {
  const { company } = useCompanyContext();
  return (
    <>
      {/* <ReturnHome /> */}
      <Auth
        children={
          <Screen
            component={<Edit />}
            title={company ? `Edit Admins in ${company?.name}` : "Edit Admin"}
          />
        }
      />
    </>
  );
};
