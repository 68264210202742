import React, { createContext, useContext, useState } from "react";

import axios from "axios";

import { API_URL } from "../config";
import { useAdminContext } from "../admin/context/AdminContext";

const PageContext = createContext();

export const usePageContext = () => useContext(PageContext);

export const PageProvider = (props) => {
  const [page, setPage] = useState(null);
  const [events, setEvents] = useState(null);
  const [eventsCount, setEventsCount] = useState(null);
  const [attractions, setAttractions] = useState(null);
  const [attractionsCount, setAttractionsCount] = useState(null);
  const [categories, setCategories] = useState(null);
  const { sysAdmin } = useAdminContext();

  const updatePageState = async (data) => {
    // await fetchPages();
    return setPage(data);
  };

  /**
   * Fetch all page
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchPages = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/page/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setPage(response.data.data.pages);
        setCategories(response.data.data.categories);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch all events
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchEvents = async (page = 1, pageSize = 50) => {
    try {
      const token = sysAdmin.token;
      const fetch_url =
        API_URL + `/api/v1/admin/event/list/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) {
        setEvents(response.data.data.data);
        setEventsCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch all attractions
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchAttractions = async (page = 1, pageSize = 50) => {
    try {
      const token = sysAdmin.token;
      const fetch_url =
        API_URL + `/api/v1/attraction/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios
        .get(fetch_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) {
        setAttractions(response.data.data.data);
        setAttractionsCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Fetch all page
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const getCategories = async () => {
    try {
      const fetch_url = API_URL + `/api/v1/page/categories`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });
      if (response) {
        setCategories(response.data.data);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new page
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOnePage = async (data) => {
    try {
      const add_url = API_URL + "/api/v1/admin/page/create";
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) {
        getCategories();
        return response;
      } else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a page
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updatePage = async (data) => {
    try {
      const update_url = API_URL + `/api/v1/admin/page/update`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a page
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deletePage = async (page_id) => {
    try {
      const delete_url = API_URL + `/api/v1/admin/page/delete/${page_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <PageContext.Provider
      value={{
        page,
        createOnePage,
        fetchPages,
        updatePage,
        deletePage,
        updatePageState,
        categories,
        getCategories,
        events,
        fetchEvents,
        eventsCount,
        fetchAttractions,
        attractionsCount,
        attractions,
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};
