export const _TYPES_ = Object.freeze({
  ART: "art",
  NEWS: "news",
  BOOK: "book",
  FOOD: "food",
  MOVIE: "movie",
  PARTY: "party",
  HOTEL: "hotel",
  DANCE: "dance",
  MUSIC: "music",
  EVENT: "event",
  CINEMA: "cinema",
  SAFARI: "safari",
  CLASSES: "class",
  MUSEUM: "museum",
  THEATER: "theater",
  PRODUCT: "product",
  SHOPPING: "shopping",
  MINISTRY: "ministry",
  ADV: "advertisement",
  NIGHTLIFE: "nightlife",
  CITYGUIDE: "cityguide",
  THINGSTODO: "thingstodo",
  RESTAURANT: "restaurant",
  ATTRACTION: "attraction",
  TOURPACKAGE: "tourpackage",
  BESTOFAFRICA: "bestOfAfrica",
  NEIGHBORHOOD: "neighborhood",
  TIPSANDADVICE: "tipsandadvice",
  GETTINGAROUND: "gettingaround",
  COMPANY: "companies",
  EMBASSY: "embassy",
});
