import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';

import { API_URL } from '../config';
import { useAdminContext } from '../admin/context/AdminContext';

const LanguageContext = createContext();

export const useLanguageContext = () => useContext(LanguageContext);

export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState(null);
  const [count, setCount] = useState(0);
  const { sysAdmin } = useAdminContext();

  const updateLanguageState = async (data) => {
    // await fetchLanguages();
    return setLanguage(data);
  };

  /**
   * Fetch all language
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const fetchLanguages = async (page = 1, pageSize = 50) => {
    try {
      const fetch_url =
        API_URL + `/api/v1/language/?page=${page}&pageSize=${pageSize}`;
      let errorResponse = null;
      const response = await axios.get(fetch_url).catch((error) => {
        errorResponse = error.response;
      });

      if (response) {
        setLanguage(response.data.data.data);
        setCount(response.data.data.count);
        return response;
      } else {
        return errorResponse;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Add a new language
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const createOneLanguage = async (data) => {
    try {
      const add_url = API_URL + '/api/v1/admin/language/create';
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .post(add_url, data, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Update a language
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const updateLanguage = async (language_id, data) => {
    try {
      const update_url =
        API_URL + `/api/v1/admin/language/update/${language_id}`;
      const token = sysAdmin.token;

      let errorResponse = null;
      const response = await axios
        .put(update_url, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  /**
   * Delete a language
   *
   * @author Ntavigwa Bashombe
   * @returns response
   */
  const deleteLanguage = async (language_id) => {
    try {
      const delete_url =
        API_URL + `/api/v1/admin/language/delete/${language_id}`;

      const token = sysAdmin.token;
      let errorResponse = null;

      const response = await axios
        .delete(delete_url, { headers: { Authorization: `Bearer ${token}` } })
        .catch((error) => {
          errorResponse = error.response;
        });

      if (response) return response;
      else return errorResponse;
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        createOneLanguage,
        fetchLanguages,
        updateLanguage,
        deleteLanguage,
        updateLanguageState,
        count,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};
