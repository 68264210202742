import { ROUTES } from "./routes";
import * as PAGES from "../pages/pages";

export const MY_ROUTES_COLLECTION = [
  {
    route: ROUTES.HOME,
    component: <PAGES.DashBoard />,
  },
  {
    route: ROUTES.LOGIN,
    component: <PAGES.Login />,
  },
  {
    route: ROUTES.SIGNUP,
    component: <PAGES.SignUp />,
  },
  {
    route: ROUTES.REGIONS,
    component: <PAGES.Regions />,
  },
  {
    route: ROUTES.REGION_MANAGE,
    component: <PAGES.RegionOverview />,
  },
  {
    route: ROUTES.REGION_ADD,
    component: <PAGES.AddRegionComp />,
  },
  {
    route: ROUTES.REGION_EDIT,
    component: <PAGES.EditRegionComp />,
  },
  {
    route: ROUTES.REGION_DASH,
    component: <PAGES.SingleRegionDashboard />,
  },

  {
    route: ROUTES.COUNTRIES,
    component: <PAGES.Countries />,
  },
  {
    route: ROUTES.COUNTRIES_DASH,
    component: <PAGES.CountriesDashboard />,
  },
  {
    route: ROUTES.COUNTRIES_DASH_ID,
    component: <PAGES.CountryDashboard />,
  },
  {
    route: ROUTES.COUNTRIES_ADD,
    component: <PAGES.AddCountry />,
  },
  {
    route: ROUTES.COUNTRIES_EDIT,
    component: <PAGES.EditCountry />,
  },

  {
    route: ROUTES.LEVELS_FIRST,
    component: <PAGES.Levels />,
  },
  {
    route: ROUTES.LEVELS_SECOND,
    component: <PAGES.Levels />,
  },
  {
    route: ROUTES.LEVELS_THIRD,
    component: <PAGES.Levels />,
  },
  {
    route: ROUTES.LEVELS_FOURTH,
    component: <PAGES.Levels />,
  },
  {
    route: ROUTES.LEVELS_DASH,
    component: <PAGES.LevelsDashboard />,
  },
  {
    route: ROUTES.LEVEL_DASH,
    component: <PAGES.LevelDashboard />,
  },
  {
    route: ROUTES.LEVEL_ADD,
    component: <PAGES.AddLevel />,
  },
  {
    route: ROUTES.LEVEL_EDIT,
    component: <PAGES.EditLevel />,
  },

  {
    route: ROUTES.CITIES,
    component: <PAGES.Cities />,
  },
  {
    route: ROUTES.CITIES_DASH,
    component: <PAGES.CitiesDashboard />,
  },
  {
    route: ROUTES.CITY_DASH,
    component: <PAGES.CityDashboard />,
  },

  {
    route: ROUTES.DATA,
    component: <PAGES.DataContent />,
  },

  {
    route: ROUTES.DATA_EVENT,
    component: <PAGES.Events />,
  },
  {
    route: ROUTES.DATA_EVENT_ADD,
    component: <PAGES.AddEvent />,
  },
  {
    route: ROUTES.DATA_EVENT_EDIT,
    component: <PAGES.EditEvent />,
  },

  {
    route: ROUTES.DATA_ATTR,
    component: <PAGES.Attractions />,
  },
  {
    route: ROUTES.DATA_ATTR_ADD,
    component: <PAGES.AddAttraction />,
  },
  {
    route: ROUTES.DATA_ATTR_VIEW,
    component: <PAGES.ViewAttraction />,
  },
  {
    route: ROUTES.DATA_ATTR_EDIT,
    component: <PAGES.EditAttraction />,
  },

  {
    route: ROUTES.DATA_BEST,
    component: <PAGES.BestOfAfrica />,
  },
  {
    route: ROUTES.DATA_BEST_ADD,
    component: <PAGES.AddBestOfAfrica />,
  },
  {
    route: ROUTES.DATA_BEST_VIEW,
    component: <PAGES.ViewBestOfAfrica />,
  },
  {
    route: ROUTES.DATA_BEST_EDIT,
    component: <PAGES.EditBestOfAfrica />,
  },

  {
    route: ROUTES.DATA_CINEMA,
    component: <PAGES.Cinema />,
  },
  {
    route: ROUTES.DATA_CINEMA_ADD,
    component: <PAGES.AddCinema />,
  },
  {
    route: ROUTES.DATA_CINEMA_VIEW,
    component: <PAGES.ViewCinema />,
  },
  {
    route: ROUTES.DATA_CINEMA_EDIT,
    component: <PAGES.EditCinema />,
  },

  {
    route: ROUTES.DATA_MUSIC,
    component: <PAGES.Music />,
  },
  {
    route: ROUTES.DATA_MUSIC_ADD,
    component: <PAGES.AddMusic />,
  },
  {
    route: ROUTES.DATA_MUSIC_VIEW,
    component: <PAGES.ViewMusic />,
  },
  {
    route: ROUTES.DATA_MUSIC_EDIT,
    component: <PAGES.EditMusic />,
  },

  {
    route: ROUTES.DATA_NEWS,
    component: <PAGES.News />,
  },
  {
    route: ROUTES.DATA_NEWS_ADD,
    component: <PAGES.AddNews />,
  },
  {
    route: ROUTES.DATA_NEWS_VIEW,
    component: <PAGES.ViewNews />,
  },
  {
    route: ROUTES.DATA_NEWS_EDIT,
    component: <PAGES.EditNews />,
  },

  {
    route: ROUTES.DATA_SAFARI,
    component: <PAGES.Safari />,
  },
  {
    route: ROUTES.DATA_SAFARI_ADD,
    component: <PAGES.AddSafari />,
  },
  {
    route: ROUTES.DATA_SAFARI_VIEW,
    component: <PAGES.ViewSafari />,
  },
  {
    route: ROUTES.DATA_SAFARI_EDIT,
    component: <PAGES.EditSafari />,
  },

  {
    route: ROUTES.DATA_RESTAU,
    component: <PAGES.Restaurant />,
  },
  {
    route: ROUTES.DATA_RESTAU_ADD,
    component: <PAGES.AddRestaurant />,
  },
  {
    route: ROUTES.DATA_RESTAU_VIEW,
    component: <PAGES.ViewRestaurant />,
  },
  {
    route: ROUTES.DATA_RESTAU_EDIT,
    component: <PAGES.EditRestaurant />,
  },

  {
    route: ROUTES.DATA_TDO,
    component: <PAGES.ThingsToDo />,
  },
  {
    route: ROUTES.DATA_TDO_ADD,
    component: <PAGES.AddThingsToDo />,
  },
  {
    route: ROUTES.DATA_TDO_EDIT,
    component: <PAGES.EditThingsToDo />,
  },

  {
    route: ROUTES.DATA_PARTY,
    component: <PAGES.Party />,
  },
  {
    route: ROUTES.DATA_PARTY_ADD,
    component: <PAGES.AddParty />,
  },
  {
    route: ROUTES.DATA_PARTY_EDIT,
    component: <PAGES.EditParty />,
  },

  {
    route: ROUTES.DATA_NLIFE,
    component: <PAGES.Nightlife />,
  },
  {
    route: ROUTES.DATA_NLIFE_ADD,
    component: <PAGES.AddNightlife />,
  },
  {
    route: ROUTES.DATA_NLIFE_EDIT,
    component: <PAGES.EditNightlife />,
  },

  {
    route: ROUTES.DATA_CGUIDE,
    component: <PAGES.CityGuides />,
  },
  {
    route: ROUTES.DATA_CGUIDE_ADD,
    component: <PAGES.AddCityGuide />,
  },
  {
    route: ROUTES.DATA_CGUIDE_EDIT,
    component: <PAGES.EditCityGuide />,
  },

  {
    route: ROUTES.DATA_HOTEL,
    component: <PAGES.Hotels />,
  },
  {
    route: ROUTES.DATA_HOTEL_ADD,
    component: <PAGES.AddHotel />,
  },
  {
    route: ROUTES.DATA_HOTEL_EDIT,
    component: <PAGES.EditHotel />,
  },

  {
    route: ROUTES.DATA_CLASS,
    component: <PAGES.Classes />,
  },
  {
    route: ROUTES.DATA_CLASS_ADD,
    component: <PAGES.AddClass />,
  },
  {
    route: ROUTES.DATA_CLASS_EDIT,
    component: <PAGES.EditClass />,
  },

  {
    route: ROUTES.DATA_SHOP,
    component: <PAGES.Shopping />,
  },
  {
    route: ROUTES.DATA_SHOP_ADD,
    component: <PAGES.AddShopping />,
  },
  {
    route: ROUTES.DATA_SHOP_EDIT,
    component: <PAGES.EditShopping />,
  },

  {
    route: ROUTES.DATA_FOOD,
    component: <PAGES.Foods />,
  },
  {
    route: ROUTES.DATA_FOOD_ADD,
    component: <PAGES.AddFood />,
  },
  {
    route: ROUTES.DATA_FOOD_EDIT,
    component: <PAGES.EditFood />,
  },

  {
    route: ROUTES.DATA_BOOK,
    component: <PAGES.Books />,
  },
  {
    route: ROUTES.DATA_BOOK_ADD,
    component: <PAGES.AddBook />,
  },
  {
    route: ROUTES.DATA_BOOK_EDIT,
    component: <PAGES.EditBook />,
  },

  {
    route: ROUTES.DATA_NBOR,
    component: <PAGES.Neighborhoods />,
  },
  {
    route: ROUTES.DATA_NBOR_ADD,
    component: <PAGES.AddNeighborhood />,
  },
  {
    route: ROUTES.DATA_NBOR_EDIT,
    component: <PAGES.EditNeighborhood />,
  },

  {
    route: ROUTES.DATA_MINISTRY,
    component: <PAGES.Ministry />,
  },
  {
    route: ROUTES.DATA_MINISTRY_ADD,
    component: <PAGES.AddMinistry />,
  },
  {
    route: ROUTES.DATA_MINISTRY_EDIT,
    component: <PAGES.EditMinistry />,
  },

  {
    route: ROUTES.DATA_GA,
    component: <PAGES.GettingAround />,
  },
  {
    route: ROUTES.DATA_GA_ADD,
    component: <PAGES.AddGettingAround />,
  },
  {
    route: ROUTES.DATA_GA_EDIT,
    component: <PAGES.EditGettingAround />,
  },

  {
    route: ROUTES.DATA_ART,
    component: <PAGES.Arts />,
  },
  {
    route: ROUTES.DATA_ART_ADD,
    component: <PAGES.AddArt />,
  },
  {
    route: ROUTES.DATA_ART_EDIT,
    component: <PAGES.EditArt />,
  },

  {
    route: ROUTES.DATA_MOVIE,
    component: <PAGES.Movies />,
  },
  {
    route: ROUTES.DATA_MOVIE_ADD,
    component: <PAGES.AddMovie />,
  },
  {
    route: ROUTES.DATA_MOVIE_EDIT,
    component: <PAGES.EditMovie />,
  },

  {
    route: ROUTES.DATA_DANCE,
    component: <PAGES.Dances />,
  },
  {
    route: ROUTES.DATA_DANCE_ADD,
    component: <PAGES.AddDance />,
  },
  {
    route: ROUTES.DATA_DANCE_EDIT,
    component: <PAGES.EditDance />,
  },

  {
    route: ROUTES.DATA_THEATER,
    component: <PAGES.Theaters />,
  },
  {
    route: ROUTES.DATA_THEATER_ADD,
    component: <PAGES.AddTheater />,
  },
  {
    route: ROUTES.DATA_THEATER_EDIT,
    component: <PAGES.EditTheater />,
  },

  {
    route: ROUTES.DATA_TIPS,
    component: <PAGES.TipsAndAdvice />,
  },
  {
    route: ROUTES.DATA_TIPS_ADD,
    component: <PAGES.AddTipsAndAdvice />,
  },
  {
    route: ROUTES.DATA_TIPS_EDIT,
    component: <PAGES.EditTipsAndAdvice />,
  },

  {
    route: ROUTES.DATA_MUSEUM,
    component: <PAGES.Museums />,
  },
  {
    route: ROUTES.DATA_MUSEUM_ADD,
    component: <PAGES.AddMuseum />,
  },
  {
    route: ROUTES.DATA_MUSEUM_EDIT,
    component: <PAGES.EditMuseum />,
  },

  {
    route: ROUTES.DATA_TEAM,
    component: <PAGES.Team />,
  },
  {
    route: ROUTES.DATA_TEAM_ADD,
    component: <PAGES.AddTeam />,
  },
  {
    route: ROUTES.DATA_TEAM_EDIT,
    component: <PAGES.EditTeam />,
  },
  {
    route: ROUTES.DATA_TO,
    component: <PAGES.TradeOrganization />,
  },
  {
    route: ROUTES.DATA_TO_ADD,
    component: <PAGES.AddTradeOrganization />,
  },
  {
    route: ROUTES.DATA_TO_EDIT,
    component: <PAGES.EditTradeOrganization />,
  },
  {
    route: ROUTES.DATA_RBLOCKS,
    component: <PAGES.RegionalBlocks />,
  },
  {
    route: ROUTES.DATA_RBLOCKS_ADD,
    component: <PAGES.AddRegionalBlocks />,
  },
  {
    route: ROUTES.DATA_RBLOCKS_EDIT,
    component: <PAGES.EditRegionalBlocks />,
  },
  {
    route: ROUTES.DATA_PIMAGE,
    component: <PAGES.PlugImage />,
  },
  {
    route: ROUTES.DATA_PIMAGE_ADD,
    component: <PAGES.AddPlugImage />,
  },
  {
    route: ROUTES.DATA_PIMAGE_EDIT,
    component: <PAGES.EditPlugImage />,
  },

  {
    route: ROUTES.DATA_CLIMATE,
    component: <PAGES.Climate />,
  },
  {
    route: ROUTES.DATA_CLIMATE_ADD,
    component: <PAGES.AddClimate />,
  },
  {
    route: ROUTES.DATA_CLIMATE_EDIT,
    component: <PAGES.EditClimate />,
  },

  {
    route: ROUTES.DATA_EGROUP,
    component: <PAGES.EthnicGroup />,
  },
  {
    route: ROUTES.DATA_EGROUP_ADD,
    component: <PAGES.AddEthnicGroup />,
  },
  {
    route: ROUTES.DATA_EGROUP_EDIT,
    component: <PAGES.EditEthnicGroup />,
  },

  {
    route: ROUTES.DATA_LANG,
    component: <PAGES.Language />,
  },
  {
    route: ROUTES.DATA_LANG_ADD,
    component: <PAGES.AddLanguage />,
  },
  {
    route: ROUTES.DATA_LANG_EDIT,
    component: <PAGES.EditLanguage />,
  },

  {
    route: ROUTES.DATA_REL,
    component: <PAGES.Religion />,
  },
  {
    route: ROUTES.DATA_REL_ADD,
    component: <PAGES.AddReligion />,
  },
  {
    route: ROUTES.DATA_REL_EDIT,
    component: <PAGES.EditReligion />,
  },

  {
    route: ROUTES.DATA_AGENT,
    component: <PAGES.Agent />,
  },
  {
    route: ROUTES.DATA_AGENT_ADD,
    component: <PAGES.AddAgent />,
  },
  {
    route: ROUTES.DATA_AGENT_EDIT,
    component: <PAGES.EditAgent />,
  },

  {
    route: ROUTES.DATA_ADMIN,
    component: <PAGES.Admin />,
  },
  {
    route: ROUTES.DATA_ADMIN_ADD,
    component: <PAGES.AddAdmin />,
  },
  {
    route: ROUTES.DATA_ADMIN_EDIT,
    component: <PAGES.EditAdmin />,
  },

  {
    route: ROUTES.DATA_USER,
    component: <PAGES.User />,
  },
  {
    route: ROUTES.DATA_USER_ADD,
    component: <PAGES.AddUser />,
  },
  {
    route: ROUTES.DATA_USER_EDIT,
    component: <PAGES.EditUser />,
  },

  {
    route: ROUTES.DATA_TOUR,
    component: <PAGES.TourPackage />,
  },
  {
    route: ROUTES.DATA_TOUR_ADD,
    component: <PAGES.AddTourPackage />,
  },
  {
    route: ROUTES.DATA_TOUR_EDIT,
    component: <PAGES.EditTourPackage />,
  },

  {
    route: ROUTES.DATA_PROD,
    component: <PAGES.Product />,
  },
  {
    route: ROUTES.DATA_PROD_ADD,
    component: <PAGES.AddProduct />,
  },
  {
    route: ROUTES.DATA_PROD_EDIT,
    component: <PAGES.EditProduct />,
  },

  {
    route: ROUTES.DATA_BANNER,
    component: <PAGES.Banner />,
  },
  {
    route: ROUTES.DATA_BANNER_ADD,
    component: <PAGES.AddBanner />,
  },
  {
    route: ROUTES.DATA_BANNER_EDIT,
    component: <PAGES.EditBanner />,
  },

  {
    route: ROUTES.DATA_PAGE,
    component: <PAGES.Page />,
  },
  {
    route: ROUTES.DATA_PAGE_ADD,
    component: <PAGES.AddPage />,
  },
  {
    route: ROUTES.DATA_PAGE_EDIT,
    component: <PAGES.EditPage />,
  },

  {
    route: ROUTES.DATA_NEWSLETTER,
    component: <PAGES.Newsletter />,
  },
  {
    route: ROUTES.DATA_FOOTER,
    component: <PAGES.Footer />,
  },
  {
    route: ROUTES.DATA_FOOTER_EDIT,
    component: <PAGES.EditFooter />,
  },
  {
    route: ROUTES.DATA_TERMS,
    component: <PAGES.Terms />,
  },
  {
    route: ROUTES.DATA_TERMS_EDIT,
    component: <PAGES.EditTerms />,
  },
  {
    route: ROUTES.DATA_PRIVACY,
    component: <PAGES.Privacy />,
  },
  {
    route: ROUTES.DATA_PRIVACY_EDIT,
    component: <PAGES.EditPrivacy />,
  },
  {
    route: ROUTES.DATA_SERVICE,
    component: <PAGES.Service />,
  },
  {
    route: ROUTES.DATA_SERVICE_EDIT,
    component: <PAGES.EditService />,
  },

  {
    route: ROUTES.DATA_CONTACT,
    component: <PAGES.Contact />,
  },

  {
    route: ROUTES.DATA_ABOUT,
    component: <PAGES.About />,
  },
  {
    route: ROUTES.DATA_ABOUT_EDIT,
    component: <PAGES.EditAbout />,
  },

  {
    route: ROUTES.PARTNER,
    component: <PAGES.Partner />,
  },
  {
    route: ROUTES.PARTNER_ADD,
    component: <PAGES.AddPartner />,
  },
  {
    route: ROUTES.PARTNER_EDIT,
    component: <PAGES.EditPartner />,
  },

  {
    route: ROUTES.DATA_BLOG,
    component: <PAGES.Blog />,
  },
  {
    route: ROUTES.DATA_BLOG_ADD,
    component: <PAGES.AddBlog />,
  },
  {
    route: ROUTES.DATA_BLOG_EDIT,
    component: <PAGES.EditBlog />,
  },
  {
    route: ROUTES.DATA_EMAIL,
    component: <PAGES.EmailTemplates />,
  },
  {
    route: ROUTES.DATA_EMAIL_EDIT,
    component: <PAGES.EditEmailTemplates />,
  },
  {
    route: ROUTES.CONTENT_STATS,
    component: <PAGES.ContentStats />,
  },
  {
    route: ROUTES.ADMINS_STATS,
    component: <PAGES.AdminsStats />,
  },
  {
    route: ROUTES.DATA_AD,
    component: <PAGES.DoseeAds />,
  },
  {
    route: ROUTES.DATA_AD_ADD,
    component: <PAGES.AddDoseeAd />,
  },
  {
    route: ROUTES.DATA_AD_EDIT,
    component: <PAGES.EditDoseeAd />,
  },
  {
    route: ROUTES.COMPANY,
    component: <PAGES.Company />,
  },
  {
    route: ROUTES.COMPANY_ADD,
    component: <PAGES.AddCompany />,
  },
  {
    route: ROUTES.COMPANY_EDIT,
    component: <PAGES.EditCompany />,
  },
  {
    route: ROUTES.COMPANY_VIEW,
    component: <PAGES.ViewCompany />,
  },
  {
    route: ROUTES.COMPANY_DASHBOARD,
    component: <PAGES.CompanyDashboard />,
  },
  {
    route: ROUTES.SEARCH,
    component: <PAGES.Search />,
  },
  {
    route: ROUTES.PASSWORD,
    component: <PAGES.Password />,
  },
  {
    route: ROUTES.PAYMENT,
    component: <PAGES.Payment />,
  },
  {
    route: ROUTES.CURRENCY,
    component: <PAGES.Currency />,
  },
  {
    route: ROUTES.CURRENCY_ADD,
    component: <PAGES.AddCurrency />,
  },
  {
    route: ROUTES.CURRENCY_EDIT,
    component: <PAGES.EditCurrency />,
  },
  {
    route: ROUTES.DATA_COMPANY,
    component: <PAGES.DataCompany />,
  },
  {
    route: ROUTES.DATA_COMPANY_ADD,
    component: <PAGES.AddDataCompany />,
  },
  {
    route: ROUTES.DATA_COMPANY_EDIT,
    component: <PAGES.EditDataCompany />,
  },
  {
    route: ROUTES.DATA_EMBASSY,
    component: <PAGES.Embassy />,
  },
  {
    route: ROUTES.DATA_EMBASSY_ADD,
    component: <PAGES.AddEmbassy />,
  },
  {
    route: ROUTES.DATA_EMBASSY_EDIT,
    component: <PAGES.EditEmbassy />,
  },
  {
    route: ROUTES.GENERATED_EVENTS,
    component: <PAGES.GeneratedDataEvents />,
  },
  {
    route: "*",
    component: <PAGES.PageNotFound />,
  },
];
