import {
  codeOk,
  Responses,
  emptyFieldMessage,
  invalidFieldMessage,
} from '../../middlewares/index';

export const validateFields = (fullname, phone, email) => {
  if (fullname === null || fullname === '' || fullname === undefined) {
    return Responses(true, 400, emptyFieldMessage('Full Name'));
  } else if (phone === null || phone === '' || phone === undefined) {
    return Responses(true, 400, emptyFieldMessage('Phone'));
  } else if (email === null || email === '' || email === undefined) {
    return Responses(true, 400, emptyFieldMessage('Email'));
  } else {
    return Responses(false, codeOk, 'validation passed');
  }
};

export const validatePassword = (password) => {
  if (
    password === null ||
    password === undefined ||
    password === '' ||
    password.toString().length < 6
  ) {
    return Responses(true, 400, invalidFieldMessage('Password'));
  } else {
    return Responses(false, codeOk, 'validation passed');
  }
};
