import React from "react";
import { FaTimes } from "react-icons/fa";

const MessageBox = ({ error, success, onClick, message }) => {
  if (!error && !success) return null;
  return (
    <div
      onClick={onClick}
      className={`flex justify-between items-center py-4 w-full text-white h-full cursor-pointer ${
        success ? " bg-green-400 " : " bg-red-800"
      }`}
    >
      <div className="flex flex-col items-start text-xs font-bold justify-start px-4 w-full h-full">
        <p>{success ? "SUCCESS" : "ERROR"}</p>
        <p className="capitalize">{message}</p>
      </div>
      <button
        onClick={onClick}
        type="button"
        className="w-auto text-4xl px-2 border-l-2 border-white h-full"
      >
        <FaTimes className="hover:text-gray-400" />
      </button>
    </div>
  );
};

export default MessageBox;
